import { RESTAURANT_VENDOR_STATS_FAIL, RESTAURANT_VENDOR_STATS_REQUEST, RESTAURANT_VENDOR_STATS_SUCCESS } from "../constants/webOrderConstants";

const initialState = {
    stats: {
        newOrders: 0,
        pendingDelivery: 0,
        delivered: 0,
        assigned: 0,
        paymentMethods: {},
    },
    loading: false,
    error: null,
};

export const restaurantVendorStatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESTAURANT_VENDOR_STATS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null, // Reset error when making a new request
            };
        case RESTAURANT_VENDOR_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                stats: action.payload,
            };
        case RESTAURANT_VENDOR_STATS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
