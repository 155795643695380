import {
    START_LOADING,
    CREATE_SECTION_RW,
    GET_SECTIONS_RW,
    END_LOADING,
    UPDATE_SECTION_RW,
    DELETE_SECTION_REQUEST_RW,
    DELETE_SECTION_SUCCESS_RW,
    DELETE_SECTION_FAILURE_RW,
    GET_RETAILER_WHOLESALERS,
} from '../constants/actionTypes.js';

const initialState = {
    sectionsRW: [],
    retailersWholesalers: [],
    isLoading: false,
    error: null,
};

export const retailerWholesalerSectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, isLoading: true };
        case CREATE_SECTION_RW:
            return { ...state, sectionsRW: [...state.sectionsRW, action.payload], isLoading: false };
        case GET_SECTIONS_RW:
            return { ...state, sectionsRW: action.payload, isLoading: false };
        case UPDATE_SECTION_RW:
            return {
                ...state,
                sectionsRW: state.sectionsRW.map((section) =>
                    section._id === action.payload._id ? action.payload : section
                ),
                isLoading: false,
            };
        case DELETE_SECTION_REQUEST_RW:
            return { ...state, isLoading: true };
        case DELETE_SECTION_SUCCESS_RW:
            return {
                ...state,
                sectionsRW: state.sectionsRW.filter((section) => section._id !== action.payload),
                isLoading: false,
            };
        case DELETE_SECTION_FAILURE_RW:
            return { ...state, error: action.payload, isLoading: false };
        case GET_RETAILER_WHOLESALERS:
            return { ...state, retailersWholesalers: action.payload, isLoading: false }; // Add this case
        case END_LOADING:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
