export const convertTransparentToSolidColor = async (
  file,
  backgroundColor = "white"
) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0);

      canvas.toBlob((blob) => {
        resolve(
          new File([blob], file.name, {
            type: "image/png",
            lastModified: file.lastModified,
          })
        );
      }, "image/png");
    };
  });
};
