import {
  CREATE_SHIPPING_COST,
  DELETE_SHIPPING_COST,
  END_LOADING,
  FETCH_SHIPPING_COST,
  FETCH_SHIPPING_COSTS,
  RESET_SHIPPING_COST,
  START_LOADING,
  UPDATE_SHIPPING_COST,
} from "../constants/shippingCostsConstants";

export const getShippingCostsReducer = (
  state = {
    shippingCosts: [],
    shippingCost: {},
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, loading: true };

    case CREATE_SHIPPING_COST:
      return {
        ...state,
        loading: false,
        shippingCosts: [...state.shippingCosts, action.payload],
      };
    case UPDATE_SHIPPING_COST:
      return {
        ...state,
        loading: false,
        shippingCosts: state.shippingCosts.map((shippingCost) =>
          shippingCost._id === action.payload._id
            ? action.payload
            : shippingCost
        ),
      };
    case RESET_SHIPPING_COST:
      return {
        ...state,
        loading: false,
        shippingCosts: [],
      };

    case FETCH_SHIPPING_COSTS:
      return {
        ...state,
        loading: false,
        shippingCosts: action.payload,
      };

    case FETCH_SHIPPING_COST:
      return {
        ...state,
        loading: false,
        shippingCost: action.payload.shippingCost,
      };

    case DELETE_SHIPPING_COST:
      return {
        ...state,
        loading: false,
        shippingCosts: state.shippingCosts.filter(
          (shippingCost) => shippingCost._id !== action.payload
        ),
      };
    case END_LOADING:
      return { ...state, loading: false };

    default:
      return state;
  }
};
