import {
  START_LOADING_RIDER,
  CREATE_RIDER,
  END_LOADING_RIDER,
  DELETE_RIDER,
  FETCH_RESTAURANT_RIDERS,
  CREATE_RESTAURANT_RIDER,
  UPDATE_RESTAURANT_RIDER,
  DELETE_RESTAURANT_RIDER,
  FETCH_RETAILER_RIDERS,
  CREATE_RETAILER_RIDER,
  UPDATE_RETAILER_RIDER,
  DELETE_RETAILER_RIDER,
  UPDATE_RESTAURANT_RIDER_STATUS,
  UPDATE_RETAILER_RIDER_STATUS,
  FETCH_ALL_RIDERS,
  UPDATE_RIDER_STATUS,
  UPDATE_RIDER,
  // CREATE_RIDER_FAIL,
} from "../constants/ridersConstant.js";
import { toast } from "react-toastify";
import * as api from "../api/index.js";

// Create rider by super admin
export const createRider = (riderData, creatorId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_RIDER });
    const { data } = await api.createRiderApi(riderData, creatorId);

    dispatch({ type: CREATE_RIDER, payload: data.result });
    toast.success("Rider created successfully");
  } catch (error) {
    toast.error(error.response.data.message);
  } finally {
    dispatch({ type: END_LOADING_RIDER });
  }
};

// Get All Riders by SuperAdmin
export const getAllRiders = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_RIDER });
    const { data } = await api.getAllRidersApi();
    dispatch({ type: FETCH_ALL_RIDERS, payload: data });
    dispatch({ type: END_LOADING_RIDER });
  } catch (error) {
    console.error("Error fetching all riders:", error);
    dispatch({ type: END_LOADING_RIDER });
  }
};

export const updateRiderStatusSuperAdmin = (statusUpdate) => ({
  type: UPDATE_RIDER_STATUS,
  payload: statusUpdate
});

// Update rider by super admin
export const updateRider = (riderId, riderData) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_RIDER });
    const { data } = await api.updateRiderApi(riderId, riderData);
    dispatch({ type: UPDATE_RIDER, payload: data });
    toast.success("Rider updated successfully");
  } catch (error) {
    console.error("Error updating rider:", error);
    toast.error(error.response?.data?.message || "Error updating rider");
  } finally {
    dispatch({ type: END_LOADING_RIDER });
  }
};

// Delete a rider by super admin
export const deleteRider = (riderId, staff_id) => async (dispatch) => {
  try {
    const { data } = await api.deleteRiderApi(riderId, staff_id);
    dispatch({ type: DELETE_RIDER, payload: riderId });
    toast.success(data.message);
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

// Fetch Riders for a Restaurant Vendor
export const getRestaurantRiders = (vendorId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_RIDER });
    const { data } = await api.getRestaurantRidersApi(vendorId);
    dispatch({ type: FETCH_RESTAURANT_RIDERS, payload: data });
  } catch (error) {
    toast.error(error.response);
  } finally {
    dispatch({ type: END_LOADING_RIDER });
  }
};

// Create Rider for a Restaurant Vendor
export const createRestaurantRider = (riderData, vendorId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_RIDER });
    const { data } = await api.createRestaurantRiderApi(riderData, vendorId);
    dispatch({ type: CREATE_RESTAURANT_RIDER, payload: data.result });
    toast.success("Rider created successfully");
  } catch (error) {
    console.log(error.response)
    toast.error(error.response.data.message);
  } finally {
    dispatch({ type: END_LOADING_RIDER });
  }
};

// Update Rider for a Restaurant Vendor
export const updateRestaurantRider = (riderData, vendorId, riderId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_RIDER });
    const { data } = await api.updateRestaurantRiderApi(riderData, vendorId, riderId);
    dispatch({ type: UPDATE_RESTAURANT_RIDER, payload: data });
    toast.success("Rider updated successfully");
  } catch (error) {
    toast.error(error.response.data.message);
  } finally {
    dispatch({ type: END_LOADING_RIDER });
  }
};

// Delete Rider for a Restaurant Vendor
export const deleteRestaurantRider = (vendorId, riderId) => async (dispatch) => {
  try {
    const { data } = await api.deleteRestaurantRiderApi(vendorId, riderId);
    dispatch({ type: DELETE_RESTAURANT_RIDER, payload: riderId });
    toast.success(data.message);
  } catch (error) {
    toast.error(error.response.data.message);
  } finally {
    dispatch({ type: END_LOADING_RIDER });
  }
};

export const updateRiderStatus = (statusUpdate) => (dispatch) => {
  dispatch({
    type: UPDATE_RESTAURANT_RIDER_STATUS,
    payload: statusUpdate
  });
};

// Fetch Riders for a Retailer/Wholesaler Vendor
export const getRetailerRiders = (vendorId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_RIDER });
    const { data } = await api.getRetailerRidersApi(vendorId);
    dispatch({ type: FETCH_RETAILER_RIDERS, payload: data });
    dispatch({ type: END_LOADING_RIDER });
  } catch (error) {
    toast.error(error.response);
    dispatch({ type: END_LOADING_RIDER });
  }
};

// Create Rider for a Retailer/Wholesaler Vendor
export const createRetailerRider = (riderData, vendorId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_RIDER });
    const { data } = await api.createRetailerRiderApi(riderData, vendorId);
    dispatch({ type: CREATE_RETAILER_RIDER, payload: data.result });
    toast.success("Rider created successfully");
    dispatch({ type: END_LOADING_RIDER });
  } catch (error) {
    // console.log(error.response);
    toast.error(error.response.data.message);
    dispatch({ type: END_LOADING_RIDER });
  }
};

// Update Rider for a Retailer/Wholesaler Vendor
export const updateRetailerRider = (riderData, vendorId, riderId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_RIDER });
    const { data } = await api.updateRetailerRiderApi(riderData, vendorId, riderId);
    dispatch({ type: UPDATE_RETAILER_RIDER, payload: data });
    toast.success("Rider updated successfully");
    dispatch({ type: END_LOADING_RIDER });
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({ type: END_LOADING_RIDER });
  }
};

// Delete Rider for a Retailer/Wholesaler Vendor
export const deleteRetailerRider = (vendorId, riderId) => async (dispatch) => {
  try {
    const { data } = await api.deleteRetailerRiderApi(vendorId, riderId);
    dispatch({ type: DELETE_RETAILER_RIDER, payload: riderId });
    toast.success(data.message);
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

// Update Rider Status for a Retailer/Wholesaler Vendor
export const updateRetailerRiderStatus = (statusUpdate) => (dispatch) => {
  dispatch({
    type: UPDATE_RETAILER_RIDER_STATUS,
    payload: statusUpdate
  });
};
