// Import necessary components and libraries
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Table, Button } from 'reactstrap';
import { Modal } from "react-bootstrap";
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import retailerWholesalerPlaceholderImage from '../assets/img/restaurantimage_unavailable.png'; // Import your placeholder image here
import { getUserFromLocalStorage } from 'utilities/user.js';
import { fetchRetailersWholesalers, updateRetailerWholesalerProfile, deleteRetailerWholesaler } from '../actions/retailerWholesalerProfileActions.js';
import EditRetailerWholesalerForm from './EditRetailerWholesalerForm.js';

// Define custom styles for the switches
const useStyles = makeStyles(theme => ({
    switchBase: {
        color: theme.palette.secondary.main,
        '&$checked': {
            color: theme.palette.secondary.main,
        },
        '&$checked + $track': {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    promotedSwitchBase: {
        color: theme.palette.primary.main,
        '&$checked': {
            color: theme.palette.primary.main,
        },
        '&$checked + $track': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    checked: {},
    track: {},
}));

// Main component
const RetailersWholesalers = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { loading, retailersWholesalers, error } = useSelector((state) => state.retailerWholesalerProfile); // Adjusted state
    const [editingRetailerWholesaler, setEditingRetailerWholesaler] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [retailerWholesalerToDelete, setRetailerWholesalerToDelete] = useState(null);

    const user = getUserFromLocalStorage();
    const adminId = user?.result?._id;
    const isSuperAdmin = user?.result?.userType === 'LUSUKU STAFF' && user?.result?.user_role === 'SUPERUSER';

    useEffect(() => {
        dispatch(fetchRetailersWholesalers(adminId, isSuperAdmin));
    }, [dispatch, adminId, isSuperAdmin]);

    const handleRetry = () => {
        dispatch(fetchRetailersWholesalers(adminId, isSuperAdmin));
    };

    const handleDeleteRetailerWholesaler = (id) => {
        if (isSuperAdmin) {
            setRetailerWholesalerToDelete(id);
            setShowDeleteModal(true);
        }
    };

    const confirmDeleteRetailerWholesaler = () => {
        if (retailerWholesalerToDelete) {
            dispatch(deleteRetailerWholesaler(retailerWholesalerToDelete));
            setShowDeleteModal(false);
        }
    };

    const handleEditClick = (retailerWholesaler) => {
        setEditingRetailerWholesaler(retailerWholesaler);
    };

    const handleSave = (updatedRetailerWholesaler) => {
        dispatch(updateRetailerWholesalerProfile(updatedRetailerWholesaler.owner, updatedRetailerWholesaler));
        setEditingRetailerWholesaler(null);
    };

    const handleCancel = () => {
        setEditingRetailerWholesaler(null);
    };

    const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

    let content;

    if (loading) {
        content = <div>Loading...</div>;
    } else if (error) {
        content = (
            <div>
                {error}
                <Button onClick={handleRetry} color="primary" style={{ marginLeft: '10px' }}>
                    Retry!
                </Button>
            </div>
        );
    } else {
        content = (
            <Table responsive hover striped>
                <thead className="thead-dark">
                    <tr>
                        <th style={{ textAlign: 'left', verticalAlign: 'middle' }}>#</th>
                        <th style={{ textAlign: 'left', verticalAlign: 'middle' }}>Name</th>
                        <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Logo</th>
                        <th style={{ textAlign: 'left', verticalAlign: 'middle', width: '20%' }}>Location</th>
                        <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Activation</th>
                        <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Promoted</th>
                        <th style={{ textAlign: 'left', verticalAlign: 'middle' }}>ID</th>
                        <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {retailersWholesalers.map((retailerWholesaler, index) => (
                        <tr key={retailerWholesaler._id}>
                            <td style={{ textAlign: 'left', verticalAlign: 'middle', padding: '15px' }}>{index + 1}</td>
                            <td style={{ textAlign: 'left', verticalAlign: 'middle', padding: '15px' }}>{retailerWholesaler.name}</td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px' }}>
                                <img
                                    src={retailerWholesaler.imageUrl || retailerWholesalerPlaceholderImage}
                                    alt={retailerWholesaler.name || "retailerWholesalerPlaceholderImage"}
                                    style={{ width: '100px', height: 'auto', borderRadius: '25px' }}
                                    onError={(e) => e.target.src = retailerWholesalerPlaceholderImage} // Fallback to placeholder image if there's an error
                                />
                            </td>
                            <td style={{ textAlign: 'left', verticalAlign: 'middle', padding: '15px' }}>{retailerWholesaler.address}</td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px' }}>
                                <Switch
                                    checked={retailerWholesaler.isActive}
                                    disabled
                                    classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.checked,
                                        track: classes.track,
                                    }}
                                />
                            </td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px' }}>
                                <Switch
                                    checked={retailerWholesaler.promoted}
                                    disabled
                                    classes={{
                                        switchBase: classes.promotedSwitchBase,
                                        checked: classes.checked,
                                        track: classes.track,
                                    }}
                                />
                            </td>
                            <td style={{ textAlign: 'left', verticalAlign: 'middle', padding: '15px' }}>{retailerWholesaler._id}</td>
                            <td
                                style={{
                                    textAlign: 'left',
                                    verticalAlign: 'middle',
                                    padding: '15px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    gap: '5px',
                                    cursor: isSuperAdmin ? 'pointer' : 'not-allowed',
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Button
                                        color="warning"
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        onClick={() => handleEditClick(retailerWholesaler)}
                                    >
                                        <FaEdit style={{ marginRight: '5px' }} /> Edit
                                    </Button>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: !isSuperAdmin ? 'not-allowed' : 'pointer' }}>
                                    <Button
                                        color="danger"
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: !isSuperAdmin ? 'red' : 'white' }}
                                        onClick={() => handleDeleteRetailerWholesaler(retailerWholesaler.owner)}
                                        disabled={!isSuperAdmin}
                                    >
                                        <FaTrash style={{ marginRight: '5px', color: !isSuperAdmin ? 'red' : 'white' }} /> Delete
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }

    return (
        <div style={{ padding: '20px' }}>
            <Card style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <CardHeader style={{ backgroundColor: '#343a40', color: '#fff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', textAlign: 'center' }}>
                    <h2>Retailers & Wholesalers</h2>
                </CardHeader>
                <CardBody>
                    {editingRetailerWholesaler ? (
                        <EditRetailerWholesalerForm
                            retailerWholesaler={editingRetailerWholesaler}
                            onSave={handleSave}
                            onCancel={handleCancel}
                        />
                    ) : (
                        content
                    )}
                </CardBody>
            </Card>
            <Modal
                show={showDeleteModal}
                onHide={toggleDeleteModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Confirm Delete
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this retailer/wholesaler? This action can't be undone!
                </Modal.Body>
                <Modal.Footer>
                    <Button color="danger" onClick={confirmDeleteRetailerWholesaler}>Delete</Button>
                    <Button color="secondary" onClick={toggleDeleteModal}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default RetailersWholesalers;
