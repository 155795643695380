import { START_LOADING, END_LOADING } from "../constants/webOrderConstants";
import {
  FETCH_SI_UNITS,
  FETCH_SI_UNIT,
  DELETE_SI_UNIT,
  RESET_SI_UNIT,
  SI_UNIT_FETCH_FAIL,
  CREATE_SI_UNIT,
  UPDATE_SI_UNIT,
} from "../constants/siUnitConstants";

export const getSiUnitsReducer = (
  state = {
    siUnits: [],
    siUnit: {},
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, loading: true };

    case CREATE_SI_UNIT:
      return {
        ...state,
        loading: false,
        siUnits: [...state.siUnits, action.payload],
      };

    case FETCH_SI_UNITS:
      return {
        ...state,
        loading: false,
        siUnits: action.payload,
      };
    case FETCH_SI_UNIT:
      return {
        ...state,
        loading: false,
        siUnit: action.payload.siUnit,
      };

    case UPDATE_SI_UNIT:
      return {
        ...state,
        siUnits: state.siUnits.map((siUnit) =>
          siUnit._id === action.payload._id ? action.payload : siUnit
        ),
      };
    case DELETE_SI_UNIT:
      return {
        ...state,
        siUnits: state.siUnits.filter(
          (siUnit) => siUnit._id !== action.payload
        ),
      };

    case RESET_SI_UNIT:
      return {
        ...state,
        siUnit: {},
      };
    case SI_UNIT_FETCH_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case END_LOADING:
      return { ...state, loading: false };

    default:
      return state;
  }
};
