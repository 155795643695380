import {
  FETCH_ALL,
  FETCH_ALL_ORDERS_PAGINATED,
  FETCH_ALL_ORDERS_PAGINATED_SEARCH,
  FETCH_ALL_MOBILE_ORDERS_NO_LOADER,
  FETCH_VENDOR_ORDERS,
  // MOBILE_ORDER_DETAILS_REQUEST,
  // MOBILE_ORDER_DETAILS_FAIL,
  // MOBILE_ORDER_DETAILS_SUCCESS,
  UPDATE,
  MOBILE_ORDER_DELIVER_REQUEST,
  MOBILE_ORDER_DELIVER_FAIL,
  MOBILE_ORDER_DELIVER_RESET,
  MOBILE_ORDER_DELIVER_SUCCESS,
  FETCH_ORDER,
  DELETE,
  END_LOADING,
  START_LOADING,
  FETCH_ALL_VENDOR_ORDERS_PAGINATED_SEARCH,
} from "../constants/actionTypes";

export default (
  state = {
    // isLoading: true,
    mobileOrders: [],
    vendorOrders: [],
    mobileOrder: {},
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };

    case FETCH_VENDOR_ORDERS:
      return {
        ...state,
        vendorOrders: action.payload.data,
        isLoading: false,
      };
    case FETCH_ALL:
      return {
        ...state,
        mobileOrders: action.payload.data,
        isLoading: false,
      };
    case FETCH_ALL_ORDERS_PAGINATED:
      return {
        ...state,
        mobileOrders: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
        isLoading: false,
      };
    case FETCH_ALL_ORDERS_PAGINATED_SEARCH:
      return {
        ...state,
        mobileOrders: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
        isLoading: false,
      };
    case FETCH_ALL_VENDOR_ORDERS_PAGINATED_SEARCH:
      return {
        ...state,
        vendorOrders: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
        isLoading: false,
      };

    case FETCH_ALL_MOBILE_ORDERS_NO_LOADER:
      return {
        ...state,
        isLoading: false,
        mobileOrders: action.payload.data,
      };
    case FETCH_ORDER:
      return {
        ...state,
        mobileOrder: action.payload.mobileOrder,
        isLoading: false,
      };
    // case MOBILE_ORDER_DETAILS_SUCCESS:
    //   return { ...state, mobileOrder: action.payload.mobileOrder };
    case UPDATE:
      return {
        ...state,
        mobileOrders: state.mobileOrders.map((order) =>
          order._id === action.payload._id ? action.payload : order
        ),
        isLoading: false,
      };
    case DELETE:
      return {
        ...state,
        isLoading: false,
        mobileOrders: state.mobileOrders.filter(
          (order) => order._id !== action.payload
        ),
      };
    case END_LOADING:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

// export const mobileOrderDetailsReducer = (
//   state = {
//     loading: true,
//     // orderItems: {},
//     mobileOrder: [],
//     // shippingAddress: {},
//   },
//   action
// ) => {
//   switch (action.type) {
//     case START_LOADING:
//       return {
//         ...state,
//         loading: true,
//       };
//     case END_LOADING:
//       return {
//         ...state,
//         loading: false,
//       };
//     case MOBILE_ORDER_DETAILS_SUCCESS:
//       return {
//         ...state,
//         mobileOrder: action.payload,
//       };
//     case MOBILE_ORDER_DETAILS_FAIL:
//       return {
//         loading: false,
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };

export const mobileOrderDeliverReducer = (state = {}, action) => {
  switch (action.type) {
    case MOBILE_ORDER_DELIVER_REQUEST:
      return {
        loading: true,
      };
    case MOBILE_ORDER_DELIVER_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case MOBILE_ORDER_DELIVER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case MOBILE_ORDER_DELIVER_RESET:
      return {};
    default:
      return state;
  }
};
