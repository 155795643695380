import * as api from '../api';
import {
    RESTAURANT_VENDOR_ORDERS_REQUEST,
    RESTAURANT_VENDOR_ORDERS_SUCCESS,
    RESTAURANT_VENDOR_ORDERS_FAIL,
} from "../constants/webOrderConstants";


export const fetchRestaurantVendorOrders = (vendorId, page = 1, limit = 10) => async (dispatch) => {
    try {
        dispatch({ type: RESTAURANT_VENDOR_ORDERS_REQUEST });

        const { data } = await api.fetchRestaurantVendorOrdersApi(vendorId, page, limit);

        dispatch({
            type: RESTAURANT_VENDOR_ORDERS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: RESTAURANT_VENDOR_ORDERS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};
