import * as api from '../api';
import {
    FETCH_RETAILER_WHOLESALER_PROFILE,
    UPDATE_RETAILER_WHOLESALER_PROFILE,
    START_LOADING, END_LOADING,
    FETCH_RETAILER_WHOLESALER_PROFILE_ERROR,
    FETCH_RETAILERS_WHOLESALERS,
    DELETE_RETAILER_WHOLESALER
} from '../constants/actionTypes';

export const fetchRetailerWholesalerProfile = (vendorId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });

        const { data } = await api.apiFetchRetailerWholesalerProfile(vendorId);

        dispatch({ type: FETCH_RETAILER_WHOLESALER_PROFILE, payload: data });

        dispatch({ type: END_LOADING });
    } catch (error) {
        dispatch({ type: FETCH_RETAILER_WHOLESALER_PROFILE_ERROR, payload: error.message });
        dispatch({ type: END_LOADING });
    }
};
export const fetchRetailersWholesalers = (adminId, isSuperAdmin) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });

        const { data } = await api.apiFetchRetailersWholesalers(adminId, isSuperAdmin);

        dispatch({ type: FETCH_RETAILERS_WHOLESALERS, payload: data.retailersWholesalers });

        dispatch({ type: END_LOADING });
    } catch (error) {
        dispatch({ type: FETCH_RETAILER_WHOLESALER_PROFILE_ERROR, payload: error.message });
        dispatch({ type: END_LOADING });
    }
};

export const updateRetailerWholesalerProfile = (vendorId, formData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });

        const { data } = await api.apiUpdateRetailerWholesalerProfile(vendorId, formData);

        dispatch({ type: UPDATE_RETAILER_WHOLESALER_PROFILE, payload: data });

        dispatch({ type: END_LOADING });
    } catch (error) {
        dispatch({ type: FETCH_RETAILER_WHOLESALER_PROFILE_ERROR, payload: error.message });
        dispatch({ type: END_LOADING });
    }
};

export const deleteRetailerWholesaler = (id) => async (dispatch) => {
    try {
        await api.apiDeleteRetailerWholesaler(id);

        dispatch({ type: DELETE_RETAILER_WHOLESALER, payload: id });
    } catch (error) {
        console.error("Failed to delete retailer/wholesaler:", error.message);
    }
};
