import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { createRestaurantOptionGroup, fetchOptionsRestaurants } from '../actions/optionAction';
import { fetchRestaurants } from '../actions/restaurantActions.js';
import { getUserFromLocalStorage } from '../utilities/user';

const RestaurantOptionGroupForm = ({ show, handleClose }) => {
    const user = getUserFromLocalStorage();
    const dispatch = useDispatch();
    const history = useHistory();
    const options = useSelector(state => state.options.options);
    const restaurants = useSelector(state => state.restaurants.restaurants);
    const isLoading = useSelector(state => state.options.isLoading);

    const [optionGroupName, setOptionGroupName] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [minOptions, setMinOptions] = useState(0);
    const [maxOptions, setMaxOptions] = useState(0);
    const [selectedRestaurantId, setSelectedRestaurantId] = useState("");

    useEffect(() => {
        dispatch(fetchRestaurants(user?.result?._id));
    }, [dispatch]);

    useEffect(() => {
        if (show) {
            dispatch(fetchOptionsRestaurants(user?.result?._id));
        }
    }, [show, dispatch]);

    const handleOptionGroupSubmit = async (e) => {
        e.preventDefault();
        const optionGroupData = {
            name: optionGroupName,
            restaurantProductId: selectedRestaurantId,
            options: selectedOptions.map(option => ({
                value: option.value,
                mandatory: minOptions > 0 // Set mandatory based on minOptions
            })),
            minOptions,
            maxOptions,
            owner: user.result._id,
        };

        try {
            await dispatch(createRestaurantOptionGroup(optionGroupData));
            toast.success("Option group created successfully!");
            handleClose();

            // Navigate based on the user role
            if (user.result.user_role === 'SUPERUSER') {
                history.push('/admin/menu');
            } else {
                history.push('/admin/rest-menu');
            }
        } catch (error) {
            toast.error("Failed to create option group: " + (error.response?.data?.message || error.message));
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create New Option Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleOptionGroupSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Option Group Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter option group name"
                            value={optionGroupName}
                            onChange={e => setOptionGroupName(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="restaurantSelectOptionGroup">
                        <Form.Label>Select Restaurant</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedRestaurantId}
                            onChange={e => setSelectedRestaurantId(e.target.value)}
                        >
                            <option value="" disabled>Select a restaurant</option>
                            {restaurants.map((restaurant) => (
                                <option key={restaurant._id} value={restaurant._id}>
                                    {restaurant.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Options</Form.Label>
                        <Select
                            isMulti
                            options={options.map(option => ({
                                value: option._id,
                                label: option.name,
                            }))}
                            value={selectedOptions}
                            onChange={setSelectedOptions}
                            placeholder="Select options..."
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Min Options</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter minimum options"
                            value={minOptions}
                            onChange={e => setMinOptions(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Max Options</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter maximum options"
                            value={maxOptions}
                            onChange={e => setMaxOptions(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Button
                        variant="secondary"
                        onClick={handleClose}
                        disabled={isLoading}
                        style={{ color: isLoading ? 'gray' : 'white' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        className="ms-2"
                        disabled={isLoading}
                        style={{ color: isLoading ? 'gray' : 'white' }}
                    >
                        {isLoading ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-2"
                                    style={{ color: 'gray' }}
                                />
                                Creating Option Group...
                            </>
                        ) : (
                            'Create Option Group'
                        )}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default RestaurantOptionGroupForm;
