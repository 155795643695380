import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { createSiUnit, updateSiUnit } from "../../actions/siUnitActions";
import { RESET_SI_UNIT } from "constants/siUnitConstants";

toast.configure();

const SiUnitForm = () => {
  const [postData, setPostData] = useState({
    unit: "",
  });

  const clear = () => {
    setPostData({ unit: "" });
  };

  const siUnits = useSelector((state) => state.siUnits);
  const { siUnit, loading } = siUnits;

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!siUnit?._id) {
      dispatch(
        createSiUnit(
          { ...postData },
          history.push("/admin/units"),
          toast.success("Unit Added!"),
          clear()
        )
      );
      // clear();
    } else {
      dispatch(
        updateSiUnit(
          siUnit?._id,
          { ...postData },
          history.push("/units"),
          toast.success("Unit Updated!")
          // clear()
        )
      );
      dispatch({ type: RESET_SI_UNIT });
    }
  };

  useEffect(() => {
    if (siUnit?._id) {
      setPostData(siUnit);
    } else {
      clear();
    }
  }, [siUnit]);

  return (
    <>
      {loading ? (
        <Spinner
          animation="grow"
          variant="danger"
          style={{
            marginTop: "70px",
            top: "50%",
            left: "50%",
            position: "fixed",
          }}
        />
      ) : (
        <Col>
          <Form id="deliveryZone" autoComplete="off" onSubmit={handleSubmit}>
            <Card>
              <Card.Title className="pt-2 p-2" as="h4">
                Add || Edit SI Unit
              </Card.Title>
              <Card.Body className="pt-0">
                <Col md={6}>
                  <Form.Group>
                    <Card.Body>
                      <label>Unit</label>
                      <Form.Control
                        name="unit"
                        type="text"
                        placeholder="Add Unit..."
                        value={postData.unit}
                        onChange={(e) =>
                          setPostData({ ...postData, unit: e.target.value })
                        }
                      ></Form.Control>
                    </Card.Body>
                  </Form.Group>
                </Col>
              </Card.Body>

              <Card.Body>
                <Card.Footer className="text-right">
                  <Button
                    className="btn-fill pull-right"
                    variant="danger"
                    onClick={clear}
                    style={{ marginRight: "5px" }}
                  >
                    Clear
                  </Button>
                  <Button
                    className="btn-fill pull-right"
                    variant="info"
                    type="submit"
                    disabled={postData.unit === ""}
                  >
                    Submit
                  </Button>
                  <div className="clearfix"></div>
                </Card.Footer>
              </Card.Body>
            </Card>
          </Form>
        </Col>
      )}
    </>
  );
};

export default SiUnitForm;
