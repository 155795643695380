import { FETCH_SELLER_STATS, FETCH_SELLER_STATS_SUCCESS, FETCH_SELLER_STATS_ERROR } from "../constants/actionTypes";

const initialState = {
    loading: false,
    stats: {},
    error: null,
};

export const sellerStatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SELLER_STATS:
            return { ...state, loading: true };
        case FETCH_SELLER_STATS_SUCCESS:
            return { ...state, loading: false, stats: action.payload };
        case FETCH_SELLER_STATS_ERROR:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};
