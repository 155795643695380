import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";

import { createStaffProfile } from "../../actions/auth";
import { getUserFromLocalStorage } from "utilities/user";

const UserForm = () => {
  const user = getUserFromLocalStorage();

  const [postData, setPostData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    user_role: "",
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, confirmPassword } = postData;
    if (password !== confirmPassword) {
      toast.error("Passwords don't match!");
    } else {
      dispatch(
        createStaffProfile(
          {
            ...postData,
          },
          user?.result?._id
        )
      );
      history.push("/admin/users");
    }
  };
  return (
    <Container fluid>
      <Col md="6">
        <Card className="stacked-form">
          <Card.Header>
            <Card.Title as="h4">Create User</Card.Title>
          </Card.Header>
          <Card.Body>
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <Form.Group>
                <label>First Name</label>
                <Form.Control
                  placeholder="First  Name..."
                  name="firstName"
                  type="text"
                  value={postData.firstName}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      firstName: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group>
                <label>Last Name</label>
                <Form.Control
                  placeholder="Last Name..."
                  name="lastName"
                  type="text"
                  value={postData.lastName}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      lastName: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group>
                <label>Email Address</label>
                <Form.Control
                  placeholder="Enter email"
                  name="email"
                  type="email"
                  value={postData.email}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      email: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group>
                <label>Password</label>
                <Form.Control
                  placeholder="Enter password"
                  name="password"
                  type="password"
                  value={postData.password}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      password: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group>
                <label>Confirm Password</label>
                <Form.Control
                  placeholder="Confirm password"
                  name="confirmPassword"
                  type="password"
                  value={postData.confirmPassword}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      confirmPassword: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Col md="6">
                <h4 className="title">Assign Role</h4>
                <Row>
                  <Col md="10">
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="user_role"
                      value={postData.value}
                      onChange={(value) =>
                        setPostData({
                          ...postData,
                          user_role: value.value,
                        })
                      }
                      options={[
                        {
                          value: "ADMIN",
                          label: "Admin",
                        },
                        {
                          value: "SUPERVISOR",
                          label: "Supervisor",
                        },
                        {
                          value: "CREATOR",
                          label: "Creator",
                        },
                        // {
                        //   value: "VENDOR",
                        //   label: "Vendor",
                        // },
                      ]}
                      placeholder="Select Role"
                    />
                  </Col>
                </Row>
              </Col>
              <Card.Footer>
                <Button className="btn-behance" variant="info" type="submit">
                  Submit
                </Button>
              </Card.Footer>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Container>
  );
};

export default UserForm;
