import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import { Button, Spinner } from "react-bootstrap";
import { useInterval } from "hooks/useInterval";
import { useHistory } from "react-router";

const MomoModal = ({ open, setOpen }) => {
  const { client_id } = useSelector((state) => state.orderDetails.order);

  const paymentMethod = useSelector((state) => state.orderDetails.order);

  const clientID = client_id?._id;

  const [momoStatus, setMomoStatus] = useState("Processing...");
  const [checkPaymentTime, setCheckPaymentTime] = useState(null);
  // console.log(momoStatus, "momoStatus");

  const history = useHistory();

  // CHECKS FOR TRANSACTION STATUS

  useInterval(() => {
    if (
      momoStatus === "No Pending Momo Transactions" ||
      momoStatus === "Transaction Failed"
    ) {
      checkTransactionStatus();
    } else {
      checkTransactionStatus();
    }
  }, checkPaymentTime);

  useEffect(() => {
    if (paymentMethod?.paymentMethod === "MOMO") {
      if (clientID) {
        fetchMomoStatus();
        setCheckPaymentTime(5000);
      }
    }
  }, []);

  const fetchMomoStatus = async () => {
    const res = await fetch(`/momo/${clientID}`);
    const data = await res.json();
    // console.log(data.msg, "data");
    setMomoStatus(data.msg);
  };

  const checkTransactionStatus = () => {
    if (
      momoStatus === "Transaction Failed" ||
      momoStatus === "Transaction Successful"
    ) {
      setCheckPaymentTime(null);
    } else {
      fetchMomoStatus();
    }
  };

  // useEffect(() => {
  //   const timer = setInterval(fetchMomoStatus, 10000);
  //   return () => clearInterval(timer);
  // }, []);

  const proceedToManageOrder = () => {
    setOpen(false);
    fetchMomoStatus();
    history.push("/admin/paginatedOrdersTable");
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title text-center"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{
            backgroundColor: "#fafafa",
            color: "#000",
            fontSize: "1.5rem",
            fontWeight: "bold",
            textTransform: "uppercase",
            textAlign: "center",
            padding: "1rem",
            marginTop: "10",
          }}
        >
          Processing Momo Payment!
        </DialogTitle>
        {/* <DialogContentText>Processing Momo Payment</DialogContentText> */}
        {/* <Typography
          variant="h6"
          style={{
            // fontWeight: "bold",
            textTransform: "uppercase",
            textAlign: "center",
            padding: "1rem",
            color: "#1D438A",
          }}
        >
          Processing Momo Payment
        </Typography> */}
        {// momoStatus === "No Pending Momo Transactions" ||
        momoStatus === "Transaction Pending" && (
          <Spinner
            animation="grow"
            variant="primary"
            style={{
              margin: "auto",
              display: "block",
              width: "1rem",
              height: "1rem",
              borderRadius: "50%",
              color: "#1D438A",
              fontSize: "1.5rem",
              fontWeight: "bold",
              padding: "1rem",
            }}
          />
        )}

        <Divider />
        <DialogContentText className="mx-auto">
          <Typography
            // variant="h6"

            component={"span"}
            style={{
              // fontWeight: "bold",
              fontSize: "13px",
              textTransform: "uppercase",
              textAlign: "center",
              alignSelf: "center",
              justifySelf: "center",
              justifyContent: "center",

              padding: "1rem",
              color: "#1D438A",
            }}
          >
            Status: {momoStatus}
          </Typography>
        </DialogContentText>
        <DialogActions>
          <Button
            onClick={proceedToManageOrder}
            style={{
              backgroundColor: "#1D438A",
              color: "#fff",
              fontSize: "1.5rem",
              fontWeight: "bold",
              padding: "1rem",
            }}
          >
            Proceed to Manage Order
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MomoModal;
