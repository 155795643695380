export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";

export const PROCESS_ORDER_CREATE_REQUEST = "PROCESS_ORDER_CREATE_REQUEST";
export const PROCESS_ORDER_CREATE_SUCCESS = "PROCESS_ORDER_CREATE_SUCCESS";
export const PROCESS_ORDER_CREATE_FAIL = "PROCESS_ORDER_CREATE_FAIL";
export const PROCESS_ORDER_CREATE_RESET = "PROCESS_ORDER_CREATE_RESET";

export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";

export const CASH_ORDER_CREATE_REQUEST = "CASH_ORDER_CREATE_REQUEST";
export const CASH_ORDER_CREATE_SUCCESS = "CASH_ORDER_CREATE_SUCCESS";
export const CASH_ORDER_CREATE_FAIL = "CASH_ORDER_CREATE_FAIL";

// export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";

// export const UPDATE_ORDER_DETAILS = "UPDATE_ORDER_DETAILS";

export const UPDATE_DELIVERY_STATUS = "UPDATE_DELIVERY_STATUS";

export const VENDOR_ORDER_DETAILS_SUCCESS = "VENDOR_ORDER_DETAILS_SUCCESS";
export const VENDOR_ORDER_DETAILS_FAIL = "VENDOR_ORDER_DETAILS_FAIL";
export const UPDATE_DELIVERY_STATUS_COMPLETE =
  "UPDATE_DELIVERY_STATUS_COMPLETE";
export const RESET_UPDATE_DELIVERY_STATUS_COMPLETE =
  "RESET_UPDATE_DELIVERY_STATUS_COMPLETE";

export const UPDATE_OVERALL_ORDER_DELIVERY_STATUS =
  "UPDATE_OVERALL_ORDER_DELIVERY_STATUS";

export const MARK_ORDER_PAID = "MARK_ORDER_PAID";
export const MARK_ORDER_PAID_FAIL = "MARK_ORDER_PAID_FAIL";

export const CASH_ON_DELIVERY_ORDER_REQUEST = "ORDER_DETAILS_REQUEST";
export const CASH_ON_DELIVERY_ORDER_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const CASH_ON_DELIVERY_ORDER_FAIL = "ORDER_DETAILS_FAIL";

export const CASH_ON_DELIVERY_ORDER_REQUEST_NO_LOADER =
  "CASH_ON_DELIVERY_ORDER_REQUEST_NO_LOADER";
export const CASH_ON_DELIVERY_ORDER_SUCCESS_NO_LOADER =
  "CASH_ON_DELIVERY_ORDER_SUCCESS_NO_LOADER";
export const CASH_ON_DELIVERY_ORDER_FAIL_NO_LOADER =
  "CASH_ON_DELIVERY_ORDER_FAIL_NO_LOADER";

export const ORDER_PAY_REQUEST = "ORDER_PAY_REQUEST";
export const ORDER_PAY_SUCCESS = "ORDER_PAY_SUCCESS";
export const ORDER_PAY_FAIL = "ORDER_PAY_FAIL";
export const ORDER_PAY_RESET = "ORDER_PAY_RESET";

export const ORDER_LIST_MY_REQUEST = "ORDER_LIST_MY_REQUEST";
export const ORDER_LIST_MY_SUCCESS = "ORDER_LIST_MY_SUCCESS";
export const ORDER_LIST_MY_FAIL = "ORDER_LIST_MY_FAIL";
export const ORDER_LIST_MY_RESET = "ORDER_LIST_MY_RESET";

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";

// export const EDIT_ORDER_STATUS = "EDIT_ORDER_STATUS";
// export const ORDER_DELIVER_REQUEST = "ORDER_DELIVER_REQUEST";
// export const ORDER_DELIVER_SUCCESS = "ORDER_DELIVER_SUCCESS";
// export const ORDER_DELIVER_FAIL = "ORDER_DELIVER_FAIL";
// export const ORDER_DELIVER_RESET = "ORDER_DELIVER_RESET";

export const MOMO_REMOVE_SHIPPING_ADDRESS = "MOMO_REMOVE_SHIPPING_ADDRESS";
export const COD_REMOVE_SHIPPING_ADDRESS = "COD_REMOVE_SHIPPING_ADDRESS";

export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";

export const RESTAURANT_VENDOR_STATS_REQUEST = "RESTAURANT_VENDOR_STATS_REQUEST";
export const RESTAURANT_VENDOR_STATS_SUCCESS = "RESTAURANT_VENDOR_STATS_SUCCESS";
export const RESTAURANT_VENDOR_STATS_FAIL = "RESTAURANT_VENDOR_STATS_FAIL";

export const RESTAURANT_VENDOR_ORDERS_REQUEST = "RESTAURANT_VENDOR_ORDERS_REQUEST";
export const RESTAURANT_VENDOR_ORDERS_SUCCESS = "RESTAURANT_VENDOR_ORDERS_SUCCESS";
export const RESTAURANT_VENDOR_ORDERS_FAIL = "RESTAURANT_VENDOR_ORDERS_FAIL";

export const RETAILER_WHOLESALER_VENDOR_ORDERS_REQUEST = "RETAILER_WHOLESALER_VENDOR_ORDERS_REQUEST";
export const RETAILER_WHOLESALER_VENDOR_ORDERS_SUCCESS = "RETAILER_WHOLESALER_VENDOR_ORDERS_SUCCESS";
export const RETAILER_WHOLESALER_VENDOR_ORDERS_FAIL = "RETAILER_WHOLESALER_VENDOR_ORDERS_FAIL";

export const RETAILER_WHOLESALER_ORDER_DETAILS_REQUEST = "RETAILER_WHOLESALER_ORDER_DETAILS_REQUEST";
export const RETAILER_WHOLESALER_ORDER_DETAILS_SUCCESS = "RETAILER_WHOLESALER_ORDER_DETAILS_SUCCESS";
export const RETAILER_WHOLESALER_ORDER_DETAILS_FAIL = "RETAILER_WHOLESALER_ORDER_DETAILS_FAIL";
export const UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_REQUEST = "UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_REQUEST";
export const UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_SUCCESS = "UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_SUCCESS";
export const UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_FAIL = "UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_FAIL";

export const SET_ORDER_DATA_BY_RIDER = "SET_ORDER_DATA_BY_RIDER";