import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Alert,
  Form,
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  ProgressBar,
  Spinner,
} from "react-bootstrap";
import { MdAddAPhoto, MdWarning } from "react-icons/md";
import { Switch, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {
  fetchRetailerWholesalerSections,
  fetchRetailerWholesalers,
} from "../actions/retailerWholesalerSectionActions";
import { fetchRetailerWholesalerOptionGroups } from "../actions/retailerWholesalerOptionActions";
// import OptionGroupForm from './OptionsGroupForm';
import { createRetailerWholesalerProduct } from "../actions/retailerWholesalerProductActions";
import { getUserFromLocalStorage } from "../utilities/user.js";
import RetailerWholesalerOptionGroupForm from "./RetailerWholesalerOptionGroupForm";
import { getSiUnits } from "actions/siUnitActions";

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: "normal",
    fontSize: "12px",
    textTransform: "capitalize",
  },
}));

const CreateRetailerWholesalerProductForm = () => {
  const user = getUserFromLocalStorage();
  const vendorId = user?.result?._id;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const sections = useSelector(
    (state) => state.retailerWholesalerSections.sectionsRW
  );
  const retailersWholesalers = useSelector(
    (state) => state.retailerWholesalerSections.retailersWholesalers
  );
  const optionGroups = useSelector(
    (state) => state.retailerWholesalerOptions.optionGroups
  );

  const { siUnits, loading } = useSelector((state) => state.siUnits);

  const isMounted = useRef(true);

  useEffect(() => {
    dispatch(fetchRetailerWholesalerSections(vendorId));
    dispatch(fetchRetailerWholesalers(vendorId));
    dispatch(fetchRetailerWholesalerOptionGroups(vendorId));

    return () => {
      isMounted.current = false;
    };
  }, [dispatch]);

  //   Get Units
  useEffect(() => {
    dispatch(getSiUnits());
  }, []);

  useEffect(() => {
    const mappedUnits = siUnits.map((unit) => ({
      value: unit._id,
      label: unit.unit,
      name: unit.unit,
    }));
    setUnits(mappedUnits);
  }, [siUnits]);

  const initialSection = location.state?.sectionId || "";

  const [product, setProduct] = useState({
    name: "",
    description: "",
    section: initialSection,
    retailerWholesaler: "",
    price: 0.0,
    countInStock: 0,
    image: null,
    enabled: true,
    optionGroups: [],
    measurements: 1,
  });

  const [priceWarning, setPriceWarning] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [isFormatCorrect, setIsFormatCorrect] = useState(false);
  const [isSizeCorrect, setIsSizeCorrect] = useState(false);
  const [isResolutionCorrect, setIsResolutionCorrect] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([]);

  const [selectedOptionGroups, setSelectedOptionGroups] = useState([]);
  const [showOptionGroupModal, setShowOptionGroupModal] = useState(false);

  const [minimumOrderVolume, setMinimumOrderVolume] = useState(10);
  const [operationMode, setOperationMode] = useState("");

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [inputValue, setValue] = useState("");

  useEffect(() => {
    if (retailersWholesalers.length > 0) {
      setOperationMode(retailersWholesalers[0].operationMode);
    }
  }, [retailersWholesalers]);

  // Category Select
  const loadCategory = useCallback(async (inputValue) => {
    const res = await fetch("/category/get-all");
    const categories = await res.json();
    if (!inputValue) {
      return categories;
    }

    const filteredCategories = categories.filter((category) =>
      category.category.toLowerCase().includes(inputValue.toLowerCase())
    );

    return filteredCategories;
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "price") {
      if (parseFloat(value) < 0) {
        setPriceWarning("Please enter a Price higher than 0.");
        setProduct((prev) => ({ ...prev, [name]: "" }));
        return;
      } else {
        setPriceWarning("");
      }
    }

    if (name === "countInStock" && value < 1) {
      toast.error("Count in Stock cannot be less than 1");
      return;
    }

    setProduct({ ...product, [name]: value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setUploadError("No file was selected.");
      toast.error("No file was selected.");
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        if (!isMounted.current) return;

        setImagePreview(reader.result);
        setProduct((prev) => ({ ...prev, image: reader.result }));
        setShowPreviewModal(true);

        const validFormats = ["image/jpeg", "image/png"];
        const isCurrentFormatCorrect = validFormats.includes(file.type);
        const isCurrentSizeCorrect = file.size <= 10 * 1024 * 1024;
        const isCurrentResolutionCorrect =
          img.width >= 1000 && img.height >= 1000;

        setIsFormatCorrect(isCurrentFormatCorrect);
        setIsSizeCorrect(isCurrentSizeCorrect);
        setIsResolutionCorrect(isCurrentResolutionCorrect);

        if (
          !isCurrentFormatCorrect ||
          !isCurrentSizeCorrect ||
          !isCurrentResolutionCorrect
        ) {
          let errorMessage =
            "Please ensure the image meets all the requirements:";
          if (!isCurrentFormatCorrect)
            errorMessage += " Must be JPG or PNG format.";
          if (!isCurrentSizeCorrect)
            errorMessage += " Must be less than or equal to 10MB.";
          if (!isCurrentResolutionCorrect)
            errorMessage += " Must be at least 1000x1000 pixels.";
          setUploadError(errorMessage);
        } else {
          setUploadError(null);
        }
      };
      img.onerror = () => {
        if (!isMounted.current) return;

        setUploadError("There was an error processing your image.");
        setImagePreview(null);
        setShowPreviewModal(false);
        toast.error("There was an error processing your image.");
      };
      img.src = reader.result;
    };
    reader.onerror = () => {
      if (!isMounted.current) return;

      setUploadError("Failed to read the image file.");
      setImagePreview(null);
      setProduct((prev) => ({ ...prev, image: null }));
      setShowPreviewModal(false);
    };
    reader.readAsDataURL(file);
  };

  const uploadImageToCloudinary = async (file) => {
    if (!file) {
      toast.error("No image file selected!");
      return null;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "lusuku-clou");
    formData.append("cloud_name", "MM-iCT");
    formData.append("folder", "lusuku_menus");

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLOUDINARY_URL,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            if (!isMounted.current) return;
            setUploadProgress(percentCompleted);
          },
        }
      );
      const secureUrl = response.data.secure_url;
      if (!isMounted.current) return null;

      setProduct((prevProduct) => ({
        ...prevProduct,
        image: secureUrl,
      }));
      setUploading(false);
      setShowPreviewModal(false);
      return secureUrl;
    } catch (error) {
      if (!isMounted.current) return null;

      console.error("Error uploading the image:", error);
      toast.error(
        `Failed to upload image: ${
          error.response?.data?.message || error.message
        }`
      );
      setUploading(false);
      setShowPreviewModal(false);
      return null;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setUploading(true);
    setSubmitting(true);

    let uploadedImageUrl = product.image;
    if (
      uploadedImageUrl &&
      typeof uploadedImageUrl === "string" &&
      uploadedImageUrl.startsWith("data:")
    ) {
      uploadedImageUrl = await uploadImageToCloudinary(uploadedImageUrl);
      if (!uploadedImageUrl) {
        if (isMounted.current) {
          toast.error("Image upload failed, please try again.");
          setUploading(false);
          setSubmitting(false);
        }
        return;
      }
    } else if (!uploadedImageUrl) {
      if (isMounted.current) {
        toast.error("Please add an image to the product.");
        setUploading(false);
        setSubmitting(false);
      }
      return;
    }

    const retailerWholesalerProductData = {
      name: product.name,
      description: product.description,
      sectionId: product.section,
      retailerWholesalerId: product.retailerWholesaler,
      price: product.price,
      image: uploadedImageUrl,
      countInStock: product.countInStock,
      enabled: product.enabled,
      optionGroups: selectedOptionGroups.map((group) => group.value),
      unit_id: selectedUnit?.value,
      measurements: product.measurements,
      category: selectedCategory,
    };
    if (operationMode === "WHOLESALER") {
      retailerWholesalerProductData.minimumOrderVolume = minimumOrderVolume;
    }

    try {
      await dispatch(
        createRetailerWholesalerProduct(
          retailerWholesalerProductData,
          user?.result?._id
        )
      );
      if (isMounted.current) {
        setProduct({
          name: "",
          description: "",
          section: "",
          retailerWholesaler: "",
          price: 0.0,
          countInStock: 0,
          image: null,
          enabled: true,
          optionGroups: [],
          //   minimumOrderVolume: 10,
          measurements: 1,
        });
        setSelectedOptionGroups([]);
        setSelectedUnit([]);
        setMinimumOrderVolume(10);
        setShowConfirmModal(false);
        history.push("/admin/catalog");
      }
    } catch (error) {
      if (isMounted.current) {
        toast.error("Failed to save product, please try again.");
        setSubmitting(false);
      }
    } finally {
      if (isMounted.current) {
        setUploading(false);
        setSubmitting(false);
      }
    }
  };

  const handleDiscardChanges = () => {
    setProduct({
      name: "",
      description: "",
      section: "",
      retailerWholesaler: "",
      price: 0.0,
      countInStock: 0,
      image: null,
      enabled: true,
    });
    setShowConfirmModal(false);
    history.push("/admin/catalog");
  };

  const handleCloseDiscardModal = () => {
    setShowConfirmModal(false);
  };

  const handleOptionGroupSave = (newOptionGroup) => {
    if (newOptionGroup && newOptionGroup._id) {
      setSelectedOptionGroups((prevGroups) => [
        ...prevGroups,
        {
          value: newOptionGroup._id,
          label: `${newOptionGroup.name} (Min: ${newOptionGroup.minOptions}, Max: ${newOptionGroup.maxOptions})`,
        },
      ]);
    } else {
      console.error("Invalid option group data:", newOptionGroup);
    }
  };

  // handle Unit Selection
  const handleUnitChange = (value) => {
    setSelectedUnit(value);
  };

  // handle selection
  const handleCategoryInputChange = (value) => {
    setValue(value);
  };

  // handle category Selection
  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={12}>
          <Card style={{ padding: "30px" }}>
            <Card.Title as="h3">Create Product</Card.Title>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>1. Product Image</Form.Label>
                  <div className="d-flex">
                    <Card style={{ width: "18rem" }}>
                      <Card.Body
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{ minHeight: "200px" }}
                      >
                        {product.image ? (
                          <img
                            src={product.image}
                            alt="Product"
                            style={{ maxHeight: "200px", maxWidth: "100%" }}
                          />
                        ) : (
                          <Card.Text>
                            <Button
                              variant="primary"
                              as="label"
                              className="d-flex align-items-center justify-content-center"
                              style={{ color: "#ffff" }}
                              disabled={submitting}
                            >
                              <MdAddAPhoto size={24} className="me-2" />
                              Add Image
                              <Form.Control
                                type="file"
                                hidden
                                accept=".jpg, .png, .jpeg"
                                onChange={handleImageChange}
                                id="file-input"
                                disabled={submitting}
                              />
                            </Button>
                          </Card.Text>
                        )}
                      </Card.Body>
                    </Card>
                    <div className="ms-3">
                      <Form.Text className="text-muted">
                        <p style={{ marginBottom: "0" }}>
                          <strong>File requirements: </strong>
                        </p>
                        <p style={{ fontStyle: "italic", fontSize: "11px" }}>
                          JPG or PNG up to 10 MB. Minimum size of 1000x1000
                          pixels.
                        </p>
                        {product.image && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Button
                              variant="link"
                              as="label"
                              style={{
                                color: "green",
                                textDecoration: "none",
                                textTransform: "capitalize",
                                fontSize: "12px",
                              }}
                              disabled={submitting}
                            >
                              Replace Image
                              <Form.Control
                                type="file"
                                hidden
                                accept=".jpg, .png, .jpeg"
                                onChange={handleImageChange}
                                id="file-input"
                                disabled={submitting}
                              />
                            </Button>
                            <span
                              style={{
                                borderLeft: "1px solid #ccc",
                                height: "30px",
                              }}
                            ></span>
                            <Button
                              variant="link"
                              style={{
                                color: "red",
                                textDecoration: "none",
                                fontSize: "12px",
                              }}
                              onClick={() =>
                                setProduct({ ...product, image: null })
                              }
                              disabled={submitting}
                            >
                              Remove Image
                            </Button>
                          </div>
                        )}
                      </Form.Text>
                    </div>
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label>2. Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={product.name}
                    onChange={handleInputChange}
                    required
                    placeholder="Product name..."
                    disabled={submitting}
                  />
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label>3. Description (optional)</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    value={product.description}
                    onChange={handleInputChange}
                    style={{ height: "100px" }}
                    placeholder="Enter a brief description of the product..."
                    disabled={submitting}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>4. Choose the Retailer / Wholesaler</Form.Label>
                  <Form.Control
                    as="select"
                    name="retailerWholesaler"
                    value={product.retailerWholesaler}
                    onChange={handleInputChange}
                    required
                    disabled={submitting}
                  >
                    <option>Choose...</option>
                    {retailersWholesalers.map((rw) => (
                      <option key={rw._id} value={rw.owner}>
                        {rw.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label>5. Select Section</Form.Label>
                  <Form.Control
                    as="select"
                    name="section"
                    value={product.section}
                    onChange={handleInputChange}
                    disabled={submitting}
                  >
                    <option>Choose...</option>
                    {sections.map((section) => (
                      <option key={section._id} value={section._id}>
                        {section.title}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label>6. Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="price"
                    value={product.price}
                    onChange={handleInputChange}
                    required
                    disabled={submitting}
                  />
                  {priceWarning && (
                    <Alert variant="danger">
                      <MdWarning size={24} className="me-2" />
                      {priceWarning}
                    </Alert>
                  )}
                </Form.Group>

                {operationMode === "WHOLESALER" && (
                  <Form.Group controlId="minimumOrderVolume" className="mt-3">
                    <Form.Label>7. Minimum Order Volume (Units)</Form.Label>
                    <Form.Control
                      as="select"
                      value={minimumOrderVolume}
                      onChange={(e) =>
                        setMinimumOrderVolume(parseInt(e.target.value))
                      }
                    >
                      {[10, 20, 30, 50, 100, 150, 200, 300, 500].map(
                        (volume) => (
                          <option key={volume} value={volume}>
                            {volume}
                          </option>
                        )
                      )}
                    </Form.Control>
                    {/* Foot note ~ Default iss 10 Units */}
                    <Form.Text className="text-muted mb-3">
                      <strong
                        style={{
                          color: "lightblue",
                          fontSize: "10px",
                          fontStyle: "italic",
                        }}
                      >
                        Minimum Order Volume Default: {minimumOrderVolume} Units
                      </strong>
                    </Form.Text>
                  </Form.Group>
                )}
                <Form.Group className="mt-3">
                  <Form.Label>8. Count in Stock</Form.Label>
                  <Form.Control
                    type="number"
                    name="countInStock"
                    value={product.countInStock}
                    onChange={handleInputChange}
                    required
                    min="1"
                    disabled={submitting}
                  />
                </Form.Group>

                {/* Measurements */}
                <Form.Group className="mt-3">
                  <Form.Label>9. Measurement Units</Form.Label>
                  <Form.Control
                    name="measurements"
                    type="number"
                    inputMode="numeric"
                    pattern="^\d*(\.\d{0,2})?$"
                    value={product.measurements}
                    onChange={handleInputChange}
                    required
                    disabled={submitting}
                  />
                </Form.Group>

                {/* Units Dropdown*/}
                <Form.Group className="mt-3">
                  <Form.Label>10. Units</Form.Label>
                  <Select
                    options={units}
                    onChange={handleUnitChange}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Select Measurement Units..."
                    isDisabled={submitting}
                  />
                </Form.Group>

                {/* Category */}
                <Form.Group className="mt-3">
                  <Form.Label>11. Select Category</Form.Label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={selectedCategory}
                    getOptionLabel={(e) => e.category}
                    getOptionValue={(e) => e._id}
                    loadOptions={loadCategory}
                    onInputChange={handleCategoryInputChange}
                    onChange={handleCategoryChange}
                    isClearable={true}
                    isMulti={true}
                    isDisabled={submitting}
                  />
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label>12. Option Groups</Form.Label>
                  <Select
                    isMulti
                    options={optionGroups.map((optionGroup) => ({
                      value: optionGroup._id,
                      label: `${optionGroup.name} (Min: ${optionGroup.minOptions}, Max: ${optionGroup.maxOptions})`,
                    }))}
                    value={selectedOptionGroups}
                    onChange={setSelectedOptionGroups}
                    placeholder="Select option groups..."
                    isDisabled={submitting}
                  />
                  <Button
                    variant="link"
                    onClick={() => setShowOptionGroupModal(true)}
                    disabled={submitting}
                  >
                    Create New Option Group
                  </Button>
                </Form.Group>

                <Form.Group>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={product.enabled}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            enabled: e.target.checked,
                          })
                        }
                        name="enabled"
                        color="secondary"
                        disabled={submitting}
                      />
                    }
                    label={
                      product.enabled
                        ? "Activate Product For Customers"
                        : "Deactivate Product For Customers"
                    }
                    classes={{ label: classes.label }}
                  />
                </Form.Group>

                <Button
                  variant="danger"
                  onClick={() => setShowConfirmModal(true)}
                  disabled={submitting}
                  style={{
                    color: submitting ? "black" : "white",
                  }}
                >
                  Discard changes?
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  style={{
                    color: submitting ? "black" : "white",
                    backgroundColor: submitting ? "#e9ecef" : "#007BFF",
                    marginLeft: "10px",
                  }}
                  disabled={uploading || submitting}
                >
                  {uploading ? (
                    <>
                      <Spinner animation="border" size="sm" /> Saving...
                    </>
                  ) : (
                    "Save changes"
                  )}
                </Button>
              </Form>
              {uploading && (
                <div style={{ marginTop: "10px", textAlign: "center" }}>
                  <h5>Uploading Image: {uploadProgress}%</h5>
                  <ProgressBar
                    now={uploadProgress}
                    label={`${uploadProgress}%`}
                    animated
                    striped
                    variant="success"
                    style={{ height: "25px" }}
                  />
                </div>
              )}
            </Card.Body>
          </Card>

          {/* Image preview Modal */}
          <Modal
            show={showPreviewModal}
            onHide={() => setShowPreviewModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add product image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center">
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ width: "100%", maxWidth: "500px" }}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <hr />
                <Form.Text className="text-muted">
                  <strong>The image must:</strong>
                  <ul style={{ fontSize: "12px" }}>
                    <li>
                      Be JPG or PNG format
                      {isFormatCorrect ? (
                        <span className="text-success"> ✔</span>
                      ) : (
                        <span className="text-danger"> ✘</span>
                      )}
                    </li>
                    <li>
                      Be less than or equal to 10MB
                      {isSizeCorrect ? (
                        <span className="text-success"> ✔</span>
                      ) : (
                        <span className="text-danger"> ✘</span>
                      )}
                    </li>
                    <li>
                      Be at least 1000x1000 pixels
                      {isResolutionCorrect ? (
                        <span className="text-success"> ✔</span>
                      ) : (
                        <span className="text-danger"> ✘</span>
                      )}
                    </li>
                  </ul>
                </Form.Text>
              </div>
              {uploadError && (
                <Alert variant="danger" fontSize="12px">
                  {uploadError}
                </Alert>
              )}
            </Modal.Body>
            <Modal.Footer
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "8px",
              }}
            >
              <Button
                variant="danger"
                onClick={() => setShowPreviewModal(false)}
                disabled={submitting}
              >
                Cancel
              </Button>
              {uploadError ? (
                <Button
                  variant="warning"
                  onClick={() => document.getElementById("file-input").click()}
                  style={{ color: "white", backgroundColor: "#FFA500" }}
                  disabled={submitting}
                >
                  Try Another Image
                </Button>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => setShowPreviewModal(false)}
                  style={{ color: "white", backgroundColor: "#007BFF" }}
                  disabled={submitting}
                >
                  Save
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>

      {/* Modal for confirmation of product creation cancelation */}
      <Modal show={showConfirmModal} onHide={handleCloseDiscardModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "1.5rem",
              }}
            >
              <MdWarning
                size="30px"
                color="orange"
                style={{ marginRight: "10px" }}
              />
              You might have unsaved changes!
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>If you leave this page you will lose the changes you made.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseDiscardModal}
            disabled={submitting}
          >
            Go back
          </Button>
          <Button
            variant="danger"
            onClick={handleDiscardChanges}
            disabled={submitting}
          >
            Discard changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for creating a new option group */}
      {/* <OptionGroupForm
                show={showOptionGroupModal}
                handleClose={() => setShowOptionGroupModal(false)}
                handleSave={handleOptionGroupSave}
            /> */}
      <RetailerWholesalerOptionGroupForm
        show={showOptionGroupModal}
        handleClose={() => setShowOptionGroupModal(false)}
        handleSave={handleOptionGroupSave}
      />
    </Container>
  );
};

export default CreateRetailerWholesalerProductForm;
