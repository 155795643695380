import React, { useState } from "react";
import { Button, Card, Col, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";

const NotificationsForm = () => {
  //   console.log(process.env.REACT_APP_EXPO_PUSH_TOKEN, "token");
  const [postData, setPostData] = useState({
    title: "",
    message: "",
    // token: process.env.REACT_APP_EXPO_PUSH_TOKEN,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // fetch("http://localhost:8001/expoPushTokens/message", {
    fetch(
      `${process.env.REACT_APP_DASHBOARD_PROD_URL}/expoPushTokens/message`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.success, "data");
        // If data.success is true, then the push notification was sent successfully
        if (data.success) {
          // alert("Push notification sent successfully");
          toast.success("Push notification sent successfully");
          // Clear form fields
          setPostData({
            title: "",
            message: "",
          });
        } else {
          // alert("Error sending push notification");
          toast.error("Error sending push notification");
        }
      });
  };

  return (
    <Container fluid>
      <Col md="6">
        <Card className="stacked-form">
          <Card.Header>
            <Card.Title as="h4">Notifications</Card.Title>
          </Card.Header>
          <Card.Body>
            <Form
              autoComplete="off"
              autoCapitalize="off"
              autoCorrect="off"
              autoSave="off"
              onSubmit={handleSubmit}
            >
              <Form.Group>
                <label>Title</label>
                <Form.Control
                  placeholder="Enter title..."
                  name="title"
                  type="text"
                  value={postData.title}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      title: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group>
                <label>Message</label>
                <Form.Control
                  placeholder="Enter message..."
                  name="message"
                  type="text"
                  value={postData.message}
                  onChange={(e) =>
                    setPostData({
                      ...postData,
                      message: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Button
                className="btn-fill pull-right mt-2"
                type="submit"
                variant="info"
              >
                Send Push Notification
              </Button>
              <div className="clearfix"></div>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Container>
  );
};

export default NotificationsForm;
