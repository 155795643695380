import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ButtonToolbar,
  Container,
  Card,
  Table,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import moment from "moment";
import { Chip, Typography } from "@material-ui/core";
import { useHistory } from "react-router";

import {
  getMobileOrders,
  getOrdersNoLoader,
} from "../../actions/mobileOrdersAction";
import useStyles from "../Forms/Listings/Listing/orderDetailsStyles";
import { getOrderDetailsToProcess } from "../../actions/webOrderActions";
import { getUserFromLocalStorage } from "utilities/user";

const MobileWebOrderTable = () => {
  const user = getUserFromLocalStorage();
  // console.log(user?.result._id, "THIS IS THE USER");
  const { mobileOrders, isLoading } = useSelector(
    (state) => state.mobileOrders
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  // Fetching the mobile orders with redux & Polling // Deploy useInterval here
  useEffect(() => {
    dispatch(getMobileOrders());
    // Fetching the mobile orders after 1 Minute
    const interval = setInterval(() => {
      // dispatch(getCashOnDeliveryOrders());
      dispatch(getOrdersNoLoader());
    }, process.env.REACT_APP_SET_INTERVAL_POLLING);

    return () => clearInterval(interval);
  }, []);

  //RESTRICTED VIEW FOR ADMINS ONLY
  if (
    user?.result?.user_role === "CLIENT" ||
    user?.result?.user_role === "CREATOR"
  ) {
    return (
      <>
        <Container fluid>
          <Row>
            <Col md={6}>
              <Typography elevation={6} variant="h5" color="error">
                <i className="fas fa-users"></i>&nbsp;&nbsp; Restricted View For
                Admins Only!
              </Typography>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  return (
    <>
      {isLoading ? (
        <Spinner
          animation="grow"
          variant="danger"
          style={{
            marginTop: "70px",
            top: "50%",
            left: "50%",
            position: "fixed",
          }}
        />
      ) : (
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="table-with-links">
                <Card.Header>
                  <Card.Title as="h4">
                    Admin Mobile App & Web Orders' History
                  </Card.Title>
                  <p className="card-category">All Orders ~ no pagination!</p>
                </Card.Header>
                <Card.Body className="table-full-width">
                  <Table className="table-hover table-striped" responsive>
                    <thead>
                      <tr className="danger">
                        <th style={{ color: "black" }} className="text-center">
                          #
                        </th>
                        <th
                          style={{ color: "black", fontStyle: "italic" }}
                          className="text-left"
                        >
                          <strong>Order No.</strong>
                        </th>
                        <th style={{ color: "black", fontStyle: "italic" }}>
                          <strong>Order Src</strong>
                        </th>
                        <th style={{ color: "black", fontStyle: "italic" }}>
                          <strong>Cart Price</strong>
                        </th>
                        <th
                          style={{ color: "black", fontStyle: "italic" }}
                          className="text-right"
                        >
                          <strong>Shipping Zone</strong>
                        </th>
                        <th style={{ color: "black", fontStyle: "italic" }}>
                          <strong>Payment Mode</strong>
                        </th>
                        <th style={{ color: "black", fontStyle: "italic" }}>
                          <strong>Customer's No.</strong>
                        </th>
                        {/* <th style={{ color: 'black',  fontStyle: 'italic' }} className="text-right"><strong>Language</strong></th> */}
                        <th
                          style={{ color: "black", fontStyle: "italic" }}
                          className="text-right"
                        >
                          <strong>Customer's Name</strong>
                        </th>
                        <th
                          style={{ color: "black", fontStyle: "italic" }}
                          className="text-right"
                        >
                          <strong>Order Date</strong>
                        </th>
                        {/* <th
                          style={{ color: "black", fontStyle: "italic" }}
                          className="text-right"
                        >
                          <strong>By Ref</strong>
                        </th> */}
                        <th
                          style={{ color: "black", fontStyle: "italic" }}
                          className="text-right"
                        >
                          <strong>Payment Status</strong>
                        </th>
                        <th style={{ color: "black", fontStyle: "italic" }}>
                          <strong>Delivery Status</strong>
                        </th>

                        <th
                          style={{ color: "black", fontStyle: "italic" }}
                          className="text-right"
                        >
                          <strong>Order Actions</strong>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {mobileOrders?.map((order, index) => (
                        <tr key={order?._id}>
                          <td className="text-center">{index + 1}.</td>
                          <td
                            style={{
                              textTransform: "capitalize",
                              fontSize: "12px",
                            }}
                          >
                            {order?.order_number
                              ? order?.order_number
                              : order?._id || "Not Available"}
                          </td>
                          <td className="text-right">
                            {/* {order?.amount ? order?.amount : order.itemsPrice} */}
                            {order?.orderSrc ? order?.orderSrc : "Agent Call"}
                          </td>
                          <td className="text-right">
                            {/* {order?.amount ? order?.amount : order.itemsPrice} */}
                            {order?.totalPrice
                              ?.toString()
                              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td
                            style={{
                              textTransform: "capitalize",
                              fontSize: "12px",
                            }}
                          >
                            {order?.shippingAddress?.locationName?.name}
                            {order?.shippingAddress?.address?.address
                              ?.slice(0, 40)
                              ?.concat("...")}
                            {order?.shippingAddress?.description
                              ?.slice(0, 40)
                              ?.concat("...")}
                          </td>
                          <td
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {order?.paymentMethod}
                          </td>
                          <td className="text-right">
                            {order?.shippingAddress?.customerNumber}
                          </td>
                          <td className="text-right">
                            {order?.client_id?.name}
                          </td>
                          <td className="text-right">
                            {moment(order?.createdAt).fromNow()}
                          </td>
                          <td
                            className={
                              order?.isPaid &&
                                order?.momo_id?.status !== "Failed"
                                ? "text-success text-center"
                                : "text-danger text-center"
                            }
                          >
                            {order?.paymentMethod === "MOMO"
                              ? order?.momo_id?.status
                              : (order?.isPaid && "Paid") || "Not Paid"}
                          </td>
                          <td
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            <Chip
                              variant="default"
                              label={order?.delivery_status}
                              className={
                                order.delivery_status === "REJECTED"
                                  ? classes.red
                                  : order?.delivery_status === "DISPATCHED"
                                    ? classes.dispatchGrey
                                    : order?.delivery_status === "DELIVERED"
                                      ? classes.deliveredColor
                                      : classes.processingOrange
                              }
                            />
                          </td>

                          <td className="td-actions">
                            <ButtonToolbar>
                              {["left"].map((placement) => (
                                <OverlayTrigger
                                  key={placement}
                                  placement={placement}
                                  overlay={
                                    <Tooltip id={`tooltip-${placement}`}>
                                      Manage
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    size="lg"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      dispatch(
                                        getOrderDetailsToProcess(
                                          order?._id
                                          //  user.result._id,
                                          //  user.result.user_role,
                                        )
                                      ); //Ordering is postID/userId/role
                                      history.push(
                                        `/orderDetails/${order?._id}`
                                      );
                                    }}
                                  >
                                    <i className="fas fa-edit"></i>
                                  </Button>
                                </OverlayTrigger>
                              ))}
                            </ButtonToolbar>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>

                <Col style={{ paddingBottom: "15px" }}>
                  <Card.Body>
                    <Col className="font-icon-list" lg="2" md="3" sm="4" xs="6">
                      <Button
                        className="btn-outline btn-round btn-wd mr-1"
                        variant="danger"
                        onClick={() => history.push("/admin/shopCards")}
                      >
                        {" "}
                        <i
                          className="nc-icon nc-simple-add"
                          style={{ marginRight: "5px" }}
                        ></i>
                        New Order!
                      </Button>
                    </Col>
                  </Card.Body>

                  {/* <Card.Body>
                           <Button
                              className="btn btn-round btn-wd mr-1"
                              type="button"
                              variant="info"
                           >
                              <CSVLink
                                 data={posts}
                                 style={{
                                    textDecoration: 'none',
                                    fontSize: '12px',
                                    color: 'white',
                                 }}
                                 filename={'clients-data.csv'}
                              >
                                 EXPORT
                              </CSVLink>
                              <span className="btn-label btn-label-right">
                                 <i className="fas fa-arrow-down"></i>
                              </span>
                           </Button>
                        </Card.Body> */}
                </Col>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default MobileWebOrderTable;
