import { END_LOADING, START_LOADING } from "constants/webOrderConstants";
import {
  ZONE_CREATE_FAIL,
  ZONE_CREATE_REQUEST,
  ZONE_CREATE_SUCCESS,
  ZONE_CREATE_RESET,
  FETCH_DELIVERY_ZONES,
  FETCH_DELIVERY_ZONE,
  DELIVERY_ZONE_FETCH_FAIL,
  RESET_DELIVERY_ZONE,
  DELETE_DELIVERY_ZONE,
  CREATE_ZONE,
} from "../constants/zoneConstants";

export const orderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, loading: true };

    case ZONE_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        zone: action.payload,
      };
    case ZONE_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    // case ZONE_CREATE_RESET:
    //   return {};
    default:
      return state;
  }
};

export const getDeliveryZoneReducer = (
  state = { zones: [], zone: {} },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, loading: true };
    case END_LOADING:
      return { ...state, loading: false };
    case CREATE_ZONE:
      return {
        ...state,
        loading: false,
        zones: [...state.zones, action.payload],
      };

    case FETCH_DELIVERY_ZONES:
      return {
        ...state,
        zones: action.payload,
      };
    case FETCH_DELIVERY_ZONE:
      return {
        ...state,
        zone: action.payload.zone,
      };
    case DELETE_DELIVERY_ZONE:
      return {
        ...state,
        zones: state.zones.filter((zone) => zone._id !== action.payload),
      };
    case RESET_DELIVERY_ZONE:
      return {
        ...state,
        zone: {},
      };
    case DELIVERY_ZONE_FETCH_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
