import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Col, Image, ListGroup, Row, Spinner, Table } from "react-bootstrap";
import { getOuttaStockListings } from 'actions/listingsActions';
import { getListing } from 'actions/listingsActions';
import { useHistory } from "react-router-dom";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import { getUserFromLocalStorage } from 'utilities/user';


const OutOfStock = () => {
  const user = getUserFromLocalStorage();
  const listings = useSelector((state) => state.listings);
  const { isLoading, outtaStock } = listings;

  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {
    if (user?.result?._id) {
      dispatch(getOuttaStockListings(user?.result?._id));
    }
  }, [dispatch, user?.result?._id]);


  return (
    <>
      {isLoading ? (
        <Spinner
          animation="grow"
          size="lg"
          variant="primary"
          style={{
            marginTop: "70px",
            top: "50%",
            left: "50%",
            position: "fixed",
          }}
        />
      ) : (
        <div>
          <h1>Out of Stock Products</h1>
          <Row>
            <Col md={12}>
              <ListGroup>
                <ListGroup.Item>
                  <Row>
                    <Table striped hover>
                      {outtaStock?.data?.length === 0 ? (
                        <div className="text-center">
                          <h1>No Out of Stock Listings</h1>
                        </div>
                      ) : (
                        <>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Image</th>
                              <th>Listing Price</th>
                              <th>Listing Description</th>
                              <th>Verified</th>
                              <th>Stock Count</th>
                              <th>Update Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {outtaStock?.data?.map((listing) => (
                              <tr key={listing._id}>
                                <td>{listing.title}</td>
                                <td>
                                  <Image
                                    src={listing.product_image}
                                    resizemode="contain"
                                    style={{
                                      width: 60,
                                      height: 55,
                                      borderRadius: "15%",
                                    }}
                                    alt={listing.title}
                                  />
                                </td>
                                <td>Shs {listing.price.toLocaleString()}</td>
                                <td dangerouslySetInnerHTML={{ __html: listing.description }} />
                                <td>
                                  {listing?.verifyListing === "VERIFIED" ? (
                                    <VerifiedUserIcon style={{ color: "#1DA1F2", fontSize: 28 }} />
                                  ) : (
                                    <TimelapseIcon style={{ color: "#FF2976", fontSize: 27 }} />
                                  )}
                                </td>
                                <td className="text-center">{listing.countInStock}</td>
                                <td>
                                  <button
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      dispatch(
                                        getListing(
                                          listing._id,
                                          history.push(`/admin/shopEdit/${listing?._id}`)
                                        )
                                      );
                                    }}
                                  >
                                    Restock
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </>
                      )}
                    </Table>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default OutOfStock;