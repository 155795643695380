import React, { useState } from "react";
import { Button, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import { Alert, AlertTitle } from "@material-ui/lab";
import AppBar from "@material-ui/core/AppBar";

import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
// import rabbit from "../../assets/img/bannyAuth.png";
import lusukuLogo517 from "../../assets/img/lusukuLogo517.png";
import { useHistory } from "react-router";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const MobileClientPasswordResetForm = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  //   console.log(match.params.id, "idX");
  const [resetStatus, setResetStatus] = useState("");
  const [success, setSuccess] = useState(false);
  //   console.log(success, "success?");
  // console.log(resetStatus, "reset Status");

  const user_id = match?.params?.id;
  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const clearInput = () => {
    setInput({
      password: "",
      confirmPassword: "",
    });
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const response = await fetch(`/user/reset-password/${user_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: values.password,
        }),
      });
      const data = await response.json();
      // console.log(data, "data");
      setResetStatus(data.message);
      // toast.info(data.message);
      if (data.success) {
        // alert("Password reset successfully");
        toast.success("Password reset successfully!");
        setSuccess(true);
        history.replace("/agent-reset-password/:token/:id");
        setSubmitting(false);
      } else {
        // alert("Password reset failed");
        toast.info(data.message);
        history.replace("/agent-reset-password/:token/:id");
        clearInput();
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (success) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="card">
              <div className="card-body">
                <h4 className="text-center">Password Reset Successful</h4>
                <Alert severity="success">
                  <AlertTitle>Success</AlertTitle>
                  <p className="text-center">
                    {resetStatus} You can now login to Lusuku Mobile App with
                    your new password!
                  </p>
                </Alert>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" color="inherit">
          <Toolbar>
            <Link to="/">
              <Image
                width="80"
                height="85"
                className="d-inline-block align-top pb-2 mt-2"
                src={lusukuLogo517}
                alt="logo"
              />
            </Link>
          </Toolbar>
        </AppBar>
      </div>
      <div className="container pt-4">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="card">
              <div className="card-body">
                <h1 style={{ fontSize: 30, fontWeight: 300 }}>
                  Lusuku Password Reset
                </h1>
                <Formik
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                  }}
                  validationSchema={Yup.object({
                    password: Yup.string()
                      .required("Password is required!")
                      .min(8, "Password must be between 8 and 20 characters.")
                      .max(20, "Password must be between 8 and 20 characters.")
                      .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        "Password must contain at least one uppercase, one lowercase, one number and one special character."
                      ),
                    confirmPassword: Yup.string()
                      .required("Confirm Password is required")
                      .oneOf(
                        [Yup.ref("password"), null],
                        "Passwords must match!"
                      ),
                  })}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <Field
                          type="password"
                          name="password"
                          className={
                            "form-control" +
                            (errors.password && touched.password
                              ? " is-invalid"
                              : "")
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />

                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                        <Field
                          type="password"
                          name="confirmPassword"
                          className={
                            "form-control" +
                            (errors.confirmPassword && touched.confirmPassword
                              ? " is-invalid"
                              : "")
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                        />

                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                          disabled={isSubmitting}
                        >
                          Reset Password
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileClientPasswordResetForm;
