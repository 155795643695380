// restaurantProductReducer.js
import {
    START_LOADING_RESTAURANT_PRODUCT,
    CREATE_RESTAURANT_PRODUCT,
    GET_RESTAURANT_PRODUCTS,
    UPDATE_RESTAURANT_PRODUCT,
    END_LOADING_RESTAURANT_PRODUCT,
    CREATE_RESTAURANT_PRODUCT_ERROR,
    FETCH_RESTAURANT_PRODUCT_DETAILS,
    UPDATE_RESTAURANT_PRODUCT_ERROR,
    FETCH_RESTAURANT_PRODUCT_DETAILS_ERROR,
    DELETE_RESTAURANT_SUCCESS_PRODUCT
} from "../constants/actionTypes.js";

const initialState = {
    isLoading: false,
    restaurantProduct: [],
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case START_LOADING_RESTAURANT_PRODUCT:
            return { ...state, isLoading: true };
        case END_LOADING_RESTAURANT_PRODUCT:
            return { ...state, isLoading: false };
        case CREATE_RESTAURANT_PRODUCT:
            return { ...state, restaurantProduct: [...state.restaurantProduct, action.payload.data], error: null };
        case GET_RESTAURANT_PRODUCTS:
            return { ...state, restaurantProduct: action.payload, error: null };
        case FETCH_RESTAURANT_PRODUCT_DETAILS:
            return {
                ...state,
                restaurantProduct: state.restaurantProduct.map((product) =>
                    product._id === action.payload._id ? action.payload : product
                ),
                error: null
            };
        case UPDATE_RESTAURANT_PRODUCT:
            return {
                ...state,
                restaurantProduct: state.restaurantProduct.map((product) =>
                    product._id === action.payload._id ? action.payload : product
                ),
                error: null
            };
        case CREATE_RESTAURANT_PRODUCT_ERROR:
        case UPDATE_RESTAURANT_PRODUCT_ERROR:
        case FETCH_RESTAURANT_PRODUCT_DETAILS_ERROR:
            return { ...state, error: action.payload };
        case DELETE_RESTAURANT_SUCCESS_PRODUCT:
            return {
                ...state,
                loading: false,
                restaurantProduct: state.restaurantProduct.filter((product) => product._id !== action.payload),
                error: null
            };
        default:
            return state;
    }
};
