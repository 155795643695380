import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Form, Col, Spinner, Container, FormControl } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import DOMPurify from "dompurify";
import { useHistory } from "react-router";
import { updateListing, getListing } from "../../actions/listingsActions";
import axios from "axios";
import ImageUploadStyledAlert from "views/Components/ImageUploadStyledAlert";
import { getUserFromLocalStorage } from "utilities/user";

const ListingEditForm = ({ match }) => {
  const listings = useSelector((state) => state.listings);
  const { listing, isLoading } = listings;

  const ListingId = match.params.id;

  const user = getUserFromLocalStorage();

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([]);

  //Return policy
  const [useDefaultPolicy, setUseDefaultPolicy] = useState(true);
  const [customPolicy, setCustomPolicy] = useState('');

  const [inputValue, setValue] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");

  // Alert
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [postData, setPostData] = useState({
    title: "",
    description: "",
    price: "",
    countInStock: "",
    measurements: "",
    vendorName: "",
    vendorContactNumber: "",
    vendorAddress: "",
    pic: "",
    category: "",
  }); const [image, setImage] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    dispatch(getListing(ListingId));
  }, [ListingId]);

  const dispatch = useDispatch();
  const history = useHistory();

  const clear = () => {
    setPostData({
      title: "",
      description: "",
      price: "",
      countInStock: "",
      measurements: "",
      pic: "",
      category: "",
      vendorName: "",
      vendorContactNumber: "",
      vendorAddress: ""
    }),
      // setDescription("");
      setSelectedCategory([]);
    setSelectedUnit([]);
    setCustomPolicy('');
    setImage("");
    setIsUploading(false);
    setUploadDone(false);
    setUploadProgress(0);
  };

  const backToEditTable = () => {
    history.push("/admin/shopCards");
  };

  //PRELOADS THE FORM WITH DATA FROM LISTING
  useEffect(() => {
    //   if(!post?.title) clear();
    let active = true;

    if (listing?._id) {
      setPostData({
        ...listing,
      });
      if (active) {
        setImage(listing?.product_image);
      }
      if (listing?.category?.length > 0) {
        setSelectedCategory(listing?.category);
      }
      if (listing?.unit_id?.unit.length > 0) {
        setSelectedUnit(listing?.unit_id);
      }
    } else {
      clear();
    }
    return () => {
      active = false;
    };
  }, [listing]);

  const fetchImageToUpload = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      setIsUploading(true);
      const data = new FormData();
      data.append("file", image);
      data.append("upload_preset", "lusuku-clou");
      data.append("cloud_name", "MM-iCT");

      // percentage count
      const onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
      };

      const abortController = new AbortController();
      try {
        const res = await axios.post(
          process.env.REACT_APP_CLOUDINARY_URL,
          data,
          {
            onUploadProgress,
            signal: abortController.signal,
          }
        );

        const fileData = await res.data;
        setImage(fileData.secure_url);
        setIsUploading(false);
        setUploadDone(true);

        resolve(abortController);
      } catch (error) {
        reject(error);
      }
    });
  }, [image]);

  useEffect(() => {
    let active = true;
    let abortControllerPromise;

    if (image?.name) {
      if (active) {
        abortControllerPromise = fetchImageToUpload();
      }
    }

    return () => {
      active = false;
      if (abortControllerPromise) {
        abortControllerPromise.then((abortController) => {
          abortController.abort();
        });
      }
    };
  }, [image?.name, fetchImageToUpload]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (image?.name && !uploadDone) {
      const abortController = await fetchImageToUpload();
      if (abortController && !abortController.signal.aborted) {
        abortController.abort();
      }
    }

    // Sanitize customPolicy text
    const sanitizedCustomPolicy = DOMPurify.sanitize(customPolicy);

    dispatch(
      updateListing(
        listing?._id,
        {
          ...postData,
          pic: image,
          name: user?.result?.name,
          category: selectedCategory,
          unit_id: selectedUnit,
          description: postData.description, // Use the Quill editor content
          useDefaultPolicy: useDefaultPolicy,
          customPolicy: sanitizedCustomPolicy,
        },
        user.result._id,
        // user.result.user_role,
        clear(),
        history.push("/admin/shopCards"),
      )
    );
  };

  //Fetch categories
  const loadCategory = async (inputValue) => {
    const res = await fetch("/category/get-all");
    const categories = await res.json();
    if (!inputValue) {
      return categories;
    }

    const filteredCategories = categories.filter((category) =>
      category.category.toLowerCase().includes(inputValue.toLowerCase())
    );

    return filteredCategories;
  };

  // handle selection
  const handleInputChange = (value) => {
    setValue(value);
  };

  // handle Category Selection
  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const loadUnit = async (inputValue) => {
    const res = await fetch(`/unit`);
    const units = await res.json();

    if (!inputValue) {
      return units;
    }

    const filteredUnits = units.filter((unit) =>
      unit.unit.toLowerCase().includes(inputValue.toLowerCase())
    );

    return filteredUnits;
  };

  // handle Unit Selection
  const handleUnitChange = (value) => {
    setSelectedUnit(value);
  };

  const isJPEG = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const buffer = e.target.result;
        const view = new DataView(buffer);
        const isJPEG =
          view.byteLength >= 2 && view.getUint16(0, false) === 0xffd8; // Check if the file starts with a JPEG SOI marker (0xFFD8)
        resolve(isJPEG);
      };
      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const isFileJPEG = await isJPEG(file);
    const fileSizeInMB = file.size / (1024 * 1024);

    if (!isFileJPEG) {
      setSnackbarSeverity("error");
      setSnackbarMessage(
        "Invalid file type. Only JPG, JPEG, and PNG files are allowed."
      );
      setOpenSnackbar(true);
      setUploadedFileName(""); // Clear the uploaded file name
      return;
    }

    if (fileSizeInMB > 1) {
      setSnackbarSeverity("error");
      setSnackbarMessage(
        "File size exceeds 1 MB. Please choose a smaller image."
      );
      setOpenSnackbar(true);
      setUploadedFileName(""); // Clear the uploaded file name
      return;
    }

    // Continue with the upload process
    setImage(file);
    setUploadedFileName(file.name); // Set the uploaded file name
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // const handleDescriptionChange = (value) => {
  //   setDescription(value);
  // };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'color': [] }, { 'background': [] }],
      ['clean']
    ],
  };
  return isLoading ? (
    <Spinner
      animation="grow"
      variant="danger"
      style={{
        marginTop: "70px",
        top: "50%",
        left: "50%",
        position: "fixed",
      }}
    />
  ) : (
    <>
      <ImageUploadStyledAlert
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
      <Container fluid>
        <div className="section-image" data-image="../../assets/img/bg5.jpg">
          <Col md="10">
            <Form
              action=""
              id="RegisterValidation"
              autoComplete="off"
              method=""
              onSubmit={handleSubmit}
            >
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Edit Listing Form</Card.Title>
                </Card.Header>
                <Card.Body className="pt-0">
                  {/* Product Name */}
                  <Col md={6}>
                    <Form.Group>
                      <Card.Body>
                        <label>
                          1. Product Name <span className="star">*</span>
                        </label>
                        <Form.Control
                          name="title"
                          type="text"
                          value={postData.title}
                          maxLength="40"
                          onChange={(e) =>
                            setPostData({
                              ...postData,
                              title: e.target.value,
                            })
                          }
                        ></Form.Control>
                      </Card.Body>
                      {/* Description */}
                      <Card.Body>
                        <label>
                          2. Product Description
                          <span className="star">*</span>
                        </label>
                        <ReactQuill
                          value={postData.description}
                          // onChange={handleDescriptionChange}
                          onChange={(content) => {
                            setPostData({
                              ...postData,
                              description: content,
                            })
                          }}
                          modules={modules}
                          formats={[
                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                            'list', 'bullet', 'size', 'color', 'background', 'code-block'
                          ]}
                          style={{ height: '220px', marginBottom: '50px' }} // Adjust marginBottom if needed
                          placeholder="Enter product description here..."
                        />
                      </Card.Body>
                      {/* Price */}
                      <Card.Body>
                        <label>
                          3. Item Price
                          <span className="star">*</span>
                        </label>
                        <Form.Control
                          name="mobile"
                          type="number"
                          value={postData.price}
                          onChange={(e) =>
                            setPostData({
                              ...postData,
                              price: e.target.value,
                            })
                          }
                        ></Form.Control>
                      </Card.Body>
                      {/* Count In Stock */}
                      <Card.Body>
                        <label>
                          4. In Stock
                          <span className="star">*</span>
                        </label>
                        <Form.Control
                          name="countInStock"
                          type="text"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          value={postData.countInStock}
                          onChange={(e) =>
                            setPostData({
                              ...postData,
                              countInStock: e.target.value,
                            })
                          }
                        ></Form.Control>
                      </Card.Body>
                      <Card.Body>
                        <label>5. Select Category</label>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          value={selectedCategory}
                          getOptionLabel={(e) => e.category}
                          getOptionValue={(e) => e._id}
                          loadOptions={loadCategory}
                          onInputChange={handleInputChange}
                          onChange={handleCategoryChange}
                          isClearable={true}
                          isMulti={true}
                        />
                      </Card.Body>
                      <Card.Body>
                        <label>
                          6. Measurements
                          <span className="star">*</span>
                        </label>
                        <Form.Control
                          name="measurements"
                          type="text"
                          inputMode="numeric"
                          // pattern="[0-9]*"
                          pattern="^\d*(\.\d{0,2})?$"
                          value={postData.measurements}
                          onChange={(e) =>
                            setPostData({
                              ...postData,
                              measurements: e.target.value,
                            })
                          }
                        ></Form.Control>
                        {/* Vendor Name */}
                        <Card.Body>
                          <Form.Group>
                            <label>7. Vendor Name</label>
                            <Form.Control
                              type="text"
                              value={postData.vendorName}
                              onChange={(e) => setPostData({ ...postData, vendorName: e.target.value })}
                              placeholder="Enter vendor's name"
                            />
                          </Form.Group>
                        </Card.Body>

                        {/* Vendor Contact Number */}
                        <Card.Body>
                          <Form.Group>
                            <label>8. Vendor Primary Contact Number</label>
                            <Form.Control
                              type="text"
                              value={postData.vendorContactNumber}
                              onChange={(e) => setPostData({ ...postData, vendorContactNumber: e.target.value })}
                              placeholder="Enter vendor's contact number..."
                            />
                          </Form.Group>
                        </Card.Body>

                        {/* Vendor Address */}
                        <Card.Body>
                          <Form.Group>
                            <label>9. Vendor's Physical Address</label>
                            <Form.Control
                              type="text"
                              value={postData.vendorAddress}
                              onChange={(e) => setPostData({ ...postData, vendorAddress: e.target.value })}
                              placeholder="Enter vendor's Address..."
                            />
                          </Form.Group>
                        </Card.Body>
                      </Card.Body>
                      <Card.Body>
                        <label>
                          10. Measurement Unit
                          <span className="star">*</span>
                        </label>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          value={selectedUnit}
                          getOptionLabel={(e) => e.unit}
                          getOptionValue={(e) => e._id}
                          loadOptions={loadUnit}
                          onInputChange={handleInputChange}
                          onChange={handleUnitChange}
                          isClearable={true}
                          placeholder="Select Unit..."
                        />
                        {/* <Select
                                options={units}
                                // defaultValue={units}
                                onChange={handleUnitChange}
                                isClearable={true}
                                isSearchable={true}
                                placeholder="Select Measurement Unit..."
                              /> */}
                      </Card.Body>
                      {/* Return Policy */}
                      <Card.Body>
                        <Form.Group>
                          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <input
                              type="checkbox"
                              id="default-return-policy-checkbox"
                              checked={useDefaultPolicy}
                              onChange={(e) => setUseDefaultPolicy(e.target.checked)}
                              style={{ marginRight: '8px' }}
                            />
                            <label htmlFor="default-return-policy-checkbox">Use default return policy</label>
                          </div>
                          {!useDefaultPolicy && (
                            <>
                              <label>Enter Custom Return Policy<span className="star">*</span></label>
                              <FormControl
                                as="textarea"
                                rows={5}
                                placeholder="Please provide the custom return policy here!"
                                style={{ minHeight: '170px', borderColor: 'orange' }}
                                value={customPolicy}
                                // onChange={handleCustomPolicyChange}
                                onChange={(e) => setCustomPolicy(e.target.value)}
                                required={!useDefaultPolicy}
                              />
                              <div style={{ color: 'orange', marginTop: '5px' }}>Please enter a custom return policy.</div>
                            </>
                          )}
                          {useDefaultPolicy && (
                            <div style={{ color: 'green', marginTop: '2px', fontStyle: "italic", fontSize: "12px" }}>Default return policy will be used!</div>
                          )}
                        </Form.Group>
                      </Card.Body>
                      <Card.Body className="pt-0">
                        <div style={{ width: "97%", margin: "10px 0" }}>
                          <div>
                            <label
                              htmlFor="image-upload"
                              style={{
                                display: "inline-block",
                                padding: "6px 12px",
                                cursor: "pointer",
                                backgroundColor: "#f8f9fa",
                                border: "1px solid #ced4da",
                                borderRadius: "4px",
                                fontWeight: 400,
                                color: "#495057",
                                fontSize: "1rem",
                                lineHeight: "1.5",
                              }}
                              onMouseEnter={(e) => {
                                e.target.style.backgroundColor = "#e9ecef";
                                e.target.style.borderColor = "#adb5bd";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.backgroundColor = "#f8f9fa";
                                e.target.style.borderColor = "#ced4da";
                              }}
                            >
                              {uploadedFileName || "Upload an image..."}
                            </label>
                            <input
                              id="image-upload"
                              name="image"
                              type="file"
                              accept=".jpg, .png, .jpeg"
                              // onChange={(e) => setImage(e.target.files[0])}
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                            />
                          </div>
                        </div>
                        <div>
                          {uploadedFileName && (
                            <p
                              style={{
                                fontSize: "13px",
                                fontStyle: "italic",
                              }}
                            >
                              File Name: {uploadedFileName}
                            </p>
                          )}
                          {isUploading && (
                            <p
                              style={{
                                color: "red",
                                fontSize: "13px",
                                fontStyle: "italic",
                              }}
                            >
                              Uploading... {uploadProgress}%
                            </p>
                          )}
                          {uploadDone && !isUploading ? (
                            <p
                              style={{
                                color: "green",
                                fontSize: "13px",
                                fontStyle: "italic",
                              }}
                            >
                              Image Upload Done!
                            </p>
                          ) : null}
                        </div>
                      </Card.Body>
                    </Form.Group>
                  </Col>
                </Card.Body>

                <Card.Body>
                  <Card.Footer className="text-right">
                    <Button
                      className="btn-fill pull-right"
                      variant="danger"
                      // type="submit"
                      onClick={backToEditTable}
                      style={{ marginRight: "5px" }}
                    >
                      Back
                    </Button>
                    <Button
                      className="btn-fill pull-right"
                      variant="info"
                      type="submit"
                      disabled={
                        // !uploadDone ||
                        selectedCategory.length <= 0 ||
                        selectedUnit?.length === 0
                      }
                    // onClick={() => {}}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Footer>
                </Card.Body>
              </Card>
            </Form>
          </Col>
        </div>
      </Container>
    </>
  );
};

export default ListingEditForm;
