import {
  deactivateCategory,
  // deleteCategory,
  // getCategories,
  getActiveCategories,
  // getActiveCategory,
  getCategory,
} from "../../actions/categoryActions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Button,
  ButtonToolbar,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
// import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";

const CategoriesTable = () => {
  const { activeCategories, loading } = useSelector(
    (state) => state.categories
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getActiveCategories());
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Spinner
          animation="grow"
          variant="danger"
          style={{
            marginTop: "70px",
            top: "50%",
            left: "50%",
            position: "fixed",
          }}
        />
      ) : (
        <Container fluid>
          <Row>
            <Col md="10">
              <Card className="regular-table-with-color">
                <Card.Header>
                  <Card.Title as="h4">Active Categories</Card.Title>
                  <p className="card-category">
                    Categorization ~ All Active Categories.
                  </p>
                </Card.Header>
                <Card.Body className="table-full-width">
                  <Table className="table-hover table-striped w-full">
                    <thead>
                      <tr className="info">
                        <th style={{ color: "black" }} className="text-center">
                          #
                        </th>
                        <th style={{ color: "black", fontStyle: "italic" }}>
                          <strong>Category</strong>
                        </th>
                        <th style={{ color: "black", fontStyle: "italic" }} className="text-center">
                          <strong>Image</strong>
                        </th>
                        <th style={{ color: "black", fontStyle: "italic" }} className="text-center">
                          <strong>Listings-in-Category</strong>
                        </th>
                        <th style={{ color: "black", fontStyle: "italic" }}>
                          <strong>Category ID</strong>
                        </th>
                        <th
                          style={{ color: "black", fontStyle: "italic" }}
                          className="text-right"
                        >
                          <strong>Action</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {activeCategories?.map((item, index) => (
                        <tr key={item?._id}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-right">{item?.category}</td>
                          <td className="text-center">
                          {item?.category_pic ? (
                              <img
                                src={item.category_pic}
                                alt={item.category}
                                style={{ width: "80px", height: "50px", objectFit: "cover", borderRadius: "10px" }}
                              />
                              ) : (
                                <img
                                  src="https://res.cloudinary.com/lusuku/image/upload/v1702635550/Lusuku_Public_Resources/No_Category_Pic_Placecholder-Prod_ltsbhf.jpg"
                                  alt="Placeholder"
                                  style={{ width: "80px", height: "50px", objectFit: "cover", borderRadius: "10px" }}
                                />
                              )}
                          </td>
                          <td className="text-center">
                            {item?.post_listings?.length}
                          </td>
                          <td className="text-right">{item?._id}</td>
                          <td className="td-actions text-right">
                            <ButtonToolbar>
                              {["right"].map((placement) => (
                                <OverlayTrigger
                                  key={placement}
                                  placement={placement}
                                  overlay={
                                    <Tooltip id={`tooltip-${placement}`}>
                                      Edit Category
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      // dispatch(getActiveCategory(item._id));
                                      dispatch(getCategory(item._id));
                                      history.push("/admin/category-create");
                                    }}
                                  >
                                    <i className="fas fa-edit"></i>
                                  </Button>
                                </OverlayTrigger>
                              ))}
                            </ButtonToolbar>
                            {/* <ButtonToolbar>
                              {["right"].map((placement) => (
                                <OverlayTrigger
                                  key={placement}
                                  placement={placement}
                                  overlay={
                                    <Tooltip id={`tooltip-${placement}`}>
                                      Delete Category
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      dispatch(deleteCategory(item._id));
                                    }}
                                  >
                                    <i className="fas fa-trash"></i>
                                  </Button>
                                </OverlayTrigger>
                              ))}
                            </ButtonToolbar> */}
                            {item?.active_status === "Active" ? (
                              <ButtonToolbar>
                                {["right"].map((placement) => (
                                  <OverlayTrigger
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                      <Tooltip id={`tooltip-${placement}`}>
                                        Deactivate Category
                                      </Tooltip>
                                    }
                                  >
                                    <ToggleOnIcon
                                      fontSize="large"
                                      className="text-success"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(deactivateCategory(item._id));
                                        // history.push("/admin/category-create");
                                      }}
                                    />
                                  </OverlayTrigger>
                                ))}
                              </ButtonToolbar>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {/* Units */}
                  <Col style={{ paddingBottom: "15px" }}>
                    <Card.Body>
                      <Row
                        className="font-icon-list "
                        lg="6"
                        md="6"
                        sm="6"
                        xs="6"
                      >
                        <Button
                          className="btn-outline btn-round btn-wd"
                          variant="danger"
                          onClick={() => history.push("/admin/category-create")}
                        >
                          <i
                            className="nc-icon nc-simple-add"
                            // style={{ marginRight: "5px" }}
                          ></i>
                          Add Category
                        </Button>
                        <Button
                          className="btn-outline btn-round btn-wd"
                          variant="label"
                          onClick={() => history.push("/admin/units")}
                        >
                          <i
                            className="nc-icon nc-simple-add"
                            // style={{ marginRight: "5px" }}
                          ></i>
                          Units
                        </Button>
                        <Button
                          className="btn-outline btn-round btn-wd"
                          variant="label"
                          // onClick={() => history.push("/shipping-cost-create")}
                          onClick={() => history.push("/admin/shipping-cost")}
                        >
                          <i
                            className="nc-icon nc-simple-add"
                            // style={{ marginRight: "5px" }}
                          ></i>
                          Shipping Cost
                        </Button>
                      </Row>
                    </Card.Body>
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default CategoriesTable;
