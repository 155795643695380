import {
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_CREATE_RESET,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_FAIL,
  ORDER_PAY_RESET,
  CASH_ORDER_CREATE_REQUEST,
  CASH_ORDER_CREATE_SUCCESS,
  CASH_ORDER_CREATE_FAIL,
  CASH_ON_DELIVERY_ORDER_REQUEST,
  CASH_ON_DELIVERY_ORDER_SUCCESS,
  CASH_ON_DELIVERY_ORDER_FAIL,
  START_LOADING,
  END_LOADING,
  CASH_ON_DELIVERY_ORDER_REQUEST_NO_LOADER,
  CASH_ON_DELIVERY_ORDER_SUCCESS_NO_LOADER,
  CASH_ON_DELIVERY_ORDER_FAIL_NO_LOADER,
  VENDOR_ORDER_DETAILS_SUCCESS,
  VENDOR_ORDER_DETAILS_FAIL,
  UPDATE_DELIVERY_STATUS,
  RESET_UPDATE_DELIVERY_STATUS_COMPLETE,
  UPDATE_DELIVERY_STATUS_COMPLETE,
  UPDATE_OVERALL_ORDER_DELIVERY_STATUS,
  MARK_ORDER_PAID,
  MARK_ORDER_PAID_FAIL,
  SET_ORDER_DATA_BY_RIDER,
} from "../constants/webOrderConstants";

export const orderCreateReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case ORDER_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case ORDER_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_CREATE_RESET:
      return {};
    case END_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

// TO DELETE
export const cashOrderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CASH_ORDER_CREATE_REQUEST:
      return {
        loading: true,
      };
    case CASH_ORDER_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case CASH_ORDER_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const orderDetailsReducer = (
  state = {
    order: {},
    loading: true,
    vendorOrderDetails: {},
    isUpdateDeliveryStatusComplete: false, // new state field
  },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, loading: true };

    case ORDER_DETAILS_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      };
    case VENDOR_ORDER_DETAILS_SUCCESS:
      return {
        vendorOrderDetails: action.payload,
        loading: false,
      };

    case ORDER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case VENDOR_ORDER_DETAILS_FAIL:
      return {
        error: action.payload,
        loading: false,
      };

    case SET_ORDER_DATA_BY_RIDER:
      return {
        ...state,
        order: action.payload,
        loading: false,
      };

    case UPDATE_DELIVERY_STATUS:
      return {
        ...state,
        order: {
          ...state.order,
          orderItems: state?.order?.orderItems?.map((item) =>
            item._id === action.payload.itemId
              ? { ...item, delivery_status: action.payload.delivery_status }
              : item
          ),
        },
        vendorOrderDetails: {
          ...state.vendorOrderDetails,
          orderItems: state?.vendorOrderDetails?.orderItems?.map((item) =>
            item._id === action.payload.itemId
              ? { ...item, delivery_status: action.payload.delivery_status }
              : item
          ),
        },
        loading: false,
      };
    case UPDATE_OVERALL_ORDER_DELIVERY_STATUS:
      return {
        ...state,
        order: {
          ...state?.order,
          delivery_status: action?.payload?.delivery_status,
        },
        loading: false,
      }; // Update overall order delivery_status

    case UPDATE_DELIVERY_STATUS_COMPLETE:
      return {
        ...state,
        isUpdateDeliveryStatusComplete: true, // set to true when operation is complete
      };

    case RESET_UPDATE_DELIVERY_STATUS_COMPLETE:
      return {
        ...state,
        isUpdateDeliveryStatusComplete: false, // reset after refetching
      };

    case MARK_ORDER_PAID:
      return {
        ...state,
        order: {
          ...state.order,
          isPaid: true,
        },
        loading: false,
      };

    case MARK_ORDER_PAID_FAIL:
      return {
        ...state,
        isPaid: false,
        error: action.payload,
      };
    case END_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
};

// export const vendorOrderDetailsReducer = (
//   state = { vendorOrderDetails: {}, vendorOrderDetailsLoading: true },
//   action
// ) => {
//   switch (action.type) {
//     case START_LOADING:
//       return { ...state, loading: true };

//     case VENDOR_ORDER_DETAILS_SUCCESS:
//       return {
//         vendorOrderDetailsLoading: false,
//         vendorOrderDetails: action.payload,
//       };

//     case VENDOR_ORDER_DETAILS_FAIL:
//       return {
//         vendorOrderDetailsLoading: false,
//         error: action.payload,
//       };

//     // case UPDATE_DELIVERY_STATUS:
//     //   return {
//     //     ...state,
//     //     vendorOrderDetails: {
//     //       ...state.vendorOrderDetails,
//     //       orderItems: action.payload.updatedOrder.orderItems.map((item) =>
//     //         item._id === action.payload.itemId
//     //           ? action.payload.updatedOrder
//     //           : item
//     //       ),
//     //     },
//     //     vendorOrderDetailsLoading: false,
//     //   };
//     case UPDATE_DELIVERY_STATUS:
//       return {
//         ...state,
//         vendorOrderDetails: {
//           ...state.vendorOrderDetails,
//           orderItems: state?.vendorOrderDetails?.orderItems?.map((item) =>
//             item._id === action.payload.itemId
//               ? { ...item, delivery_status: action.payload.delivery_status }
//               : item
//           ),
//         },
//         vendorOrderDetailsLoading: false,
//       };

//     case END_LOADING:
//       return { ...state, vendorOrderDetailsLoading: false };

//     default:
//       return state;
//   }
// };

export const cashOnDeliveryOrderReducer = (
  state = {
    loading: true,
    orderItems: {},
    order: [],
    orders: [],
    shippingAddress: {},
  },
  action
) => {
  switch (action.type) {
    case CASH_ON_DELIVERY_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CASH_ON_DELIVERY_ORDER_SUCCESS:
      return {
        loading: false,
        orders: action.payload.data,
      };
    case CASH_ON_DELIVERY_ORDER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CASH_ON_DELIVERY_ORDER_REQUEST_NO_LOADER:
      return {
        ...state,
        // loading: true,
      };
    case CASH_ON_DELIVERY_ORDER_SUCCESS_NO_LOADER:
      return {
        // loading: false,
        ...state,
        orders: action.payload.data,
      };
    case CASH_ON_DELIVERY_ORDER_FAIL_NO_LOADER:
      return {
        // loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const orderPayReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_PAY_REQUEST:
      return {
        loading: true,
      };
    case ORDER_PAY_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_PAY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_PAY_RESET:
      return {};
    default:
      return state;
  }
};
