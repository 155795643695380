import React, { useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  ButtonToolbar,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  deleteShippingCost,
  getShippingCost,
  getShippingCosts,
} from "../../actions/shippingCostActions";
import { getUserFromLocalStorage } from "utilities/user";

const ShippingCostTable = () => {
  const user = getUserFromLocalStorage();
  // console.log(user.result._id, "hghghghghgh");

  const { shippingCosts, loading } = useSelector(
    (state) => state.shippingCosts
  );
  const userRole = user?.result?.user_role;

  // console.log(shippingCosts, "shippingCosts youuu");
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getShippingCosts());
  }, []);

  return (
    <>
      {loading ? (
        <Spinner
          animation="grow"
          variant="danger"
          style={{
            marginTop: "70px",
            top: "50%",
            left: "50%",
            position: "fixed",
          }}
        />
      ) : (
        <>
          <Container fluid>
            <Row>
              <Col md="8" className="mx-auto mt-5">
                <Card className="regular-table-with-color">
                  <Card.Header>
                    <Card.Title as="h4">Shipping Costs</Card.Title>
                    <p className="card-category">
                      Shipping Costs ~ All Shipping Costs.
                    </p>
                  </Card.Header>
                  <Card.Body className="table-full-width">
                    <Table className="table-hover table-striped w-full">
                      <thead>
                        <tr className="info">
                          {/* <th
                            style={{ color: "black" }}
                            className="text-center"
                          >
                            #
                          </th> */}
                          <th style={{ color: "black", fontStyle: "italic" }}>
                            <strong>Shipping Cost</strong>
                          </th>
                          <th style={{ color: "black", fontStyle: "italic" }}>
                            <strong>Shipping Cost ID</strong>
                          </th>
                          <th style={{ color: "black", fontStyle: "italic" }}>
                            <strong>Shipping Cost Desc</strong>
                          </th>
                          <th
                            style={{ color: "black", fontStyle: "italic" }}
                            className="text-right"
                          >
                            <strong>Action</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {shippingCosts.map((item, index) => (
                          <tr key={item._id}>
                            {/* <td className="text-center">{index + 1}</td> */}
                            <td>{item.cost}</td>
                            <td>{item._id}</td>
                            <td>{item.shippingCostDescription}</td>
                            <td className="text-right">
                              <ButtonToolbar className="mb-1">
                                {["top"].map((placement) => (
                                  <OverlayTrigger
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                      <Tooltip id={`tooltip-${placement}`}>
                                        Edit Shipping Cost
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant="primary"
                                      size="sm"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(getShippingCost(item._id));
                                        history.push("/admin/shipping-cost");
                                      }}
                                    >
                                      <i className="fas fa-edit"></i>
                                    </Button>
                                  </OverlayTrigger>
                                ))}
                              </ButtonToolbar>
                              {/* <Button
                                className="btn-icon btn-link like"
                                variant="info"
                                onClick={() =>
                                  history.push(
                                    `/admin/shipping-costs/${item._id}`
                                  )
                                }
                              >
                                <i className="fa fa-edit" />
                              </Button> */}
                              <Button
                                className="btn-icon btn-link like"
                                variant="danger"
                                onClick={() =>
                                  dispatch(
                                    deleteShippingCost({
                                      _id: item._id,
                                      userRole: userRole,
                                      userId: user?.result?._id,
                                    })
                                  )
                                }
                              >
                                <i className="fa fa-times" />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default ShippingCostTable;
