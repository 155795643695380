import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Table, Button } from 'reactstrap';
import { Modal } from "react-bootstrap";
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRestaurants, updateRestaurant, deleteRestaurant } from '../actions/restaurantActions.js'; // Import deleteRestaurant action
import EditRestaurantForm from './EditRestaurantForm.js';
import restaurantPlaceholderImage from '../assets/img/restaurantimage_unavailable.png'; // Import your placeholder image here
import { getUserFromLocalStorage } from 'utilities/user.js';

const useStyles = makeStyles(theme => ({
    switchBase: {
        color: theme.palette.secondary.main,
        '&$checked': {
            color: theme.palette.secondary.main,
        },
        '&$checked + $track': {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    checked: {},
    track: {},
}));

const Restaurants = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { loading, restaurants, error } = useSelector((state) => state.restaurants);
    const [editingRestaurant, setEditingRestaurant] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [restaurantToDelete, setRestaurantToDelete] = useState(null);

    const user = getUserFromLocalStorage();
    const adminId = user?.result?._id;
    const isSuperAdmin = user?.result?.userType === 'LUSUKU STAFF' && user?.result?.user_role === 'SUPERUSER';

    useEffect(() => {
        dispatch(fetchRestaurants(adminId, isSuperAdmin));
    }, [dispatch, adminId, isSuperAdmin]);

    const handleRetry = () => {
        dispatch(fetchRestaurants(adminId, isSuperAdmin));
    };

    const handleDeleteRestaurant = (id) => {
        if (isSuperAdmin) {
            setRestaurantToDelete(id);
            setShowDeleteModal(true);
        }
    };

    const confirmDeleteRestaurant = () => {
        if (restaurantToDelete) {
            dispatch(deleteRestaurant(restaurantToDelete));
            setShowDeleteModal(false);
        }
    };

    const handleEditClick = (restaurant) => {
        setEditingRestaurant(restaurant);
    };

    const handleSave = (updatedRestaurant) => {
        dispatch(updateRestaurant(updatedRestaurant._id, updatedRestaurant));
        setEditingRestaurant(null);
    };

    const handleCancel = () => {
        setEditingRestaurant(null);
    };

    const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

    let content;

    if (loading) {
        content = <div>Loading...</div>;
    } else if (error) {
        content = (
            <div>
                {error}
                <Button onClick={handleRetry} color="primary" style={{ marginLeft: '10px' }}>
                    Retry!
                </Button>
            </div>
        );
    } else {
        content = (
            <Table responsive hover striped>
                <thead className="thead-dark">
                    <tr>
                        <th style={{ textAlign: 'left', verticalAlign: 'middle' }}>#</th>
                        <th style={{ textAlign: 'left', verticalAlign: 'middle' }}>Name</th>
                        <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Logo</th>
                        <th style={{ textAlign: 'left', verticalAlign: 'middle', width: '20%' }}>Location</th>
                        <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Activation</th>
                        <th style={{ textAlign: 'left', verticalAlign: 'middle' }}>ID</th>
                        <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {restaurants.map((restaurant, index) => (
                        <tr key={restaurant._id}>
                            <td style={{ textAlign: 'left', verticalAlign: 'middle', padding: '15px' }}>{index + 1}</td>
                            <td style={{ textAlign: 'left', verticalAlign: 'middle', padding: '15px' }}>{restaurant.name}</td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px' }}>
                                <img
                                    src={restaurant.imageUrl || restaurantPlaceholderImage}
                                    alt={restaurant.name || "restaurantPlaceholderImage"}
                                    style={{ width: '100px', height: 'auto', borderRadius: '25px' }}
                                    onError={(e) => e.target.src = restaurantPlaceholderImage} // Fallback to placeholder image if there's an error
                                />
                            </td>
                            <td style={{ textAlign: 'left', verticalAlign: 'middle', padding: '15px' }}>{restaurant.address}</td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px' }}>
                                <Switch
                                    checked={restaurant.isActive}
                                    disabled
                                    classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.checked,
                                        track: classes.track,
                                    }}
                                />
                            </td>
                            <td style={{ textAlign: 'left', verticalAlign: 'middle', padding: '15px' }}>{restaurant._id}</td>
                            <td
                                style={{
                                    textAlign: 'left',
                                    verticalAlign: 'middle',
                                    padding: '15px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    gap: '5px',
                                    cursor: isSuperAdmin ? 'pointer' : 'not-allowed',
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Button
                                        color="warning"
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        onClick={() => handleEditClick(restaurant)}
                                    >
                                        <FaEdit style={{ marginRight: '5px' }} /> Edit
                                    </Button>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: !isSuperAdmin ? 'not-allowed' : 'pointer' }}>
                                    <Button
                                        color="danger"
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: !isSuperAdmin ? 'red' : 'white' }}
                                        onClick={() => handleDeleteRestaurant(restaurant.owner)}
                                        disabled={!isSuperAdmin}
                                    >
                                        <FaTrash style={{ marginRight: '5px', color: !isSuperAdmin ? 'red' : 'white' }} /> Delete
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }

    return (
        <div style={{ padding: '20px' }}>
            <Card style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <CardHeader style={{ backgroundColor: '#343a40', color: '#fff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', textAlign: 'center' }}>
                    <h2>Restaurants</h2>
                </CardHeader>
                <CardBody>
                    {editingRestaurant ? (
                        <EditRestaurantForm
                            restaurant={editingRestaurant}
                            onSave={handleSave}
                            onCancel={handleCancel}
                        />
                    ) : (
                        content
                    )}
                </CardBody>
            </Card>
            <Modal
                show={showDeleteModal}
                onHide={toggleDeleteModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Confirm Delete
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this restaurant? This action can't be undone!
                </Modal.Body>
                <Modal.Footer>
                    <Button color="danger" onClick={confirmDeleteRestaurant}>Delete</Button>
                    <Button color="secondary" onClick={toggleDeleteModal}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Restaurants;
