import { makeStyles } from "@material-ui/core/styles";
import { deepPurple } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
  heading: {
    color: "rgba(0,183,255, 1)",
    textDecoration: "none",
  },
  image: {
    marginLeft: "15px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    width: "400px",
  },
  profile: {
    display: "flex",
    justifyContent: "space-between",
    width: "400px",
  },
  search: {
    display: "flex",
    marginBottom: "10px",
  },
  searchInput: {
    //  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    boxShadow: "0px 0px 5px #ccc",
    marginLeft: "10px",
    width: "200px",
    height: "38px",
    borderTopLeftRadius: "25px",
    borderBottomLeftRadius: "25px",
    border: "1px solid #ccc",
    paddingLeft: "10px",
    fontSize: "14px",
    color: "#333",
    fontFamily: "Roboto, sans-serif",
    fontStyle: "italic",
    outline: "none",
    "&:focus": {
      border: "1px solid #cccc",
    },
  },

  userName: {
    display: "flex",
    alignItems: "center",
  },
  brandContainer: {
    display: "flex",
    alignItems: "center",
  },
  smMargin: {
    margin: theme.spacing(1),
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },

  [theme.breakpoints.down("sm")]: {
    appBar: {
      padding: "10px 20px",
    },
    heading: {
      display: "none",
    },
    userName: {
      display: "none",
    },
    image: {
      marginLeft: "5px",
    },
    toolbar: {
      display: "flex",
      justifyContent: "flex-end",
      width: "160px",
    },
  },

  actionDiv: {
    textAlign: "center",
  },
}));
