import {
  AUTH,
  USER_CREATED,
  CREATE_USER,
  DELETE_USER,
  END_LOADING,
  FETCH_USERS,
  FETCH_USER_SINGLE,
  START_LOADING,
  UPDATE_USER,
  USER_LOGIN_FAIL,
  FETCH_CLIENT_BY_SEARCH,
} from "../constants/actionTypes.js";
import * as api from "../api/index.js";
import { toast } from "react-toastify";
import { debounce } from 'lodash';

export const signin = (formData, router) => async (dispatch) => {
  const debouncedSignin = debounce(async () => {
    try {
      dispatch({ type: START_LOADING });

      // const { data } = await api.signIn(formData);
      const response = await api.signIn(formData);
      // console.log(response, "This response data>>>>")
      const { data, status } = response;
      dispatch({ type: AUTH, data });
      if (status !== 200) {
        toast.success("Login Success!");
      }

      if (data.result.operationMode === "RESTAURANT") {
        router.push("/admin/kitchen");
      } else if (data.result.operationMode === "RETAILER" || data.result.operationMode === "WHOLESALER") {
        router.push("/admin/dashboard");
      } else {
        router.push("/admin/dashboard");
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      toast.error(error.response.data.message);
    } finally {
      dispatch({ type: END_LOADING });
    }
  }, 300);
  debouncedSignin();
};

export const signup = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.signUp(formData);

    dispatch({ type: AUTH, data });
    toast.success("Account Created");

    router.push("/");
    // window.location.reload(true); // Reloads the page
  } catch (error) {
    console.log(error);
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(error.response.data.message);
  }
};

// Create Client Profile
export const createClientProfile = (post, userId) => async (dispatch) => {
  dispatch({ type: START_LOADING });
  try {
    const { data } = await api.createClientProfileApi(post, userId);

    dispatch({ type: CREATE_USER, payload: data });
    dispatch({ type: END_LOADING });
    toast.success("Profile Created");
    return Promise.resolve(); // For managing error in modal
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
    return Promise.reject(error); // For managing error in modal
  }
};

// Create Staff Profile
export const createStaffProfile = (post, userId) => async (dispatch) => {
  dispatch({ type: START_LOADING });
  try {
    const { data } = await api.createStaffProfileApi(post, userId);

    dispatch({ type: CREATE_USER, payload: data });
    dispatch({ type: USER_CREATED });

    toast.success("Profile Created");

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

//Rewrite this
export const getUsers = (userId, role) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchUsers(userId, role);

    dispatch({ type: FETCH_USERS, payload: { data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.response.data);
    toast.error(error.response.data);
    dispatch({ type: END_LOADING });
  }
};

export const getMobileUsers = (userId, role) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchMobileUsers(userId, role);
    // console.log(data)

    dispatch({ type: FETCH_USERS, payload: { data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getUser = (editedUserId, staff_id, role) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.fetchUser(editedUserId, staff_id, role);

    dispatch({ type: FETCH_USER_SINGLE, payload: { agent: data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.message);
    toast.error(error.message);
    dispatch({ type: END_LOADING });
  }
};

export const getMobileUser =
  (id, userId, role, history) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });

      const { data } = await api.fetchMobileUser(id, userId, role, history);

      dispatch({ type: FETCH_USER_SINGLE, payload: { agent: data } });
      dispatch({ type: END_LOADING });
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

export const profileUpdate =
  (userId, dataLoad, agentId) => async (dispatch) => {
    // console.log(userId, "userId", role, "role", agentId, "agentId");
    try {
      // if (role == "ADMIN") {
      // dispatch({ type: START_LOADING });
      const { data } = await api.updateUserRole(userId, dataLoad, agentId);

      dispatch({ type: UPDATE_USER, payload: data });

      // router.push('/admin/users');
      // } else {
      //   toast.error("Only Admins can Update Users!");
      // }
      dispatch({ type: END_LOADING });
      toast.success("Profile Updated");
    } catch (error) {
      // console.log(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };

export const deleteMobileUser = (client_id, userId) => async (dispatch) => {
  try {
    const { data } = await api.apiDeleteMobileUser(client_id, userId);

    dispatch({ type: DELETE_USER, payload: client_id });
    toast.success(data.message);
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};
export const deleteStaffUser = (staff_id, userId) => async (dispatch) => {
  try {
    const { data } = await api.deleteStaffUser(staff_id, userId);
    dispatch({ type: DELETE_USER, payload: staff_id });
    toast.success(data.message);
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

export const getClientsBySearch = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data },
    } = await api.fetchClientsBySearch(searchQuery);

    dispatch({ type: FETCH_CLIENT_BY_SEARCH, payload: { data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};
