import { toast } from 'react-toastify';
import * as api from '../api';
import {
    START_LOADING,
    CREATE_PRODUCT_RW,
    GET_PRODUCT_RW,
    UPDATE_PRODUCT_RW,
    DELETE_PRODUCT_RW,
    END_LOADING
} from '../constants/actionTypes.js';

// Create Product
export const createRetailerWholesalerProduct = (productData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiCreateRetailerWholesalerProduct(productData);
        dispatch({ type: CREATE_PRODUCT_RW, payload: data.data });
        dispatch({ type: END_LOADING });
        toast.success(data.message);
    } catch (error) {
        console.log(error.response?.data?.message || error.message);
        toast.error(error.response?.data?.message || error.message);
        dispatch({ type: END_LOADING });
    }
};

// Fetch Product Details
export const fetchRetailerWholesalerProductDetails = (productId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiFetchRetailerWholesalerProductDetails(productId);
        dispatch({ type: GET_PRODUCT_RW, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.error('Error fetching product details:', error);
        toast.error('Failed to fetch product details: ' + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Update Product
export const updateRetailerWholesalerProduct = (productId, updatedProductData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiUpdateRetailerWholesalerProduct(productId, updatedProductData);
        dispatch({ type: UPDATE_PRODUCT_RW, payload: data });
        dispatch({ type: END_LOADING });
        toast.success('Product updated successfully!');
    } catch (error) {
        console.error('Error updating product:', error);
        toast.error('Failed to update product: ' + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Delete Product
export const deleteRetailerWholesalerProduct = (productId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        await api.apiDeleteRetailerWholesalerProduct(productId);
        dispatch({ type: DELETE_PRODUCT_RW, payload: productId });
        dispatch({ type: END_LOADING });
        toast.success('Product deleted successfully!');
    } catch (error) {
        console.error('Error deleting product:', error);
        toast.error('Failed to delete product: ' + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};
