import {
    FETCH_RESTAURANTS_REQUEST,
    FETCH_RESTAURANTS_SUCCESS,
    FETCH_RESTAURANTS_FAILURE,
    UPDATE_RESTAURANT_REQUEST,
    UPDATE_RESTAURANT_SUCCESS,
    UPDATE_RESTAURANT_FAILURE,
    DELETE_RESTAURANT_REQUEST,
    DELETE_RESTAURANT_SUCCESS,
    DELETE_RESTAURANT_FAILURE,
} from "../constants/actionTypes.js";

const initialState = {
    restaurants: [],
    loading: false,
    error: null,
};

const restaurantReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESTAURANTS_REQUEST:
        case UPDATE_RESTAURANT_REQUEST:
        case DELETE_RESTAURANT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_RESTAURANTS_SUCCESS:
            return {
                ...state,
                loading: false,
                restaurants: action.payload,
            };
        case FETCH_RESTAURANTS_FAILURE:
        case UPDATE_RESTAURANT_FAILURE:
        case DELETE_RESTAURANT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_RESTAURANT_SUCCESS:
            return {
                ...state,
                loading: false,
                restaurants: state.restaurants.map(restaurant =>
                    restaurant._id === action.payload._id || restaurant.owner === action.payload.owner ? action.payload : restaurant
                ),
            };
        case DELETE_RESTAURANT_SUCCESS:
            return {
                ...state,
                loading: false,
                // restaurants: state.restaurants.filter(restaurant => restaurant._id !== action.payload),
                restaurants: action.payload,
            };

        default:
            return state;
    }
};

export default restaurantReducer;




// // src/reducers/restaurantReducer.js
// import {
//     FETCH_RESTAURANTS_REQUEST,
//     FETCH_RESTAURANTS_SUCCESS,
//     FETCH_RESTAURANTS_FAILURE,
//     UPDATE_RESTAURANT_FAILURE,
//     UPDATE_RESTAURANT_REQUEST,
//     UPDATE_RESTAURANT_SUCCESS,
//     DELETE_RESTAURANT_REQUEST,
//     DELETE_RESTAURANT_SUCCESS,
//     DELETE_RESTAURANT_FAILURE
// } from "../constants/actionTypes.js";

// const initialState = {
//     restaurants: [],
//     loading: false,
//     error: null,
// };

// const restaurantReducer = (state = initialState, action) => {
//     switch (action.type) {
//         case FETCH_RESTAURANTS_REQUEST:
//         case UPDATE_RESTAURANT_REQUEST:
//         case DELETE_RESTAURANT_REQUEST:
//             return {
//                 ...state,
//                 loading: true,
//                 error: null,
//             };
//         case FETCH_RESTAURANTS_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 restaurants: action.payload,
//             };
//         case FETCH_RESTAURANTS_FAILURE:
//         case UPDATE_RESTAURANT_FAILURE:
//         case DELETE_RESTAURANT_FAILURE:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload,
//             };
//         case UPDATE_RESTAURANT_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 restaurants: state.restaurants.map(restaurant =>
//                     restaurant._id === action.payload._id ? action.payload : restaurant
//                 ),
//             };
//         case DELETE_RESTAURANT_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 // restaurants: state.restaurants.filter(restaurant => restaurant._id !== action.payload),
//                 restaurants: action.payload,
//             };
//         default:
//             return state;
//     }
// };

// export default restaurantReducer;
