export const CREATE_ZONE = "CREATE_ZONE";
export const FETCH_DELIVERY_ZONES = "FETCH_DELIVERY_ZONES";
export const FETCH_DELIVERY_ZONE = "FETCH_DELIVERY_ZONE";
export const RESET_DELIVERY_ZONE = "RESET_DELIVERY_ZONE";
export const UPDATE_DELIVERY_ZONE = "UPDATE_DELIVERY_ZONE";
export const DELETE_DELIVERY_ZONE = "DELETE_DELIVERY_ZONE";
export const DELIVERY_ZONE_FETCH_FAIL = "DELIVERY_ZONE_FETCH_FAIL";

export const ZONE_CREATE_REQUEST = "ZONE_CREATE_REQUEST";
export const ZONE_CREATE_SUCCESS = "ZONE_CREATE_SUCCESS";
export const ZONE_CREATE_FAIL = "ZONE_CREATE_FAIL";
export const ZONE_CREATE_RESET = "ZONE_CREATE_RESET";
