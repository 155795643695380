import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Collapse, Nav } from "react-bootstrap";
import { Avatar } from "@material-ui/core";
import useStyles from "./sidebarStyles";
import { getUserFromLocalStorage } from "../../utilities/user.js";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";

function Sidebar({ routes, image, background }) {
  const classes = useStyles();
  let location = useLocation();
  const history = useHistory();

  const [user, setUser] = useState(getUserFromLocalStorage());
  const [userCollapseState, setUserCollapseState] = useState(false);
  const [state, setState] = useState({});
  // console.log("Encrypted user Sidebar:", localStorage.getItem("profile"));
  // console.log("Token sidebar:", localStorage.getItem("token"));
  // console.log(restaurantProfile, "restaurantProfile in Sidebar")

  const { profile: retailerWholesalerProfile } = useSelector((state) => state.retailerWholesalerProfile);
  const { profile: restaurantProfile } = useSelector((state) => state.restaurantProfile);


  useAuth(true); // Ensure user is authenticated before rendering the sidebar

  useEffect(() => {
    setState(getCollapseStates(routes));
  }, [routes]);

  useEffect(() => {
    setUser(getUserFromLocalStorage());
  }, [location]);

  const getCollapseStates = (routes) => {
    if (!routes) {
      return {};
    }
    let initialState = {};
    routes.map((prop) => {
      if (prop.hidden) {
        return null;
      }
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname === routes[i].layout + routes[i].path) {
        return true;
      }
    }
    return false;
  };

  const createLinks = (routes) => {
    if (!routes) {
      return null;
    }
    return routes.map((prop, key) => {
      if (prop.redirect || prop.hidden) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <Nav.Item
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            as="li"
            key={key}
          >
            <Nav.Link
              className={state[prop.state] ? "collapsed" : ""}
              data-toggle="collapse"
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, ...st });
              }}
              aria-expanded={state[prop.state]}
            >
              <i className={prop.icon}></i>
              <p>
                {prop.name} <b className="caret"></b>
              </p>
            </Nav.Link>
            <Collapse in={state[prop.state]}>
              <div>
                <Nav as="ul">{createLinks(prop.views)}</Nav>
              </div>
            </Collapse>
          </Nav.Item>
        );
      }
      return (
        <Nav.Item
          className={activeRoute(prop.layout + prop.path)}
          key={key}
          as="li"
        >
          <Nav.Link to={prop.layout + prop.path} as={Link}>
            {prop.icon ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </Nav.Link>
        </Nav.Item>
      );
    });
  };

  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  const profileTab = () => {
    history.push("/admin/profile");
  };

  return (
    <>
      <div className="sidebar" data-color={background} data-image={image}>
        <div className="sidebar-wrapper">
          <div className="logo">
            <a className="simple-text logo-mini" href="#">
              <div className="logo-img">
                <img
                  src={require("assets/img/lusukuLogo80_80.png")}
                  alt="lusuku_logo"
                />
              </div>
            </a>
            <a
              className="simple-text logo-normal pt-3"
              href="#"
              style={{
                textDecoration: "none",
                fontStyle: "italic",
                fontSize: "1.6rem",
                fontFamily: "monospace",
                fontWeight: "bold",
              }}
            >
              Lusuku
            </a>
          </div>
          <div className="user">
            <div className="photo">
              {user?.result?.name?.charAt(0) ? (
                <Avatar
                  className={classes.purple}
                  alt={user?.result?.name}
                  src={user?.result?.imageUrl || restaurantProfile?.imageUrl || retailerWholesalerProfile?.imageUrl}
                >
                  {user?.result?.name?.charAt(0) || ""}
                </Avatar>
              ) : (
                <Avatar
                  src={require("assets/img/Vendor-Dashboard-Icon.png").default || restaurantProfile?.imageUrl || retailerWholesalerProfile?.imageUrl}
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
              )}
            </div>
            <div className="info">
              <p
                className={userCollapseState ? "collapsed" : ""}
                data-toggle="collapse"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  setUserCollapseState(!userCollapseState);
                }}
                aria-expanded={userCollapseState}
              >
                <span style={{ textTransform: "capitalize" }}>
                  {user?.result?.name || user?.result?.businessName || "Guest"}
                  <b className="caret"></b>
                </span>
              </p>
              <Collapse id="collapseExample" in={userCollapseState}>
                <div>
                  <Nav as="ul">
                    <li>
                      <a
                        className="profile-dropdown"
                        href="#profile"
                        onClick={profileTab}
                      >
                        <span className="sidebar-mini">EP</span>
                        <span className="sidebar-normal">Edit Profile</span>
                      </a>
                    </li>
                  </Nav>
                </div>
              </Collapse>
            </div>
          </div>
          <Nav as="ul">{createLinks(routes)}</Nav>
        </div>
        <div
          className="sidebar-background"
          style={{
            backgroundImage: "url('" + image + "')",
          }}
        ></div>
      </div>
    </>
  );
}

let linkPropTypes = {
  path: PropTypes.string,
  layout: PropTypes.string,
  name: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
};

Sidebar.defaultProps = {
  image: "",
  background: "black",
  routes: [],
};

Sidebar.propTypes = {
  image: PropTypes.string,
  background: PropTypes.oneOf([
    "black",
    "azure",
    "green",
    "orange",
    "red",
    "purple",
  ]),
  routes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        ...linkPropTypes,
        icon: PropTypes.string,
      }),
      PropTypes.shape({
        collapse: true,
        path: PropTypes.string,
        name: PropTypes.string,
        state: PropTypes.string,
        icon: PropTypes.string,
        views: PropTypes.shape({
          ...linkPropTypes,
          mini: PropTypes.string,
        }),
      }),
    ])
  ),
};

export default Sidebar;
