import { START_LOADING } from "constants/actionTypes";
import {
  ADD_TO_CART,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
  CLEAR_CART,
  END_LOADING,
  REMOVE_FROM_CART,
  RESET_CART,
} from "../constants/cartConstants";

export const cartReducer = (
  state = { cartItems: [], paymentMethod: "", product: {}, isLoading: false },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };

    case ADD_TO_CART:
      const item = action.payload;
      const existItem = state.cartItems.find((x) => x._id === item._id);

      if (existItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x._id === existItem._id ? item : x
          ),
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        };
      }

    case REMOVE_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x._id !== action.payload),
      };

    case CLEAR_CART:
      return {
        ...state,
        cartItems: [],
        shippingAddress: {},
        paymentMethod: "",
        itemsPrice: 0,
        totalPrice: "",
      };

    case CART_SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
      };

    case RESET_CART:
      return {
        ...state,
        shippingAddress: [],
        paymentMethod: {},
        cartItems: [],
      };

    case CART_SAVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
        isLoading: false,
      };

    case END_LOADING:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};
