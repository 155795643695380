import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  media: {
    height: 0,
    paddingTop: "80%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    // backgroundBlendMode: 'darken',
  },
  mediaOuttaStock: {
    height: 0,
    paddingTop: "80%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backgroundBlendMode: "darken",
  },
  pOuttaStock: {
    justifyContent: "center",
    textAlign: "center",
    // color: " rgba(254, 240, 246, 0.8)",
    color: "#D0DDF3",
    fontSize: "1.5rem",
    fontWeight: "bold",
    transform: "rotate(-22deg) skew( -15deg)",
    paddingBottom: "30px",
  },

  cancelButton: {
    backgroundColor: "#003d66",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#002333",
      color: "#ffffff",
    },
  },

  cancelActivationButton: {
    backgroundColor: "#002333",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#404040",
      color: "#ffffff",
    },
  },
  activateListingButton: {
    color: "#ffffff",
    backgroundColor: "#0487D9",
    "&:hover": {
      backgroundColor: "#13676F",
      color: "#ffffff",
    },
  },
  deleteButton: {
    color: "#ffffff",
    backgroundColor: "#ff3b4b",
    "&:hover": {
      backgroundColor: "#ff7d7d",
      color: "#ffffff",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "15px",
    height: "100%",
    position: "relative",
  },
  inCartChip: {
    color: "#037D30",
  },
  moreGrey: {
    color: "#868686",
  },
  overlay2: {
    position: "absolute",
    top: "5px",
    right: "-6px",
    color: "white",
  },
  grid: {
    display: "flex",
  },
  details: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px",
  },
  unitCount: {
    padding: "0 16px",
    paddingBottom: "5px",
    fontSize: "0.6rem",
    fontStyle: "italic",
  },
  title: {
    padding: "0 16px",
  },
  cardActions: {
    padding: "0 16px 8px 16px",
    display: "flex",
    justifyContent: "space-between",
  },
  cardAction: {
    display: "block",
    textAlign: "initial",
  },
});
