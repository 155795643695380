import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Row, Col, ListGroup, Image, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "views/Components/Message";
import CheckoutSteps from "views/Components/CheckoutSteps";
import { createOrder } from "../../actions/webOrderActions";
import { ORDER_CREATE_RESET } from "constants/webOrderConstants";
import { Paper } from "@material-ui/core";
import { getShippingCosts } from "../../actions/shippingCostActions";
import { getUserFromLocalStorage } from "utilities/user";

const PlaceOrderScreen = ({ history }) => {
  const user = getUserFromLocalStorage();

  // const serverShippingFees = useSelector((state) => state.shippingCostServer);

  const cartItems = useSelector((state) => state.cart);
  const cart = useSelector((state) => state.cart);
  // const { zones } = useSelector((state) => state.deliveryZones);

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error, loading } = orderCreate;

  // Get Shipping cost from the server
  const { shippingCosts, loading: loadingShippingCost } = useSelector(
    (state) => state.shippingCosts
  );

  const serverShippingFees = shippingCosts.map((cost) => cost.cost);
  const serverShippingFeesID = shippingCosts.map((cost) => cost._id);

  const { shippingAddress } = cart;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!shippingCosts.length > 0) {
      dispatch(getShippingCosts());
    }
  }, []);

  if (!cartItems?.shippingAddress?.customerNumber) {
    history.push("/admin/shipping");
  } else if (!cartItems.paymentMethod) {
    history.push("/admin/payment");
  }

  //   Calculate prices
  cartItems.itemsPrice = cartItems.cartItems.reduce(
    (acc, item) => acc + item.price * item.qty,
    0
  );

  // cartItems.shippingPrice = cartItems.itemsPrice > 4000 ? 2000 : 3000; // If Item Price is greater than 50k, Shipping fees are 8k otherwise 2k

  // cartItems.taxPrice = Number((0.18 * cartItems.itemsPrice).toFixed(2));

  cartItems.totalPrice =
    // Number(cartItems.itemsPrice) + Number(cartItems.shippingPrice)
    // (Number(cartItems.itemsPrice) + shippingAddress?.shippingCost).toFixed(2);
    // Number(cartItems.itemsPrice).toFixed(2);
    // Number(cartItems.itemsPrice) + Number(5000); // Adding shipping fees of 5k manually
    Number(cartItems.itemsPrice) + Number(serverShippingFees); // Adding shipping fees from the server
  // TOTAL PRICE CALCULATIONS WITH VAT INCLUDED
  // cartItems.totalPrice = (
  //   Number(cartItems.itemsPrice) +
  //   Number(cartItems.shippingPrice) +
  //   Number(cartItems.taxPrice)
  // ).toFixed(2);

  // console.log(loading, "orderYYYYYYY");

  // const clientId = cartItems?.shippingAddress?.selectedClient?._id;
  // delete cartItems.shippingAddress.selectedClient;

  useEffect(() => {
    if (success && !loading) {
      dispatch({ type: ORDER_CREATE_RESET });
      history.push(`/admin/order/${order?._id}`);
    } else {
      history.push("/admin/placeorder");
    }
  }, [dispatch, history, success]);

  const placeOrderHandler = () => {
    // console.log('placeOrderHandler')
    dispatch(
      createOrder({
        // orderItems: cartItems?.cartItems,
        orderItems: cartItems?.cartItems.map((item) => ({
          ...item,
          vendorId: item.vendorId,
        })),
        shippingAddress: cartItems?.shippingAddress,
        paymentMethod: cartItems?.paymentMethod,
        itemsPrice: cartItems?.itemsPrice,
        // shippingCost: shippingAddress?.selectedVillage?.shippingCost,
        shippingCost: serverShippingFees[0],
        shippingCost_id: serverShippingFeesID[0],
        // taxPrice: cartItems?.taxPrice,
        totalPrice: cartItems?.totalPrice,
        // client_id: clientId,
        client_id: shippingAddress?.client_id,
        // client_id: shippingAddress?.selectedClient?.value,
        agent_id: user?.result?._id,
        orderSrc: "Agent",
      })
    );
  };

  return (
    <>
      <Col md="10" className="mx-auto mt-3 pt-4">
        <Paper className="mx-auto mt-3" elevation={4}>
          <Card.Body>
            <CheckoutSteps step1 step2 step3 step4 />
            <Row>
              <Col md={8}>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <h2>Shipping</h2>
                    <p>
                      <strong style={{ textTransform: "uppercase" }}>
                        Delivery Address:{" "}
                      </strong>
                      {cartItems?.shippingAddress?.locationName?.name},{" "}
                      {cartItems?.shippingAddress?.locationName?.vicinity},{" "}
                      {/* {cartItems?.shippingAddress?.city}{" "} */}
                      {cartItems?.shippingAddress?.country}
                    </p>
                    <p>
                      Contact Number:{" "}
                      {cartItems?.shippingAddress?.customerNumber}
                    </p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <h2>Payment Method</h2>
                    <strong style={{ textTransform: "uppercase" }}>
                      Method:{" "}
                    </strong>
                    {cartItems?.paymentMethod}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <h2>Order Items Summary</h2>
                    {cartItems?.cartItems?.length === 0 ? (
                      <Message>Your cart is empty</Message>
                    ) : (
                      <ListGroup variant="flush">
                        {cartItems?.cartItems?.map((item, index) => (
                          <ListGroup.Item key={index}>
                            <Row>
                              <Col md={1}>
                                <Image
                                  src={item.imageUrl}
                                  alt={item.name}
                                  fluid
                                  rounded
                                />
                              </Col>
                              <Col>
                                <Link to={`/admin/listing-details/${item._id}`}>
                                  {item.name}
                                </Link>
                              </Col>
                              <Col md={6}>
                                {item.qty} x {item.price} = <b>Shs. </b>
                                {item.qty * item.price} /=
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col md={4}>
                <Paper elevation={5}>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <h2>Order Summary</h2>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col style={{ color: "#1D438A" }}>Items Cost</Col>
                        <Col>
                          Shs{" "}
                          {cartItems?.itemsPrice
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col style={{ color: "#1D438A" }}>Shipping</Col>
                        {/* <Col>Shs {cartItems?.shippingPrice}</Col> */}
                        {/* <Col>
                          Shs{" "}
                          {shippingAddress?.shippingCost
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          5,000
                        </Col> */}
                        {/* Get Shipping Fees from the server */}
                        <Col>
                          {serverShippingFees
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    {/* <ListGroup.Item>
                <Row>
                  <Col>VAT (18%)</Col>
                  <Col>Shs {cartItems.taxPrice}</Col>
                </Row>
              </ListGroup.Item> */}
                    <ListGroup.Item>
                      <Row>
                        <Col style={{ color: "#B3370B", fontWeight: "bold" }}>
                          Total:
                        </Col>
                        <Col style={{ color: "#B3370B", fontWeight: "bold" }}>
                          Shs{" "}
                          {cartItems?.totalPrice
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      {error && <Message variant="danger">{error}</Message>}
                    </ListGroup.Item>
                    {/* <ListGroup.Item> */}
                    <Button
                      type="button"
                      // className="btn-block"
                      className="bg-success mt-2"
                      disabled={cartItems?.cartItems === 0}
                      onClick={placeOrderHandler}
                    >
                      Place Order
                    </Button>
                    {/* </ListGroup.Item> */}
                  </ListGroup>
                </Paper>
              </Col>
            </Row>
          </Card.Body>
        </Paper>
      </Col>
    </>
  );
};

export default PlaceOrderScreen;
