import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { Link } from "react-router-dom";
import useStyles from "./paginationStyles";
import { useDispatch, useSelector } from "react-redux";
import { getListings } from "../../../actions/listingsActions";
import { getUserFromLocalStorage } from "utilities/user";

const Paginate = ({ page }) => {
  const { numberOfPages } = useSelector((state) => state.listings);
  const lastUpdateTimestamp = useSelector(
    (state) => state.listings.lastUpdateTimestamp
  );
  const user = getUserFromLocalStorage();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (page) {
      dispatch(getListings(user?.result?._id, page));
    }
  }, [page, lastUpdateTimestamp]);

  return (
    <Pagination
      classes={{ ul: classes.ul }}
      count={numberOfPages}
      page={Number(page) || 1}
      variant="text"
      color="secondary"
      renderItem={(item) => (
        <PaginationItem
          {...item}
          component={Link}
          to={`/admin/shopCards/?page=${item.page}`}
        />
      )}
    />
  );
};

export default Paginate;
