import * as api from '../api';
import { RESTAURANT_VENDOR_STATS_FAIL, RESTAURANT_VENDOR_STATS_REQUEST, RESTAURANT_VENDOR_STATS_SUCCESS } from "../constants/webOrderConstants";

export const fetchRestaurantVendorOrderStats = (vendorId) => async (dispatch) => {
    try {
        dispatch({ type: RESTAURANT_VENDOR_STATS_REQUEST });

        const { data } = await api.apiFetchRestaurantVendorStat(vendorId);
        // console.log("Fetched stats from API:", data);
        dispatch({
            type: RESTAURANT_VENDOR_STATS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.error("Failed to fetch stats:", error);
        dispatch({
            type: RESTAURANT_VENDOR_STATS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};
