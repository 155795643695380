import { toast } from "react-toastify";

import {
  VENDOR_REGISTER_FAIL,
  VENDOR_REGISTER_REQUEST,
  VENDOR_REGISTER_SUCCESS,
  GET_SELLERS,
  GET_SELLERS_FAIL,
  GET_SELLER,
  GET_SELLER_FAIL,
  DELETE_SELLER,
  UPDATE_SELLER_REQUEST,
  UPDATE_SELLER_SUCCESS,
} from "../constants/actionTypes.js";
import * as api from "../api/index.js";
import { START_LOADING } from "constants/webOrderConstants.js";
import { END_LOADING } from "constants/webOrderConstants.js";

// Vendor Registration Action
export const submitVendorRegistration =
  (vendorFormData) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: VENDOR_REGISTER_REQUEST });

        const { data } = await api.submitVendorRegistration(vendorFormData);
        dispatch({ type: VENDOR_REGISTER_SUCCESS, payload: data });
        toast.success(data.message);
        resolve(data);
      } catch (error) {
        console.log(error);
        dispatch({
          type: VENDOR_REGISTER_FAIL,
          payload: error.response.data.message,
        });
        toast.error(error.response.data.message);
        reject(error);
      }
    });
  };

// Get sellers
export const getSellers = (staff_id) => async (dispatch) => {
  try {
    const { data } = await api.fetchSellers(staff_id);
    dispatch({ type: GET_SELLERS, payload: data });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_SELLERS_FAIL,
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
  }
};

// Get seller Details
export const getSellerDetail = (seller_id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchSellerDetail(seller_id);
    dispatch({ type: GET_SELLER, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_SELLER_FAIL,
      payload: error.response.data.message,
    });
    toast.error(error.response.data.message);
  }
};

// Soft Delete Seller
export const softDeleteSeller = (seller_id, staff_id) => async (dispatch) => {
  try {
    const { data } = await api.softDeleteSellerApi(seller_id, staff_id);
    dispatch({ type: DELETE_SELLER, payload: data });
    toast.success(data.message);
    dispatch(getSellers(staff_id));
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};
// Delete Seller
export const deleteSeller = (seller_id, staff_id) => async (dispatch) => {
  try {
    const { data } = await api.deleteSellerApi(seller_id, staff_id);
    dispatch({ type: DELETE_SELLER, payload: data });
    toast.success(data.message);
    dispatch(getSellers(staff_id));
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

// Verify Seller
export const verifySeller = (
  seller_id,
  staff_id,
  sellerVerification,
  sellerVerificationDate
) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_SELLER_REQUEST });

    try {
      const { data } = await api.verifySellerApi(seller_id, staff_id, {
        sellerVerification,
        sellerVerificationDate,
      });
      dispatch({ type: UPDATE_SELLER_SUCCESS, payload: data });
      dispatch(getSellerDetail(seller_id));
      toast.success(data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
};

// Reactivate Seller
export const reactivateSeller = (seller_id, staff_id) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_SELLER_REQUEST });

    try {
      const { data } = await api.reactivateSellerApi(seller_id, staff_id, {
        isActive: true,
      });
      dispatch({ type: UPDATE_SELLER_SUCCESS, payload: data });
      dispatch(getSellerDetail(seller_id));
      dispatch(getSellers(staff_id)); // Add this line to refetch updated sellers
      toast.success(data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
};
