import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Table,
  Row,
  Col,
  Button,
  Spinner,
  Alert,
  Form,
  Modal,
  ProgressBar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";
import { withStyles } from "@material-ui/core/styles";
import { FormControlLabel, Switch, Badge } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import {
  MdCancel,
  MdContacts,
  MdLocalPrintshop,
  MdSportsMotorsports,
  MdArrowBackIosNew,
  MdOutlineCreditCardOff,
  MdOutlineCreditCard,
  MdMap,
} from "react-icons/md";
import { toast } from "react-toastify";
import {
  getRetailerWholesalerOrderDetails,
  updateRetailerWholesalerOrderStatus,
} from "../../../../actions/retailerWholesalerVendorOrdersActions";
import "./RetailerWholesalerOrderDetails.css";
import { getUserFromLocalStorage } from "../../../../utilities/user.js";
import { markOrderAsPaid } from "../../../../actions/webOrderActions.js";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const isDevelopment = process.env.NODE_ENV === "development";

const SuperAdminOrderDetails = () => {
  const user = getUserFromLocalStorage();
  const isSuperUserId = user?.result?._id; //We use superuser to be able to get all orderItems for all retailers in this specific cart basket
  const { orderId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [riders, setRiders] = useState([]);
  const [riderId, setRiderId] = useState("");
  const [loadingRiders, setLoadingRiders] = useState(true);
  const [elapsedAssignedTime, setElapsedAssignedTime] = useState(0);
  const [elapsedDispatchedTime, setElapsedDispatchedTime] = useState(0);

  const [showMapModal, setShowMapModal] = useState(false);

  const orderDetails = useSelector(
    (state) => state.retailerWholesalerVendorOrders
  );
  const { order, loading, error, isUpdateDeliveryStatusComplete } =
    orderDetails;

  useEffect(() => {
    dispatch(getRetailerWholesalerOrderDetails(orderId, isSuperUserId));
    if (isUpdateDeliveryStatusComplete) {
      dispatch(getRetailerWholesalerOrderDetails(orderId, isSuperUserId));
    }
  }, [isUpdateDeliveryStatusComplete, orderId, dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_SERVER_URL;

    const socket = io(
      socketUrl,
      {
        query: {
          user: user?.result?._id,
        },
      },
      {
        transports: ["websocket"],
      }
    );

    socket.on("connect", () => {
      if (isDevelopment) {
        console.log("Webclient Connected");
      }
      // Emit the getRiders event after connecting
      socket.emit("getRiders");
    });

    socket.on("initialRidersList", (socketData) => {
      setRiders(socketData);
      setLoadingRiders(false);
    });

    socket.on("updatedRidersList", (updatedRiders) => {
      setRiders(updatedRiders);
    });

    socket.on("riderAdded", (newRider) => {
      setRiders((prevRiders) => [...prevRiders, newRider]);
    });

    socket.on("riderUpdated", (updatedRider) => {
      setRiders((prevRiders) =>
        prevRiders.map((rider) =>
          rider._id === updatedRider._id ? updatedRider : rider
        )
      );
    });

    socket.on("riderDeleted", (deletedRider) => {
      setRiders((prevRiders) =>
        prevRiders.filter((rider) => rider._id !== deletedRider._id)
      );
    });

    return () => {
      socket.disconnect();
    };
  }, [user?.result?._id]);

  const handleBack = () => {
    history.push("/admin/paginatedOrdersTable");
  };

  const handleUpdateStatus = (newStatus) => {
    if (newStatus === "ASSIGNED" && !riderId) {
      toast.warn("Please select a rider before assigning the order.");
      return;
    }
    if (newStatus === "ASSIGNED") {
      assignRetailerWholesalerOrderToRider();
    } else {
      dispatch(updateRetailerWholesalerOrderStatus(order?._id, newStatus));
    }
  };

  const handleCancelOrder = () => {
    dispatch(updateRetailerWholesalerOrderStatus(order?._id, "REJECTED"));
    setShowCancelModal(false);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleContactCustomer = () => {
    alert(`Contacting customer: ${order?.shippingAddress.customerNumber}`);
  };

  const handleMarkAsPaid = () => {
    if (user?.result?.userType !== "LUSUKU STAFF") {
      toast.warn("🚫 You are not authorized to mark order as paid!");
      return;
    } else {
      dispatch(markOrderAsPaid(order?._id)); // Dispatch the action to mark order as paid
    }
  };

  const assignRetailerWholesalerOrderToRider = () => {
    const delivery_status = "ASSIGNED";
    const assignedTime = Date.now();
    toast.success(`Assigning order ${order?._id} to rider ${riderId}`);
    localStorage.setItem(`order_${order?._id}_assignedTime`, assignedTime);
    dispatch(updateRetailerWholesalerOrderStatus(order?._id, delivery_status));
    setTimeout(() => {
      dispatch(getRetailerWholesalerOrderDetails(order?._id, isSuperUserId));
    }, 500);
  };

  const handleDispatchRetailerWholesalerOrder = () => {
    const delivery_status = "DISPATCHED";
    const dispatchedTime = Date.now();
    localStorage.setItem(`order_${order?._id}_dispatchedTime`, dispatchedTime);
    dispatch(updateRetailerWholesalerOrderStatus(order?._id, delivery_status));
    setTimeout(() => {
      dispatch(getRetailerWholesalerOrderDetails(order?._id, isSuperUserId));
    }, 500);
  };

  const handleDeliverRetailerWholesalerOrder = () => {
    const delivery_status = "DELIVERED";
    dispatch(updateRetailerWholesalerOrderStatus(order?._id, delivery_status));
    setTimeout(() => {
      dispatch(getRetailerWholesalerOrderDetails(order?._id, isSuperUserId));
    }, 500);
  };

  const getProgress = () => {
    switch (order?.delivery_status) {
      case "PROCESSING":
        return 20;
      case "ASSIGNED":
        return 40;
      case "DISPATCHED":
        return 60;
      case "DELIVERED":
        return 100;
      case "PAID":
        return 100;
      default:
        return 0;
    }
  };

  const getBadgeStyle = (status) => {
    switch (status) {
      case "PROCESSING":
        return {
          backgroundColor: "orange",
          color: "white",
          borderRadius: "10px",
          padding: "5px",
        };
      case "ASSIGNED":
        return {
          backgroundColor: "blue",
          color: "white",
          borderRadius: "10px",
          padding: "5px",
        };
      case "DISPATCHED":
        return {
          backgroundColor: "purple",
          color: "white",
          borderRadius: "10px",
          padding: "5px",
        };
      case "DELIVERED":
        return {
          backgroundColor: "green",
          color: "white",
          borderRadius: "10px",
          padding: "5px",
        };
      case "REJECTED":
        return {
          backgroundColor: "red",
          color: "white",
          borderRadius: "10px",
          padding: "5px",
        };
      default:
        return {
          backgroundColor: "grey",
          color: "white",
          borderRadius: "10px",
          padding: "5px",
        };
    }
  };

  const getPaidBadgeStyle = (isPaid) => {
    return {
      backgroundColor: isPaid ? "green" : "#2C3A40",
      color: "white",
      borderRadius: "10px",
      padding: "3px",
    };
  };

  const canCancelOrder =
    order?.delivery_status === "PROCESSING" ||
    order?.delivery_status === "PAID";

  const getDisabledStatusOptions = () => {
    const disabledOptions = {
      PROCESSING: order?.delivery_status !== "PROCESSING",
      ASSIGNED:
        order?.delivery_status !== "PROCESSING" &&
        order?.delivery_status !== "ASSIGNED",
      DISPATCHED:
        order?.delivery_status !== "ASSIGNED" &&
        order?.delivery_status !== "DISPATCHED",
      DELIVERED:
        order?.delivery_status !== "DISPATCHED" &&
        order?.delivery_status !== "DELIVERED",
      REJECTED:
        order?.delivery_status === "DELIVERED" ||
        order?.delivery_status === "REJECTED",
    };
    return disabledOptions;
  };

  const disabledStatusOptions = getDisabledStatusOptions();

  useEffect(() => {
    if (order?.delivery_status === "ASSIGNED") {
      const storedAssignedTime = localStorage.getItem(
        `order_${order?._id}_assignedTime`
      );
      if (storedAssignedTime) {
        const elapsed = Math.floor(
          (Date.now() - parseInt(storedAssignedTime, 10)) / 1000
        );
        setElapsedAssignedTime(elapsed);
      }
    }
    if (order?.delivery_status === "DISPATCHED") {
      const storedDispatchedTime = localStorage.getItem(
        `order_${order?._id}_dispatchedTime`
      );
      if (storedDispatchedTime) {
        const elapsed = Math.floor(
          (Date.now() - parseInt(storedDispatchedTime, 10)) / 1000
        );
        setElapsedDispatchedTime(elapsed);
      }
    }
    const interval = setInterval(() => {
      if (order?.delivery_status === "ASSIGNED") {
        setElapsedAssignedTime((prev) => prev + 1);
      }
      if (order?.delivery_status === "DISPATCHED") {
        setElapsedDispatchedTime((prev) => prev + 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [order?.delivery_status, order?._id]);

  if (loading) {
    return (
      <Spinner
        animation="grow"
        variant="primary"
        style={{
          marginTop: "70px",
          top: "50%",
          left: "50%",
          position: "fixed",
        }}
      />
    );
  }

  if (error) {
    return (
      <Alert variant="danger" style={{ marginTop: "70px" }}>
        {error}
      </Alert>
    );
  }

  const handleShowMap = () => {
    setShowMapModal(true);
  };

  const handleCloseMap = () => {
    setShowMapModal(false);
  };

  return (
    <Container fluid>
      <Row className="mb-2">
        <Col md="12">
          <Button variant="secondary" onClick={handleBack}>
            <MdArrowBackIosNew
              style={{
                marginRight: "3px",
                fontSize: "19px",
                color: "#ffff",
                fontWeight: "bold",
              }}
            />
            Back to Orders
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card className="shadow-sm">
            <Card.Header
              className="justify-content-between align-items-center"
              style={{
                backgroundColor: "#012030",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Card.Title as="h4" style={{ color: "white" }}>
                Order Details - Order #{order?._id}
              </Card.Title>
              <Button
                style={{
                  backgroundColor: "#13678A",
                  color: "white",
                  border: "none",
                  fontSize: "14px",
                  padding: "10px",
                }}
                onClick={handlePrint}
              >
                Print Receipt
                <MdLocalPrintshop style={{ marginLeft: "4px" }} />
              </Button>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md="6">
                  <h5
                    style={{
                      color: "#023059",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Order Items
                  </h5>
                  <Table className="table-hover table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Image</th>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order?.orderItems?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="image-container">
                              <img
                                src={item.image}
                                alt={item.name}
                                className="table-image"
                              />
                              <div className="image-hover">
                                <img
                                  src={item.image}
                                  alt={item.name}
                                  className="hover-image"
                                />
                              </div>
                            </div>
                          </td>
                          <td>{item.name}</td>
                          <td>{item.qty}</td>
                          <td>Ush {item.price.toLocaleString()}</td>
                          <td>
                            Ush {(item.qty * item.price).toLocaleString()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
                <Col md="6">
                  <h5
                    style={{
                      color: "#023059",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Order Summary
                  </h5>
                  <Card
                    className="mb-3 p-3 shadow"
                    style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}
                  >
                    <p style={{ fontSize: "14px" }}>
                      <strong
                        style={{
                          color: "#012030",
                          fontSize: "14px",
                        }}
                      >
                        Customer Name:
                      </strong>
                      {} {order?.customerName}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      <strong
                        style={{
                          color: "#012030",
                          fontSize: "14px",
                        }}
                      >
                        Payment Method:
                      </strong>
                      {} {order?.paymentMethod}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      <strong
                        style={{
                          color: "#012030",
                          fontSize: "14px",
                        }}
                      >
                        Items Price:
                      </strong>
                      {} Ush {order?.itemsPrice?.toLocaleString()}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      <strong
                        style={{
                          color: "#012030",
                          fontSize: "14px",
                        }}
                      >
                        Tax Price:
                      </strong>
                      {} Ush {order?.taxPrice?.toLocaleString()}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      <strong
                        style={{
                          color: "#080",
                          fontSize: "14px",
                        }}
                      >
                        Total Price:
                      </strong>
                      {} Ush {order?.totalPrice?.toLocaleString()}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      <strong
                        style={{
                          color: "#012030",
                          fontSize: "14px",
                        }}
                      >
                        Payment Status:
                      </strong>{" "}
                      <Badge
                        overlap="rectangular"
                        style={getPaidBadgeStyle(order?.isPaid)}
                      >
                        {order?.isPaid ? (
                          <MdOutlineCreditCard
                            style={{
                              fontSize: "14px",
                              verticalAlign: "middle",
                            }}
                          />
                        ) : (
                          <MdOutlineCreditCardOff
                            style={{
                              fontSize: "14px",
                              verticalAlign: "middle",
                            }}
                          />
                        )}
                      </Badge>
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      <strong
                        style={{
                          color: "#012030",
                          fontSize: "14px",
                        }}
                      >
                        Order Status:
                      </strong>{" "}
                      <Badge
                        overlap="rectangular"
                        style={getBadgeStyle(order?.delivery_status)}
                      >
                        {order.delivery_status}
                      </Badge>
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      <strong
                        style={{
                          color: "#012030",
                          fontSize: "14px",
                        }}
                      >
                        Order Date:
                      </strong>
                      {}{" "}
                      {moment(order?.createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </p>
                  </Card>
                  <h5
                    style={{
                      color: "#023059",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Shipping Address
                  </h5>
                  <Card
                    className="p-3 shadow"
                    style={{ boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)" }}
                  >
                    {order?.shippingAddress?.locationName?.name && (
                      <p style={{ fontSize: "14px" }}>
                        {order?.shippingAddress?.locationName?.name},
                      </p>
                    )}
                    <p style={{ fontSize: "14px" }}>
                      {order?.shippingAddress?.locationName?.vicinity}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      <strong
                        style={{
                          color: "#012030",
                          fontSize: "15px",
                        }}
                      >
                        Contact Number:
                      </strong>
                      <br />
                      <span style={{ color: "#004152" }}>
                        <FaPhoneAlt
                          style={{
                            fontSize: "15px",
                            color: "#004152",
                            marginRight: "5px",
                          }}
                        />
                        {order?.shippingAddress?.customerNumber}
                      </span>
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      <strong
                        style={{
                          color: "#012030",
                          fontSize: "15px",
                        }}
                      >
                        LatLng:
                      </strong>
                      <span style={{ color: "#004152" }}>
                        <br />
                        {order?.shippingAddress?.latLng?.lat} ||{" "}
                        {order?.shippingAddress?.latLng?.lng}
                      </span>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="map-tooltip">
                            View Delivery Address on Map!
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="link"
                          onClick={handleShowMap}
                          style={{
                            padding: 0,
                            marginLeft: "10px",
                            size: "30px",
                          }}
                        >
                          <MdMap size={30} color="#063" />
                        </Button>
                      </OverlayTrigger>
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      <strong
                        style={{
                          color: "#012030",
                          fontSize: "15px",
                        }}
                      >
                        Delivery Address:
                      </strong>
                      <span style={{ color: "#004152" }}>
                        <br />
                        {order?.shippingAddress?.address}
                      </span>
                    </p>
                  </Card>
                  {/* Map Modal */}
                  <Modal show={showMapModal} onHide={handleCloseMap} size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title>
                        Delivery Location Map ||{" "}
                        {order?.shippingAddress?.address}
                      </Modal.Title>
                      {/* Divider line */}
                      <hr
                        style={{
                          borderTop: "1px solid #063",
                          width: "100%",
                          marginTop: "5px",
                        }}
                      />
                    </Modal.Header>
                    <Modal.Body>
                      <iframe
                        width="100%"
                        height="450"
                        frameBorder="0"
                        style={{ border: 0 }}
                        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${order?.shippingAddress?.latLng?.lat},${order?.shippingAddress?.latLng?.lng}&zoom=15`}
                        allowFullScreen
                      ></iframe>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={handleCloseMap}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col
                  md="6"
                  className="d-flex flex-column justify-content-between"
                >
                  <Form.Group
                    controlId="formRiderSelect"
                    className="shadow p-3 h-100"
                    style={{
                      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                      height: "100%",
                    }}
                  >
                    <Form.Label>Select Rider</Form.Label>
                    <Form.Control
                      as="select"
                      value={order?.riderId?._id || riderId}
                      onChange={(e) => setRiderId(e.target.value)}
                      disabled={
                        !!order?.riderId ||
                        order?.delivery_status === "DELIVERED"
                      }
                    >
                      <option value="">Select Rider</option>
                      {loadingRiders ? (
                        <option value="" disabled>
                          Searching for riders...
                        </option>
                      ) : (
                        riders?.map((rider) => (
                          <option key={rider._id} value={rider._id}>
                            {rider.firstName + " " + rider.lastName} ||{" "}
                            {rider.onlineStatus}
                          </option>
                        ))
                      )}
                    </Form.Control>
                    {loadingRiders && (
                      <div style={{ marginLeft: "2px" }}>
                        <Spinner animation="border" size="sm" />
                      </div>
                    )}
                    {order?.riderId && (
                      <p
                        style={{
                          marginTop: "10px",
                          fontSize: "12px",
                          fontStyle: "italic",
                        }}
                      >
                        Assigned to:{" "}
                        {order?.riderId?.firstName +
                          " " +
                          order?.riderId?.lastName}
                        <MdSportsMotorsports
                          style={{
                            marginLeft: "5px",
                            fontSize: "19px",
                            color: "green",
                          }}
                        />
                        <br />
                        {order?.delivery_status === "ASSIGNED" && (
                          <span>
                            Time Elapsed after Assigning:{" "}
                            {Math.floor(elapsedAssignedTime / 3600)}h{" "}
                            {Math.floor((elapsedAssignedTime % 3600) / 60)}m{" "}
                            {elapsedAssignedTime % 60}s
                          </span>
                        )}
                        {order?.delivery_status === "DISPATCHED" && (
                          <span>
                            Time Elapsed from Dispatch:{" "}
                            {Math.floor(elapsedDispatchedTime / 3600)}h{" "}
                            {Math.floor((elapsedDispatchedTime % 3600) / 60)}m{" "}
                            {elapsedDispatchedTime % 60}s
                          </span>
                        )}
                      </p>
                    )}
                    {!order?.riderId && (
                      <Button
                        variant="primary"
                        onClick={assignRetailerWholesalerOrderToRider}
                        className="mt-2"
                        disabled={
                          !riderId ||
                          loadingRiders ||
                          order?.delivery_status === "DELIVERED"
                        }
                        style={{
                          visibility: riderId ? "visible" : "hidden",
                          backgroundColor:
                            !riderId || order?.delivery_status === "DELIVERED"
                              ? "#8C1F28"
                              : "#8C1F28",
                          color: !riderId ? "black" : "white",
                        }}
                      >
                        Assign Order to Rider
                      </Button>
                    )}
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group
                    controlId="formOrderStatus"
                    className="shadow p-3 h-100"
                    style={{ boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)" }}
                  >
                    <Form.Label>Update Order Status</Form.Label>
                    <Form.Control
                      as="select"
                      value={order.delivery_status}
                      onChange={(e) => handleUpdateStatus(e.target.value)}
                    >
                      <option
                        value="PROCESSING"
                        disabled={disabledStatusOptions.PROCESSING}
                      >
                        1. Processing
                      </option>
                      <option
                        value="ASSIGNED"
                        disabled={disabledStatusOptions.ASSIGNED}
                      >
                        2. Assigned
                      </option>
                      <option
                        value="DISPATCHED"
                        disabled={disabledStatusOptions.DISPATCHED}
                      >
                        3. Dispatched
                      </option>
                      <option
                        value="DELIVERED"
                        disabled={disabledStatusOptions.DELIVERED}
                      >
                        4. Delivered
                      </option>
                      <option
                        value="REJECTED"
                        disabled={disabledStatusOptions.REJECTED}
                      >
                        5. Cancel Order
                      </option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="d-flex justify-content-end align-items-center">
                  <div
                    style={{
                      cursor: !canCancelOrder ? "not-allowed" : "pointer",
                      backgroundColor: !canCancelOrder
                        ? "#8C1F28"
                        : "transparent",
                      borderRadius: "5px",
                      padding: "2px",
                      marginRight: "5px",
                      width: "160px",
                      height: "40px",
                    }}
                  >
                    <Button
                      variant="danger"
                      onClick={() => setShowCancelModal(true)}
                      className="mr-2"
                      disabled={!canCancelOrder}
                      style={{
                        backgroundColor: !canCancelOrder
                          ? "transparent"
                          : "red",
                        color: !canCancelOrder ? "white" : "white",
                        border: "none",
                        fontSize: "14px",
                        width: "100%",
                        height: "100%",
                        padding: "6px",
                      }}
                    >
                      <MdCancel
                        style={{
                          fontSize: "20px",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "5px",
                        }}
                      />
                      Cancel Order
                    </Button>
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                      backgroundColor: "transparent",
                      borderRadius: "5px",
                      padding: "4px",
                      marginRight: "5px",
                      width: "180px",
                      height: "50px",
                    }}
                  >
                    <Button
                      variant="success"
                      onClick={handleContactCustomer}
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        border: "none",
                        fontSize: "14px",
                        width: "100%",
                        height: "100%",
                        padding: "10px",
                      }}
                    >
                      Contact Customer
                      <MdContacts style={{ marginLeft: "4px" }} />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md="12">
                  <ProgressBar
                    now={getProgress()}
                    label={`${getProgress()}%`}
                    style={{ height: "30px" }}
                    variant={
                      order?.delivery_status === "DELIVERED"
                        ? "success"
                        : undefined
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md="12" className="d-flex justify-content-between">
                  <div
                    style={{
                      cursor:
                        order?.delivery_status !== "ASSIGNED"
                          ? "not-allowed"
                          : "pointer",
                      backgroundColor:
                        order?.delivery_status !== "ASSIGNED"
                          ? "#4B4952"
                          : "transparent",
                      borderRadius: "5px",
                      padding: "2px",
                    }}
                  >
                    <Button
                      variant="warning"
                      onClick={handleDispatchRetailerWholesalerOrder}
                      disabled={order?.delivery_status !== "ASSIGNED"}
                      style={{
                        backgroundColor:
                          order?.delivery_status !== "ASSIGNED"
                            ? "transparent"
                            : "orange",
                        color:
                          order?.delivery_status !== "ASSIGNED"
                            ? "white"
                            : "black",
                        border: "none",
                        fontSize: "14px",
                      }}
                    >
                      Dispatch Order
                    </Button>
                  </div>
                  <div
                    style={{
                      cursor:
                        order?.delivery_status !== "DISPATCHED"
                          ? "not-allowed"
                          : "pointer",
                      backgroundColor:
                        order?.delivery_status !== "DISPATCHED"
                          ? "green"
                          : "transparent",
                      borderRadius: "5px",
                      padding: "2px",
                    }}
                  >
                    <Button
                      variant="success"
                      onClick={handleDeliverRetailerWholesalerOrder}
                      disabled={order?.delivery_status !== "DISPATCHED"}
                      style={{
                        backgroundColor:
                          order?.delivery_status !== "DISPATCHED"
                            ? "transparent"
                            : "green",
                        color:
                          order?.delivery_status !== "DISPATCHED"
                            ? "white"
                            : "black",
                        border: "none",
                        fontSize: "14px",
                      }}
                    >
                      Mark as Delivered
                    </Button>
                  </div>
                  <div
                    style={{
                      cursor: order?.isPaid ? "not-allowed" : "pointer",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={order?.isPaid}
                          onChange={handleMarkAsPaid}
                          color="primary"
                          disabled={
                            order?.delivery_status !== "DELIVERED" ||
                            order?.isPaid
                          }
                        />
                      }
                      label={order?.isPaid ? "Paid" : "Mark as Paid"}
                    />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Cancellation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel this order?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={handleCancelOrder}>
            Cancel Order
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default SuperAdminOrderDetails;
