import { START_LOADING, END_LOADING } from "../constants/webOrderConstants";
import {
  FETCH_CATEGORIES,
  FETCH_CATEGORY,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_ACTIVATE_CATEGORY,
  // DELETE_CATEGORY,
  RESET_CATEGORY,
  CATEGORY_FETCH_FAIL,
  ACTIVATE_CATEGORY,
  FETCH_INACTIVE_CATEGORIES,
  FETCH_ACTIVE_CATEGORY,
} from "../constants/categoryConstants";

export const getCategoriesReducer = (
  state = { activeCategories: [], inActiveCategories: [], category: {} },
  action
) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, loading: true };
    case END_LOADING:
      return { ...state, loading: false };

    case FETCH_CATEGORIES:
      return {
        ...state,
        activeCategories: action.payload,
      };
    case FETCH_ACTIVE_CATEGORY:
      return {
        ...state,
        category: action.payload.category,
      };
    case FETCH_INACTIVE_CATEGORIES:
      return {
        ...state,
        inActiveCategories: action.payload,
      };
    case FETCH_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    //Not Delete categories At the moment
    // case DELETE_CATEGORY:
    //   return {
    //     ...state,
    //     inActiveCategories: state.inActiveCategories.filter(
    //       (category) => category._id !== action.payload
    //     ),
    //   };
    case CREATE_CATEGORY:
      return {
        ...state,
        activeCategories: [...state.activeCategories, action.payload],
      };

    case ACTIVATE_CATEGORY:
      return {
        ...state,
        activeCategories: state.activeCategories.map((category) => {
          if (category._id === action.payload) {
            category.active_status = "Active";
          }
          return category;
        }),
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        activeCategories: state.activeCategories.filter(
          (category) => category._id !== action.payload
        ),
      };
    case UPDATE_ACTIVATE_CATEGORY:
      return {
        ...state,
        inActiveCategories: state.inActiveCategories.filter(
          (category) => category._id !== action.payload
        ),
      };
    case RESET_CATEGORY:
      return {
        ...state,
        category: {},
      };
    case CATEGORY_FETCH_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
