import React, { useState } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { Input } from "reactstrap";
import { toast } from "react-toastify";
import { Alert, AlertTitle } from "@material-ui/lab";
import AppBar from "@material-ui/core/AppBar";

import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "../../assets/img/signin-logo.png";
// import rabbit from "../../assets/img/lusuku_rabbit_80x80.png";
import lusukuLogo80_80 from "../../assets/img/lusukuLogo80_80.png";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const PasswordResetForm = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  //   console.log(match.params.id, "idX");
  const [resetStatus, setResetStatus] = useState("");
  const [success, setSuccess] = useState(false);
  // console.log(resetStatus, "reset Status");

  const user_id = match?.params?.id;
  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });
  // console.log(error.confirmPassword, ".....");

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const clearInput = () => {
    setInput({
      password: "",
      confirmPassword: "",
    });
  };

  const validateInput = (e) => {
    const { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "Password is required!";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] = "Passwords do not match!.";
          } else {
            (input.password.trim().length < 8 ||
              input.password.trim().length > 20) &&
              (stateObj[name] =
                "Password must be between 8 and 20 characters.");
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Confirm Password is required";
          } else {
            input.password &&
              value !== input.password &&
              (stateObj[name] = "Passwords do not match!.");
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
  };

  const onSubmitX = (e) => {
    e.preventDefault();
    if (input.password === input.confirmPassword) {
      // fetch(`http://localhost:8000/user/reset-password/${user_id}`, {
      fetch(`/user/reset-password/${user_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: input.password,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data, "data");
          setResetStatus(data.message);
          // toast.info(data.message);
          if (data.success) {
            // alert("Password reset successfully");
            toast.success("Password reset successfully!");
            setSuccess(true);
            history.replace("/reset-password/:token/:id");
          } else {
            // alert("Password reset failed");
            toast.info(data.message);
            history.replace("/reset-password/:token/:id");
            clearInput();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // alert("Passwords do not match");
      // setError("Passwords do not match");
      toast.error("Something Went wrong!");
    }
  };

  if (success) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <h1>Password Reset Status</h1>
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              <p>{resetStatus} You can now login with your new password!</p>
              {/* This is a success alert — <strong>check it out!</strong> */}
            </Alert>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {" "}
      <div className={classes.root}>
        <AppBar position="static" color="primary">
          <Toolbar>
            {/* <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton> */}
            <Image
              width="140"
              height="100"
              className="d-inline-block align-top"
              src={lusukuLogo80_80}
              alt="logo"
            />
            <Image
              width="170"
              height="100"
              className="d-inline-block align-top"
              src={logo}
              alt="logo"
            />
            {/* <Typography variant="h6" className={classes.title}>
              News
            </Typography> */}
            {/* <Button color="inherit">Login</Button> */}
          </Toolbar>
        </AppBar>
      </div>
      <div
        className="container"
        style={{
          marginTop: "100px",
          width: "500px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <h1 style={{ fontSize: 30 }}>Lusuku Password Reset</h1>
        <Form autoComplete="off" onSubmit={onSubmitX}>
          <label htmlFor="password">Password</label>

          <Input
            type="password"
            name="password"
            className="form-control"
            id="password"
            placeholder="Password"
            value={input.password}
            onChange={onInputChange}
            onBlur={validateInput}
          ></Input>
          {error.password && (
            <span
              className="err"
              style={{ color: "red", fontSize: "12px", fontStyle: "italic" }}
            >
              {error.password}
            </span>
          )}
          <div
            className="form-group"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              name="confirmPassword"
              className="form-control"
              id="confirmPassword"
              placeholder="Confirm Password"
              value={input.confirmPassword}
              onChange={onInputChange}
              onBlur={validateInput}
            ></input>
            {/* {error.password && (
              <span
                className="err"
                style={{ color: "red", fontSize: 13, fontStyle: "italic" }}
              >
                {error.confirmPassword}
              </span>
            )} */}
          </div>
          <p style={{ color: "red", fontSize: "12px", fontStyle: "italic" }}>
            {" "}
            {error.confirmPassword}
          </p>
          <Button
            type="submit"
            className="btn btn-primary bg-danger"
            style={{ width: "100%" }}
            disabled={
              !input.password ||
              !input.confirmPassword ||
              error.password ||
              error.confirmPassword
            }
          >
            Reset Password
          </Button>
        </Form>
      </div>
    </>
  );
};

export default PasswordResetForm;
