import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  createDeliveryZone,
  updateDeliveryZone,
} from "../../actions/zoneActions";
import { RESET_DELIVERY_ZONE } from "constants/zoneConstants";

toast.configure();

const DeliveryZoneForm = () => {
  const [postData, setPostData] = useState({
    village: "",
    district: "",
    shippingCost: "",
  });

  const clear = () => {
    setPostData({ village: "", district: "", shippingCost: "" });
  };

  const deliveryZones = useSelector((state) => state.deliveryZones);
  const { zone, loading } = deliveryZones;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (zone?._id) {
      setPostData(zone);
    } else {
      clear();
    }
  }, [zone]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!zone?._id) {
      dispatch(
        createDeliveryZone(
          { ...postData },

          history.push("/admin/delivery-table"),
          toast.success("Delivery Zone Added!"),
          clear()
        )
      );
      // clear();
    } else {
      dispatch(
        updateDeliveryZone(
          zone._id,
          { ...postData },
          history.push("/admin/delivery-table"),
          toast.success("Delivery Zone Updated!")
          // clear()
        )
      );
      dispatch({ type: RESET_DELIVERY_ZONE });
    }
  };

  return (
    <>
      {loading ? (
        <Spinner
          animation="grow"
          variant="danger"
          style={{
            marginTop: "70px",
            top: "50%",
            left: "50%",
            position: "fixed",
          }}
        />
      ) : (
        <Col>
          <Form id="deliveryZone" autoComplete="off" onSubmit={handleSubmit}>
            <Card>
              <Card.Title className="pt-2 p-2" as="h4">
                Add Delivery Zone
              </Card.Title>
              <Card.Body className="pt-0">
                <Col md={6}>
                  <Form.Group>
                    <Card.Body>
                      <label>Village</label>
                      <Form.Control
                        name="village"
                        type="text"
                        placeholder="Add village..."
                        value={postData.village}
                        onChange={(e) =>
                          setPostData({ ...postData, village: e.target.value })
                        }
                      ></Form.Control>
                    </Card.Body>
                    <Card.Body>
                      <label>District</label>
                      <Form.Control
                        name="district"
                        type="text"
                        placeholder="Add District..."
                        value={postData.district}
                        onChange={(e) =>
                          setPostData({ ...postData, district: e.target.value })
                        }
                      ></Form.Control>
                    </Card.Body>
                    <Card.Body>
                      <label>Shipping Cost</label>
                      <Form.Control
                        name="shippingCost"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        placeholder="Shipping Cost.."
                        value={postData.shippingCost}
                        onChange={(e) =>
                          setPostData({
                            ...postData,
                            shippingCost: e.target.value,
                          })
                        }
                      ></Form.Control>
                    </Card.Body>
                  </Form.Group>
                </Col>
              </Card.Body>

              <Card.Body>
                <Card.Footer className="text-right">
                  <Button
                    className="btn-fill pull-right"
                    variant="danger"
                    onClick={clear}
                    style={{ marginRight: "5px" }}
                  >
                    Clear
                  </Button>
                  <Button
                    className="btn-fill pull-right"
                    variant="info"
                    type="submit"
                    disabled={
                      postData.village === "" || postData.district === ""
                    }
                  >
                    Submit
                  </Button>
                  <div className="clearfix"></div>
                </Card.Footer>
              </Card.Body>
            </Card>
          </Form>
        </Col>
      )}
    </>
  );
};

export default DeliveryZoneForm;
