import {
  START_LOADING,
  FETCH_ALL,
  FETCH_ALL_ORDERS_PAGINATED,
  FETCH_ALL_ORDERS_PAGINATED_SEARCH,
  END_LOADING,
  // FETCH_ORDER,
  // MOBILE_ORDER_DELIVER_REQUEST,
  // MOBILE_ORDER_DELIVER_SUCCESS,
  // MOBILE_ORDER_DELIVER_FAIL,
  // MOBILE_ORDER_DETAILS_FAIL,
  FETCH_ALL_MOBILE_ORDERS_NO_LOADER,
  FETCH_VENDOR_ORDERS,
  FETCH_ALL_VENDOR_ORDERS_PAGINATED_SEARCH,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

import * as api from "../api/index.js";

// In Use ~ Switched with SSE // Switched Back
export const getMobileOrders = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data },
    } = await api.fetchOrders();

    dispatch({ type: FETCH_ALL, payload: { data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

// Needed for setInterval without a laoding Indicator
export const getOrdersNoLoader = () => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await api.fetchOrders();

    dispatch({ type: FETCH_ALL_MOBILE_ORDERS_NO_LOADER, payload: { data } });
  } catch (error) {
    console.log(error);
  }
};

//GET ALL ORDERS WITH PAGINAITON ~ 50 ITEMS PER PAGE
export const getPaginatedOrders = (page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data, currentPage, numberOfPages },
    } = await api.fetchPaginatedOrders(page);

    dispatch({
      type: FETCH_ALL_ORDERS_PAGINATED,
      payload: { data, currentPage, numberOfPages },
    });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

//GET ALL ORDERS FOR A VENDOR
export const getVendorOrders = (vendorId, page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data, currentPage, numberOfPages },
    } = await api.fetchVendorOrders(vendorId, Number(page));

    dispatch({
      type: FETCH_VENDOR_ORDERS,
      payload: { data, currentPage, numberOfPages },
    });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

// SEARCH PAGINATED ORDERS
export const getPaginatedOrdersBySearch = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const {
      data: { data },
    } = await api.searchOrders(searchQuery);

    dispatch({
      type: FETCH_ALL_ORDERS_PAGINATED_SEARCH,
      payload: { data },
    });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.response.data.message);
    toast.error(error.response.data.message);
    dispatch({ type: END_LOADING });
  }
};

// SEARCH VENDOR PAGINATED ORDERS
export const getPaginatedVendorOrdersBySearch =
  (vendorId, searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      // console.log(searchQuery, "whooo"); // add this line to log the value of searchQuery

      const {
        data: { data },
      } = await api.searchVendorOrders(vendorId, searchQuery);
      // console.log("Response from API:", data); // add this line to log the value of data

      dispatch({
        type: FETCH_ALL_VENDOR_ORDERS_PAGINATED_SEARCH,
        payload: { data },
      });

      dispatch({ type: END_LOADING });
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
      dispatch({ type: END_LOADING });
    }
  };