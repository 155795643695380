import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  getMobileUser,
  getMobileUsers,
  deleteMobileUser,
  getUsers,
  getClientsBySearch,
} from "../../actions/auth";
import {
  Button,
  ButtonToolbar,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button as MaterialButton,
} from "@material-ui/core";
import { useLocation } from "react-router";
import useStyles from "./mobileUserStyles.js";
import { getUserFromLocalStorage } from "utilities/user";

function useQuery() {
  return new URLSearchParams(useLocation().searchClient);
}

const MobileUsers = () => {
  // const { auth, isLoading } = useSelector((state) => state.auth);
  const { authorizedUsers, isLoading, userCreated } = useSelector(
    (state) => state.auth
  );
  const user = getUserFromLocalStorage();
  const userId = user?.result?._id;
  const query = useQuery();

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [openDeletionDialog, setOpenDeletionDialog] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);

  // Search Clients
  const searchQuery = query.get("searchQuery");
  const [searchClient, setSearchClient] = useState("");

  useEffect(() => {
    dispatch(getMobileUsers(user?.result._id, user?.result.user_role));
  }, [userCreated]);

  const handleDeleteClient = (clientId) => {
    dispatch(deleteMobileUser(clientId, user?.result?._id)).then(() => {
      // dispatch(getMobileUsers(user?.result?._id, user?.result?.user_role));
      dispatch(getUsers(user?.result?._id, user?.result?.user_role));
    });
  };

  if (
    user?.result?.user_role === "CLIENT" ||
    user?.result?.user_role === "CREATOR"
  ) {
    return (
      <>
        <Container fluid>
          <Row>
            <Col md={6}>
              <Typography elevation={6} variant="h5" color="error">
                <i className="fas fa-users"></i>&nbsp;&nbsp; Restricted View For
                Admins Only!
              </Typography>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  const showDeletionDialog = (clientId) => {
    setSelectedClientId(clientId);
    setOpenDeletionDialog(true);
  };

  const handleMobileClientDeletion = () => {
    if (selectedClientId) {
      handleDeleteClient(selectedClientId);
    }
    setOpenDeletionDialog(false);
  };

  // Search Clients
  const searchClients = () => {
    if (searchClient.trim()) {
      dispatch(getClientsBySearch({ searchClient, user_id: userId }));
      history.push(`/admin/mobileUsers?searchQuery=${searchClient || "none"}`);
    } else {
      history.push("/admin/mobileUsers");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchClients();
    }
  };

  const backToClients = () => {
    history.push("/admin/mobileUsers");
    setSearchClient("");
    dispatch(getUsers(userId, user?.result?.user_role));
  };

  const handleInputClear = (e) => {
    if (e.target.value === "") {
      history.push("/admin/mobileUsers"); // Navigate to the unfiltered clients page
      dispatch(getUsers(userId, user?.result?.user_role));
      setSearchClient(""); // Reset the searchClient state
    }
  };

  return (
    <>
      <Container fluid>
        {isLoading ? (
          <Spinner
            animation="grow"
            variant="danger"
            style={{
              marginTop: "70px",
              top: "50%",
              left: "50%",
              position: "fixed",
            }}
          />
        ) : (
          <Row>
            <Col md="11">
              <Card className="regular-table-with-color">
                <Card.Header>
                  <Card.Title as="h4">Mobile Users</Card.Title>
                  <p className="card-category">Active Mobile Users</p>
                </Card.Header>
                <div className={classes.searchClient}>
                  {/* Search Input Field */}
                  <div className="input-group-prepend">
                    <input
                      type="search"
                      id="search"
                      className={classes.searchClientInput}
                      placeholder="Search clients..."
                      value={searchClient}
                      onChange={(e) => setSearchClient(e.target.value)}
                      onKeyDown={handleKeyPress}
                      onInput={handleInputClear}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={searchClients}
                    style={{
                      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
                    }}
                  >
                    <i
                      className="nc-icon nc-zoom-split"
                      style={{ color: "white" }}
                    ></i>
                  </button>
                </div>
                <Col md="12">
                  <Card.Body className="table-responsive p-0">
                    <Table className="table-hover table-striped w-full">
                      <thead>
                        <tr style={{ background: "#D2E8E3" }}>
                          <th style={{ fontWeight: "bold", color: "#2C3532" }}>
                            #
                          </th>
                          <th style={{ fontWeight: "bold", color: "#2C3532" }}>
                            Name
                          </th>
                          <th style={{ fontWeight: "bold", color: "#2C3532" }}>
                            Email
                          </th>
                          <th style={{ fontWeight: "bold", color: "#2C3532" }}>
                            ID
                          </th>
                          <th style={{ fontWeight: "bold", color: "#2C3532" }}>
                            Created At
                          </th>
                          <th style={{ fontWeight: "bold", color: "#2C3532" }}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {authorizedUsers
                          .map((x) => ({ user: x.user || x })) // Normalize data
                          .filter((x) => x.user.userType === "CLIENT")
                          .map((agent, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}.</td>
                                <td>{agent?.user?.name}</td>
                                <td>{agent?.user?.email}</td>
                                <td>{agent?.user?._id}</td>
                                <td>
                                  {moment(agent?.user?.createdAt).fromNow()}
                                </td>
                                <td>
                                  <ButtonToolbar>
                                    {["right"].map((placement) => (
                                      <OverlayTrigger
                                        key={placement}
                                        placement={placement}
                                        overlay={
                                          <Tooltip id={`tooltip-${placement}`}>
                                            Edit
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          variant="primary"
                                          size="sm"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(
                                              getMobileUser(
                                                agent?.user?._id,
                                                user?.result?._id,
                                                user?.result.user_role
                                              )
                                            );
                                            history.push(
                                              `/admin/user-edit/${agent.user?._id}`
                                            );
                                          }}
                                        >
                                          <i className="fas fa-edit"></i>
                                        </Button>
                                      </OverlayTrigger>
                                    ))}
                                  </ButtonToolbar>
                                  <ButtonToolbar>
                                    {["right"].map((placement) => (
                                      <OverlayTrigger
                                        key={placement}
                                        placement={placement}
                                        overlay={
                                          <Tooltip id={`tooltip-${placement}`}>
                                            Delete User
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          variant="danger"
                                          size="sm"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            showDeletionDialog(
                                              agent?.user?._id
                                            );
                                          }}
                                        >
                                          <i className="fas fa-trash"></i>
                                        </Button>
                                      </OverlayTrigger>
                                    ))}
                                  </ButtonToolbar>
                                </td>
                              </tr>
                            );
                          })}
                        {/* If Search results are empty */}
                        {searchClient &&
                          authorizedUsers.filter((x) => x.userType === "CLIENT")
                            .length === 0 ? (
                          <tr>
                            <td colSpan="6">
                              No results found for user{" "}
                              <em style={{ color: "red" }}>"{searchClient}"</em>
                              .
                            </td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button variant="primary" onClick={backToClients}>
                                Back
                              </Button>
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Col>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
      {/* Client Deletion Dialog Component */}
      <Dialog
        open={openDeletionDialog}
        onClose={() => setOpenDeletionDialog(false)}
        aria-labelledby="deletion-dialog-title"
        aria-describedby="deletion-dialog-description"
      >
        <DialogTitle id="deletion-dialog-title">{"Delete Agent"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="deletion-dialog-description">
            Are you sure you want to delete this Client?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MaterialButton
            onClick={() => setOpenDeletionDialog(false)}
            color="primary"
            className={classes.cancelDeletionMobileUser}
          >
            Cancel
          </MaterialButton>
          <MaterialButton
            onClick={handleMobileClientDeletion}
            color="primary"
            autoFocus
            style={{ marginLeft: "8px" }}
            className={classes.deleteMobileUserButton}
          >
            Delete
          </MaterialButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MobileUsers;
