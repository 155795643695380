// productActions.js

import axios from 'axios';
import {
  START_LOADING_RESTAURANT_PRODUCT,
  CREATE_RESTAURANT_PRODUCT,
  END_LOADING_RESTAURANT_PRODUCT,
  CREATE_RESTAURANT_PRODUCT_ERROR,
  UPDATE_RESTAURANT_PRODUCT,
  UPDATE_RESTAURANT_PRODUCT_ERROR,
  FETCH_RESTAURANT_PRODUCT_DETAILS,
  FETCH_RESTAURANT_PRODUCT_DETAILS_ERROR
} from '../constants/actionTypes.js';
import { toast } from 'react-toastify';
import * as api from '../api/index.js';
// API call to create a Restaurant product
export const createRestaurantMenuProduct = (restaurantProductData, userId) => async (dispatch) => {

  try {
    dispatch({ type: START_LOADING_RESTAURANT_PRODUCT });
    const { data } = await api.apiCreateRestaurantProduct(restaurantProductData, userId);
    dispatch({ type: CREATE_RESTAURANT_PRODUCT, payload: data });

    toast.success('Product created successfully!');
  } catch (error) {
    dispatch({
      type: CREATE_RESTAURANT_PRODUCT_ERROR,
      payload: error.response?.data?.message || error.message
    });
    toast.error(error.response?.data?.message || error.message);
  } finally {
    dispatch({ type: END_LOADING_RESTAURANT_PRODUCT });
  }
};


// API call to fetch product details
export const fetchRestaurantProductDetails = (productId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_RESTAURANT_PRODUCT });
    const { data } = await api.apiFetchRestaurantProductDetail(productId);
    dispatch({ type: FETCH_RESTAURANT_PRODUCT_DETAILS, payload: data });
  } catch (error) {
    dispatch({
      type: FETCH_RESTAURANT_PRODUCT_DETAILS_ERROR,
      payload: error.response?.data?.message || error.message
    });
    toast.error(error.response?.data?.message || error.message);
  } finally {
    dispatch({ type: END_LOADING_RESTAURANT_PRODUCT });
  }
};

// API call to update a Restaurant product
export const updateRestaurantProduct = (productId, updateData) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_RESTAURANT_PRODUCT });
    const { data } = await api.apiUpdateRestaurantProduct(productId, updateData);
    // console.log(data, "This data>>>>>s")
    dispatch({ type: UPDATE_RESTAURANT_PRODUCT, payload: data.data });
    toast.success(data.message);
    dispatch(fetchRestaurantProductDetails(productId)); // Fetch updated product details
  } catch (error) {
    dispatch({
      type: UPDATE_RESTAURANT_PRODUCT_ERROR,
      payload: error.response?.data?.message || error.message
    });
    toast.error(error.response?.data?.message || error.message);
  } finally {
    dispatch({ type: END_LOADING_RESTAURANT_PRODUCT });
  }
};
