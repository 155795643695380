import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// import AuthNavbar from "components/Navbars/AuthNavbar.js";
// import AuthFooter from "components/Footers/AuthFooter.js";
import Login from "../views/Pages/Login.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { authRoute } from "../routes.js";

function Auth() {
  return (

    <div className="wrapper">
      <Sidebar
        routes={authRoute}
        image={require("assets/img/sidebar-3.jpg")}
        background="black"
      />
      <div className="main-panel">
        {/* <AuthNavbar /> */}
        <div className="content">
          <Switch>
            <Route path="/auth/login-page" component={Login} />
            {/* Add other auth routes here if needed */}
            <Redirect from="/auth" to="/auth/login-page" />
          </Switch>
        </div>
        {/* <AuthFooter /> */}
      </div>
    </div>
  );
}

export default Auth;