import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Button,
  Alert,
  Row,
  Col,
  ProgressBar,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { toast } from "react-toastify";
import RiderSelectionDropdown from "./RiderSelectionDropdown";
import {
  MdLocalShipping,
  MdLocationOn,
  MdPerson,
  MdInfo,
  //   MdArrowBackIosNew,
} from "react-icons/md";
import { Badge } from "@material-ui/core";

import {
  updatePackageOrderDeliveryStatus,
  assignPackageOrderToDeliveryAgent,
  setOrderDataByRider,
  getOrderDetails,
} from "../../../../actions/webOrderActions";

const PackageDeliveryManagement = () => {
  const { id: orderId } = useParams();
  const dispatch = useDispatch();
  const [selectedRider, setSelectedRider] = useState("");
  const [message, setMessage] = useState("");
  const [riders, setRiders] = useState([]);
  const [loadingRiders, setLoadingRiders] = useState(true);
  const [elapsedAssignedTime, setElapsedAssignedTime] = useState(0);
  const [elapsedDispatchedTime, setElapsedDispatchedTime] = useState(0);

  const { order, loading, error } = useSelector((state) => state.orderDetails);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(getOrderDetails(orderId));
  }, [dispatch, orderId]);

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_SERVER_URL;
    const socket = io(
      socketUrl,
      {
        query: {
          user: user?.result?._id,
        },
      },
      {
        transports: ["websocket"],
      }
    );

    socket.on("connect", () => {
      console.log("Webclient Connected");
      socket.emit("getRiders");
      socket.emit("joinOrderRoom", orderId);
    });

    socket.on("orderUpdated", (updatedOrder) => {
      if (updatedOrder._id === orderId) {
        dispatch(setOrderDataByRider(updatedOrder));
        if (
          updatedOrder.delivery_status === "PROCESSING" &&
          order?.delivery_status === "ASSIGNED"
        ) {
          toast.info(
            "Rider has declined the order. Order status reverted to PROCESSING."
          );
          setSelectedRider(""); // Reset the selected rider
        }
      }
    });

    socket.on("initialRidersList", (socketData) => {
      setRiders(socketData);
      setLoadingRiders(false);
    });

    socket.on("updatedRidersList", (updatedRiders) => {
      setRiders(updatedRiders);
    });

    socket.on("riderAdded", (newRider) => {
      setRiders((prevRiders) => [...prevRiders, newRider]);
    });

    socket.on("riderUpdated", (updatedRider) => {
      setRiders((prevRiders) =>
        prevRiders.map((rider) =>
          rider._id === updatedRider._id ? updatedRider : rider
        )
      );
    });

    socket.on("riderDeleted", (deletedRider) => {
      setRiders((prevRiders) =>
        prevRiders.filter((rider) => rider._id !== deletedRider._id)
      );
    });

    socket.on("orderStatusChanged", ({ orderId, status }) => {
      if (orderId) {
        dispatch(
          setOrderDataByRider({ _id: orderId, delivery_status: status })
        );
        toast.info(`Order status updated to ${status}`);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user?.result?._id, orderId, dispatch, order?.delivery_status]);

  useEffect(() => {
    let interval;
    if (
      order?.delivery_status === "ASSIGNED" ||
      order?.delivery_status === "DISPATCHED"
    ) {
      interval = setInterval(() => {
        const now = new Date().getTime();
        if (order.delivery_status === "ASSIGNED") {
          const assignedTime = new Date(order.assignedAt).getTime();
          setElapsedAssignedTime(Math.floor((now - assignedTime) / 1000));
        } else if (order.delivery_status === "DISPATCHED") {
          const dispatchedTime = new Date(order.dispatchedAt).getTime();
          setElapsedDispatchedTime(Math.floor((now - dispatchedTime) / 1000));
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [order?.delivery_status, order?.assignedAt, order?.dispatchedAt]);

  const assignPackageOrderToRider = () => {
    if (selectedRider) {
      const delivery_status = "ASSIGNED";
      const assignedTime = Date.now();
      toast.success(`Assigning order ${orderId} to rider ${selectedRider}`);
      localStorage.setItem(`order_${orderId}_assignedTime`, assignedTime);
      dispatch(
        assignPackageOrderToDeliveryAgent(
          orderId,
          selectedRider,
          delivery_status
        )
      );
      setMessage("Rider assignment request sent");
    } else {
      setMessage("Please select a rider");
    }
  };

  const handleUpdateStatus = (newStatus) => {
    if (newStatus === "ASSIGNED" && !selectedRider) {
      toast.warn("Please select a rider before assigning the order.");
      return;
    }
    if (newStatus === "ASSIGNED") {
      assignPackageOrderToRider();
    } else {
      dispatch(updatePackageOrderDeliveryStatus(orderId, newStatus));
    }
  };

  const getBadgeStyle = (status) => {
    switch (status) {
      case "PROCESSING":
        return {
          backgroundColor: "orange",
          color: "white",
          borderRadius: 5,
          padding: 5,
        };
      case "ASSIGNED":
        return {
          backgroundColor: "blue",
          color: "white",
          borderRadius: 5,
          padding: 5,
        };
      case "DISPATCHED":
        return {
          backgroundColor: "purple",
          color: "white",
          borderRadius: 5,
          padding: 5,
        };
      case "DELIVERED":
        return {
          backgroundColor: "green",
          color: "white",
          borderRadius: 5,
          padding: 5,
        };
      case "REJECTED":
        return {
          backgroundColor: "red",
          color: "white",
          borderRadius: 5,
          padding: 5,
        };
      default:
        return {
          backgroundColor: "grey",
          color: "white",
          borderRadius: 5,
          padding: 5,
        };
    }
  };

  const getProgress = () => {
    switch (order?.delivery_status) {
      case "PROCESSING":
        return 20;
      case "ASSIGNED":
        return 40;
      case "DISPATCHED":
        return 60;
      case "DELIVERED":
        return 100;
      default:
        return 0;
    }
  };

  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <Container fluid>
      <Card className="shadow-sm">
        <Card.Header className="bg-primary">
          <Card.Title as="h4" className="text-white">
            Package Delivery Management
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={8}>
              <h5>
                <MdLocalShipping className="mr-2" size={28} color="orange" />{" "}
                Order ID: {order?._id}
              </h5>
              <p>
                <MdPerson className="mr-2" size={28} color="brown" /> Customer:{" "}
                {order?.shippingAddress?.customerNumber}
              </p>
              <p>
                <MdLocationOn className="mr-2" size={28} color="red" /> Pickup
                Address: {order?.pickupAddress?.address} ||{" "}
                {order?.pickupAddress?.latLng?.lat},{" "}
                {order?.pickupAddress?.latLng?.lng}
              </p>
              <p>
                <MdLocationOn className="mr-2" size={28} color="green" />{" "}
                Delivery Address: {order?.shippingAddress?.address} ||{" "}
                {order?.shippingAddress?.latLng?.lat},{" "}
                {order?.shippingAddress?.latLng?.lng}
              </p>
              {order?.package?.description && (
                <p>
                  <MdInfo className="mr-2" size={28} color="gray" /> Package
                  Description: {order?.package?.description}
                </p>
              )}
              {order?.package?.specialInstructions && (
                <p>
                  <MdInfo className="mr-2" size={28} color="orange" /> Special
                  Instructions: {order?.package?.specialInstructions}
                </p>
              )}
              {order?.package?.isFragile && (
                <p>
                  <MdInfo className="mr-2" size={28} color="red" /> Fragile
                  Package: {order?.package?.isFragile ? "Yes" : "No"}
                </p>
              )}
              <p>
                <MdInfo className="mr-2" /> Package Weight:{" "}
                {order?.package?.weight}kg
              </p>
              <p>
                <strong>Delivery Status:</strong>{" "}
                <Badge
                  overlap="rectangular"
                  style={getBadgeStyle(order?.delivery_status)}
                >
                  {order?.delivery_status}
                </Badge>
              </p>
            </Col>
            <Col md={4}>
              <RiderSelectionDropdown
                order={order}
                riderId={selectedRider}
                setRiderId={setSelectedRider}
                loadingRiders={loadingRiders}
                riders={riders}
                assignRestaurantOrderToRider={assignPackageOrderToRider}
                elapsedAssignedTime={elapsedAssignedTime}
                elapsedDispatchedTime={elapsedDispatchedTime}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={12}>
              <Form.Group controlId="formOrderStatus">
                <Form.Label>Update Order Status</Form.Label>
                <Form.Control
                  as="select"
                  value={order?.delivery_status}
                  onChange={(e) => handleUpdateStatus(e.target.value)}
                >
                  <option value="PROCESSING">1. Processing</option>
                  <option value="ASSIGNED">2. Assigned</option>
                  <option value="DISPATCHED">3. Dispatched</option>
                  <option value="DELIVERED">4. Delivered</option>
                  <option value="REJECTED">5. Cancel Order</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={12}>
              <ProgressBar
                now={getProgress()}
                label={`${getProgress()}%`}
                style={{ height: "30px" }}
                variant={
                  order?.delivery_status === "DELIVERED" ? "success" : undefined
                }
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={12} className="d-flex justify-content-between">
              <Button
                variant="warning"
                onClick={() => handleUpdateStatus("DISPATCHED")}
                disabled={order?.delivery_status !== "ASSIGNED"}
              >
                Dispatch Order
              </Button>
              <Button
                variant="success"
                onClick={() => handleUpdateStatus("DELIVERED")}
                disabled={order?.delivery_status !== "DISPATCHED"}
              >
                Mark as Delivered
              </Button>
            </Col>
          </Row>
          {message && (
            <Alert variant="info" className="mt-3">
              {message}
            </Alert>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PackageDeliveryManagement;
