import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { LOGOUT } from "constants/actionTypes";
import { getUserFromLocalStorage } from "../../utilities/user.js";

function AdminNavbar() {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [user, setUser] = useState(getUserFromLocalStorage());

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const getCartCount = () => {
    return cartItems.reduce((qty, item) => Number(item.qty) + qty, 0);
  };

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    setUser(getUserFromLocalStorage());
  }, [location]);

  const login = () => {
    history.push("/auth/login-page");
  };

  // const logout = () => {
  //   dispatch({ type: LOGOUT });

  //   history.push("/auth/login-page");
  //   window.location.reload(true);
  // };
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('profile');
    // Redirect to login page
    window.location.href = "/auth/login-page";
  };

  return (
    <>
      <Navbar expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-fill btn-round btn-icon d-none d-lg-block bg-dark border-dark"
                variant="dark"
                onClick={() => document.body.classList.toggle("sidebar-mini")}
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button>
              <Button
                className="btn-fill btn-round btn-icon d-block d-lg-none bg-dark border-dark"
                variant="dark"
                onClick={() =>
                  document.documentElement.classList.toggle("nav-open")
                }
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button>
            </div>
            <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
              {/* Toggle Button and text to be here! */}
            </Navbar.Brand>
          </div>
          <button
            className="navbar-toggler navbar-toggler-right border-0"
            type="button"
            onClick={() => setCollapseOpen(!collapseOpen)}
          >
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
          </button>
          <Navbar.Collapse className="justify-content-end" in={collapseOpen}>
            <Nav.Link
              onClick={(e) => {
                e.preventDefault();
                history.push("/admin/cart-page");
              }}
            >
              <Nav.Item style={{ color: "#000002" }}>
                <span
                  style={{
                    position: "relative",
                    backgroundColor: "#1a8300",
                    textAlign: "center",
                    borderRadius: "10px",
                    minWidth: "18px",
                    padding: "0 5px",
                    height: "18px",
                    fontSize: "14px",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                    lineHeight: "18px",
                    top: "-10px",
                  }}
                >
                  {getCartCount()}
                </span>
                <i className="nc-icon nc-cart-simple"></i>
                {""} Cart
              </Nav.Item>
            </Nav.Link>
            <Nav navbar>
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  id="dropdown-414718872"
                  variant="default"
                >
                  <i className="nc-icon nc-bell-55 mr-1"></i>
                  <span className="notification">1</span>
                  <span className="d-lg-none">Notification</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    New order
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  id="dropdown-41471887333"
                  variant="default"
                >
                  <i className="nc-icon nc-bullet-list-67"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="nc-icon nc-email-85"></i>
                    Messages
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="nc-icon nc-umbrella-13"></i>
                    Call Center
                  </Dropdown.Item>
                  {user?.result ? (
                    <Dropdown.Item
                      className="text-danger"
                      href="#pablo"
                      onClick={logout}
                    >
                      <i className="nc-icon nc-button-power"></i>
                      Log out
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      className="text-danger"
                      href="#login"
                      onClick={login}
                    >
                      <i className="nc-icon nc-button-power"></i>
                      Log In
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
