import React from "react";
import { Spinner } from "react-bootstrap";

const DotLoader = () => {
  return (
    <Spinner
      style={{
        width: "50px",
        height: "50px",
        margin: "auto",
        display: "block",
      }}
      //   style={{
      //     marginTop: "70px",
      //     top: "50%",
      //     left: "50%",
      //     position: "fixed",
      //   }}
      animation="grow"
      size=""
      variant="primary"
    >
      {/* <span>Loading....</span> */}
    </Spinner>
  );
};

export default DotLoader;
