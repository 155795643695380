import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Row, Spinner, Modal } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { getSellerDetail, verifySeller } from "../../../actions/vendorActions";
import ListGroup from "react-bootstrap/ListGroup";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import BlockIcon from "@material-ui/icons/Block";
import { getUserFromLocalStorage } from "utilities/user";

const SellerDetailsCard = ({ match }) => {
  const user = getUserFromLocalStorage();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const sellerID = match.params.id;
  const { seller, loading } = useSelector((state) => state.sellers);

  useEffect(() => {
    dispatch(getSellerDetail(sellerID));
  }, []);

  const handleMouseEnter = () => {
    setShowModal(true);
  };

  const handleMouseLeave = () => {
    setShowModal(false);
  };

  return (
    <>
      {loading ? (
        <Spinner
          animation="grow"
          variant="primary"
          style={{ position: "absolute", top: "50%", left: "50%" }}
        />
      ) : (
        <Container fluid>
          <div>
            <Col md="10">
              <Card style={{ width: "36rem" }}>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "200px", // Set the desired height for the image container
                    overflow: "hidden",
                  }}
                  onMouseEnter={handleMouseEnter}
                >
                  <Card.Img
                    variant="top"
                    src={seller?.businessTradingLicense}
                    style={{
                      width: "100%",
                      height: "auto",
                      userSelect: "none",
                    }}
                    loading="lazy"
                  />
                </div>
                <Card.Body>
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item
                      style={{
                        alignItems: "right",
                        justifyContent: "right",
                        display: "flex",
                        fontWeight: "bold",
                        color: "#13678A",
                        fontStyle: "italic",
                      }}
                    >
                      VERIFIED:
                      {seller?.sellerVerification === "PENDING" ? (
                        <BlockIcon style={{ color: "red" }} />
                      ) : (
                        <VerifiedUserIcon style={{ color: "green" }} />
                      )}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Business Name: {seller?.businessName}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Type of Goods: {seller?.typeOfGoods}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Operation Mode:{" "}
                      {seller?.operationMode?.charAt(0)?.toUpperCase() +
                        seller?.operationMode?.slice(1)?.toLowerCase()}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Seller Verification:{" "}
                      {seller?.sellerVerification?.charAt(0)?.toUpperCase() +
                        seller?.sellerVerification?.slice(1)?.toLowerCase()}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Business Address: {seller?.businessAddress}
                    </ListGroup.Item>
                    <ListGroup.Item>Email: {seller?.email}</ListGroup.Item>
                    <ListGroup.Item>
                      Business Phone: {seller?.businessPhone}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      User Type:{" "}
                      {seller?.userType?.charAt(0)?.toUpperCase() +
                        seller?.userType?.slice(1)?.toLowerCase()}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      User Role:{" "}
                      {seller?.user_role?.charAt(0)?.toUpperCase() +
                        seller?.user_role?.slice(1)?.toLowerCase()}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Business TIN: {seller?.businessTIN}
                    </ListGroup.Item>
                  </ListGroup>
                  <Card.Text>{seller?.isActive}</Card.Text>
                  <div className="d-flex justify-content-end">
                    {seller?.sellerVerification === "PENDING" ? (
                      <Button
                        variant="danger"
                        className="w-100"
                        disabled={user?.result?.user_role !== "SUPERUSER"}
                        onClick={() =>
                          dispatch(
                            verifySeller(
                              sellerID,
                              user?.result?._id,
                              "VERIFIED",
                              new Date()
                            )
                          )
                        }
                      >
                        Verify Vendor
                      </Button>
                    ) : (
                      <Button
                        variant="success"
                        className="w-100"
                        onClick={() =>
                          dispatch(
                            verifySeller(
                              sellerID,
                              user?.result?._id,
                              "PENDING",
                              new Date()
                            )
                          )
                        }
                      >
                        Deactivate Vendor
                      </Button>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Container>
      )}
      <Modal
        show={showModal}
        onHide={handleMouseLeave}
        onMouseLeave={handleMouseLeave}
        centered
      >
        <Modal.Body>
          <img
            src={seller?.businessTradingLicense}
            alt="Business Trading License"
            style={{ width: "100%" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SellerDetailsCard;
