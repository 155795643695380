import React, { useState, useEffect } from "react";
import { Avatar } from "@material-ui/core";
// import { Route } from "react-router-dom";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUser, profileUpdate } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
// import { createUser } from "actions/auth";

// import Sidebar from "components/Sidebar/Sidebar";
// import AdminNavbar from "components/Navbars/AdminNavbar";
// import AdminFooter from "components/Footers/AdminFooter";
// import Auth from "../../views/Pages/Auth";
import moment from "moment";
import { getUserFromLocalStorage } from "utilities/user";

const UserEdit = ({ match }) => {
  const user = getUserFromLocalStorage();

  const userId = match.params.userId;
  const auth = useSelector((state) => state.auth);
  const { agent, isLoading } = auth;


  const [postData, setPostData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    user_role: agent?.user_role || "",
  });

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    // if (agent?._id) {
    dispatch(getUser(userId, user?.result?._id, user?.result.user_role));
    // }
  }, [userId]);

  const clear = () => {
    setPostData({
      email: "",
      password: "",
      confirmPassword: "",
      user_role: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate the form inputs
    if (postData.password !== postData.confirmPassword) {
      alert("Password and Confirm Password do not match.");
      return;
    }
    dispatch(
      profileUpdate(
        user?.result?._id,
        {
          role: postData.user_role || agent?.user_role,
          newPassword: postData.password,
          newEmail: postData.email || agent?.email,
        },
        agent?._id
      ),
      history.push("/admin/users")
    );
  };

  const handleChange = async (e) => {
    setPostData({ ...postData, [e.target.name]: e.target.value });
  };

  return isLoading ? (
    <Spinner
      animation="grow"
      variant="danger"
      style={{
        marginTop: "70px",
        top: "50%",
        left: "50%",
        position: "fixed",
      }}
    />
  ) : (
    <>
      {/* you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " */}
      <Container>
        <Row>
          <Col md="8" sm="6">
            <Form action="" className="form" method="" onSubmit={handleSubmit}>
              <Card>
                <Card.Header>
                  <Card.Header>
                    <Card.Title as="h4">Edit Role</Card.Title>
                  </Card.Header>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col className="pr-1" md="5">
                      <Form.Group>
                        <label>Creation Date </label>
                        <Form.Control
                          defaultValue={moment(agent?.createdAt)
                            .utc()
                            .format("DD-MM-YYYY")}
                          disabled
                          placeholder="Company"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>User Names</label>
                        <Form.Control
                          defaultValue={agent?.name}
                          disabled
                          //   placeholder="Company"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Email Address</label>
                        <Form.Control
                          defaultValue={agent?.email}
                          disabled={!user?.result?.user_role === "SUPERUSER"}
                          //   placeholder="Last Name"
                          autoComplete="off"
                          type="email"
                          name="email"
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row></Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Password Reset</label>
                        <Form.Control
                          //   defaultValue="Mike"
                          placeholder="Enter Password"
                          type="password"
                          name="password"
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Confirm Password</label>
                        <Form.Control
                          //   defaultValue="Mike"
                          placeholder="Confirm Password"
                          type="password"
                          name="confirmPassword"
                          onChange={handleChange}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <label>
                      Assign Role <span className="star">*</span>
                    </label>
                    <Select
                      className="basic-multi-select"
                      classNamePrefix="react-select"
                      name="user_role"
                      // value={postData.value}
                      defaultValue={{
                        value: postData.user_role || agent?.user_role,
                        label: postData.user_role || agent?.user_role,
                      }}
                      onChange={(value) =>
                        setPostData({
                          ...postData,
                          user_role: value.value,
                        })
                      }
                      options={[
                        // {
                        //   value: "",
                        //   label: "User Role",
                        //   isDisabled: true,
                        // },
                        {
                          value: "ADMIN",
                          label: "Admin",
                        },
                        {
                          value: "SUPERVISOR",
                          label: "Supervisor",
                        },
                        {
                          value: "CREATOR",
                          label: "Creator",
                        },
                        {
                          value: "VENDOR",
                          label: "Vendor",
                        },
                      ]}
                      placeholder="Select Role"
                    />
                  </Row>
                  <Button
                    className="btn-fill pull-right mt-3"
                    type="submit"
                    variant="info"
                    disabled={user?.result?.user_role !== "SUPERUSER"}
                  >
                    Update Profile
                  </Button>
                  <div className="clearfix"></div>
                </Card.Body>
              </Card>
            </Form>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <Card.Header className="no-padding">
                <div className="card-image">
                  <img
                    alt="..."
                    src={require("assets/img/full-screen-image-3.jpg").default}
                  ></img>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="author">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <Avatar
                      className="avatar border-gray mx-auto"
                      alt={agent?.email}
                      src={""}
                    >
                      {agent?.name?.charAt[0] || ""}
                      {agent?.name?.charAt(0) || ""}
                    </Avatar>
                    <Card.Title as="h5" style={{ textTransform: "capitalize" }}>
                      {agent?.name}
                    </Card.Title>
                  </a>
                  <p className="card-description">Role: {agent?.user_role}</p>
                </div>
                <div className="card-description text-center">
                  User ID: <br></br>
                  <p
                    style={{
                      fontStyle: "italic",
                      color: "#43BFFF",
                    }}
                  >
                    {agent?._id}
                  </p>
                </div>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                {/* <div className="button-container text-center">
                            <Button
                              className="btn-simple btn-icon"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                              variant="link"
                            >
                              <i className="fab fa-facebook-square"></i>
                            </Button>
                            <Button
                              className="btn-simple btn-icon"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                              variant="link"
                            >
                              <i className="fab fa-twitter"></i>
                            </Button>
                            <Button
                              className="btn-simple btn-icon"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                              variant="link"
                            >
                              <i className="fab fa-google-plus-square"></i>
                            </Button>
                          </div> */}
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserEdit;
