import { createContext, useContext, useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";

const GoogleMapsContext = createContext();

export const useGoogleMaps = () => {
  return useContext(GoogleMapsContext);
};

export const GoogleMapsProvider = ({ children }) => {
  const [mapsApiLoaded, setMapsApiLoaded] = useState(false);
  const [loading, setLoading] = useState(true); // Corrected line

  window.initGoogleMaps = () => {
    setMapsApiLoaded(true);
    setLoading(false);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initGoogleMaps`;

    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (loading)
    return (
      <div>
        <Spinner
          style={{
            marginTop: "70px",
            top: "50%",
            left: "50%",
            position: "fixed",
          }}
          animation="grow"
          size="lg"
          variant="danger"
        />
      </div>
    );
  return (
    <GoogleMapsContext.Provider value={{ mapsApiLoaded }}>
      {children}
    </GoogleMapsContext.Provider>
  );
};
