import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  media: {
    borderRadius: "20px",
    objectFit: "cover",
    width: "100%",
    maxHeight: "600px",
  },
  disabledButton: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
  },
  notAllowedCursor: {
    "&[data-disabled='true']:hover": {
      cursor: "not-allowed !important",
    },
  },

  buttonCancel: {
    backgroundColor: "#cf0700",
    color: "white",
    marginBottom: 20,
    marginTop: 10,
    marginRight: 10,
    "&:hover": {
      backgroundColor: "#5a5c5a",
    },
    "&:disabled": {
      backgroundColor: "#5a5c5a",
      color: "white",
    },
  },
  card: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      flexDirection: "column",
    },
  },
  section: {
    borderRadius: "20px",
    // backgroundColor: '#FCB134',
    margin: "10px",
    padding: "15px",
    flex: 1,
  },
  p: {
    color: "#7AA0C2",
  },
  cardSection: {
    marginLeft: "10px",
    backgroundColor: "#181E2B",
    // backgroundColor: '#7A235B',
    width: "75%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: "100%",
      // height: "120px",
    },
  },
  messageText: {
    // paddingLeft: "30px",
    margin: "10px",
  },
  imageSection: {
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  recommendedPosts: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  red: {
    color: "red",
  },
  search: {
    display: "flex",
    marginBottom: "10px",
  },
  searchInput: {
    //  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    boxShadow: "0px 0px 5px #ccc",
    marginLeft: "10px",
    width: "250px",
    height: "38px",
    borderTopLeftRadius: "25px",
    borderBottomLeftRadius: "25px",
    border: "1px solid #ccc",
    paddingLeft: "10px",
    fontSize: "14px",
    color: "#333",
    fontFamily: "Roboto, sans-serif",
    fontStyle: "italic",
    outline: "none",
    "&:focus": {
      border: "1px solid #cccc",
    },
  },
  deliveredColor: {
    color: "#009E22",
  },
  dispatchGrey: {
    color: "grey",
  },
  paid: {
    color: "#9FC131",
  },
  processingOrange: {
    color: "#E08219",
  },
  loadingPaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    borderRadius: "15px",
    height: "39vh",
  },
  list: {
    listStyleType: 'none', // remove the default bullet
    paddingLeft: theme.spacing(1), // Add some padding to push the content away from the custom bullet
  },
  listItem: {
    position: 'relative',
    paddingLeft: theme.spacing(1.5),  // Adjust this value to reduce/increase the space between the bullet and the text
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      width: 6,
      height: 6,
      borderRadius: '50%', // this will make it round
      backgroundColor: '#ffff',
    }
  },
}));
