import {
  START_LOADING_RIDER,
  CREATE_RIDER,
  FETCH_RIDERS,
  FETCH_RIDER,
  UPDATE_RIDER,
  DELETE_RIDER,
  RESET_RIDER,
  RIDER_FETCH_FAIL,
  END_LOADING_RIDER,
  CREATE_RESTAURANT_RIDER,
  FETCH_RESTAURANT_RIDERS,
  UPDATE_RESTAURANT_RIDER,
  DELETE_RESTAURANT_RIDER,
  UPDATE_RESTAURANT_RIDER_STATUS,
  FETCH_RETAILER_RIDERS,
  CREATE_RETAILER_RIDER,
  UPDATE_RETAILER_RIDER,
  DELETE_RETAILER_RIDER,
  UPDATE_RETAILER_RIDER_STATUS,
  FETCH_ALL_RIDERS,
  UPDATE_RIDER_STATUS
} from "../constants/ridersConstant";

export const ridersReducer = (state = { riders: [] }, action) => {
  switch (action.type) {
    case START_LOADING_RIDER:
      return { ...state, loading: true };
    case CREATE_RIDER:
      return {
        ...state,
        loading: false,
        riders: [...state.riders, action.payload],
      };
    case FETCH_RIDERS:
      return {
        ...state,
        loading: false,
        riders: action.payload,
      };
    case FETCH_RIDER:
      return {
        ...state,
        loading: false,
        rider: action.payload.rider,
      };
    case UPDATE_RIDER:
      return {
        ...state,
        riders: state.riders.map((rider) =>
          rider._id === action.payload._id ? action.payload : rider
        ),
        loading: false,
      };
    case DELETE_RIDER:
      return {
        ...state,
        riders: state.riders.filter((rider) => rider._id !== action.payload),
        loading: false,
      };
    case RESET_RIDER:
      return {
        ...state,
        rider: {},
      };
    case RIDER_FETCH_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case END_LOADING_RIDER:
      return { ...state, loading: false };
    case FETCH_ALL_RIDERS:
      return {
        ...state,
        loading: false,
        riders: action.payload,
      };
    case UPDATE_RIDER_STATUS:
      return {
        ...state,
        riders: state.riders.map((rider) =>
          rider._id === action.payload.riderId
            ? { ...rider, onlineStatus: action.payload.status }
            : rider
        ),
      };
    // Restaurant specific reducers
    case CREATE_RESTAURANT_RIDER:
      return {
        ...state,
        loading: false,
        riders: [...state.riders, action.payload],
      };
    case FETCH_RESTAURANT_RIDERS:
      return {
        ...state,
        loading: false,
        riders: action.payload,
      };
    case UPDATE_RESTAURANT_RIDER:
      return {
        ...state,
        riders: state.riders.map((rider) =>
          rider._id === action.payload._id ? action.payload : rider
        ),
        loading: false,
      };
    case DELETE_RESTAURANT_RIDER:
      return {
        ...state,
        riders: state.riders.filter((rider) => rider._id !== action.payload),
        loading: false,
      };
    case UPDATE_RESTAURANT_RIDER_STATUS:
      return {
        ...state,
        riders: state.riders.map((rider) =>
          rider._id === action.payload.riderId
            ? { ...rider, onlineStatus: action.payload.status }
            : rider
        ),
      };

    // Retailer/Wholesaler specific reducers
    case FETCH_RETAILER_RIDERS:
      return {
        ...state,
        loading: false,
        riders: action.payload,
      };
    case CREATE_RETAILER_RIDER:
      return {
        ...state,
        loading: false,
        riders: [...state.riders, action.payload],
      };
    case UPDATE_RETAILER_RIDER:
      return {
        ...state,
        riders: state.riders.map((rider) =>
          rider._id === action.payload._id ? action.payload : rider
        ),
        loading: false,
      };
    case DELETE_RETAILER_RIDER:
      return {
        ...state,
        riders: state.riders.filter((rider) => rider._id !== action.payload),
        loading: false,
      };
    case UPDATE_RETAILER_RIDER_STATUS:
      return {
        ...state,
        riders: state.riders.map((rider) =>
          rider._id === action.payload.riderId
            ? { ...rider, onlineStatus: action.payload.status }
            : rider
        ),
      };
    default:
      return state;
  }
};
