import React, { useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  ButtonToolbar,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  deleteDeliveryZone,
  getDeliveryZone,
  getDeliveryZones,
} from "../../actions/zoneActions";
// Delete DotLoader since not in use
// import DotLoader from "views/Components/DotLoader";

const DeliveryZoneTable = () => {
  const { zones, deliveryZones, loading } = useSelector(
    (state) => state.deliveryZones
  );

  // console.log(zones, "All Delivery Zones");
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getDeliveryZones());
  }, [dispatch]);
  return (
    <>
      {loading ? (
        <Spinner
          animation="grow"
          variant="danger"
          style={{
            marginTop: "70px",
            top: "50%",
            left: "50%",
            position: "fixed",
          }}
        />
      ) : (
        <>
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card className="table-with-links">
                  <Card.Header>
                    <Card.Title as="h4">Delivery Zones</Card.Title>
                    <p className="card-category">
                      Shipping Matrix ~ Areas in delivered coverage.
                    </p>
                  </Card.Header>
                  <Card.Body className="table-full-width">
                    <Table className="table-hover table-striped w-full">
                      <thead>
                        <tr>
                          <th
                            style={{ color: "black" }}
                            className="text-center"
                          >
                            #
                          </th>
                          <th style={{ color: "black", fontStyle: "italic" }}>
                            <strong>Parish</strong>
                          </th>
                          <th style={{ color: "black", fontStyle: "italic" }}>
                            <strong>District</strong>
                          </th>
                          <th style={{ color: "black", fontStyle: "italic" }}>
                            <strong>shipping Cost</strong>
                          </th>
                          <th
                            style={{ color: "black", fontStyle: "italic" }}
                            className="text-right"
                          >
                            <strong>Action</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {zones?.map((item, index) => (
                          <tr key={item?._id}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-right">{item?.village}</td>
                            <td className="text-right">{item?.district}</td>
                            <td className="text-right">{item?.shippingCost}</td>
                            <td>
                              <ButtonToolbar className="mb-1">
                                {["top"].map((placement) => (
                                  <OverlayTrigger
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                      <Tooltip id={`tooltip-${placement}`}>
                                        Edit Zone
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant="primary"
                                      size="sm"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(getDeliveryZone(item._id));
                                        history.push(
                                          "/admin/deliveryzone-create"
                                        );
                                      }}
                                    >
                                      <i className="fas fa-edit"></i>
                                    </Button>
                                  </OverlayTrigger>
                                ))}
                              </ButtonToolbar>

                              <ButtonToolbar>
                                {["right"].map((placement) => (
                                  <OverlayTrigger
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                      <Tooltip id={`tooltip-${placement}`}>
                                        Delete Zone
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant="danger"
                                      size="sm"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(deleteDeliveryZone(item._id));
                                      }}
                                    >
                                      <i className="fas fa-trash"></i>
                                    </Button>
                                  </OverlayTrigger>
                                ))}
                              </ButtonToolbar>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                  <Col style={{ paddingBottom: "15px" }}>
                    <Card.Body>
                      <Col
                        className="font-icon-list"
                        lg="2"
                        md="3"
                        sm="4"
                        xs="6"
                      >
                        <Button
                          className="btn-outline btn-round btn-wd mr-1"
                          variant="danger"
                          // size="sm"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push("/admin/deliveryzone-create");
                          }}
                        >
                          <i
                            className="nc-icon nc-simple-add"
                            style={{ marginRight: "5px" }}
                          ></i>
                          Add Zone
                        </Button>
                      </Col>
                    </Card.Body>
                  </Col>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default DeliveryZoneTable;
