import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Button, Typography, CircularProgress, makeStyles, Tabs, Tab, Box, Card, CardContent, FormControl, InputLabel, Input, Switch, FormControlLabel } from '@material-ui/core';
import { fetchRestaurantProfile, updateRestaurantProfile } from '../../../../actions/restaurantProfileActions';
import { getUserFromLocalStorage } from '../../../../utilities/user.js';
import restaurantPlaceholderImage from '../../../../assets/img/restaurantimage_unavailable.png'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(4),
        backgroundColor: '#f5f5f5',
        width: '100%', // Ensuring the container takes up the full width
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(1),
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    disabledField: {
        cursor: 'not-allowed',
    },
    button: {
        marginTop: theme.spacing(2),
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    error: {
        color: theme.palette.error.main,
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    },
    tabs: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        marginBottom: theme.spacing(2),
    },
    tabContent: {
        padding: theme.spacing(2),
    },
    card: {
        maxWidth: '100%', // Increase the maximum width to 100%
        margin: 'auto',
        marginTop: theme.spacing(2),
        boxShadow: theme.shadows[5],
    },
    cardContent: {
        padding: theme.spacing(3),
    },
    switchContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    profileImage: {
        width: '120px',
        height: '120px',
        borderRadius: '10%',
        objectFit: 'cover',
        marginBottom: theme.spacing(2),
    },
    // imageInput: {
    //     display: 'none',
    // },
}));

const RestaurantProfile = () => {
    const user = getUserFromLocalStorage();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { profile, loading, error } = useSelector((state) => state.restaurantProfile);

    const vendorId = user?.result?._id;

    const [form, setForm] = useState({
        name: '',
        address: '',
        phone: '',
        email: '',
        profileImage: '',
    });

    const [imagePreview, setImagePreview] = useState(null);
    const [notifications, setNotifications] = useState({
        sms: false,
        email: false,
        whatsapp: false,
        phoneCall: false,
        dropOff: false,
    });

    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        dispatch(fetchRestaurantProfile(vendorId));
    }, [dispatch, vendorId]);

    useEffect(() => {
        if (profile) {
            setForm(profile);
            setImagePreview(profile.imageUrl || restaurantPlaceholderImage);
        }
    }, [profile]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({ ...prevForm, [name]: value }));
    };

    const handleNotificationChange = (e) => {
        const { name, checked } = e.target;
        setNotifications((prevNotifications) => ({ ...prevNotifications, [name]: checked }));
    };

    // const handleImageChange = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         setImagePreview(URL.createObjectURL(file));
    //         setForm((prevForm) => ({ ...prevForm, profileImage: file }));
    //     }
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateRestaurantProfile(vendorId, form));
    };

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    if (loading) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <Container className={classes.root}>
            {error && (
                <Typography variant="h6" className={classes.error}>
                    Error: {error}
                </Typography>
            )}
            <Card className={classes.card}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    aria-label="profile tabs"
                    className={classes.tabs}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Account" />
                    <Tab label="Notifications" />
                    <Tab label="Advanced" />
                    <Tab label="Deactivate" />
                </Tabs>
                <CardContent className={classes.cardContent}>
                    <TabPanel value={tabIndex} index={0} className={classes.tabContent}>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <img src={imagePreview} alt="Profile Preview" className={classes.profileImage} />
                            {/* <input
                                accept="image/*"
                                className={classes.imageInput}
                                id="profile-image"
                                type="file"
                                onChange={handleImageChange}
                            /> */}
                            {/* <label htmlFor="profile-image">
                                <Button variant="contained" color="primary" component="span">
                                    Upload Image
                                </Button>
                            </label> */}
                            <FormControl className={`${classes.textField} ${classes.disabledField}`} variant="outlined">
                                <InputLabel htmlFor="name">Name</InputLabel>
                                <Input
                                    id="name"
                                    name="name"
                                    value={form.name}
                                    onChange={handleChange}
                                    disabled
                                />
                            </FormControl>
                            <FormControl className={classes.textField} variant="outlined">
                                <InputLabel htmlFor="address">Address</InputLabel>
                                <Input
                                    id="address"
                                    name="address"
                                    value={form.address}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <FormControl className={classes.textField} variant="outlined">
                                <InputLabel htmlFor="phone">Phone</InputLabel>
                                <Input
                                    id="phone"
                                    name="phone"
                                    value={form.phone}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <FormControl className={classes.textField} variant="outlined">
                                <InputLabel htmlFor="email">Email</InputLabel>
                                <Input
                                    id="email"
                                    name="email"
                                    value={form.email}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <Button className={classes.button} variant="contained" color="primary" type="submit">
                                Update Profile
                            </Button>
                        </form>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1} className={classes.tabContent}>
                        <Typography variant="h6" gutterBottom>Notification Settings</Typography>
                        <div className={classes.switchContainer}>
                            <FormControlLabel
                                control={<Switch checked={notifications.sms} onChange={handleNotificationChange} name="sms" color="primary" />}
                                label="SMS"
                            />
                            <FormControlLabel
                                control={<Switch checked={notifications.email} onChange={handleNotificationChange} name="email" color="primary" />}
                                label="Email"
                            />
                            <FormControlLabel
                                control={<Switch checked={notifications.whatsapp} onChange={handleNotificationChange} name="whatsapp" color="primary" />}
                                label="WhatsApp"
                            />
                            <FormControlLabel
                                control={<Switch checked={notifications.phoneCall} onChange={handleNotificationChange} name="phoneCall" color="primary" />}
                                label="Phone Call"
                            />
                            <FormControlLabel
                                control={<Switch checked={notifications.dropOff} onChange={handleNotificationChange} name="dropOff" color="primary" />}
                                label="Drop-Off"
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2} className={classes.tabContent}>
                        <Typography variant="h6">Advanced Settings</Typography>
                        {/* Add your advanced settings fields here */}
                    </TabPanel>
                    <TabPanel value={tabIndex} index={3} className={classes.tabContent}>
                        <Typography variant="h6">Deactivate Account</Typography>
                        {/* Add your deactivate account fields here */}
                    </TabPanel>
                </CardContent>
            </Card>
        </Container>
    );
};

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

export default RestaurantProfile;
