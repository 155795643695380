import Dashboard from "./views/Dashboard.js";
import ListForm from "./views/Forms/ListForm";
import Menu from "./views/Menu";
import RestaurantVendorMenu from "./views/RestaurantVendorMenu";
import Restaurants from "./views/Restaurants.js";
import PromoForm from "./views/Forms/PromoForm";
import DeliveryZoneForm from "./views/Forms/DeliveryZoneForm";
import ShopCard from "./views/Forms/Listings/ShopCard";
import DeactivatedListings from "./views/Forms/Listings/DeactivatedListings";
import PaginatedOrderTable from "./views/Tables/PaginatedOrderTable";
import RestaurantOrderTable from "./views/Tables/RestaurantOrderTable";
import VendorOrderTable from "./views/Tables/VendorOrderTable";
import MobileWebOrderTable from "./views/Tables/MobileWebOrderTable";
import DeliveryZoneTable from "./views/Tables/DeliveryZoneTable";
import UserForm from "./views/Forms/UserForm.js";
import Users from "./views/Components/Users.js";
import MobileUsers from "./views/Components/MobileUsers.js";
import CartScreen from "./views/Cart/CartScreen.js";
import CategoryForm from "./views/Forms/CategoryForm.js";
import CategoriesTable from "./views/Tables/CategoriesTable.js";
import RidersTable from "./views/Tables/RidersTable.js";
import DeactiveCategoriesTable from "./views/Tables/DeactiveCategoriesTable.js";
import SiUnitForm from "./views/Forms/SiUnitForm.js";
import RiderForm from "./views/Forms/RiderForm.js";
import NotificationsForm from "./views/Forms/NotificationsForm.js";
import ShippingCostForm from "./views/Forms/shippingCostForm";
import Sellers from "./views/Components/Sellers";
import ListingDetails from "./views/Forms/Listings/ListingDetails";
import SellerDetailsCard from "./views/Forms/Sellers/SellerDetailsCard";
import OrderDetailsCard from "./views/Forms/Listings/Listing/OrderDetailsCard";
import RestaurantOrderDetails from "./views/Forms/Listings/Listing/RestaurantOrderDetails";
import ShippingScreen from "./views/Shipping/ShippingScreen";
import PaymentScreen from "./views/Payment/PaymentScreen";
import PlaceOrderScreen from "./views/PlaceOrder/PlaceOrderScreen";
import PayOrderScreen from "./views/PlaceOrder/PayOrderScreen";
import ListingEditForm from "./views/Forms/ListingEditForm";
import SellerEditForm from "./views/Forms/Sellers/SellerEditForm";
import AgentPasswordResetRequestScreen from "./views/Forms/AgentPasswordResetRequestScreen";
import UserEdit from "./views/Forms/UserEdit.js";
import SiUnitTable from "./views/Tables/SiUnitTable";
import VendorOrderDetailsCard from "./views/Forms/Listings/Listing/VendorOrderDetailsCard";
import processRefund from "views/Forms/ProcessRefundForm";
import OutOfStock from "./views/Forms/Listings/OutOfStock";
import CreateRestaurantProductForm from "./views/CreateRestaurantProductForm.js";
import EditMenuProductForm from "./views/EditMenuProductForm.js";
import RestaurantVendorDashboard from "./views/RestaurantVendorDashboard.js";
import RestaurantVendorOrderDetails from "./views/Forms/Listings/Listing/RestaurantVendorOrderDetails.js";
import SellerDashboard from "./views/SellerDashboard.js";
import RestaurantProfile from "./views/Forms/Listings/Listing/RestaurantProfile.js";
import RetailerWholesalerVendorMenu from "./views/RetailerWholesalerVendorMenu.js";
import CreateRetailerWholesalerProductForm from "./views/CreateRetailerWholesalerProductForm.js";
import RetailerWholesalerOrderTable from "./views/Tables/RetailerWholesalerOrderTable.js";
import RetailerWholesalerOrderDetails from "./views/Forms/Listings/Listing/RetailerWholesalerOrderDetails.js";
import RetailerWholesalerProfile from "./views/Forms/Listings/Listing/RetailerWholesalerProfile.js";
import EditCatalogProductForm from "./views/EditCatalogProductForm.js";
import Login from "./views/Pages/Login.js";
import RestaurantRidersTable from "./views/Tables/RestaurantRidersTable.js";
import RetailerRidersTable from "./views/Tables/RetailerRidersTable.js";
import SuperAdminOrderDetails from "./views/Forms/Listings/Listing/SuperAdminOrderDetails.js";
import SuperAdminRestaurantOrderDetails from "./views/Forms/Listings/Listing/SuperAdminRestaurantOrderDetails.js";
import RetailersWholesalers from "./views/RetailersWholesalers.js";
import RetailerWholesalerAccount from "./views/RetailerWholesalerAccount.js";
import PackageDeliveryManagement from "./views/Forms/Listings/Listing/PackageDeliveryManagement.js";
import AdvertManager from "./views/Adverts/AdvertManager.js";

const publicRoutes = [
  // {
  //   path: "/auth/login-page",
  //   layout: "/admin",
  //   name: "Login Page",
  //   component: Login,
  // },
  {
    path: "/resetPassword",
    layout: "/public",
    name: "Reset Password",
    component: AgentPasswordResetRequestScreen,
  },
];
// Auth route
const authRoute = [
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
  },
  {
    path: "/login-page",
    layout: "/auth",
    name: "Login",
    icon: "nc-icon nc-chart-pie-35",
    component: Login,
  },
];

// Super user routes
const superUserRoutes = [
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
  },

  {
    collapse: true,
    path: "/shop",
    name: "SHOP",
    state: "openShop",
    icon: "nc-icon nc-paper-2",
    views: [
      {
        path: "/shopCards",
        layout: "/admin",
        name: "E-Shop",
        mini: "ES",
        component: ShopCard,
      },
      {
        path: "/listing-details/:id",
        name: "Listing Details",
        icon: "nc-icon nc-single-copy-04",
        component: ListingDetails,
        layout: "/admin",
        hidden: true,
      },
      {
        path: "/shopEdit/:id",
        name: "Edit Listing",
        component: ListingEditForm,
        layout: "/admin",
        hidden: true,
      },
      {
        path: "/shipping",
        name: "Shipping Address",
        component: ShippingScreen,
        layout: "/admin",
        hidden: true,
      },
      {
        path: "/payment",
        name: "Payment",
        component: PaymentScreen,
        layout: "/admin",
        hidden: true,
      },
      {
        path: "/placeorder",
        name: "Place Order",
        component: PlaceOrderScreen,
        layout: "/admin",
        hidden: true,
      },
      {
        path: "/order/:id",
        name: "Pay Order",
        component: PayOrderScreen,
        layout: "/admin",
        hidden: true,
      },
      {
        path: "/deactivatedListings",
        layout: "/admin",
        name: "Deactivated Listings",
        mini: "DL",
        component: DeactivatedListings,
      },
      {
        path: "/outOfStockListings",
        layout: "/admin",
        name: "Out Of Stock",
        mini: "OS",
        component: OutOfStock,
      },
    ],
  },
  {
    collapse: true,
    path: "/restaurant",
    name: "RESTAURANT",
    state: "e-Kitchen",
    icon: "nc-icon nc-paper-2",
    views: [
      {
        path: "/restaurants",
        layout: "/admin",
        name: "Restaurants",
        mini: "Rt",
        component: Restaurants,
      },
      // {
      //   path: "/menu",
      //   layout: "/admin",
      //   name: "Menu",
      //   mini: "Mn",
      //   component: Menu,
      // },
      {
        path: "/menu-create",
        layout: "/admin",
        // name: "Menu Product Form",
        mini: "Mc",
        component: CreateRestaurantProductForm,
        hidden: true,
      },
      {
        path: "/edit-menu-product/:productId",
        layout: "/admin",
        // name: "Menu Product Form",
        mini: "EM",
        component: EditMenuProductForm,
        hidden: true,
      },
    ],
  },
  {
    collapse: true,
    path: "/rw",
    name: "R & W",
    state: "e-traders",
    icon: "nc-icon nc-paper-2",
    views: [
      {
        path: "/rw-vendors",
        layout: "/admin",
        name: "Vendors",
        mini: "RW",
        component: RetailersWholesalers,
      },
    ],
  },
  {
    collapse: true,
    path: "/forms",
    name: "Forms",
    state: "openForms",
    icon: "nc-icon nc-notes",
    views: [
      {
        path: "/list-form",
        layout: "/admin",
        name: "Create Listing Form",
        mini: "LF",
        component: ListForm,
      },
      {
        path: "/promo-form",
        layout: "/admin",
        name: "Promo Form",
        mini: "PF",
        component: PromoForm,
      },
      {
        path: "/unit-form",
        layout: "/admin",
        name: "Create Unit Form",
        mini: "UF",
        component: SiUnitForm,
      },
      {
        path: "/shipping-cost",
        layout: "/admin",
        name: "Create Cost Form",
        mini: "CC",
        component: ShippingCostForm,
      },
    ],
  },
  {
    path: "/orderDetails/:id",
    layout: "/admin",
    name: "Order Details",
    mini: "OD",
    component: OrderDetailsCard,
    hidden: true,
  },
  {
    path: "/restaurantOrderDetails/:id",
    layout: "/admin",
    name: "Restaurant Order Details",
    mini: "RO",
    component: RestaurantOrderDetails,
    hidden: true,
  },
  {
    path: "/shopEdit/:id",
    name: "Edit Listing",
    component: ListingEditForm,
    layout: "/admin",
    hidden: true,
  },
  {
    collapse: true,
    path: "/tables",
    name: "Orders",
    state: "openTables",
    icon: "nc-icon nc-paper-2",
    views: [
      {
        path: "/paginatedOrdersTable",
        layout: "/admin",
        name: "Manage Orders",
        mini: "PO",
        component: PaginatedOrderTable,
      },
      {
        path: "/vendorOrders-mng/:orderId",
        layout: "/admin",
        name: "Manage Vendors Orders",
        mini: "SAOD",
        component: SuperAdminOrderDetails,
        hidden: true,
      },
      {
        path: "/restaurantOrders-mng/:orderId",
        layout: "/admin",
        name: "Manage Restaurant Vendors Orders",
        mini: "SAROD",
        component: SuperAdminRestaurantOrderDetails,
        hidden: true,
      },
      {
        path: "/package-delivery/:id",
        layout: "/admin",
        name: "Package Delivery",
        mini: "PD",
        component: PackageDeliveryManagement,
        hidden: true,
      },
      {
        path: "/mobileWebOrdersTable",
        layout: "/admin",
        name: "Orders History",
        mini: "MO",
        component: MobileWebOrderTable,
      },
    ],
  },
  {
    collapse: true,
    path: "/users",
    name: "Users",
    state: "openUsers",
    icon: "nc-icon nc-app",
    views: [
      {
        path: "/user-form",
        layout: "/admin",
        name: "Create User",
        mini: "CU",
        component: UserForm,
      },
      {
        path: "/users",
        layout: "/admin",
        name: "Lusuku Staff",
        mini: "LS",
        component: Users,
      },
      {
        path: "/user-edit/:userId",
        layout: "/admin",
        name: "Edit Agent",
        mini: "EA",
        component: UserEdit,
        hidden: true,
      },
      {
        path: "/mobileUsers",
        layout: "/admin",
        name: "Mobile Clients",
        mini: "MC",
        component: MobileUsers,
      },
      {
        path: "/sellers",
        layout: "/admin",
        name: "Sellers",
        mini: "SL",
        component: Sellers,
      },
      {
        path: "/sellerDetail/:id",
        layout: "/admin",
        name: "Seller Details",
        mini: "SL",
        component: SellerDetailsCard,
        hidden: true,
      },
      {
        path: "/sellerEdit/:id",
        layout: "/admin",
        name: "Seller Edit",
        mini: "SE",
        component: SellerEditForm,
        hidden: true,
      },
    ],
  },

  {
    collapse: true,
    path: "/cart",
    name: "Cart",
    state: "opeCart",
    icon: "nc-icon nc-cart-simple",
    views: [
      {
        path: "/cart-page",
        layout: "/admin",
        name: "Cart Page",
        mini: "CP",
        component: CartScreen,
      },
    ],
  },
  {
    collapse: true,
    path: "/delivery",
    name: "Delivery",
    state: "opeZone",
    icon: "nc-icon nc-chart",
    views: [
      {
        path: "/deliveryzone-create",
        layout: "/admin",
        name: "Create Zones",
        mini: "DC",
        component: DeliveryZoneForm,
      },
      {
        path: "/delivery-table",
        layout: "/admin",
        name: "Delivery Zones",
        mini: "DZ",
        component: DeliveryZoneTable,
      },
    ],
  },
  {
    collapse: true,
    path: "/category",
    name: "Categorization",
    state: "opeCategory",
    icon: "nc-icon ns-icon nc-tag-content",
    views: [
      {
        path: "/category-table",
        layout: "/admin",
        name: "Active Categories",
        mini: "AC",
        component: CategoriesTable,
      },
      {
        path: "/deactive-category-table",
        layout: "/admin",
        name: "Deactivated Categories",
        mini: "DC",
        component: DeactiveCategoriesTable,
      },
      {
        path: "/category-create",
        layout: "/admin",
        name: "Create Category",
        mini: "CC",
        component: CategoryForm,
      },
      {
        path: "/units",
        layout: "/admin",
        name: "Active Units",
        mini: "AU",
        component: SiUnitTable,
      },
    ],
  },
  {
    collapse: true,
    path: "/riders",
    name: "Riders",
    state: "opeRiders",
    icon: "nc-icon nc-delivery-fast",
    views: [
      {
        path: "/createRider-table",
        layout: "/admin",
        name: "Create Rider",
        mini: "CR",
        component: RiderForm,
      },
      {
        path: "/riders-table",
        layout: "/admin",
        name: "Active Riders",
        mini: "AR",
        component: RidersTable,
      },
    ],
  },
  {
    collapse: true,
    path: "/notifications",
    name: "Notifications",
    state: "opeNotifications",
    icon: "nc-icon nc-bell-55",
    views: [
      {
        path: "/notifications",
        layout: "/admin",
        name: "Notifications",
        mini: "N",
        component: NotificationsForm,
      },
    ],
  },
  {
    collapse: true,
    path: "/refund",
    name: "Refund",
    state: "opeRefund",
    icon: "nc-icon nc-money-coins",
    views: [
      {
        path: "/refund",
        layout: "/admin",
        name: "Refund",
        mini: "R",
        component: processRefund,
      },
    ],
  },
  // Advertisment
  {
    collapse: true,
    path: "/advertisement",
    name: "Advertisement",
    state: "openAdvertisement",
    icon: "nc-icon nc-money-coins",
    views: [
      {
        path: "/advertisement",
        layout: "/admin",
        name: "Ad Center",
        mini: "AC",
        component: AdvertManager,
      },
    ],
  },
];

// Supervisor routes
const supervisorRoutes = [
  // Dashboard
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
  },

  // Shop
  {
    collapse: true,
    path: "/shop",
    name: "SHOP",
    state: "openShop",
    icon: "nc-icon nc-paper-2",
    views: [
      {
        path: "/shopCards",
        layout: "/admin",
        name: "E-Shop",
        mini: "ES",
        component: ShopCard,
      },
      {
        path: "/shipping",
        name: "Shipping Address",
        icon: "nc-icon nc-single-copy-04",
        component: ShippingScreen,
        layout: "/admin",
        hidden: true,
      },
      {
        path: "/payment",
        name: "Payment Screen",
        component: PaymentScreen,
        layout: "/admin",
        hidden: true,
      },
      {
        path: "/placeorder",
        name: "Place Order",
        component: PlaceOrderScreen,
        layout: "/admin",
        hidden: true,
      },
      {
        path: "/order/:id",
        name: "Pay Order",
        component: PayOrderScreen,
        layout: "/admin",
        hidden: true,
      },
      {
        path: "/deactivatedListings",
        layout: "/admin",
        name: "Deactivated Listings",
        mini: "DL",
        component: DeactivatedListings,
      },
      {
        path: "/outOfStockListings",
        layout: "/admin",
        name: "Out Of Stock",
        mini: "OS",
        component: OutOfStock,
      },
    ],
  },
  // Listings
  {
    collapse: true,
    path: "/forms",
    name: "Forms",
    state: "openForms",
    icon: "nc-icon nc-notes",
    views: [
      {
        path: "/list-form",
        layout: "/admin",
        name: "Create Listing Form",
        mini: "LF",
        component: ListForm,
      },
    ],
  },
  // Orders
  {
    collapse: true,
    path: "/tables",
    name: "Orders",
    state: "openTables",
    icon: "nc-icon nc-paper-2",
    views: [
      {
        path: "/vendorOrdersTable",
        layout: "/admin",
        name: "My Orders",
        mini: "VO",
        component: VendorOrderTable,
      },
      {
        path: "/vendorOrderDetails/:id",
        layout: "/admin",
        name: "My Order Details",
        mini: "SO",
        component: VendorOrderDetailsCard,
        hidden: true,
      },

      {
        path: "/listing-details/:id",
        layout: "/admin",
        name: "More Order Details",
        mini: "OD",
        component: ListingDetails,
        hidden: true,
      },
    ],
  },
  {
    collapse: true,
    path: "/cart",
    name: "Cart",
    state: "opeCart",
    icon: "nc-icon nc-cart-simple",
    views: [
      {
        path: "/cart-page",
        layout: "/admin",
        name: "Cart Page",
        mini: "CP",
        component: CartScreen,
      },
    ],
  },
  // Users
  {
    collapse: true,
    path: "/users",
    name: "Users",
    state: "openUsers",
    icon: "nc-icon nc-app",
    views: [
      {
        path: "/user-form",
        layout: "/admin",
        name: "Create User",
        mini: "CU",
        component: UserForm,
      },
      {
        path: "/users",
        layout: "/admin",
        name: "Lusuku Staff",
        mini: "LS",
        component: Users,
      },
      {
        path: "/mobileUsers",
        layout: "/admin",
        name: "Mobile Clients",
        mini: "MU",
        component: MobileUsers,
      },
      {
        path: "/sellers",
        layout: "/admin",
        name: "Sellers",
        mini: "SL",
        component: Sellers,
      },
    ],
  },
  // Riders
  {
    collapse: true,
    path: "/riders",
    name: "Riders",
    state: "opeRiders",
    icon: "nc-icon nc-delivery-fast",
    views: [
      {
        path: "/riders-table",
        layout: "/admin",
        name: "Active Riders",
        mini: "AR",
        component: RidersTable,
      },
    ],
  },
];

// Seller routes
// const sellerRoutes = [
//   // Dashboard
//   {
//     path: "/dashboard",
//     layout: "/admin",
//     name: "Dashboard",
//     icon: "nc-icon nc-chart-pie-35",
//     component: Dashboard,
//   },

//   // Shop
//   {
//     collapse: true,
//     path: "/shop",
//     name: "SHOP",
//     state: "openShop",
//     icon: "nc-icon nc-paper-2",
//     views: [
//       {
//         path: "/shopCards",
//         layout: "/admin",
//         name: "E-Shop",
//         mini: "ES",
//         component: ShopCard,
//       },
//       {
//         path: "/shipping",
//         name: "Shipping Address",
//         icon: "nc-icon nc-single-copy-04",
//         component: ShippingScreen,
//         layout: "/admin",
//         hidden: true,
//       },
//       {
//         path: "/payment",
//         name: "Payment Screen",
//         component: PaymentScreen,
//         layout: "/admin",
//         hidden: true,
//       },
//       {
//         path: "/placeorder",
//         name: "Place Order",
//         component: PlaceOrderScreen,
//         layout: "/admin",
//         hidden: true,
//       },
//       {
//         path: "/order/:id",
//         name: "Pay Order",
//         component: PayOrderScreen,
//         layout: "/admin",
//         hidden: true,
//       },
//       {
//         path: "/deactivatedListings",
//         layout: "/admin",
//         name: "Deactivated Listings",
//         mini: "DL",
//         component: DeactivatedListings,
//       },
//       {
//         path: "/outOfStockListings",
//         layout: "/admin",
//         name: "Out Of Stock",
//         mini: "OS",
//         component: OutOfStock,
//       },
//     ],
//   },
//   // Listings
//   {
//     collapse: true,
//     path: "/forms",
//     name: "Forms",
//     state: "openForms",
//     icon: "nc-icon nc-notes",
//     views: [
//       {
//         path: "/list-form",
//         layout: "/admin",
//         name: "Create Listing Form",
//         mini: "LF",
//         component: ListForm,
//       },
//     ],
//   },
//   // Orders
//   {
//     collapse: true,
//     path: "/tables",
//     name: "Orders",
//     state: "openTables",
//     icon: "nc-icon nc-paper-2",
//     views: [
//       {
//         path: "/vendorOrdersTable",
//         layout: "/admin",
//         name: "My Orders",
//         mini: "VO",
//         component: VendorOrderTable,
//       },
//       {
//         path: "/vendorOrderDetails/:id",
//         layout: "/admin",
//         name: "My Order Details",
//         mini: "SO",
//         component: VendorOrderDetailsCard,
//         hidden: true,
//       },

//       {
//         path: "/listing-details/:id",
//         layout: "/admin",
//         name: "More Order Details",
//         mini: "OD",
//         component: ListingDetails,
//         hidden: true,
//       },
//     ],
//   },
//   {
//     collapse: true,
//     path: "/cart",
//     name: "Cart",
//     state: "opeCart",
//     icon: "nc-icon nc-cart-simple",
//     views: [
//       {
//         path: "/cart-page",
//         layout: "/admin",
//         name: "Cart Page",
//         mini: "CP",
//         component: CartScreen,
//       },
//     ],
//   },
//   // Users
//   {
//     collapse: true,
//     path: "/users",
//     name: "Users",
//     state: "openUsers",
//     icon: "nc-icon nc-app",
//     views: [
//       {
//         path: "/user-form",
//         layout: "/admin",
//         name: "Create User",
//         mini: "CU",
//         component: UserForm,
//       },
//     ],
//   },
//   // Riders
//   {
//     collapse: true,
//     path: "/riders",
//     name: "Riders",
//     state: "opeRiders",
//     icon: "nc-icon nc-delivery-fast",
//     views: [
//       {
//         path: "/riders-table",
//         layout: "/admin",
//         name: "Active Riders",
//         mini: "AR",
//         component: RidersTable,
//       },
//     ],
//   },
// ];
const restaurantVendorRoutes = [
  {
    path: "/kitchen",
    layout: "/admin",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: RestaurantVendorDashboard,
  },
  {
    collapse: true,
    path: "/restaurant",
    name: "RESTAURANT",
    state: "e-Kitchen",
    icon: "nc-icon nc-alien-33",
    views: [
      {
        path: "/restaurants",
        layout: "/admin",
        name: "Restaurants",
        mini: "Rt",
        component: Restaurants,
      },
      {
        path: "/rest-menu",
        layout: "/admin",
        name: "Menu",
        mini: "RM",
        component: RestaurantVendorMenu,
      },
      {
        path: "/menu-create",
        layout: "/admin",
        mini: "Mc",
        component: CreateRestaurantProductForm,
        hidden: true,
      },
      {
        path: "/edit-menu-product/:productId",
        layout: "/admin",
        mini: "EM",
        component: EditMenuProductForm,
        hidden: true,
      },
    ],
  },
  {
    collapse: true,
    path: "/orders",
    name: "Orders",
    state: "openOrders",
    icon: "nc-icon nc-paper-2",
    views: [
      // {
      //   path: "/paginatedOrdersTable",
      //   layout: "/admin",
      //   name: "Manage Orders",
      //   mini: "PO",
      //   component: PaginatedOrderTable,
      // },
      {
        path: "/resOrdersTable",
        layout: "/admin",
        name: "Restaurant Orders",
        mini: "RO",
        component: RestaurantOrderTable,
      },
      {
        path: "/restVenOrderDetails/:id",
        layout: "/admin",
        name: "Restaurant Vendor Order Details",
        mini: "RVO",
        component: RestaurantVendorOrderDetails,
        hidden: true,
      },
    ],
  },
  {
    collapse: true,
    path: "/riders",
    name: "In-House DA",
    state: "openRiders",
    icon: "nc-icon nc-delivery-fast",
    views: [
      {
        path: "/restaurant-riders",
        layout: "/admin",
        name: "Riders",
        mini: "Rd",
        component: RestaurantRidersTable,
      },
    ],
  },
  {
    collapse: true,
    path: "/account",
    name: "Account",
    state: "accountState",
    icon: "nc-icon nc-single-02",
    views: [
      {
        path: "/profile",
        layout: "/admin",
        name: "Profile",
        mini: "RP",
        component: RestaurantProfile,
      },
    ],
  },
];
//Retailer & Wholesaler routes
const retailerWholesalerRoutes = [
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: SellerDashboard,
  },
  {
    collapse: true,
    path: "/shop",
    name: "SHOP",
    state: "openShop",
    icon: "nc-icon nc-paper-2",
    views: [
      {
        path: "/retailerWholesalerOrdersTable",
        layout: "/admin",
        name: "Shop Orders",
        mini: "SO",
        component: RetailerWholesalerOrderTable,
      },
      {
        path: "/retWholesalerOrderDetails/:id",
        layout: "/admin",
        name: "Shop Vendor Order Details",
        mini: "RWO",
        component: RetailerWholesalerOrderDetails,
        hidden: true,
      },
      {
        path: "/catalog",
        layout: "/admin",
        name: "Catalog",
        mini: "CL",
        component: RetailerWholesalerVendorMenu,
      },
      {
        path: "/rw-profile",
        layout: "/admin",
        name: "My Profile",
        mini: "MP",
        component: RetailerWholesalerAccount,
      },
      {
        path: "/rw-product-create",
        layout: "/admin",
        mini: "Rw",
        component: CreateRetailerWholesalerProductForm,
        hidden: true,
      },
      {
        path: "/edit-catalog-product/:productId",
        layout: "/admin",
        mini: "CP",
        component: EditCatalogProductForm,
        hidden: true,
      },
    ],
  },
  {
    collapse: true,
    path: "/riders",
    name: "In-House DA",
    state: "openRiders",
    icon: "nc-icon nc-delivery-fast",
    views: [
      {
        path: "/rw-riders",
        layout: "/admin",
        name: "Delivery Agents",
        mini: "Rd",
        component: RetailerRidersTable,
      },
    ],
  },
  {
    collapse: true,
    path: "/account",
    name: "Account",
    state: "accountState",
    icon: "nc-icon nc-single-02",
    views: [
      {
        path: "/profile",
        layout: "/admin",
        name: "Profile",
        mini: "RP",
        component: RetailerWholesalerProfile,
      },
    ],
  },
];

export {
  superUserRoutes,
  supervisorRoutes,
  // sellerRoutes,
  authRoute,
  publicRoutes,
  restaurantVendorRoutes,
  retailerWholesalerRoutes,
};

// export default routes;
