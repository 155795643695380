import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { createClientProfile } from "../../actions/auth";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
// import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { getUserFromLocalStorage } from "utilities/user";

const CreateClientModal = ({ open, setOpen, onSuccess, refetchUsers }) => {
  const user = getUserFromLocalStorage();

  const [postData, setPostData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const dispatch = useDispatch();

  const handleSubmitNewClient = (e) => {
    e.preventDefault();
    dispatch(createClientProfile({ ...postData }, user?.result?._id))
      .then(() => {
        setOpen(false);
        if (onSuccess) {
          onSuccess();
        }
        if (refetchUsers) {
          refetchUsers();
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error creating new client!");
      });
  };

  return (
    <div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create New Client</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create new client if this is a non-existing customer!
          </DialogContentText>
          <Form autoComplete="off" onSubmit={handleSubmitNewClient}>
            <Form.Group className="mb-2" controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                placeholder="Enter First Name..."
                type="text"
                name="firstName"
                value={postData.firstName}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    firstName: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                placeholder="Enter Last Name..."
                type="text"
                name="lastName"
                value={postData.lastName}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    lastName: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group
              className="mb-2"
              controlId="formBasicEmail"
              autoComplete="off"
            >
              <Form.Label>Email</Form.Label>
              <Form.Control
                placeholder="Enter email address..."
                type="email"
                name="email"
                value={postData.email}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    email: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicPassword">
              <Form.Label>Create Password</Form.Label>
              <Form.Control
                placeholder="Create password..."
                type="password"
                name="password"
                value={postData.password}
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    password: e.target.value,
                  })
                }
              />
            </Form.Group>
            <DialogActions>
              <Button variant="danger" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button type="submit">Create</Button>
            </DialogActions>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateClientModal;
