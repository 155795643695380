// Redux action creators
import {
  CREATE_AD,
  DELETE_AD,
  END_LOADING_AD,
  FETCH_ADS,
  START_LOADING_AD,
  UPDATE_AD,
} from "../constants/advertConstants";
import { toast } from "react-toastify";

export const fetchAds = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_AD });
    const response = await fetch("/advert/mobile-carousel");
    const data = await response.json();
    dispatch({ type: FETCH_ADS, payload: data });
  } catch (error) {
    toast.error("Failed to fetch advertisements");
  } finally {
    dispatch({ type: END_LOADING_AD });
  }
};

export const createAd = (adData) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_AD });
    const response = await fetch("/advert/mobile-carousel", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(adData),
    });
    const data = await response.json();
    dispatch({ type: CREATE_AD, payload: data });
    toast.success("Advertisement created successfully");
  } catch (error) {
    toast.error("Failed to create advertisement");
  } finally {
    dispatch({ type: END_LOADING_AD });
  }
};

export const updateAd = (id, adData) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_AD });
    const response = await fetch(`/advert/mobile-carousel/${id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(adData),
    });
    const data = await response.json();
    dispatch({ type: UPDATE_AD, payload: data });
    toast.success("Advertisement updated successfully");
  } catch (error) {
    toast.error("Failed to update advertisement");
  } finally {
    dispatch({ type: END_LOADING_AD });
  }
};

export const deleteAd = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_AD });
    await fetch(`/advert/mobile-carousel/${id}`, { method: "DELETE" });
    dispatch({ type: DELETE_AD, payload: id });
    toast.success("Advertisement deleted successfully");
  } catch (error) {
    toast.error("Failed to delete advertisement");
  } finally {
    dispatch({ type: END_LOADING_AD });
  }
};
