import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { getUserFromLocalStorage } from './utilities/user.js';
import useAuth from './hooks/useAuth.js';
import { Spinner } from 'react-bootstrap';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { loading, isAuthenticated } = useAuth(true);
    // useAuth(true); // This hook will handle redirection if the token is invalid || // Pass true to indicate this is a protected route
    // const user = getUserFromLocalStorage();

    return (
        <Route
            {...rest}
            render={props =>
                loading ? (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 9999
                    }}>
                        <Spinner
                            animation="border"
                            variant="dark"
                            role="status"
                            style={{ marginRight: '10px' }}
                        />
                        <span>Loading...</span>
                    </div>
                ) : isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{
                        pathname: "/auth/login-page",
                        state: { from: props.location }
                    }} />
                )
            }
        />
    );
};

export default PrivateRoute;