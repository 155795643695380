import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { createRetailerRider, updateRetailerRider } from '../../actions/ridersAction.js';

const RetailerRiderForm = ({ show, handleClose, vendorId, riderToEdit }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        mobile: '',
        email: '',
        password: '',
        carrierType: 'Rider' // Default value
    });

    useEffect(() => {
        if (riderToEdit) {
            setFormData({
                firstName: riderToEdit.firstName,
                lastName: riderToEdit.lastName,
                mobile: riderToEdit.mobile,
                email: riderToEdit.email,
                password: '', // Leave password empty for editing
                carrierType: riderToEdit.carrierType
            });
        } else {
            setFormData({
                firstName: '',
                lastName: '',
                mobile: '',
                email: '',
                password: '',
                carrierType: 'Rider' // Default value
            });
        }
    }, [riderToEdit]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (riderToEdit) {
            dispatch(updateRetailerRider(formData, vendorId, riderToEdit._id));
        } else {
            dispatch(createRetailerRider(formData, vendorId));
        }
        handleClose();
    };

    const handleReset = () => {
        setFormData({
            firstName: '',
            lastName: '',
            mobile: '',
            email: '',
            password: '',
            carrierType: 'Rider' // Default value
        });
    };

    return (
        <Modal show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{riderToEdit ? 'Edit Rider' : 'Add Rider'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} autoComplete="off">
                    <Form.Group controlId="formFirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="firstName"
                            placeholder="First Name..."
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formLastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="lastName"
                            placeholder="Last Name..."
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formMobile">
                        <Form.Label>Mobile</Form.Label>
                        <Form.Control
                            type="text"
                            name="mobile"
                            placeholder="Enter mobile number"
                            value={formData.mobile}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder="Enter email..."
                            value={formData.email}
                            onChange={handleChange}
                            required
                            autoComplete="off"
                        />
                    </Form.Group>
                    {!riderToEdit && (
                        <Form.Group controlId="formPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                placeholder="*********"
                                value={formData.password}
                                onChange={handleChange}
                                required
                                autoComplete="new-password"
                            />
                        </Form.Group>
                    )}
                    <Form.Group controlId="formCarrierType">
                        <Form.Label>Select Carrier Type</Form.Label>
                        <Form.Control
                            as="select"
                            name="carrierType"
                            value={formData.carrierType}
                            onChange={handleChange}
                            required
                        >
                            <option value="Rider">Rider</option>
                            <option value="Driver">Driver</option>
                            <option value="Cyclist">Cyclist</option>
                            <option value="Walk-in">Walk-in</option>
                        </Form.Control>
                    </Form.Group>
                    <Button variant="secondary" onClick={handleReset} style={{ marginTop: '10px' }}>
                        Discard
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        style={{
                            float: 'right',
                            width: '100px',
                            backgroundColor: '#FF8C00',
                            marginTop: '10px',
                        }}
                    >
                        {riderToEdit ? 'Update' : 'Add'}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default RetailerRiderForm;
