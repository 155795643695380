import React from "react";
import { Container, Popover, OverlayTrigger, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGooglePlay, faWhatsapp } from "@fortawesome/free-brands-svg-icons"; // Correct import for WhatsApp
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

function AdminFooter() {
  const today = new Date();
  const playStoreLink = "https://play.google.com/store/apps/details?id=com.suku.rider&pcampaignid=web_share";

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(playStoreLink)
      .then(() => toast.success("Link copied to clipboard!"))
      .catch((err) => console.error('Failed to copy link: ', err));
  };

  const handleWhatsAppShare = () => {
    const text = `Download the Lusuku Rider app from the Play Store: ${playStoreLink}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(text)}`;
    window.open(whatsappUrl, '_blank');
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <Button variant="link" style={{ color: "#13678A" }} onClick={handleCopyToClipboard}>
          <FontAwesomeIcon icon={faClipboard} /> Copy Link
        </Button>
        <Button variant="link" style={{ color: "#25D366" }} onClick={handleWhatsAppShare}>
          <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
        </Button>
      </Popover.Body>
    </Popover>
  );

  return (
    <footer className="footer">
      <Container fluid className="pl-4 ml-2">
        <nav>
          <ul className="footer-menu">
            <li>
              <a
                style={{ textDecoration: "none", color: "#289788" }}
                target="_blank"
                href="https://cs.lusuku.shop/"
                rel="noopener noreferrer"
              >
                Call Centre
              </a>
            </li>
            <li>
              <Link
                to="/vendorRegistration"
                style={{ textDecoration: "none", color: "#ff9999" }}
              >
                Vendor Registration
              </Link>
            </li>
            <li>
              <a
                style={{ textDecoration: "none", color: "#3b5998" }}
                target="_blank"
                href="https://www.facebook.com/lusuku.ug"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} /> Facebook
              </a>
            </li>
            <li>
              <OverlayTrigger trigger="click" placement="top" overlay={popover} rootClose>
                <a
                  style={{ textDecoration: "none", color: "#34A853", cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faGooglePlay} /> Rider App
                </a>
              </OverlayTrigger>
            </li>
          </ul>
          <p className="copyright text-center">
            <a
              style={{ textDecoration: "none" }}
              target="_blank"
              href="https://mmict.it/"
              rel="noopener noreferrer"
            >
              A product of MM-iCT
            </a>
            , {today.getFullYear()} ©
          </p>
        </nav>
      </Container>
    </footer>
  );
}

export default AdminFooter;
