import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import axios from "axios";

import {
  createCategory,
  getActiveCategories,
  updateCategory,
} from "../../actions/categoryActions";
import ImageUploadStyledAlert from "views/Components/ImageUploadStyledAlert";

toast.configure();

const CategoryForm = () => {
  const [postData, setPostData] = useState({
    category: "",
    category_pic: null,
  });

  const [image, setImage] = useState("");

  const [postListings, setPostListings] = useState({
    listingsAssoc: [],
  });

  const categories = useSelector((state) => state.categories);
  const { category: categoryToEdit, loading } = categories;

  const post_listings = postListings?.listingsAssoc;
  
  // Alert
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);




  const dispatch = useDispatch();
  const history = useHistory();

  const clear = () => {
    setPostData({ category: "", category_pic: null }),
    setIsUploading(false);
    setUploadProgress(0);
  };

  useEffect(() => {
    if (categoryToEdit?._id) {
      setPostData({
        category: categoryToEdit.category,
      });
      setPostListings({
        listingsAssoc: categoryToEdit.post_listings,
      });
    } else {
      setPostListings({
        listingsAssoc: [],
      });
    }
  }, [categoryToEdit]);


  const fetchImageCategoryToUpload = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      setIsUploading(true);
      const data = new FormData();
      data.append("file", image);
      data.append("upload_preset", "lusuku-clou");
      data.append("cloud_name", "MM-iCT");
      // Specify folder
      data.append("folder", "category_thumbnails");

      // percentage count
      const onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
      };

      const abortController = new AbortController();
      try {
        const res = await axios.post(
          process.env.REACT_APP_CLOUDINARY_URL,
          data,
          {
            onUploadProgress,
            signal: abortController.signal,
          }
        );

        const fileData = await res.data;
        setImage(fileData.secure_url);
        setIsUploading(false);
        setUploadDone(true);

        resolve(abortController);
      } catch (err) {
        reject(err);
      }
    });
  }, [image]);

  useEffect(() => {
    let active = true;
    let abortControllerPromise;

    if (image?.name) {
      if (active) {
        abortControllerPromise = fetchImageCategoryToUpload();
      }
    }
    return () => {
      active = false;
      if (abortControllerPromise) {
        abortControllerPromise.then((abortController) => {
          abortController.abort();
        });
      }
    };
  }, [image?.name, fetchImageCategoryToUpload]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (image?.name && !uploadDone) {
      await fetchImageCategoryToUpload();
      // const abortController = await fetchImageCategoryToUpload();
      // if (abortController && !abortController.signal.aborted) {
      //   abortController.abort();
      // }
    }
    const categoryData = {
      ...postData,
      category_pic: image, // Use the image URL from the `image` state
      post_listings: post_listings,
    };

    if (!categoryToEdit?._id) {
      dispatch(createCategory(categoryData));
      dispatch(getActiveCategories());
      clear();
      history.push("/admin/category-table");
    } else {
        dispatch(updateCategory(categoryToEdit?._id, categoryData));
        dispatch(getActiveCategories()),
        clear(),
        history.push("/admin/category-table");
    }
  };

    // Check if file is JPEG
    const isJPEG = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const buffer = e.target.result;
          const view = new DataView(buffer);
          const isJPEG =
            view.byteLength >= 2 && view.getUint16(0, false) === 0xffd8; // Check if the file starts with a JPEG SOI marker (0xFFD8)
          resolve(isJPEG);
        };
        reader.onerror = (e) => {
          reject(e);
        };
        reader.readAsArrayBuffer(file);
      });
    };
  
    // Check if file is a PNG
    const isPNG = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const buffer = e.target.result;
          const view = new DataView(buffer);
          const pngSignature = [137, 80, 78, 71, 13, 10, 26, 10];
          const isPNG = pngSignature.every(
            (byte, index) => view.getUint8(index) === byte
          );
          resolve(isPNG);
        };
        reader.onerror = (e) => {
          reject(e);
        };
        reader.readAsArrayBuffer(file);
      });
    };

  // Category Image Upload
  const handleCategoryImageFileChange = async (event) => {
    const file = event.target.files[0];
    const isFileJPEG = await isJPEG(file);
    const isFilePNG = await isPNG(file);
    const fileSizeInMB = file.size / (1024 * 1024);

    if (!isFileJPEG && !isFilePNG) {
      setSnackbarSeverity("error");
      setSnackbarMessage(
        "Invalid file type. Only JPG, JPEG, and PNG files are allowed."
      );
      setOpenSnackbar(true);
      setUploadedFileName(""); // Clear the uploaded file name
      return;
    }

    if (fileSizeInMB > 1) {
      setSnackbarSeverity("error");
      setSnackbarMessage(
        "File size exceeds 1 MB. Please choose a smaller image."
      );
      setOpenSnackbar(true);
      setUploadedFileName(""); // Clear the uploaded file name
      return;
    }

     // Continue with the upload process
     setImage(file);
     setUploadedFileName(file.name); // Set the uploaded file name
   };

   const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      {loading ? (
        <Spinner
          animation="grow"
          variant="danger"
          style={{
            marginTop: "70px",
            top: "50%",
            left: "50%",
            position: "fixed",
          }}
        />
      ) : (
        <>
        <ImageUploadStyledAlert
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          message={snackbarMessage}
        />
        <Col>
          <Form id="deliveryZone" autoComplete="off" onSubmit={handleSubmit}>
            <Card>
              <Card.Title className="pt-2 p-2" as="h4">
                Product Category Form
              </Card.Title>
              <Card.Body className="pt-0">
                <Col md={6}>
                  <Form.Group>
                    <Card.Body>
                      <label>Category Name</label>
                      <Form.Control
                        name="category"
                        type="text"
                        maxLength="15"
                        placeholder="Create Category.."
                        value={postData.category}
                        onChange={(e) =>
                          setPostData({
                            ...postData,
                            category: e.target.value,
                          })
                        }
                      >

                      </Form.Control>
                    </Card.Body>
                    <Card.Body className="pt-0">
                    <div style={{ width: "97%", margin: "5px 0" }}>
                    <div>
                      <label
                        htmlFor="category-image-upload"
                        style={{
                          display: "inline-block",
                          padding: "6px 12px",
                          cursor: "pointer",
                          backgroundColor: "#f8f9fa",
                          border: "1px solid #ced4da",
                          borderRadius: "4px",
                          fontWeight: 400,
                          color: "#495057",
                          fontSize: "1rem",
                          lineHeight: "1.5",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#e9ecef";
                          e.target.style.borderColor = "#adb5bd";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "#f8f9fa";
                          e.target.style.borderColor = "#ced4da";
                        }}
                      >
                        {uploadedFileName || "Upload category image..."}
                      </label>
                        <input
                          id="category-image-upload"
                          name="image"
                          type="file"
                          accept=".jpg, .png, .jpeg"
                          // onChange={(e) =>
                          //   setPostData({ ...postData, image: e.target.files[0] })
                          // }
                          onChange={handleCategoryImageFileChange}
                          style={{ display: "none" }}
                        />
                      </div>
                      </div>
                      <div>
                      {uploadedFileName && (
                        <p
                          style={{
                            fontSize: "13px",
                            fontStyle: "italic",
                          }}
                        >
                          File Name: {uploadedFileName}
                        </p>
                      )}
                      {isUploading && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "13px",
                            fontStyle: "italic",
                          }}
                        >
                          Uploading... {uploadProgress}%
                        </p>
                      )}
                      {uploadDone && !isUploading ? (
                        <p
                          style={{
                            color: "green",
                            fontSize: "13px",
                            fontStyle: "italic",
                          }}
                        >
                          Category Image Upload Complete!
                        </p>
                      ) : null}
                    </div>
                    </Card.Body>
                  </Form.Group>
                </Col>
              </Card.Body>

              <Card.Body>
                <Card.Footer className="text-right">
                  <Button
                    className="btn-fill pull-right"
                    variant="danger"
                    onClick={clear}
                    style={{ marginRight: "5px" }}
                  >
                    Clear
                  </Button>
                  <Button
                    className="btn-fill pull-right"
                    variant="info"
                    type="submit"
                    disabled={postData.category === "" ? true : false}
                    // disabled={postData.category.length < 3}
                  >
                    Submit
                  </Button>
                  <div className="clearfix"></div>
                </Card.Footer>
              </Card.Body>
            </Card>
          </Form>
        </Col>
        </>
      )}
    </>
  );
};

export default CategoryForm;
