import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Paper, Typography } from "@material-ui/core";

import useStyles from "./listingsStyles.js";
import Paginate from "./Pagination.jsx";
import { useHistory, useLocation } from "react-router";
import {
  getListings,
  getListingsBySearch,
} from "../../../actions/listingsActions.js";
import Listing from "./Listing/Listing.js";
import { useUpdateListing } from "./useUpdateListing.js";
import { getUserFromLocalStorage } from "utilities/user.js";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ShopCard = () => {
  const { listings, isLoading, fetched, lastUpdateTimestamp } = useSelector(
    (state) => state.listings
  );

  useEffect(() => {
    // Re-fetch listings when an update occurs
    dispatch(getListings(userId, page));
  }, [lastUpdateTimestamp]);

  // Hook used for checking if updated listings are fetched
  const _ = useUpdateListing();

  const user = getUserFromLocalStorage();
  const userId = user?.result?._id;
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  const page = query.get("page") || 1;
  const searchQuery = query.get("searchQuery");
  const [search, setSearch] = useState("");

  if (user?.result?.user_role === "CLIENT") {
    return (
      <>
        <Container fluid>
          <Row>
            <Col md={6}>
              <Typography elevation={6} variant="h5" color="error">
                <i className="fas fa-users"></i>&nbsp;&nbsp; Restricted View For
                Admins Only!
              </Typography>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  const searchListings = () => {
    if (search.trim()) {
      dispatch(getListingsBySearch({ search, user_id: userId }));
      history.push(`/admin/shopCards?searchQuery=${search || "none"}`);
    } else {
      history.push("/admin/shopCards");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchListings();
    }
  };

  const backToListings = () => {
    history.push("/admin/shopCards");
    setSearch("");
  };

  const handleInputClear = (e) => {
    if (e.target.value === "") {
      history.push("/admin/shopCards"); // Navigate to the unfiltered listings page
      dispatch(getListingsBySearch({ search: "", user_id: userId })); // Fetch unfiltered listings
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner
          animation="grow"
          size="lg"
          variant="primary"
          style={{
            marginTop: "70px",
            top: "50%",
            left: "50%",
            position: "fixed",
          }}
        />
      ) : (
        <Container fluid>
          <Card>
            <Card.Body>
              <Container
                fluid
                // align to the extreme left
                className="pt-3 mb-3"
              >
                <Row>
                  <Col md={6}>
                    <Typography variant="h6" color="inherit">
                      <i
                        className="fas fa-clock"
                        style={{ color: "#00bcd4" }}
                      ></i>
                      &nbsp;
                      {
                        listings?.filter(
                          (listing) => listing.verifyListing === "PENDING"
                        ).length
                      }{" "}
                      Product(s) Pending Verification.
                    </Typography>
                  </Col>
                </Row>
              </Container>
              <div className={classes.search}>
                <div className="input-group-prepend">
                  <input
                    type="search"
                    id="search"
                    className={classes.searchInput}
                    placeholder="Search Products..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={handleKeyPress}
                    onInput={handleInputClear}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-behance"
                  onClick={searchListings}
                  style={{
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
                  }}
                >
                  <i
                    className="nc-icon nc-zoom-split"
                    style={{ color: "white" }}
                  ></i>
                </button>
              </div>
              <Grid
                className={classes.container}
                container
                alignItems="stretch"
                spacing={3}
              >
                {fetched && listings.length === 0 ? (
                  <Container fluid className="pt-3 mb-5">
                    <Row>
                      <Col md={6}>
                        <Typography variant="h6" color="inherit">
                          <i className="fas fa-users"></i>&nbsp;&nbsp; No
                          Product at the moment ~ Please check back later!
                        </Typography>
                        <Button
                          variant="contained"
                          color="inherit"
                          // onClick={() => history.push("/admin/shopCards")}
                          onClick={backToListings}
                          className="mt-2"
                          style={{
                            size: "small",
                            backgroundColor: "#00bcd4",
                            color: "white",
                            borderRadius: "50px",
                            border: "none",
                            textTransform: "none",
                          }}
                        >
                          Back to Shop
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                ) : (
                  listings?.map((listing) => (
                    <Grid key={listing?._id} item xs={12} sm={12} md={6} lg={3}>
                      <Listing
                        listing={listing}
                        getListings={() =>
                          dispatch(getListings(user?.result?._id, page))
                        }
                      />
                    </Grid>
                  ))
                )}
              </Grid>
            </Card.Body>
          </Card>
        </Container>
      )}
      <Grid item xs={12} sm={6} md={3}>
        {!searchQuery && (
          <Paper
            style={{
              position: "absolute",
              top: "85%",
              left: "80%",
              padding: "16px",
              borderRadius: "20px",
              align: "right",
              verticalAlign: "top",
            }}
            elevation={6}
          >
            <Paginate page={page} user={user} />
          </Paper>
        )}
      </Grid>
    </>
  );
};

export default ShopCard;
