import {
  CREATE_AD,
  DELETE_AD,
  END_LOADING_AD,
  FETCH_ADS,
  START_LOADING_AD,
  UPDATE_AD,
} from "../constants/advertConstants";

const initialState = {
  ads: [],
  loading: false,
};

export const advertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADS:
      return { ...state, ads: action.payload };
    case CREATE_AD:
      return { ...state, ads: [...state.ads, action.payload] };
    case UPDATE_AD:
      return {
        ...state,
        ads: state.ads.map((ad) =>
          ad.id === action.payload.id ? action.payload : ad
        ),
      };
    case DELETE_AD:
      return {
        ...state,
        ads: state.ads.filter((ad) => ad.id !== action.payload),
      };
    case START_LOADING_AD:
      return { ...state, loading: true };
    case END_LOADING_AD:
      return { ...state, loading: false };
    default:
      return state;
  }
};
