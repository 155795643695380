import { END_LOADING } from "constants/webOrderConstants.js";
import {
  VENDOR_REGISTER_REQUEST,
  VENDOR_REGISTER_SUCCESS,
  VENDOR_REGISTER_FAIL,
  GET_SELLERS,
  GET_SELLERS_FAIL,
  GET_SELLER,
  GET_SELLER_FAIL,
  DELETE_SELLER,
  UPDATE_SELLER_REQUEST,
  UPDATE_SELLER_SUCCESS,
  UPDATE_SELLER_FAIL,
} from "../constants/actionTypes.js";
import { START_LOADING } from "constants/webOrderConstants.js";

// Vendor Registration Reducer
const initialState = {
  vendorRegistrationData: null,
  loading: false,
};
export const vendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case VENDOR_REGISTER_REQUEST:
      return { loading: true, ...state };
    case VENDOR_REGISTER_SUCCESS:
      return { ...state, vendorRegistrationData: action.payload };
    case VENDOR_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Seller Reducer
const sellerInitialState = {
  sellers: [],
  seller: {},
  loading: true,
  error: null,
};
export const sellerReducer = (state = sellerInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_SELLERS:
      return {
        ...state,
        sellers: payload.sellers,
        loading: false,
      };
    case GET_SELLERS_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_SELLER:
      return {
        ...state,
        seller: payload.seller,
        loading: false,
      };
    case GET_SELLER_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case UPDATE_SELLER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SELLER_SUCCESS:
      return {
        ...state,
        seller: payload,
        sellers: state.sellers.map((seller) =>
          seller._id === payload._id ? payload : seller
        ),
        loading: false,
        error: null,
      };
    case UPDATE_SELLER_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case DELETE_SELLER:
      return {
        ...state,
        sellers: state.sellers.filter((seller) => seller._id !== payload),
        loading: false,
      };

    case END_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
