import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getTokenFromLocalStorage, isTokenExpired } from '../utilities/tokenUtils';
import { getUserFromLocalStorage } from '../utilities/user.js';
import { toast } from 'react-toastify';

const useAuth = (isProtected = false) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const validateAuth = async () => {
            const token = getTokenFromLocalStorage();
            if (!token || isTokenExpired(token)) {
                setIsAuthenticated(false);
                setUser(null);
                if (isProtected) {
                    // toast.warn("Your session has expired. Please log in again.");
                    toast.warn("Session Ended!");
                    history.push('/auth/login-page');
                }
            } else {
                const userData = getUserFromLocalStorage();
                if (userData) {
                    setUser(userData);
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                    if (isProtected) {
                        toast.error("Session Expired!");
                        history.push('/auth/login-page');
                    }
                }
            }
            setLoading(false);
        };

        validateAuth();
    }, [isProtected, history]);

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('profile');
        setUser(null);
        setIsAuthenticated(false);
        history.push('/auth/login-page');
    };

    return { user, loading, isAuthenticated, logout };
};

export default useAuth;