import axios from "axios";
import { toast } from "react-toastify";
import { START_LOADING, END_LOADING } from "../constants/webOrderConstants";
import {
  CREATE_CATEGORY,
  FETCH_CATEGORIES,
  FETCH_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_ACTIVATE_CATEGORY,
  FETCH_INACTIVE_CATEGORIES,
  FETCH_ACTIVE_CATEGORY,
  // DELETE_CATEGORY,
} from "../constants/categoryConstants";
import * as api from "../api/index.js";

export const createCategory = (post) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    // const { data } = await axios.post("/category/create", post);
    const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/category/create`, post);

    dispatch({ type: CREATE_CATEGORY, payload: data });

    toast.success(data.message)

    dispatch({ type: END_LOADING });
    // callback();
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};

export const getActiveCategories = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchActiveCategories();
    dispatch({
      type: FETCH_CATEGORIES,
      payload: data,
    });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

//Get Single Category
export const getCategory = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/category/get-category/${id}`);
    dispatch({ type: FETCH_CATEGORY, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};

export const getActiveCategory = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/category/active/${id}`);

    dispatch({
      type: FETCH_ACTIVE_CATEGORY,
      payload: { category: data },
    });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getInactiveCategories = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchDeactivatedCategories();
    // const { data } = await axios.get("/category/deactivated-categories");
    dispatch({
      type: FETCH_INACTIVE_CATEGORIES,
      payload: data,
    });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

// Not in use currently
export const updateCategory = (id, updatedCategory) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await axios.patch(
      `${process.env.REACT_APP_SERVER_URL}/category/update/${id}`,
      updatedCategory
    );
    toast.success(data.message);
    
    dispatch({ type: UPDATE_CATEGORY, payload: data });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};
//Not deleting categories at the moment. We update it instead
// export const deleteCategory = (id) => async (dispatch) => {
//   try {
//     const { data } = await axios.delete(`/category/delete/${id}`);
//     console.log(data, "after deletion>>>")

//     dispatch({ type: DELETE_CATEGORY, payload: id, data });
//     toast.success("Category Deleted Successfully!");
//   } catch (error) {
//     console.log(error.response.statusText);
//     toast.error(error.response.statusText);
//   }
// };

export const deactivateCategory = (id) => async (dispatch) => {
  try {
    const { data } = await axios.put(`${process.env.REACT_APP_SERVER_URL}/category/deactivate/${id}`);

    dispatch({ type: UPDATE_CATEGORY, payload: id, data });
    toast.success("Category Deactivated Successfully!");
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};

export const activateCategory = (id) => async (dispatch) => {
  try {
    const { data } = await axios.put(`${process.env.REACT_APP_SERVER_URL}/category/activate/${id}`);

    dispatch({ type: UPDATE_ACTIVATE_CATEGORY, payload: id, data });
    toast.success("Category Activated Successfully!");
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};
