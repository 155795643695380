// retailerWholesalerVendorOrdersActions.js
import * as api from "../api";
import {
  RETAILER_WHOLESALER_VENDOR_ORDERS_REQUEST,
  RETAILER_WHOLESALER_VENDOR_ORDERS_SUCCESS,
  RETAILER_WHOLESALER_VENDOR_ORDERS_FAIL,
  RETAILER_WHOLESALER_ORDER_DETAILS_REQUEST,
  RETAILER_WHOLESALER_ORDER_DETAILS_SUCCESS,
  RETAILER_WHOLESALER_ORDER_DETAILS_FAIL,
  UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_REQUEST,
  UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_SUCCESS,
  UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_FAIL,
} from "../constants/webOrderConstants";

export const fetchRetailerWholesalerVendorOrders =
  (vendorId, page = 1, limit = 10) =>
  async (dispatch) => {
    try {
      dispatch({ type: RETAILER_WHOLESALER_VENDOR_ORDERS_REQUEST });

      const { data } = await api.fetchRetailerWholesalerVendorOrdersApi(
        vendorId,
        page,
        limit
      );

      dispatch({
        type: RETAILER_WHOLESALER_VENDOR_ORDERS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: RETAILER_WHOLESALER_VENDOR_ORDERS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getRetailerWholesalerOrderDetails =
  (orderId, vendorId) => async (dispatch) => {
    try {
      dispatch({ type: RETAILER_WHOLESALER_ORDER_DETAILS_REQUEST });

      const { data } = await api.fetchRetailerWholesalerOrderDetailsApi(
        orderId,
        vendorId
      );

      dispatch({
        type: RETAILER_WHOLESALER_ORDER_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: RETAILER_WHOLESALER_ORDER_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateRetailerWholesalerOrderStatus =
  (orderId, status) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_REQUEST });

      const { data } = await api.updateRetailerWholesalerOrderStatusApi(
        orderId,
        status
      );
      dispatch({
        type: UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
