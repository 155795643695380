import {
    FETCH_RETAILER_WHOLESALER_PROFILE,
    UPDATE_RETAILER_WHOLESALER_PROFILE,
    START_LOADING,
    END_LOADING,
    FETCH_RETAILER_WHOLESALER_PROFILE_ERROR,
    FETCH_RETAILERS_WHOLESALERS,
    DELETE_RETAILER_WHOLESALER
} from '../constants/actionTypes';

const initialState = {
    profile: null,
    retailersWholesalers: [],
    loading: false,
    error: null,
};

export const retailerWholesalerProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, loading: true, error: null };
        case END_LOADING:
            return { ...state, loading: false };
        case FETCH_RETAILER_WHOLESALER_PROFILE:
            return { ...state, profile: action.payload };
        case FETCH_RETAILERS_WHOLESALERS:
            return { ...state, retailersWholesalers: action.payload };
        case UPDATE_RETAILER_WHOLESALER_PROFILE:
            return {
                ...state,
                profile: action.payload,
                retailersWholesalers: state.retailersWholesalers.map((item) =>
                    item._id === action.payload._id ? action.payload : item
                ),
            };
        case DELETE_RETAILER_WHOLESALER:
            return {
                ...state,
                retailersWholesalers: state.retailersWholesalers.filter((item) => item._id !== action.payload),
            };
        case FETCH_RETAILER_WHOLESALER_PROFILE_ERROR:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};
