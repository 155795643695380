import { toast } from 'react-toastify';
import * as api from '../api';
import {
    START_LOADING,
    END_LOADING,
    CREATE_RW_OPTION,
    GET_RW_OPTIONS,
    UPDATE_RW_OPTION,
    DELETE_RW_OPTION,
    CREATE_RW_OPTION_GROUP,
    GET_RW_OPTION_GROUPS,
    UPDATE_RW_OPTION_GROUP,
    DELETE_RW_OPTION_GROUP
} from '../constants/actionTypes';

// Create Retailer/Wholesaler Option
export const createRetailerWholesalerOption = (optionData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiCreateRetailerWholesalerOption(optionData);
        dispatch({ type: CREATE_RW_OPTION, payload: data.data });
        dispatch({ type: END_LOADING });
        toast.success(data.message);
    } catch (error) {
        console.error(error.response?.data?.message || error.message);
        toast.error(error.response?.data?.message || error.message);
        dispatch({ type: END_LOADING });
    }
};

// Fetch Retailer/Wholesaler Options
export const fetchRetailerWholesalerOptions = (vendorId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiFetchRetailerWholesalerOptions(vendorId);


        dispatch({ type: GET_RW_OPTIONS, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log(error, "fetchRetailerWholesalerOptions error>>")
        console.error("Error fetching options:", error);
        toast.error("Failed to fetch options: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Update Retailer/Wholesaler Option
export const updateRetailerWholesalerOption = (optionId, updatedOptionData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiUpdateRetailerWholesalerOption(optionId, updatedOptionData);
        dispatch({ type: UPDATE_RW_OPTION, payload: data.data });
        dispatch({ type: END_LOADING });
        toast.success("Option updated successfully!");
    } catch (error) {
        console.error("Error updating option:", error);
        toast.error("Failed to update option: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Delete Retailer/Wholesaler Option
export const deleteRetailerWholesalerOption = (optionId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        await api.apiDeleteRetailerWholesalerOption(optionId);
        dispatch({ type: DELETE_RW_OPTION, payload: optionId });
        dispatch({ type: END_LOADING });
        toast.success("Option deleted successfully!");
    } catch (error) {
        console.error("Error deleting option:", error);
        toast.error("Failed to delete option: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Create Retailer/Wholesaler Option Group
export const createRetailerWholesalerOptionGroup = (optionGroupData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiCreateRetailerWholesalerOptionGroup(optionGroupData);
        dispatch({ type: CREATE_RW_OPTION_GROUP, payload: data.data });
        dispatch({ type: END_LOADING });
        toast.success("Option group created successfully!");
    } catch (error) {
        console.error(error.response?.data?.message || error.message);
        toast.error(error.response?.data?.message || error.message);
        dispatch({ type: END_LOADING });
    }
};

// Fetch Retailer/Wholesaler Option Groups
export const fetchRetailerWholesalerOptionGroups = (ownerId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiFetchRetailerWholesalerOptionGroups(ownerId);
        dispatch({ type: GET_RW_OPTION_GROUPS, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.error("Error fetching option groups:", error);
        toast.error("Failed to fetch option groups: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Update Retailer/Wholesaler Option Group
export const updateRetailerWholesalerOptionGroup = (optionGroupId, updatedOptionGroupData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiUpdateRetailerWholesalerOptionGroup(optionGroupId, updatedOptionGroupData);
        dispatch({ type: UPDATE_RW_OPTION_GROUP, payload: data.data });
        dispatch({ type: END_LOADING });
        toast.success("Option group updated successfully!");
    } catch (error) {
        console.error("Error updating option group:", error);
        toast.error("Failed to update option group: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Delete Retailer/Wholesaler Option Group
export const deleteRetailerWholesalerOptionGroup = (optionGroupId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        await api.apiDeleteRetailerWholesalerOptionGroup(optionGroupId);
        dispatch({ type: DELETE_RW_OPTION_GROUP, payload: optionGroupId });
        dispatch({ type: END_LOADING });
        toast.success("Option group deleted successfully!");
    } catch (error) {
        console.error("Error deleting option group:", error);
        toast.error("Failed to delete option group: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};
