// actions/sectionActions.js
import { toast } from 'react-toastify';
import * as api from '../api';
import {
  START_LOADING,
  CREATE_SECTION,
  GET_SECTIONS,
  END_LOADING,
  UPDATE_SECTION,
  DELETE_SECTION_REQUEST,
  DELETE_SECTION_SUCCESS,
  DELETE_SECTION_FAILURE
} from '../constants/actionTypes.js';


// Create Section
export const createSection = (sectionData, userId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.apiCreateSection(sectionData, userId);
    dispatch({ type: CREATE_SECTION, payload: data.data });
    dispatch({ type: END_LOADING });
    toast.success(data.message);
  } catch (error) {
    console.log(error.response?.data?.message || error.message);
    toast.error(error.response?.data?.message || error.message);
    dispatch({ type: END_LOADING });
  }
};

// Action to fetch sections
export const fetchSections = (vendorId) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.apiFetchSections(vendorId);
    dispatch({
      type: GET_SECTIONS,
      payload: data,
    });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.error('Error fetching sections:', error);
    toast.error('Failed to fetch sections: ' + (error.response?.data?.message || error.message));
    dispatch({ type: END_LOADING });
  }
};

// Action to update a section
// Update Section
export const updateSection = (sectionId, updatedSectionData) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.apiUpdateSection(sectionId, updatedSectionData); // API call
    dispatch({ type: UPDATE_SECTION, payload: data });
    dispatch({ type: END_LOADING });
    toast.success('Section updated successfully!');
  } catch (error) {
    console.error('Error updating section:', error);
    toast.error('Failed to update section: ' + (error.response?.data?.message || error.message));
    dispatch({ type: END_LOADING });
  }
};

// Delete SSection Along with the products under it
export const deleteSectionRequest = () => ({
  type: DELETE_SECTION_REQUEST,
});

export const deleteSectionSuccess = (sectionId) => ({
  type: DELETE_SECTION_SUCCESS,
  payload: sectionId,
});

export const deleteSectionFailure = (error) => ({
  type: DELETE_SECTION_FAILURE,
  payload: error,
});

export const deleteSection = (sectionId) => async (dispatch) => {
  dispatch(deleteSectionRequest());
  try {
    await api.apiDeleteSection(sectionId);
    dispatch(deleteSectionSuccess(sectionId));
    dispatch(fetchSections()); // Optionally, refetch sections to update state
  } catch (error) {
    dispatch(deleteSectionFailure(error.message));
  }
};
