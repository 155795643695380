import * as api from '../api/index.js';
import { FETCH_SELLER_STATS, FETCH_SELLER_STATS_ERROR, FETCH_SELLER_STATS_SUCCESS } from '../constants/actionTypes';

export const fetchSellerOrderStats = (vendorId) => async (dispatch) => {
    dispatch({ type: FETCH_SELLER_STATS });

    try {
        const { data } = await api.apiFetchSellerStats(vendorId);
        dispatch({ type: FETCH_SELLER_STATS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: FETCH_SELLER_STATS_ERROR, payload: error.message });
    }
};
