import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form, Button, Card, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import CheckoutSteps from "views/Components/CheckoutSteps";
import DotLoader from "views/Components/DotLoader";
import { saveShippingAddress } from "../../actions/cartActions";
import FormContainer from "../../views/Components/FormContainer";
import CreateClientModal from "./CreateClientModal";
import { Divider, Paper } from "@material-ui/core";
import { getUsers } from "../../actions/auth";
import { useHistory } from "react-router-dom";

import usePlacesAutocomplete, {
  getDetails,
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import "@reach/combobox/styles.css";
import { useGoogleMaps } from "../../GoogleMapsContext";
import { toast } from "react-toastify";
import { getUserFromLocalStorage } from "utilities/user";

const ShippingScreen = () => {
  const { mapsApiLoaded } = useGoogleMaps();
  const { authorizedUsers, isLoading } = useSelector((state) => state.auth);

  const user = getUserFromLocalStorage();
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [apiRetry, setApiRetry] = useState(0);

  const dispatch = useDispatch();
  const history = useHistory();

  const [clientList, setClientList] = useState([]);

  const boxOptionRef = useRef();

  function useCustomPlacesAutocomplete(mapsApiLoaded, retry) {
    const options = {
      requestOptions: {
        componentRestrictions: { country: "ug" },
        cacheTimeout: 10 * 60 * 60 * 24,
        debounce: 100,
        location: { lat: () => 0.347596, lng: () => 32.58252 },
        radius: 200 * 1000,
        origin: { lat: 0.3148136, lng: 32.5788721 },
        strictBounds: true,
        types: ["establishment"],
      },
      debounce: 100,
    };

    const {
      value,
      ready,
      suggestions: { data } = { data: [] },
      setValue,
      clearSuggestions,
      status,
    } = usePlacesAutocomplete(options);

    useEffect(() => {
      if (mapsApiLoaded) {
        setValue("");
      }
    }, [mapsApiLoaded, retry]);

    useEffect(() => {
      if (status === "ERROR") {
        toast.error("Failed to load Google Maps API", status);
        console.error("Error fetching suggestions:", status);
      }
    }, [status]);

    return {
      value,
      ready,
      data,
      setValue,
      clearSuggestions,
    };
  }

  const { value, ready, data, setValue, clearSuggestions } =
    useCustomPlacesAutocomplete(mapsApiLoaded, apiRetry);

  useEffect(() => {
    dispatch(getUsers(user?.result?._id, user?.result?.user_role));
  }, [dispatch, user]);

  const [openClientModal, setOpenClientModal] = useState(false);

  const [customerNumber, setCustomerNumber] = useState("");

  const [selectedClient, setSelectedClient] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [latLng, setLatLng] = useState({ lat: 0.347596, lng: 32.58252 });
  const [locationName, setLocationName] = useState([]);

  //Fetch all clients
  useEffect(() => {
    const mappedData = authorizedUsers?.map((client) => ({
      value: client?.user?._id,
      label: client?.user?.name,
      name: client?.user?.name,
    }));
    setClientList(mappedData);
  }, [authorizedUsers]);

  //Get Delivery details from authorized users payload
  useEffect(() => {
    authorizedUsers?.map((obj) => {
      if (obj?.user?._id === selectedClient?.value) {
        if (obj?.delivery_details !== null) {
          setCustomerNumber(
            obj.delivery_details.shippingAddress?.customerNumber
          );
        } else {
          setCustomerNumber("");
        }
      }
    });
  }, [selectedClient]);

  useEffect(() => {
    let isCancelled = false;

    if (locationId) {
      getDetails({ placeId: locationId })
        .then((res) => {
          if (!isCancelled) {
            setLocationName({
              name: res.name,
              vicinity: res.vicinity,
            });
          }
        })
        .catch((err) => {
          if (!isCancelled) {
            console.log(err, " location details error");
          }
        });
    }
    return () => {
      isCancelled = true;
      setLocationName("");
    };
  }, [locationId]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (selectedClient && selectedClient.value) {
      dispatch(
        saveShippingAddress({
          customerNumber,
          client_id: selectedClient?.value,
          locationName, // locationName: {name: res.name, vicinity: res.vicinity}
          latLng, // latLng: { lat: 0.347596, lng: 32.58252 }
          locationId, // Delivery address locationId
        })
      );
      history.push("/admin/payment");
    } else {
      toast.warning(
        "Please select a client before proceeding, Loading clients.."
      );
    }
  };

  const handleClientChange = (value) => {
    setSelectedClient(value);
  };

  const handleSelect = async (value) => {
    setValue(value, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address: value });
      const { lat, lng } = await getLatLng(results[0]);
      if (lat && lng) {
        //  panTo({ lat, lng })
        setLocationId(results[0].place_id);
        // console.log(boxOptionRef.current, "boxOptionRef");
        // panTo({ lat, lng });
        setLatLng({ lat, lng });
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleInput = (e) => {
    if (mapsApiLoaded) {
      setValue(e.target.value);
      setLoadingSuggestions(true);
    }
  };

  const renderSuggestions = () => {
    return data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      return (
        <ComboboxOption
          key={place_id}
          value={main_text + " " + secondary_text}
        />
      );
    });
  };

  // Hook for loading suggestions
  useEffect(() => {
    if (data && data.length > 0) {
      setLoadingSuggestions(false);
    }
  }, [data]);
  return (
    <>
      {isLoading ? (
        <DotLoader />
      ) : (
        <FormContainer>
          <Paper className="mx-auto mt-3 mb-3" elevation={4}>
            <Card.Body>
              <CheckoutSteps step1 step2 />
              <h1>Shipping</h1>
              <Form onSubmit={submitHandler} autoComplete="off">
                <Form.Group controlId="userCreate" className="pt-2">
                  <Form.Label
                    style={{
                      textTransform: "uppercase",
                      color: "#1D438A",
                      fontSize: "0.85rem",
                    }}
                  >
                    Client
                  </Form.Label>

                  <Select
                    className="basic-multi-select"
                    classNamePrefix="react-select"
                    onChange={handleClientChange}
                    options={clientList}
                    placeholder="Select Client"
                    isClearable
                  />

                  <Button
                    variant="outlined"
                    onClick={() => setOpenClientModal(true)}
                  >
                    Create New Client
                  </Button>
                  <CreateClientModal
                    refetchUsers={() =>
                      dispatch(
                        getUsers(user?.result?._id, user?.result?.user_role)
                      )
                    }
                    open={openClientModal}
                    setOpen={setOpenClientModal}
                    onSuccess={() => setOpenClientModal(false)} // After successful creation of client, close modal
                    className="mb-3"
                  />
                </Form.Group>

                <Form.Group controlId="zone" className="mt-3">
                  <Form.Label
                    style={{
                      textTransform: "uppercase",
                      color: "#1D438A",
                      fontSize: "0.85rem",
                    }}
                  >
                    Select Delivery Zone
                  </Form.Label>
                  <div>
                    {mapsApiLoaded ? (
                      <Combobox ref={boxOptionRef} onSelect={handleSelect}>
                        <div style={{ position: "relative" }}>
                          <ComboboxInput
                            value={value}
                            onChange={handleInput}
                            disabled={!ready}
                            placeholder="Enter Delivery Address..."
                            // className={styles.comboboxInput}
                            style={{
                              width: "100%",
                              padding: "10px",
                              paddingRight: "35px",
                              border: "1px solid #f58c60",
                              borderRadius: "5px",
                              zIndex: "3",
                              boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.5)",
                              outline: "none",
                            }}
                          />
                          {value && (
                            <IconButton
                              size="small"
                              style={{
                                position: "absolute",
                                right: "5px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                zIndex: "4",
                                color: "#f58c60",
                              }}
                              onClick={() => {
                                setValue("");
                                clearSuggestions();
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </div>
                        {loadingSuggestions && (
                          <div
                            style={{ textAlign: "center", marginTop: "0.5rem" }}
                          >
                            <Spinner animation="border" size="sm" />
                          </div>
                        )}
                        {!ready && (
                          <div
                            style={{
                              color: "red",
                              fontSize: "0.8rem",
                              marginTop: "0.5rem",
                            }}
                          >
                            {mapsApiLoaded ? (
                              <>
                                Loading address suggestions failed. Please try
                                again later.
                                <Button
                                  variant="link"
                                  onClick={() =>
                                    setApiRetry((prev) => prev + 1)
                                  }
                                >
                                  Retry
                                </Button>
                              </>
                            ) : (
                              "Google Maps API not loaded."
                            )}
                          </div>
                        )}
                        <ComboboxPopover
                          style={{
                            backgroundColor: "white",
                            margin: "0",
                            padding: "0",
                            fontSize: "12px",
                            border: "1px solid #F58C60",
                            fontStyle: "italic",
                            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.8)",
                            // padding: "10px",
                            borderBottomRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                          }}
                        >
                          <ComboboxList
                            style={{
                              marginLeft: "10px",
                              padding: "0",
                              // listStyle: "none",
                              overflow: "auto",
                              maxHeight: "200px",
                              lineHeight: "2",
                            }}
                          >
                            {renderSuggestions()}
                          </ComboboxList>
                        </ComboboxPopover>
                      </Combobox>
                    ) : (
                      // <div
                      //   style={{
                      //     textAlign: "center",
                      //     marginTop: "0.5rem",
                      //     fontSize: "0.8rem",
                      //   }}
                      // >
                      //   Loading Google Maps API...
                      // </div>
                      <Spinner
                        style={{
                          marginTop: "60px",
                          top: "50%",
                          left: "50%",
                          position: "fixed",
                        }}
                        animation="grow"
                        size="sm"
                        variant="danger"
                      />
                    )}
                  </div>
                </Form.Group>

                <Divider style={{ margin: "10px 0" }} />

                {/* Contact Number */}
                <Form.Group controlId="customerNumber" className="pt-2">
                  <Form.Label
                    style={{
                      textTransform: "uppercase",
                      color: "#1D438A",
                      fontSize: "0.85rem",
                    }}
                  >
                    Customer's Number
                  </Form.Label>
                  <Form.Control
                    // type="number"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    placeholder="070077...(Enter numeric values only)"
                    value={customerNumber}
                    required
                    onChange={(e) => setCustomerNumber(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                {/* {!loading && ( */}
                <Button
                  type="submit"
                  className="bg-success mt-2"
                  // className="mt-3"
                  // Disable button if no client and no village selected
                  disabled={
                    !selectedClient ||
                    // !selectedVillage?.value?.length > 0 ||
                    // !address ||
                    !customerNumber
                  }
                >
                  Continue to Checkout!
                </Button>
                {/* )} */}
              </Form>
            </Card.Body>
          </Paper>
        </FormContainer>
      )}
    </>
  );
};

export default ShippingScreen;
