export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_ACTIVE_CATEGORY = "FETCH_ACTIVE_CATEGORY";
export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const FETCH_INACTIVE_CATEGORIES = "FETCH_INACTIVE_CATEGORIES";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_ACTIVATE_CATEGORY = "UPDATE_ACTIVATE_CATEGORY";
// export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const ACTIVATE_CATEGORY = "ACTIVATE_CATEGORY";
export const RESET_CATEGORY = "RESET_CATEGORY";
export const CATEGORY_FETCH_FAIL = "CATEGORY_FETCH_FAIL";
