import axios from "axios";
import { START_LOADING, END_LOADING } from "constants/webOrderConstants";
import {
  CREATE_ZONE,
  FETCH_DELIVERY_ZONES,
  FETCH_DELIVERY_ZONE,
  UPDATE_DELIVERY_ZONE,
  DELETE_DELIVERY_ZONE,
} from "../constants/zoneConstants";
import { toast } from "react-toastify";
import * as api from "../api/index.js";

export const createDeliveryZone = (zoneDate) => async (dispatch) => {
  try {
    const { data } = await api.createDeliveryZone(zoneDate);
    dispatch({ type: CREATE_ZONE, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};

export const getDeliveryZones = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchDeliveryZones();

    dispatch({
      type: FETCH_DELIVERY_ZONES,
      payload: data,
    });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getDeliveryZone = (zone_id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchDeliveryZone(zone_id);

    dispatch({
      type: FETCH_DELIVERY_ZONE,
      payload: { zone: data },
    });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const updateDeliveryZone =
  (zone_id, updatedZoneData) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.patchDeliveryZone(zone_id, updatedZoneData);

      dispatch({ type: UPDATE_DELIVERY_ZONE, payload: data });
      dispatch(getDeliveryZones());

      dispatch({ type: END_LOADING });
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

export const deleteDeliveryZone = (zone_id) => async (dispatch) => {
  try {
    const { data } = await api.deleteZone(zone_id);

    dispatch({ type: DELETE_DELIVERY_ZONE, payload: zone_id, data });
    toast.success("Zone Deleted Successfully!");
  } catch (error) {
    console.log(error);
    toast.error(error.response);
  }
};
