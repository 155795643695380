// reducers/sectionReducer.js

import { CREATE_SECTION, GET_SECTIONS, START_LOADING, END_LOADING, UPDATE_SECTION } from "../constants/actionTypes.js";

const initialState = {
  isLoading: false,
  sections: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case CREATE_SECTION:
      return {
        ...state,
        sections: [...state.sections, action.payload],
        isLoading: false,
      };
    case GET_SECTIONS:
      return { ...state, sections: action.payload, isLoading: false };
    case UPDATE_SECTION:
      return {
        ...state,
        sections: state.sections.map(section =>
          section._id === action.payload.data._id ? action.payload.data : section
        ),
        isLoading: false,
      };
    default:
      return state;
  }
};
