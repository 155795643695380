import React from "react";
import ReactDOM from "react-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "assets/css/demo.css";

import { Provider } from "react-redux";
import App from "./App";
import store from "./reducers/store";
import { BrowserRouter } from "react-router-dom";
import { GoogleMapsProvider } from "./GoogleMapsContext";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <GoogleMapsProvider>
        <App />
      </GoogleMapsProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
