import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";
import PrivateRoute from "./PrivateRoute.js";
import VendorRegistration from "./views/Forms/VendorRegistration.js";
import PasswordResetForm from "./views/Forms/PasswordResetForm.js";
import AgentPasswordResetRequestScreen from "./views/Forms/AgentPasswordResetRequestScreen.js";
import VendorRegistrationForm from "./views/Forms/VendorRegistrationForm.js";
import AgentPasswordResetForm from "./views/Forms/AgentPasswordResetForm.js";
import MobileClientPasswordResetForm from "./views/Forms/MobileClientPasswordResetForm.js";
import ShippingCostTable from "./views/Tables/ShippingCostTable.js";
import { publicRoutes } from "./routes.js";

const App = () => {
  return (
    <Router>
      <Switch>
        {/* Public Routes */}
        <Route path="/auth" component={AuthLayout} />
        <Route path="/vendorRegistration" component={VendorRegistration} />
        <Route
          path="/reset-password/:token/:id"
          component={PasswordResetForm}
        />
        <Route
          path="/agentPasswordReset-request"
          component={AgentPasswordResetRequestScreen}
        />
        <Route path="/vendor-register" component={VendorRegistrationForm} />
        <Route
          path="/agent-reset-password/:token/:id"
          component={AgentPasswordResetForm}
        />
        <Route
          path="/mobileClient-reset-password/:token/:id"
          component={MobileClientPasswordResetForm}
        />
        <Route path="/shippingCost" component={ShippingCostTable} />
        {publicRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.layout + route.path}
            component={route.component}
            exact={route.exact}
          />
        ))}

        {/* Private Routes */}
        <PrivateRoute path="/admin" component={AdminLayout} />

        {/* Redirect to login if no match */}
        <Redirect from="/" to="/auth/login-page" />
      </Switch>
    </Router>
  );
};

export default App;
