import {

    RESTAURANT_VENDOR_ORDERS_REQUEST,
    RESTAURANT_VENDOR_ORDERS_SUCCESS,
    RESTAURANT_VENDOR_ORDERS_FAIL
} from "../constants/webOrderConstants";

const initialState = {
    orders: [],
    loading: false,
    error: null,
    currentPage: 1,
    numberOfPages: 1,
    totalOrders: 0,
};

export const restaurantVendorOrdersReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESTAURANT_VENDOR_ORDERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RESTAURANT_VENDOR_ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: action.payload.data,
                currentPage: action.payload.currentPage,
                numberOfPages: action.payload.numberOfPages,
                totalOrders: action.payload.totalOrders,
            };
        case RESTAURANT_VENDOR_ORDERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
