import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  Form,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "views/Components/Message";
import Loader from "views/Components/Loader";
import {
  // createCashOnDeliveryOrder,
  getOrderDetails,
  payMomoOrder,
  processCreateOrder,
  removeShippingAddressAfterMomoPayment,
} from "../../actions/webOrderActions";
import { clearPaymentMethod } from "actions/cartActions";
import { clearCart } from "actions/cartActions";
import { Paper, Typography } from "@material-ui/core";
import MomoModal from "views/Payment/MomoModal";
import { RESET_SHIPPING_COST } from "../../constants/shippingCostsConstants";
import styles from "./payOrderScreen.module.css";
import { getUserFromLocalStorage } from "utilities/user";

const PayOrderScreen = ({ match, history }) => {
  const orderId = match.params.id;
  const user = getUserFromLocalStorage();
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  // Get shippping cost from the server
  const { shippingCosts, loading: loadingShippingCost } = useSelector(
    (state) => state.shippingCosts
  );
  // const serverShippingFees = shippingCosts.map((cost) => cost.cost);
  const serverShippingFees = order?.shippingCost_id?.cost;

  useEffect(() => {
    dispatch(getOrderDetails(orderId));
  }, [dispatch, orderId]);

  if (!order) {
    history.push("/cart-page");
  }

  const dispatch = useDispatch();

  // console.log(user?.result?._id, "XXXXX USER SCREEN");
  const [openMomoModal, setOpenMomoModal] = useState(false);
  const [postData, setPostData] = useState({
    mobile: "",
  });

  const { client_id } = useSelector((state) => state.orderDetails.order);
  const clientID = client_id?._id;

  //THIS DISABLES BACK-BROWSER NAVIGATION
  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.go(1);
      }
    };
  }, [history]);

  //THIS MIGHT HAVE TO BE RENDERED ABOVE /FIRST TO DISPATCH PROPERLY
  // useEffect(() => {
  //   dispatch(getOrderDetails(orderId));
  // }, [dispatch, orderId]);

  //  if (!loading) {
  //     order?.itemsPrice = order?.orderItems?.reduce(
  //        (acc, item) => acc + item.price * item.qty,
  //        0,
  //     )
  //  }

  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  const codCheckoutHandler = () => {
    dispatch(
      processCreateOrder(user?.result?._id),
      dispatch(clearCart()),
      dispatch(clearPaymentMethod()),
      history.push("/admin/paginatedOrdersTable")
    );
    dispatch({ type: RESET_SHIPPING_COST });
  };

  const payMoMoCheckoutHandler = () => {
    dispatch(
      payMomoOrder(
        {
          // ...postData,
          // orderItems: order.orderItems,
          // shippingAddress: order.shippingAddress,
          // paymentMethod: order.paymentMethod,
          // itemsPrice: order.itemsPrice,
          // shippingCost: order.shippingPrice,
          // taxPrice: order.taxPrice,
          mobile: postData.mobile,
          totalPrice: order.totalPrice,
          client_id: clientID,

          // user_id: user.result._id,
          // user_id: clientID,
        }
        // clientID
        // user.result._id //Should user_id be passed Twice?
      ),
      dispatch(clearCart()),
      dispatch(clearPaymentMethod()),
      clear(),
      dispatch(removeShippingAddressAfterMomoPayment()),
      setOpenMomoModal(true)

      // history.push("/admin/mobileWebOrdersTable")
    );
  };

  const clear = () => {
    setPostData({
      mobile: "",
    });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <MomoModal
            open={openMomoModal}
            clear={clear}
            setOpen={setOpenMomoModal}
          // clientId={clientID}
          />
          <Col md="10" className="mx-auto mt-3 mb-5">
            <Paper elevation={4}>
              <Card.Body>
                <h3>
                  Order No.
                  <Typography style={{ fontSize: "1.2rem", color: "#1D438A" }}>
                    {order?._id}
                  </Typography>{" "}
                </h3>
                <Row>
                  <Col md={8}>
                    <Paper elevation={3}>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          <h2>Shipping</h2>
                          <p>
                            <strong>Agent Name: </strong>{" "}
                            {order?.agent_id?.name}
                          </p>
                          <p>
                            <strong>Client Name: </strong>{" "}
                            <a href={`mailto:${order?.client_id?.email}`}>
                              {order?.client_id?.name}
                            </a>
                          </p>
                          <p>
                            <strong>Address: </strong>
                            {order?.shippingAddress?.locationName?.name} ||{" "}
                            {order?.shippingAddress?.locationName?.vicinity},{" "}
                            {/* {order?.shippingAddress?.city}{" "} */}
                            {order?.shippingAddress?.customerNumber},{" "}
                            {order?.shippingAddress?.country}
                          </p>
                          {order?.isDelivered ? (
                            <Message variant="success">
                              Delivered on {order?.deliveredAt}
                            </Message>
                          ) : (
                            <Message variant="danger">Not Delivered</Message>
                          )}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <h2>Payment Method</h2>
                          <p>
                            <strong>Method: </strong>
                            {order?.paymentMethod}
                          </p>
                          {order?.isPaid ? (
                            <Message variant="success">
                              Paid on {order?.paidAt}
                            </Message>
                          ) : (
                            <Message variant="danger">Not Paid</Message>
                          )}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <h2>Order Items</h2>
                          {order?.orderItems?.length === 0 ? (
                            <Message>Order is empty</Message>
                          ) : (
                            <ListGroup variant="flush">
                              {order?.orderItems?.map((item, index) => (
                                <ListGroup.Item key={index}>
                                  <Row>
                                    <Col md={1}>
                                      <Image
                                        src={item.imageUrl}
                                        alt={item.name}
                                        fluid
                                        rounded
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to={`/admin/listing-details/${item._id}`}
                                      >
                                        {item.name}
                                      </Link>
                                    </Col>
                                    <Col md={6}>
                                      {item.qty} x {item.price} = <b>Shs. </b>
                                      {item.qty * item.price} /=
                                    </Col>
                                  </Row>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          )}
                        </ListGroup.Item>
                      </ListGroup>
                    </Paper>
                  </Col>
                  <Col md={4}>
                    <Paper elevation={4}>
                      <ListGroup.Item>
                        <h2>Order Summary</h2>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col style={{ color: "#1D438A" }}>Items Cost</Col>
                          <Col>
                            Shs{" "}
                            {order?.itemsPrice
                              ?.toString()
                              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col style={{ color: "#1D438A" }}>Shipping Cost</Col>
                          {/* <Col>Shs {shippingAddress?.shippingCost} 5,000</Col> */}
                          <Col>Shs {serverShippingFees} </Col>
                        </Row>
                      </ListGroup.Item>
                      {/* <ListGroup.Item>
                <Row>
                  <Col>VAT (18%)</Col>
                  <Col>Shs {order?.taxPrice}</Col>
                </Row>
              </ListGroup.Item> */}
                      <ListGroup.Item>
                        <Row>
                          <Col style={{ color: "#B3370B", fontWeight: "bold" }}>
                            Total:
                          </Col>
                          <Col style={{ color: "#B3370B", fontWeight: "bold" }}>
                            Shs{" "}
                            {order?.totalPrice
                              ?.toString()
                              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      {!order?.isPaid && (
                        <ListGroup.Item>
                          <Col>
                            {order?.paymentMethod == "MOMO" && (
                              <Card.Body>
                                <label>
                                  Enter MTN Mobile Number
                                  <span className="star">*</span>
                                </label>
                                <Form.Control
                                  name="mobile"
                                  type="number"
                                  value={postData.mobile}
                                  onChange={(e) =>
                                    setPostData({
                                      ...postData,
                                      mobile: e.target.value,
                                    })
                                  }
                                  // style={{
                                  //   MozAppearance: "textfield",
                                  // }}
                                  placeholder="Enter MTN Number.."
                                  className={styles.no_arrow}
                                  onKeyDown={(e) => {
                                    if (
                                      e.key === "ArrowUp" ||
                                      e.key === "ArrowDown"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                ></Form.Control>
                                <Button
                                  id="facebook"
                                  variant="facebook"
                                  onClick={payMoMoCheckoutHandler}
                                  // type="submit"
                                  style={{ width: "100%" }}
                                >
                                  <img
                                    style={{
                                      width: 35,
                                      height: 25,
                                      borderRadius: 5,
                                      alignSelf: "center",
                                    }}
                                    src={require("assets/img/momo.jpg").default}
                                    alt="mtn-logo"
                                  />
                                  {""} Pay Now
                                </Button>
                              </Card.Body>
                            )}{" "}
                          </Col>

                          {order?.paymentMethod == "COD" && (
                            <Button
                              type="button"
                              className="bg-success"
                              // style={{ color: "#DFEFFF" }}
                              // disabled={cartItems.length === 0}
                              onClick={codCheckoutHandler}
                            >
                              Checkout COD
                            </Button>
                          )}
                        </ListGroup.Item>
                      )}
                    </Paper>
                  </Col>
                </Row>
              </Card.Body>
            </Paper>
          </Col>
        </>
      )}
    </>
  );
};

export default PayOrderScreen;
