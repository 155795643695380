import {
    START_LOADING,
    END_LOADING,
    CREATE_RW_OPTION,
    GET_RW_OPTIONS,
    UPDATE_RW_OPTION,
    DELETE_RW_OPTION,
    CREATE_RW_OPTION_GROUP,
    GET_RW_OPTION_GROUPS,
    UPDATE_RW_OPTION_GROUP,
    DELETE_RW_OPTION_GROUP
} from '../constants/actionTypes';

const initialState = {
    options: [],
    optionGroups: [],
    isLoading: false,
    error: null,
};

export const retailerWholesalerOptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, isLoading: true };
        case CREATE_RW_OPTION:
            return { ...state, options: [...state.options, action.payload], isLoading: false };
        case GET_RW_OPTIONS:
            return { ...state, options: action.payload, isLoading: false };
        case UPDATE_RW_OPTION:
            return {
                ...state,
                options: state.options.map((option) =>
                    option._id === action.payload._id ? action.payload : option
                ),
                isLoading: false,
            };
        case DELETE_RW_OPTION:
            return {
                ...state,
                options: state.options.filter((option) => option._id !== action.payload),
                isLoading: false,
            };
        case CREATE_RW_OPTION_GROUP:
            return { ...state, optionGroups: [...state.optionGroups, action.payload], isLoading: false };
        case GET_RW_OPTION_GROUPS:
            return { ...state, optionGroups: action.payload, isLoading: false };
        case UPDATE_RW_OPTION_GROUP:
            return {
                ...state,
                optionGroups: state.optionGroups.map((optionGroup) =>
                    optionGroup._id === action.payload._id ? action.payload : optionGroup
                ),
                isLoading: false,
            };
        case DELETE_RW_OPTION_GROUP:
            return {
                ...state,
                optionGroups: state.optionGroups.filter((optionGroup) => optionGroup._id !== action.payload),
                isLoading: false,
            };
        case END_LOADING:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
