import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  cancelDeletionButton: {
    backgroundColor: "#003d66",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#002333",
      color: "#ffffff",
    },
  },

  deleteAgentButton: {
    backgroundColor: "#BD2A2E",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#F25764",
      color: "#ffffff",
    },
  },
});
