import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner, Modal, Container, Row, Col, Card } from 'react-bootstrap';
import { Switch, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaInfoCircle, FaCameraRetro, FaSquareFull, FaSpellCheck, FaStar } from 'react-icons/fa';
import { MdAddAPhoto } from 'react-icons/md';

const useStyles = makeStyles(theme => ({
    label: {
        fontWeight: 'normal',
        fontSize: '12px',
        textTransform: 'capitalize'
    }
}));

const EditRestaurantForm = ({ restaurant, onSave, onCancel }) => {
    const classes = useStyles();
    const [formData, setFormData] = useState({ ...restaurant });
    const [uploading, setUploading] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [bannerPreview, setBannerPreview] = useState(null);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedBannerFile, setSelectedBannerFile] = useState(null);
    const [uploadError, setUploadError] = useState(null);
    const [isFormatCorrect, setIsFormatCorrect] = useState(false);
    const [isSizeCorrect, setIsSizeCorrect] = useState(false);
    const [isResolutionCorrect, setIsResolutionCorrect] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        setFormData({ ...restaurant });
    }, [restaurant]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const resizeImage = (file, maxWidth, maxHeight, callback) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = maxWidth;
                canvas.height = maxHeight;
                ctx.drawImage(img, 0, 0, maxWidth, maxHeight);

                canvas.toBlob(callback, file.type);
            };
        };
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (!file) {
            setUploadError('No file was selected.');
            toast.error('No file was selected.');
            return;
        }

        setSelectedFile(file);
        setIsProcessing(true);

        resizeImage(file, 588, 558, (resizedBlob) => {
            const reader = new FileReader();
            reader.readAsDataURL(resizedBlob);
            reader.onload = () => {
                const img = new Image();
                img.onload = () => {
                    setImagePreview(reader.result);
                    setFormData(prev => ({ ...prev, imageUrl: reader.result }));
                    setShowPreviewModal(true);

                    const validFormats = ['image/jpeg', 'image/png'];
                    const isCurrentFormatCorrect = validFormats.includes(file.type);
                    const isCurrentSizeCorrect = file.size <= 10 * 1024 * 1024;
                    const isCurrentResolutionCorrect = img.width === 588 && img.height === 558;

                    setIsFormatCorrect(isCurrentFormatCorrect);
                    setIsSizeCorrect(isCurrentSizeCorrect);
                    setIsResolutionCorrect(isCurrentResolutionCorrect);

                    if (!isCurrentFormatCorrect || !isCurrentSizeCorrect || !isCurrentResolutionCorrect) {
                        let errorMessage = "Please ensure the image meets all the requirements:";
                        if (!isCurrentFormatCorrect) errorMessage += " Must be JPG or PNG format.";
                        if (!isCurrentSizeCorrect) errorMessage += " Must be less than or equal to 10MB.";
                        if (!isCurrentResolutionCorrect) errorMessage += " Must be exactly 588x558 pixels.";
                        setUploadError(errorMessage);
                    } else {
                        setUploadError(null);
                    }
                    setIsProcessing(false);
                };
                img.onerror = () => {
                    setUploadError('There was an error processing your image.');
                    setImagePreview(null);
                    setShowPreviewModal(false);
                    setIsProcessing(false);
                    toast.error('There was an error processing your image.');
                };
                img.src = reader.result;
            };
        });
    };

    const handleBannerChange = (event) => {
        const file = event.target.files[0];
        if (!file) {
            setUploadError('No file was selected.');
            toast.error('No file was selected.');
            return;
        }

        setSelectedBannerFile(file);
        setIsProcessing(true);

        resizeImage(file, 1440, 175, (resizedBlob) => {
            const reader = new FileReader();
            reader.readAsDataURL(resizedBlob);
            reader.onload = () => {
                const img = new Image();
                img.onload = () => {
                    setBannerPreview(reader.result);
                    setFormData(prev => ({ ...prev, bannerUrl: reader.result }));
                    setShowPreviewModal(true);

                    const validFormats = ['image/jpeg', 'image/png'];
                    const isCurrentFormatCorrect = validFormats.includes(file.type);
                    const isCurrentSizeCorrect = file.size <= 10 * 1024 * 1024;
                    const isCurrentResolutionCorrect = img.width === 1440 && img.height === 175;

                    setIsFormatCorrect(isCurrentFormatCorrect);
                    setIsSizeCorrect(isCurrentSizeCorrect);
                    setIsResolutionCorrect(isCurrentResolutionCorrect);

                    if (!isCurrentFormatCorrect || !isCurrentSizeCorrect || !isCurrentResolutionCorrect) {
                        let errorMessage = "Please ensure the image meets all the requirements:";
                        if (!isCurrentFormatCorrect) errorMessage += " Must be JPG or PNG format.";
                        if (!isCurrentSizeCorrect) errorMessage += " Must be less than or equal to 10MB.";
                        if (!isCurrentResolutionCorrect) errorMessage += " Must be exactly 1440x175 pixels.";
                        setUploadError(errorMessage);
                    } else {
                        setUploadError(null);
                    }
                    setIsProcessing(false);
                };
                img.onerror = () => {
                    setUploadError('There was an error processing your image.');
                    setBannerPreview(null);
                    setShowPreviewModal(false);
                    setIsProcessing(false);
                    toast.error('There was an error processing your image.');
                };
                img.src = reader.result;
            };
        });
    };

    const uploadImageToCloudinary = async (file) => {
        if (!file) {
            toast.error("No image file selected!");
            return null;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'lusuku-clou');
        formData.append('cloud_name', 'MM-iCT');
        formData.append('folder', 'lusuku_restaurants');

        try {
            const response = await axios.post(process.env.REACT_APP_CLOUDINARY_URL, formData);
            return response.data.secure_url;
        } catch (error) {
            console.error('Error uploading the image:', error);
            toast.error(`Failed to upload image: ${error.response?.data?.message || error.message}`);
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let updatedData = { ...formData };
        if (selectedFile) {
            setUploading(true);
            const secureUrl = await uploadImageToCloudinary(selectedFile);
            if (secureUrl) {
                updatedData = { ...updatedData, imageUrl: secureUrl };
            }
            setUploading(false);
        }

        if (selectedBannerFile) {
            setUploading(true);
            const secureUrl = await uploadImageToCloudinary(selectedBannerFile);
            if (secureUrl) {
                updatedData = { ...updatedData, bannerUrl: secureUrl };
            }
            setUploading(false);
        }

        onSave(updatedData);
    };

    return (
        <Container fluid>
            <Row className="justify-content-md-center">
                <Col md={10}>
                    <Card className="mt-3">
                        <Card.Body>
                            <Card.Title style={{ fontSize: '1.5rem', fontWeight: 500, marginBottom: '20px' }} as="h2">
                                Edit Restaurant
                            </Card.Title>
                            <hr />
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-1 mt-2">
                                    <Form.Label>Restaurant Image</Form.Label>
                                    <Row>
                                        <Col md={6}>
                                            <Card style={{ width: '100%' }}>
                                                <Card.Body className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '220px' }}>
                                                    {isProcessing && selectedFile ? (
                                                        <Spinner animation="border" />
                                                    ) : formData.imageUrl ? (
                                                        <>
                                                            <img src={formData.imageUrl} alt="Restaurant" style={{ maxHeight: '220px', maxWidth: '100%' }} />
                                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                                                <Button variant="link" as="label" style={{ color: 'green', textDecoration: 'none', textTransform: 'capitalize', fontSize: '12px' }}>
                                                                    Replace Image
                                                                    <Form.Control type="file" hidden accept=".jpg, .png, .jpeg" onChange={handleImageChange} id="file-input" />
                                                                </Button>
                                                                <span style={{ borderLeft: '1px solid #ccc', height: '40px', margin: '0 10px' }}></span>
                                                                <Button variant="link" style={{ color: 'red', textDecoration: 'none', fontSize: '12px' }} onClick={() => setFormData({ ...formData, imageUrl: null })}>
                                                                    Delete Image
                                                                </Button>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <Card.Text>
                                                            <Button
                                                                color="primary"
                                                                as="label"
                                                                className="d-flex align-items-center justify-content-center"
                                                                style={{ color: '#fff' }}
                                                            >
                                                                <MdAddAPhoto size={24} className="me-2" />
                                                                Add Image
                                                                <Form.Control
                                                                    type="file"
                                                                    hidden
                                                                    accept=".jpg, .png, .jpeg"
                                                                    onChange={handleImageChange}
                                                                    id="file-input"
                                                                />
                                                            </Button>
                                                        </Card.Text>
                                                    )}
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col md={4}>
                                            <Card.Text as="div" className="text-muted" style={{ fontSize: '12px' }}>
                                                <p style={{ marginBottom: "0" }}><strong>File requirements: </strong></p>
                                                <p style={{ fontStyle: "italic", fontSize: "11px" }}>
                                                    <FaInfoCircle style={{ marginRight: '5px' }} />
                                                    JPG or PNG up to 10 MB. Exact size of 588x558 pixels.
                                                </p>
                                                <p style={{ fontSize: '12px' }}>
                                                    <FaCameraRetro style={{ marginRight: '5px' }} />
                                                    Ensure your product is in focus and make all the ingredients visible.
                                                </p>
                                                <p style={{ fontSize: '12px' }}>
                                                    <FaSquareFull style={{ marginRight: '5px' }} />
                                                    Show the full product and place it at the center of the frame.
                                                </p>
                                                <p style={{ fontSize: '12px' }}>
                                                    <FaSpellCheck style={{ marginRight: '5px' }} />
                                                    Remove distractions and avoid using text.
                                                </p>
                                                <p style={{ fontSize: '12px' }}>
                                                    <FaStar style={{ marginRight: '5px' }} />
                                                    Use a clear background and find bright lighting.
                                                </p>
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-1 mt-2">
                                    <Form.Label>Restaurant Banner</Form.Label>
                                    <Row>
                                        <Col md={6}>
                                            <Card style={{ width: '100%' }}>
                                                <Card.Body className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '220px' }}>
                                                    {isProcessing && selectedBannerFile ? (
                                                        <Spinner animation="border" />
                                                    ) : formData.bannerUrl ? (
                                                        <>
                                                            <img src={formData.bannerUrl} alt="Restaurant Banner" style={{ maxHeight: '220px', maxWidth: '100%' }} />
                                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                                                <Button variant="link" as="label" style={{ color: 'green', textDecoration: 'none', textTransform: 'capitalize', fontSize: '12px' }}>
                                                                    Replace Banner
                                                                    <Form.Control type="file" hidden accept=".jpg, .png, .jpeg" onChange={handleBannerChange} id="banner-file-input" />
                                                                </Button>
                                                                <span style={{ borderLeft: '1px solid #ccc', height: '40px', margin: '0 10px' }}></span>
                                                                <Button variant="link" style={{ color: 'red', textDecoration: 'none', fontSize: '12px' }} onClick={() => setFormData({ ...formData, bannerUrl: null })}>
                                                                    Delete Banner
                                                                </Button>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <Card.Text>
                                                            <Button
                                                                color="primary"
                                                                as="label"
                                                                className="d-flex align-items-center justify-content-center"
                                                                style={{ color: '#fff' }}
                                                            >
                                                                <MdAddAPhoto size={24} className="me-2" />
                                                                Add Banner
                                                                <Form.Control
                                                                    type="file"
                                                                    hidden
                                                                    accept=".jpg, .png, .jpeg"
                                                                    onChange={handleBannerChange}
                                                                    id="banner-file-input"
                                                                />
                                                            </Button>
                                                        </Card.Text>
                                                    )}
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col md={4}>
                                            <Card.Text as="div" className="text-muted" style={{ fontSize: '12px' }}>
                                                <p style={{ marginBottom: "0" }}><strong>Banner File Requirements: </strong></p>
                                                <p style={{ fontStyle: "italic", fontSize: "11px" }}>
                                                    <FaInfoCircle style={{ marginRight: '5px' }} />
                                                    JPG or PNG up to 10 MB. Exact size of 1440x175 pixels.
                                                </p>
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Enter restaurant name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        placeholder="Business phone number..."
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        id="email"
                                        placeholder="Restaurant Email..."
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Location</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="address"
                                        id="address"
                                        placeholder="Restaurant Address..."
                                        value={formData.address}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={formData.isActive}
                                                onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
                                                name="promoted"
                                                color="secondary"
                                            />
                                        }
                                        label={formData.isActive ? 'Active Restaurant' : 'Deactived Restaurant'}
                                        classes={{ label: classes.label }}

                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Created At</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="createdAt"
                                        id="createdAt"
                                        placeholder="Creation date..."
                                        value={new Date(formData.createdAt).toDateString()}
                                        readOnly
                                    />
                                </Form.Group>
                                <Button
                                    variant="danger"
                                    onClick={onCancel}
                                    style={{
                                        marginRight: '8px',
                                    }}
                                >
                                    Cancel</Button>
                                <Button variant="primary" type="submit" disabled={uploading}>
                                    {uploading ? <Spinner size="sm" /> : 'Save'}
                                </Button>{' '}
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal show={showPreviewModal} onHide={() => setShowPreviewModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Image Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        {imagePreview && <img src={imagePreview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '400px' }} />}
                        {bannerPreview && <img src={bannerPreview} alt="Banner Preview" style={{ maxWidth: '100%', maxHeight: '400px' }} />}
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <hr />
                        <div className="text-muted" style={{ fontSize: '12px' }}>
                            <strong>The image must:</strong>
                            <ul style={{ fontSize: '12px' }}>
                                <li>Be JPG or PNG format {isFormatCorrect ? <span className="text-success">✔</span> : <span className="text-danger">✘</span>}</li>
                                <li>Be less than or equal to 10MB {isSizeCorrect ? <span className="text-success">✔</span> : <span className="text-danger">✘</span>}</li>
                                {imagePreview && (
                                    <li>Be exactly 588x558 pixels {isResolutionCorrect ? <span className="text-success">✔</span> : <span className="text-danger">✘</span>}</li>
                                )}
                                {bannerPreview && (
                                    <li>Be exactly 1440x175 pixels {isResolutionCorrect ? <span className="text-success">✔</span> : <span className="text-danger">✘</span>}</li>
                                )}
                            </ul>
                        </div>
                    </div>
                    {uploadError && <p className="text-danger" style={{ fontSize: '12px' }}>{uploadError}</p>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowPreviewModal(false)}>Cancel</Button>
                    {uploadError ? (
                        <Button variant="warning" onClick={() => document.getElementById('file-input').click()}>Try Another Image</Button>
                    ) : (
                        <Button variant="primary" onClick={() => setShowPreviewModal(false)}>Confirm Upload</Button>
                    )}
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default EditRestaurantForm;
