import React, { useEffect, useState } from "react";
import { Form, Button, Col, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CheckoutSteps from "views/Components/CheckoutSteps";
import FormContainer from "../../views/Components/FormContainer";
import { savePaymentMethod } from "../../actions/cartActions";
import { Divider, Paper } from "@material-ui/core";

const PaymentScreen = ({ history }) => {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  const [paymentMethod, setPaymentMethod] = useState("COD");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!shippingAddress.customerNumber) {
      history.push("/shipping");
    } else if (cart.cartItems.length === 0) {
      history.push("/admin/cart-page");
    }
  }, [history, shippingAddress.customerNumber, cart.cartItems]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentMethod));
    history.push("/admin/placeorder");
  };

  return (
    <FormContainer>
      <Paper className="mx-auto mt-3" elevation={4}>
        <Card.Body>
          <CheckoutSteps step1 step2 step3 />
          <h1>Payment Method</h1>
          <Form onSubmit={submitHandler}>
            <Form.Group>
              <Form.Label as="legend">Select Payment Method</Form.Label>
              <Col>
                <Form.Check
                  type="radio"
                  label="MTN Mobile Money"
                  id="MOMO"
                  name="paymentMethod"
                  value="MOMO"
                  onChange={(e) => setPaymentMethod(e.target.value)}
                ></Form.Check>
                <Form.Check
                  type="radio"
                  label="Cash On  Delivery"
                  id="COD"
                  name="paymentMethod"
                  value="COD"
                  checked
                  onChange={(e) => setPaymentMethod(e.target.value)}
                ></Form.Check>
              </Col>
            </Form.Group>

            <Divider style={{ margin: "10px 0" }} />
            <Button type="submit" variant="primary">
              Continue
            </Button>
          </Form>
        </Card.Body>
      </Paper>
    </FormContainer>
  );
};

export default PaymentScreen;
