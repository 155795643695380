import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Card } from "@material-ui/core";
import ImageUploadStyledAlert from "../../views/Components/ImageUploadStyledAlert";
import axios from "axios";

import { submitVendorRegistration } from "../../actions/vendorActions.js";
import LusukuLogo from "../../views/Components/LusukuLogo";
import { convertTransparentToSolidColor } from "../../helpers/PNGConverter.js";

const options = [
  { value: "RESTAURANT", label: "Restaurant" },
  { value: "RETAILER", label: "Retailer" },
  { value: "WHOLESALER", label: "Wholesaler" },
];

const validationSchema = Yup.object().shape({
  businessName: Yup.string().required("Business name is required"),
  businessTradingName: Yup.string().required("Trading name is required"),
  operationMode: Yup.string().required("Vendor type is required"),
  email: Yup.string().email().required("Email is required"),
  businessEmail: Yup.string().email().required("Business email is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
  businessSecondaryPhone: Yup.string(),
  businessAddress: Yup.string().required("Business address is required"),
  businessPhone: Yup.string().required("Business phone number is required"),
  businessTIN: Yup.string().required("Company TIN is required"),
  sellerNIN: Yup.mixed().required("Seller NIN is required"),
  typeOfGoods: Yup.string().required(
    "Product or service specialty description is required"
  ),
  businessTradingLicense: Yup.mixed()
    .nullable()
    .required("Business Registration proof Document is required"),
});

const initialValues = {
  businessName: "",
  businessTradingName: "",
  email: "",
  businessEmail: "",
  operationMode: "",
  companyWebsite: "",
  businessAddress: "",
  businessPhone: "",
  businessTIN: "",
  businessTradingLicense: "",
  sellerNIN: "",
  typeOfGoods: "",
  password: "",
  confirmPassword: "",
  isRestaurant: false,
};

const VendorRegistrationForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [image, setImage] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);
  const [fileTouched, setFileTouched] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const fetchImageToUpload = useCallback(
    (folder) => {
      return new Promise(async (resolve, reject) => {
        setIsUploading(true);
        const data = new FormData();
        data.append("file", image);
        data.append("upload_preset", "lusuku-clou");
        data.append("cloud_name", "MM-iCT");
        data.append("folder", folder);

        const onUploadProgress = (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        };

        const abortController = new AbortController();
        try {
          const res = await axios.post(
            process.env.REACT_APP_CLOUDINARY_URL,
            data,
            {
              onUploadProgress,
              signal: abortController.signal,
            }
          );

          const fileData = await res.data;
          setImage(fileData.secure_url);
          setIsUploading(false);
          setUploadDone(true);

          resolve({ secure_url: fileData.secure_url });
        } catch (err) {
          abortController.abort();
          reject(err);
        }
      });
    },
    [image]
  );

  const handleSelectChange = (setFieldValue, e) => {
    setFieldValue("operationMode", e ? e.value : "");
    setFieldValue("isRestaurant", e.value === "RESTAURANT");
  };

  // Verify that the uploaded file is a JPEG image or PNG
  // Check if file is JPEG
  const isJPEG = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const buffer = e.target.result;
        const view = new DataView(buffer);
        const isJPEG =
          view.byteLength >= 2 && view.getUint16(0, false) === 0xffd8; // Check if the file starts with a JPEG SOI marker (0xFFD8)
        resolve(isJPEG);
      };
      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  // Check if file is a PNG
  const isPNG = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const buffer = e.target.result;
        const view = new DataView(buffer);
        const pngSignature = [137, 80, 78, 71, 13, 10, 26, 10];
        const isPNG = pngSignature.every(
          (byte, index) => view.getUint8(index) === byte
        );
        resolve(isPNG);
      };
      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  // Handle the file upload
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const isFileJPEG = await isJPEG(file);
    const isFilePNG = await isPNG(file);
    const fileSizeInMB = file.size / (1024 * 1024);

    if (!isFileJPEG && !isFilePNG) {
      setTimeout(() => {
        setSnackbarSeverity("error");
        setSnackbarMessage(
          "Invalid file type. Only JPG, JPEG, and PNG files are allowed."
        );
        setOpenSnackbar(true);
        setUploadedFileName("");
      }, 0);
      return;
    }
    let processedFile = file;
    if (isFilePNG) {
      processedFile = await convertTransparentToSolidColor(file);
    }
    if (fileSizeInMB > 5) {
      setTimeout(() => {
        setSnackbarSeverity("error");
        setSnackbarMessage(
          "File size exceeds 5 MB. Please choose a smaller image."
        );
        setOpenSnackbar(true);
        setUploadedFileName("");
      }, 0);
      return;
    }

    setImage(processedFile);
    setUploadedFileName(processedFile.name);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setIsUploading(true);
      const { secure_url } = await fetchImageToUpload("vendor_files");
      values.businessTradingLicense = secure_url;

      dispatch(submitVendorRegistration(values))
        .then((data) => {
          setSubmitting(false);
          resetForm({ initialValues: {} });
          clearFileInput();
          setIsUploading(false);
          setUploadDone(false);
        })
        .catch((error) => {
          console.error("Form submission failed:", error);
          setSubmitting(false);
          setIsUploading(false);
        });
    } catch (error) {
      console.error("Image upload failed:", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("Image upload failed. Please try again later.", error);
      setOpenSnackbar(true);
      setSubmitting(false);
      resetForm({ initialValues: {} });
      setIsUploading(false);
    }
  };

  const handleInnerFormSubmit = (
    values,
    setFieldError,
    setTouched,
    setSubmitting,
    resetForm
  ) => {
    setSubmitting(true);
    // Mark all fields as touched before validating
    if (!uploadedFileName) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Please upload a business trading license");
      setOpenSnackbar(true);
      setSubmitting(false);
      return;
    }
    setTouched({
      businessName: true,
      businessTradingName: true,
      email: true,
      businessEmail: true,
      operationMode: true,
      companyWebsite: true,
      businessAddress: true,
      businessPhone: true,
      businessTIN: true,
      businessTradingLicense: true,
      sellerNIN: true,
      typeOfGoods: true,
      password: true,
      confirmPassword: true,
    });

    if (!uploadedFileName) {
      setFileTouched(true);
      setFieldError(
        "businessTradingLicense",
        "Business Trading License is required"
      );
      return;
    }
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      // If validation passes, proceed with your form submission logic here
      handleFormSubmit(values, { setSubmitting, resetForm });
    } catch (errors) {
      // Handle validation errors here
      if (errors instanceof Yup.ValidationError) {
        errors.inner.forEach((error) => {
          setFieldError(error.path, error.message);
        });
      }
    }
  };

  // Clear the file input field after the file has been uploaded
  const clearFileInput = () => {
    const fileInput = document.getElementById("image-upload");
    if (fileInput) {
      fileInput.value = "";
    }
    setUploadedFileName("");
  };

  return (
    <>
      <ImageUploadStyledAlert
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
      <div className={classes.container}>
        <LusukuLogo />
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-6 offset-md-3 mt-4 ">
              <Card
                className="card"
                style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}
              >
                <div className="card-body">
                  <h4 className="text-center">
                    <strong>VENDOR REGISTRATION</strong>
                  </h4>
                  <hr />
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      isSubmitting,
                      setFieldValue,
                      setFieldError,
                      setTouched,
                      setSubmitting,
                      resetForm,
                    }) => (
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleInnerFormSubmit(
                            values,
                            setFieldError,
                            setTouched,
                            setSubmitting,
                            resetForm
                          );
                        }}
                        id="vendor-form"
                      >
                        {/* Operation Mode */}
                        <div className="form-group">
                          <label htmlFor="operationMode">
                            Select Operation Mode
                          </label>
                          <Row>
                            <Col md="12">
                              <Field name="operationMode">
                                {({ field, form }) => (
                                  <Select
                                    className={`react-select primary ${
                                      form.errors.operationMode &&
                                      form.touched.operationMode
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    classNamePrefix="react-select"
                                    options={options}
                                    placeholder="Your business operation mode (Restaurant, Wholesaler or Retailer)?"
                                    {...field}
                                    onChange={(e) =>
                                      handleSelectChange(form.setFieldValue, e)
                                    }
                                    value={options.find(
                                      (option) => option.value === field.value
                                    )}
                                  />
                                )}
                              </Field>
                              {errors.operationMode &&
                                touched.operationMode && (
                                  <div className="invalid-feedback">
                                    {errors.operationMode}
                                  </div>
                                )}
                            </Col>
                          </Row>
                        </div>
                        {/* Shop/Vendor Name */}
                        <div className="form-group">
                          <label htmlFor="businessName">
                            {values.operationMode === "RESTAURANT"
                              ? "Restaurant Name"
                              : "Business Name"}
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.businessName && touched.businessName
                                ? "is-invalid"
                                : ""
                            }`}
                            name="businessName"
                            placeholder={`Enter ${
                              values.operationMode === "RESTAURANT"
                                ? "restaurant"
                                : "business"
                            } name...`}
                          />
                          {errors.businessName && touched.businessName && (
                            <div className="invalid-feedback">
                              {errors.businessName}
                            </div>
                          )}
                        </div>

                        {/* Trading As ~ Bussiness Trading Name */}
                        <div className="form-group">
                          <label htmlFor="businessTradingName">
                            {values.operationMode === "RESTAURANT"
                              ? "Restaurant Trading Name"
                              : "Business Trading Name"}
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.businessTradingName &&
                              touched.businessTradingName
                                ? "is-invalid"
                                : ""
                            }`}
                            name="businessTradingName"
                            placeholder={`Enter ${
                              values.operationMode === "RESTAURANT"
                                ? "restaurant trading"
                                : "business trading"
                            } name...`}
                          />
                          {errors.businessTradingName &&
                            touched.businessTradingName && (
                              <div className="invalid-feedback">
                                {errors.businessTradingName}
                              </div>
                            )}
                        </div>
                        {/* Email */}
                        <div className="form-group mt-2">
                          <label htmlFor="email"> Email</label>
                          <Field
                            type="email"
                            className={`form-control ${
                              errors.email && touched.email ? "is-invalid" : ""
                            }`}
                            name="email"
                            placeholder="Enter email..."
                          />
                          {errors.email && touched.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </div>

                        {/* Business Email */}
                        <div className="form-group mt-2">
                          <label htmlFor="businessEmail">
                            {values.operationMode === "RESTAURANT"
                              ? "Restaurant Business Email"
                              : "Business Email"}
                          </label>
                          <Field
                            type="email"
                            className={`form-control ${
                              errors.businessEmail && touched.businessEmail
                                ? "is-invalid"
                                : ""
                            }`}
                            name="businessEmail"
                            placeholder="Enter business email..."
                          />
                          {errors.businessEmail && touched.businessEmail && (
                            <div className="invalid-feedback">
                              {errors.businessEmail}
                            </div>
                          )}
                        </div>
                        {/* Password */}
                        <div className="form-group mt-2">
                          <label htmlFor="password">Password</label>
                          <Field
                            type="password"
                            className={`form-control ${
                              errors.password && touched.password
                                ? "is-invalid"
                                : ""
                            }`}
                            name="password"
                            placeholder="Enter password..."
                          />

                          {errors.password && touched.password && (
                            <div className="invalid-feedback">
                              {errors.password}
                            </div>
                          )}
                        </div>
                        {/* Confirm Password */}
                        <div className="form-group mt-2">
                          <label htmlFor="confirmPassword">
                            Confirm Password
                          </label>
                          <Field
                            type="password"
                            className={`form-control ${
                              errors.confirmPassword && touched.confirmPassword
                                ? "is-invalid"
                                : ""
                            }`}
                            name="confirmPassword"
                            placeholder="Confirm password..."
                          />
                          {errors.confirmPassword &&
                            touched.confirmPassword && (
                              <div className="invalid-feedback">
                                {errors.confirmPassword}
                              </div>
                            )}
                        </div>
                        {/* Company Website */}
                        <div className="form-group mt-2">
                          <label htmlFor="companyWebsite">
                            {values.operationMode === "RESTAURANT"
                              ? "Restaurant Social Media Page"
                              : " Company Website or facebook page"}
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.companyWebsite && touched.companyWebsite
                                ? "is-invalid"
                                : ""
                            }`}
                            name="companyWebsite"
                            placeholder="Enter company page url..."
                          />
                          {errors.companyWebsite && touched.companyWebsite && (
                            <div className="invalid-feedback">
                              {errors.companyWebsite}
                            </div>
                          )}
                        </div>
                        {/* Business Address */}
                        <div className="form-group mt-2">
                          <label htmlFor="businessAddress">
                            {values.operationMode === "RESTAURANT"
                              ? " Restaurant Address"
                              : "Business Address"}
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.businessAddress && touched.businessAddress
                                ? "is-invalid"
                                : ""
                            }`}
                            name="businessAddress"
                            placeholder="Enter business address..."
                          />
                          {errors.businessAddress &&
                            touched.businessAddress && (
                              <div className="invalid-feedback">
                                {errors.businessAddress}
                              </div>
                            )}
                        </div>
                        {/* Business Phone */}
                        <div className="form-group mt-2">
                          <label htmlFor="businessPhone">
                            {values.operationMode === "RESTAURANT"
                              ? " Restaurant Business Phone"
                              : "Business Phone"}
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.businessPhone && touched.businessPhone
                                ? "is-invalid"
                                : ""
                            }`}
                            name="businessPhone"
                            placeholder="Enter business phone..."
                          />
                          {errors.businessPhone && touched.businessPhone && (
                            <div className="invalid-feedback">
                              {errors.businessPhone}
                            </div>
                          )}
                        </div>
                        {/* Business businessTIN */}
                        <div className="form-group mt-2">
                          <label htmlFor="businessTIN">Business TIN</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.businessTIN && touched.businessTIN
                                ? "is-invalid"
                                : ""
                            }`}
                            name="businessTIN"
                            placeholder="Enter business TIN..."
                          />
                          {errors.businessTIN && touched.businessTIN && (
                            <div className="invalid-feedback">
                              {errors.businessTIN}
                            </div>
                          )}
                        </div>

                        {/* sellerNIN */}
                        <div className="form-group mt-2">
                          <label htmlFor="sellerNIN">Director's NIN</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.sellerNIN && touched.sellerNIN
                                ? "is-invalid"
                                : ""
                            }`}
                            name="sellerNIN"
                            placeholder="Enter seller National ID Number..."
                          />
                          {errors.sellerNIN && touched.sellerNIN && (
                            <div className="invalid-feedback">
                              {errors.sellerNIN}
                            </div>
                          )}
                        </div>
                        {/* typeOfGoods textarea */}
                        <div className="form-group mt-2">
                          <label htmlFor="typeOfGoods">
                            {values.operationMode === "RESTAURANT"
                              ? "Cuisine Speciality"
                              : "Type of Goods"}
                          </label>
                          <Field
                            component="textarea"
                            className={`form-control ${
                              errors.typeOfGoods && touched.typeOfGoods
                                ? "is-invalid"
                                : ""
                            }`}
                            name="typeOfGoods"
                            placeholder={`Enter ${
                              values.operationMode === "RESTAURANT"
                                ? "Cuisine Speciality (Fast foods, local, continental etc)"
                                : "Type of goods..."
                            }`}
                          />
                          {errors.typeOfGoods && touched.typeOfGoods && (
                            <div className="invalid-feedback">
                              {errors.typeOfGoods}
                            </div>
                          )}
                        </div>
                        {/* Business businessTradingLicense */}
                        <div className="form-group mt-2">
                          <label htmlFor="businessTradingLicense">
                            Business Trading License
                          </label>
                          <div
                            style={{ width: "97%", margin: "10px 0" }}
                            className={`${
                              !uploadedFileName &&
                              fileTouched &&
                              errors.businessTradingLicense
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <div>
                              <label
                                htmlFor="image-upload"
                                style={{
                                  display: "inline-block",
                                  padding: "6px 12px",
                                  cursor: "pointer",
                                  backgroundColor: "#f8f9fa",
                                  border: "1px solid #ced4da",
                                  borderRadius: "4px",
                                  fontWeight: 250,
                                  color: "#D97904",
                                  fontSize: "0.9rem",
                                  lineHeight: "1.5",
                                }}
                                onMouseEnter={(e) => {
                                  e.target.style.backgroundColor = "#e9ecef";
                                  e.target.style.borderColor = "#adb8bd";
                                }}
                                onMouseLeave={(e) => {
                                  e.target.style.backgroundColor = "#f8f9fa";
                                  e.target.style.borderColor = "#ced4da";
                                }}
                              >
                                {uploadedFileName ||
                                  "Upload URSB Business Registration Number or Trading license image..."}
                              </label>
                              <input
                                type="file"
                                id="image-upload"
                                name="businessTradingLicense"
                                accept=".jpg, .png, .jpeg"
                                style={{ display: "none" }}
                                onChange={async (event) => {
                                  await handleFileChange(event);
                                  setFieldValue(
                                    "businessTradingLicense",
                                    image
                                  );
                                  setFileTouched(true);
                                }}
                              />
                            </div>
                          </div>
                          {!uploadedFileName &&
                            fileTouched &&
                            errors.businessTradingLicense && (
                              <div className="invalid-feedback d-block">
                                {errors.businessTradingLicense}
                              </div>
                            )}
                          <div>
                            {uploadedFileName && (
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontStyle: "italic",
                                }}
                              >
                                File Name: {uploadedFileName}
                              </p>
                            )}
                            {isUploading && (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "13px",
                                  fontStyle: "italic",
                                }}
                              >
                                Uploading... {uploadProgress}%
                              </p>
                            )}
                            {uploadDone && !isUploading ? (
                              <p
                                style={{
                                  color: "green",
                                  fontSize: "13px",
                                  fontStyle: "italic",
                                }}
                              >
                                Company Document Uploaded successfully!
                              </p>
                            ) : null}
                          </div>
                        </div>

                        {/* Submit Button */}
                        <button
                          type="submit"
                          className={
                            isSubmitting
                              ? `btn ${classes.disabledBtn}`
                              : `btn ${classes.enabledBtn}`
                          }
                          style={{
                            width: "100%",
                            color: isSubmitting ? "#02735E" : "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: isSubmitting ? "not-allowed" : "pointer",
                          }}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <>
                              Submitting...
                              <div
                                style={{
                                  marginLeft: "10px",
                                }}
                              >
                                <Spinner
                                  size="10px"
                                  style={{ margin: "0", color: "#02735E" }}
                                />
                              </div>
                            </>
                          ) : (
                            "Create Vendor Profile"
                          )}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  root: {
    flexGrow: 1,
  },
  enabledBtn: {
    backgroundColor: "#172601",
    borderColor: "#84BF04",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#2E5902",
      borderColor: "#84BF04",
    },
    cursor: "pointer",
  },
  disabledBtn: {
    backgroundColor: "#6c757d",
    borderColor: "#6c757d",
    color: "black",
    "&:hover": {
      backgroundColor: "#6c757d",
      borderColor: "#6c757d",
    },
    cursor: "not-allowed",
  },
  spinner: {
    border: "4px solid rgba(0, 0, 0, 0.1)",
    borderLeftColor: "#02735E",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));
export default VendorRegistrationForm;
