import React, { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ReadOnlyQuill = ({ value }) => {
    const quillRef = useRef(null);

    useEffect(() => {
        if (quillRef.current) {
            const editor = quillRef.current.getEditor();
            editor.disable();
            editor.root.innerHTML = value;
        }
    }, [value]);

    return (
        <ReactQuill
            ref={quillRef}
            readOnly={true}
            theme="bubble"
            modules={{ toolbar: false }}
            style={{ fontSize: '0.875em', border: 'none' }}
        />
    );
};

export default ReadOnlyQuill;