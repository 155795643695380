import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Alert, Card, Form, Button, Container, Row, Col, Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { updateRestaurantProduct } from '../actions/restaurantProductsAction.js';
import { fetchSections } from '../actions/sectionAction.js';
import { fetchRestaurants, deleteRestaurantProduct } from '../actions/restaurantActions.js';
import { createRestaurantOptionGroup, fetchOptionGroupsRestaurant, fetchOptionsRestaurants } from '../actions/optionAction.js';
import { format } from 'date-fns';
import { FaCameraRetro, FaTrash, FaSpellCheck, FaInfoCircle, FaSquareFull, FaStar } from 'react-icons/fa';
import { MdAddAPhoto } from 'react-icons/md';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Switch, FormControlLabel } from '@material-ui/core';
import { getUserFromLocalStorage } from '../utilities/user.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles(theme => ({
    label: {
        fontWeight: 'normal',
        fontSize: '12px',
        textTransform: 'capitalize'
    }
}));

// Custom font CSS
const customFontsCSS = `
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.ql-font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.ql-editor {
  font-size: 0.875em !important;  /* Always small */
}

.ql-editor * {
  font-size: inherit !important;
}

.ql-editor h1 {
  font-size: 1.5em !important;
}

.ql-editor h2 {
  font-size: 1.3em !important;
}

.ql-editor h3 {
  font-size: 1.1em !important;
}
`;

// Add custom fonts to Quill
const Font = ReactQuill.Quill.import('formats/font');
// Font.whitelist = ['montserrat', 'roboto', 'lato', 'poppins', 'sans-serif', 'serif', 'monospace'];
Font.whitelist = ['montserrat', 'sans-serif'];
ReactQuill.Quill.register(Font, true);

// Format Pasted Content by default
const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    const sanitizedText = text.replace(/(<([^>]+)>)/gi, ""); // Remove any HTML tags
    document.execCommand('insertHTML', false, `<span style="font-size: 0.875em;">${sanitizedText}</span>`);
};

const EditMenuProductForm = () => {
    const classes = useStyles();
    const { productId } = useParams();
    const user = getUserFromLocalStorage();
    const restaurantId = user?.result?._id;

    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const [productDetails, setProductDetails] = useState(location.state?.productDetails || { optionGroups: [] });

    const sections = useSelector(state => state.sections.sections);
    const restaurants = useSelector(state => state.restaurants.restaurants);
    const optionGroups = useSelector(state => state.options.optionGroups);
    const options = useSelector(state => state.options.options);

    const [imagePreview, setImagePreview] = useState(null);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const [isFormatCorrect, setIsFormatCorrect] = useState(false);
    const [isSizeCorrect, setIsSizeCorrect] = useState(false);
    const [isResolutionCorrect, setIsResolutionCorrect] = useState(false);
    const [formErrors, setFormErrors] = useState({ section: '', restaurant: '' });
    const [submitting, setSubmitting] = useState(false);

    const [selectedOptionGroups, setSelectedOptionGroups] = useState([]);
    const [optionGroupName, setOptionGroupName] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showOptionGroupModal, setShowOptionGroupModal] = useState(false);
    const [minOptions, setMinOptions] = useState(0);
    const [maxOptions, setMaxOptions] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        dispatch(fetchSections(restaurantId));
        dispatch(fetchRestaurants(restaurantId));
        dispatch(fetchOptionGroupsRestaurant(restaurantId));
        dispatch(fetchOptionsRestaurants(restaurantId));
    }, [dispatch, restaurantId]);

    useEffect(() => {
        if (Array.isArray(productDetails.optionGroups)) {
            const initialSelectedOptionGroups = productDetails.optionGroups.map(optionGroup => ({
                value: optionGroup._id,
                label: optionGroup.name
            }));
            setSelectedOptionGroups(initialSelectedOptionGroups);
        }
    }, [productDetails.optionGroups]);

    useEffect(() => {
        if (productDetails.restaurantId && restaurants.length > 0) {
            const initialRestaurant = restaurants.find(restaurant => restaurant._id === productDetails.restaurantId);
            if (initialRestaurant) {
                setProductDetails(prevDetails => ({
                    ...prevDetails,
                    restaurantId: initialRestaurant._id
                }));
            }
        }
    }, [productDetails.restaurantId, restaurants]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'countInStock' && value < 1) {
            toast.error('Count in Stock cannot be less than 1');
            return;
        }

        setProductDetails({ ...productDetails, [name]: value });
        if (formErrors[name]) {
            setFormErrors({ ...formErrors, [name]: '' });
        }
    };

    const handleDescriptionChange = (content) => {
        const smallContent = content.replace(/<p>/g, '<p><span style="font-size: 0.875em;">').replace(/<\/p>/g, '</span></p>');
        if (smallContent !== productDetails.description) {
            setProductDetails(prevDetails => ({
                ...prevDetails,
                description: smallContent
            }));
        }
    };

    // Quill modules configuration
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            // [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'color': [] }, { 'background': [] }],
            // [{ 'font': ['roboto', 'lato', 'poppins', 'sans-serif', 'serif', 'monospace'] }],
            // [{ 'font': ['poppins'] }],
            [{ 'font': ['montserrat'] }],
            [{ 'align': [] }],
            ['clean'],
            ['link', 'image']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'list', 'bullet',
        'script',
        'indent',
        'direction',
        'size',
        'color', 'background',
        'font',
        'align',
        'link', 'image'
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = { section: '', restaurant: '' };

        if (!productDetails.sectionId) {
            errors.section = 'Section is required';
        }
        if (!productDetails.restaurantId) {
            errors.restaurant = 'Restaurant is required';
        }

        if (errors.section || errors.restaurant) {
            setFormErrors(errors);
            return;
        }

        setSubmitting(true);
        setUploading(true);
        let uploadedImageUrl = productDetails.image;

        if (uploadedImageUrl && typeof uploadedImageUrl === 'string' && uploadedImageUrl.startsWith('data:')) {
            uploadedImageUrl = await uploadImageToCloudinary(uploadedImageUrl);
            if (!uploadedImageUrl) {
                toast.error('Image upload failed, please try again.');
                setUploading(false);
                setSubmitting(false);
                return;
            }
        } else if (!uploadedImageUrl) {
            toast.error('Please add an image to the product.');
            setUploading(false);
            setSubmitting(false);
            return;
        }

        const updatedProductDetails = {
            ...productDetails,
            image: uploadedImageUrl,
            optionGroups: selectedOptionGroups.map(group => ({
                _id: group.value
            }))
        };

        try {
            await dispatch(updateRestaurantProduct(productId, updatedProductDetails));
            // toast.success('Product updated successfully');
        } catch (error) {
            toast.error('Failed to update product');
        } finally {
            setSubmitting(false);
            history.push('/admin/rest-menu');
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (!file) {
            setUploadError('No file was selected.');
            toast.error('No file was selected.');
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            const img = new Image();
            img.onload = () => {
                setImagePreview(reader.result);
                setProductDetails(prev => ({ ...prev, image: reader.result }));
                setShowPreviewModal(true);

                const validFormats = ['image/jpeg', 'image/png'];
                const isCurrentFormatCorrect = validFormats.includes(file.type);
                const isCurrentSizeCorrect = file.size <= 10 * 1024 * 1024;
                const isCurrentResolutionCorrect = img.width >= 1000 && img.height >= 1000;

                setIsFormatCorrect(isCurrentFormatCorrect);
                setIsSizeCorrect(isCurrentSizeCorrect);
                setIsResolutionCorrect(isCurrentResolutionCorrect);

                if (!isCurrentFormatCorrect || !isCurrentSizeCorrect || !isCurrentResolutionCorrect) {
                    let errorMessage = "Please ensure the image meets all the requirements:";
                    if (!isCurrentFormatCorrect) errorMessage += " Must be JPG or PNG format.";
                    if (!isCurrentSizeCorrect) errorMessage += " Must be less than or equal to 10MB.";
                    if (!isCurrentResolutionCorrect) errorMessage += " Must be at least 1000x1000 pixels.";
                    setUploadError(errorMessage);
                } else {
                    setUploadError(null);
                }
            };
            img.onerror = () => {
                setUploadError('There was an error processing your image.');
                setImagePreview(null);
                setShowPreviewModal(false);
                toast.error('There was an error processing your image.');
            };
            img.src = reader.result;
        };
        reader.onerror = () => {
            setUploadError('Failed to read the image file.');
            setImagePreview(null);
            setProductDetails(prev => ({ ...prev, image: null }));
            setShowPreviewModal(false);
        };
        reader.readAsDataURL(file);
    };

    const uploadImageToCloudinary = async (file) => {
        if (!file) {
            toast.error("No image file selected!");
            return null;
        }

        setUploading(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'lusuku-clou');
        formData.append('cloud_name', 'MM-iCT');
        formData.append('folder', 'lusuku_menus');

        try {
            const response = await axios.post(process.env.REACT_APP_CLOUDINARY_URL, formData);
            const secureUrl = response.data.secure_url;
            setProductDetails(prevProduct => ({
                ...prevProduct,
                image: secureUrl
            }));
            setUploading(false);
            setShowPreviewModal(false);
            return secureUrl;
        } catch (error) {
            console.error('Error uploading the image:', error);
            toast.error(`Failed to upload image: ${error.response?.data?.message || error.message}`);
            setUploading(false);
            setShowPreviewModal(false);
            return null;
        }
    };

    const handleOptionGroupSubmit = async (e) => {
        e.preventDefault();

        const optionGroupData = {
            name: optionGroupName,
            restaurantProductId: productId,
            options: selectedOptions.map(option => ({
                value: option.value,
                mandatory: option.mandatory
            })),
            minOptions,
            maxOptions,
            owner: restaurantId
        };

        try {
            await dispatch(createRestaurantOptionGroup(optionGroupData));
            toast.success("Option group created successfully!");
            dispatch(fetchOptionGroupsRestaurant(restaurantId));
            setShowOptionGroupModal(false);
            setOptionGroupName('');
            setSelectedOptions([]);
            setMinOptions(0);
            setMaxOptions(0);
        } catch (error) {
            toast.error("Failed to create option group: " + (error.response?.data?.message || error.message));
        }
    };

    const handleDeleteProduct = async () => {
        try {
            await dispatch(deleteRestaurantProduct(productId));
            toast.success('Product deleted successfully');
            history.push('/admin/rest-menu');
        } catch (error) {
            toast.error('Failed to delete the product. Please try again.');
        }
    };

    return (
        <Container fluid>
            <style>{customFontsCSS}</style>
            <Row className="justify-content-md-center">
                <Col md={10}>
                    <Card className="mt-3">
                        <Card.Body>
                            <Card.Title
                                style={{
                                    fontSize: '1.5rem',
                                    fontWeight: 500,
                                    marginBottom: '20px'
                                }}
                                as="h2"
                            >Edit Product
                            </Card.Title>
                            <Button
                                variant="danger"
                                style={{
                                    position: 'absolute',
                                    right: '20px',
                                    top: '20px',
                                }}
                                onClick={() => setShowDeleteModal(true)}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <FaTrash
                                        style={{
                                            fontSize: '1.5rem',
                                            color: 'white',
                                        }}
                                        aria-label="Delete Product"
                                    />
                                    <span style={{ marginLeft: '0.5rem', color: 'white' }}>Delete Product</span>
                                </div>
                            </Button>
                            <hr />
                            <div className="text-muted">
                                <div style={{ marginBottom: "0", fontSize: '10px' }}><strong>Product ID: </strong>{productDetails._id}</div>
                                <div style={{ marginBottom: "0", fontSize: '10px' }}><strong>Created at: </strong>{format(new Date(productDetails.createdAt), 'MMMM dd, yyyy h:mm a')}</div>
                                <div style={{ marginBottom: "0", fontSize: '10px' }}><strong>Updated at: </strong>{format(new Date(productDetails.updatedAt), 'MMMM dd, yyyy h:mm a')}</div>
                            </div>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-1 mt-2">
                                    <Form.Label>Product Image</Form.Label>
                                    <Row>
                                        <Col md={6}>
                                            <Card style={{ width: '100%' }}>
                                                <Card.Body className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '220px' }}>
                                                    {productDetails.image ? (
                                                        <img
                                                            src={productDetails.image}
                                                            alt="Product"
                                                            style={{ maxHeight: '220px', maxWidth: '100%' }}
                                                        />
                                                    ) : (
                                                        <Card.Text>
                                                            <Button
                                                                variant="primary"
                                                                as="label"
                                                                className="d-flex align-items-center justify-content-center"
                                                                style={{ color: '#ffff' }}
                                                            >
                                                                <MdAddAPhoto size={24} className="me-2" />
                                                                Add Image
                                                                <Form.Control
                                                                    type="file"
                                                                    hidden
                                                                    accept=".jpg, .png, .jpeg"
                                                                    onChange={handleImageChange}
                                                                    id="file-input"
                                                                />
                                                            </Button>
                                                        </Card.Text>
                                                    )}
                                                </Card.Body>
                                            </Card>
                                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '45px' }}>
                                                <Button
                                                    variant="link"
                                                    as="label"
                                                    style={{
                                                        color: 'green',
                                                        textDecoration: 'none',
                                                        textTransform: 'capitalize',
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    Replace Image
                                                    <Form.Control
                                                        type="file"
                                                        hidden
                                                        accept=".jpg, .png, .jpeg"
                                                        onChange={handleImageChange}
                                                        id="file-input"
                                                    />
                                                </Button>
                                                <span style={{ borderLeft: '1px solid #ccc', height: '40px' }}></span>
                                                <Button
                                                    variant="link"
                                                    style={{
                                                        color: 'red',
                                                        textDecoration: 'none',
                                                        fontSize: '12px',
                                                    }}
                                                    onClick={() => setProductDetails({ ...productDetails, image: null })}
                                                >
                                                    Delete Image
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <Card.Text as="div" className="text-muted" style={{ fontSize: '12px' }}>
                                                <p style={{ marginBottom: "0" }}><strong>File requirements: </strong></p>
                                                <p style={{ fontStyle: "italic", fontSize: "11px" }}>
                                                    <FaInfoCircle style={{ marginRight: '5px' }} />
                                                    JPG or PNG up to 10 MB. Minimum size of 1000x1000 pixels.
                                                </p>
                                                <p style={{ fontSize: '12px' }}>
                                                    <FaCameraRetro style={{ marginRight: '5px' }} />
                                                    Ensure your product is in focus and make all the ingredients visible.
                                                </p>
                                                <p style={{ fontSize: '12px' }}>
                                                    <FaSquareFull style={{ marginRight: '5px' }} />
                                                    Show the full product and place it at the centre of the frame.
                                                </p>
                                                <p style={{ fontSize: '12px' }}>
                                                    <FaSpellCheck style={{ marginRight: '5px' }} />
                                                    Remove distractions and avoid using text.
                                                </p>
                                                <p style={{ fontSize: '12px' }}>
                                                    <FaStar style={{ marginRight: '5px' }} />
                                                    Use a clear background and find bright lighting.
                                                </p>
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter product name"
                                        name="name"
                                        value={productDetails.name || ''}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <div style={{ height: '370px' }}>
                                        <ReactQuill
                                            value={productDetails?.description || ''}
                                            onChange={handleDescriptionChange}
                                            modules={modules}
                                            formats={formats}
                                            style={{ height: '300px', marginBottom: '70px' }}
                                            onPaste={handlePaste}
                                        // defaultValue="<p><span class='ql-size-small'>Start typing here...</span></p>"
                                        />
                                    </div>
                                </Form.Group>

                                <hr style={{ margin: '28px 0' }} />

                                <Form.Group className="mb-3">
                                    <Form.Label>Section</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="sectionId"
                                        value={productDetails?.sectionId || ''}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">Choose...</option>
                                        {sections.map(section => (
                                            <option key={section._id} value={section._id}>{section.title}</option>
                                        ))}
                                    </Form.Control>
                                    {formErrors.section && <Alert variant="danger">{formErrors.section}</Alert>}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Restaurant</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="restaurantId"
                                        value={productDetails.restaurantId || ''}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">Choose...</option>
                                        {restaurants.map(restaurant => (
                                            <option key={restaurant._id} value={restaurant._id}>{restaurant.name}</option>
                                        ))}
                                    </Form.Control>
                                    {formErrors.restaurant && <Alert variant="danger">{formErrors.restaurant}</Alert>}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter product price"
                                        name="price"
                                        value={productDetails.price || 0}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Count In Stock</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter count in stock"
                                        name="countInStock"
                                        value={productDetails.countInStock || 1}
                                        onChange={handleInputChange}
                                        min="1"
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Option Groups</Form.Label>
                                    <Select
                                        isMulti
                                        options={optionGroups.map(optionGroup => ({
                                            value: optionGroup._id,
                                            label: `${optionGroup.name} (Min: ${optionGroup.minOptions}, Max: ${optionGroup.maxOptions})`
                                        }))}
                                        value={selectedOptionGroups}
                                        onChange={setSelectedOptionGroups}
                                        placeholder="Select option groups..."
                                    />
                                    <Button variant="link" onClick={() => setShowOptionGroupModal(true)}>
                                        Create New Option Group
                                    </Button>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={productDetails.enabled}
                                                onChange={e => setProductDetails({ ...productDetails, enabled: e.target.checked })}
                                                name="enabled"
                                                color="secondary"
                                                disabled={submitting}
                                            />
                                        }
                                        label={productDetails.enabled ? "Activate Product For Customers" : "Deactivate Product For Customers"}
                                        classes={{ label: classes.label }}
                                    />
                                </Form.Group>

                                <Button
                                    variant="secondary"
                                    style={{
                                        marginRight: '10px',
                                        color: submitting ? '#6c757d' : 'white',
                                    }}
                                    onClick={() => history.goBack()}
                                    disabled={submitting}
                                >
                                    Discard Changes
                                </Button>

                                <Button
                                    variant="primary"
                                    type="submit"
                                    style={{
                                        color: submitting ? '#6c757d' : 'white',
                                    }}
                                    disabled={submitting}
                                >
                                    {submitting ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={showPreviewModal} onHide={() => setShowPreviewModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add product image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <img src={imagePreview} alt="Preview" style={{ width: '100%', maxWidth: '500px' }} />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <hr />
                        <Form.Text className="text-muted">
                            <strong>The image must:</strong>
                            <ul style={{ fontSize: '12px' }}>
                                <li>
                                    Be JPG or PNG format
                                    {isFormatCorrect ? <span className="text-success"> ✔</span> : <span className="text-danger"> ✘</span>}
                                </li>
                                <li>
                                    Be less than or equal to 10MB
                                    {isSizeCorrect ? <span className="text-success"> ✔</span> : <span className="text-danger"> ✘</span>}
                                </li>
                                <li>
                                    Be at least 1000x1000 pixels
                                    {isResolutionCorrect ? <span className="text-success"> ✔</span> : <span className="text-danger"> ✘</span>}
                                </li>
                            </ul>
                        </Form.Text>
                    </div>
                    {uploadError && <Alert fontSize="12px" variant="danger">{uploadError}</Alert>}
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                    <Button variant="danger" onClick={() => setShowPreviewModal(false)}>Cancel</Button>
                    {uploadError ? (
                        <Button
                            variant="warning"
                            onClick={() => document.getElementById('file-input').click()}
                            style={{ color: 'white', backgroundColor: '#FFA500' }}
                        >
                            Try Another Image
                        </Button>
                    ) : (
                        <Button
                            variant="primary"
                            onClick={() => setShowPreviewModal(false)}
                            style={{ color: 'white', backgroundColor: '#007BFF' }}
                        >
                            Save
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

            <Modal show={showOptionGroupModal} onHide={() => setShowOptionGroupModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Option Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleOptionGroupSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Option Group Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter option group name"
                                value={optionGroupName}
                                onChange={e => setOptionGroupName(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Options</Form.Label>
                            <Select
                                isMulti
                                options={options.map(option => ({
                                    value: option._id,
                                    label: option.name,
                                }))}
                                value={selectedOptions}
                                onChange={setSelectedOptions}
                                placeholder="Select options..."
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Min Options</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter minimum options"
                                value={minOptions}
                                onChange={e => setMinOptions(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Max Options</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter maximum options"
                                value={maxOptions}
                                onChange={e => setMaxOptions(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Button variant="secondary" onClick={() => setShowOptionGroupModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" className="ms-2">
                            Create
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this product? This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteProduct}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default EditMenuProductForm;
