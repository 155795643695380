import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useUpdateListing = () => {
  const [updateListingTimestamp, setUpdateListingTimestamp] = useState(
    Date.now()
  );
  const lastUpdateTimestamp = useSelector(
    (state) => state.listings.lastUpdateTimestamp
  );

  useEffect(() => {
    if (lastUpdateTimestamp) {
      setUpdateListingTimestamp(lastUpdateTimestamp);
    }
  }, [lastUpdateTimestamp]);

  return updateListingTimestamp;
};

