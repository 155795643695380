import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRestaurantVendorOrders } from '../../actions/restaurantVendorOrdersActions';
import { useHistory } from 'react-router-dom';
import {
    Container,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    makeStyles,
    TablePagination,
    Button,
    Chip,
    Box,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { getUserFromLocalStorage } from '../../utilities/user.js';
import NoOrdersIcon from '../../assets/img/NoOrdersIcon.png'; // Ensure you have a suitable SVG or image

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        border: `1px solid ${theme.palette.divider}`,
    },
    container: {
        marginTop: theme.spacing(4),
        padding: theme.spacing(2),
        backgroundColor: '#f5f5f5',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    error: {
        color: theme.palette.error.main,
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    },
    tableHead: {
        backgroundColor: theme.palette.primary.main,
    },
    tableHeadCell: {
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    pagination: {
        marginTop: theme.spacing(2),
    },
    chipProcessing: {
        backgroundColor: '#ff9800',
        color: '#fff',
    },
    chipAssigned: {
        backgroundColor: '#3f51b5',
        color: '#fff',
    },
    chipDispatched: {
        backgroundColor: '#2196f3',
        color: '#fff',
    },
    chipDelivered: {
        backgroundColor: '#4caf50',
        color: '#fff',
    },
    chipRejected: {
        backgroundColor: '#f44336',
        color: '#fff',
    },
    noOrdersContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(8),
        textAlign: 'center',
    },
    noOrdersIcon: {
        width: '180px',
        height: '180px',
        marginBottom: theme.spacing(3),
        borderRadius: '10%',
    },
}));

const RestaurantOrderTable = () => {
    const classes = useStyles();
    const [vendorId, setVendorId] = useState(null);
    const [fetchComplete, setFetchComplete] = useState(false);
    const dispatch = useDispatch();
    const { orders, loading, error, currentPage, numberOfPages } = useSelector((state) => state.restaurantVendorOrders);
    const history = useHistory();

    const user = getUserFromLocalStorage();

    useEffect(() => {
        if (user) {
            setVendorId(user.result._id); // Ensure this is the _id of the owner
        }
    }, [user]);

    const fetchData = async () => {
        if (vendorId) {
            await dispatch(fetchRestaurantVendorOrders(vendorId, currentPage));
            setFetchComplete(true);
        }
    };

    useEffect(() => {
        fetchData();
    }, [dispatch, vendorId, currentPage]);

    const getChipClass = (status) => {
        switch (status) {
            case 'PROCESSING':
                return classes.chipProcessing;
            case 'ASSIGNED':
                return classes.chipAssigned;
            case 'DISPATCHED':
                return classes.chipDispatched;
            case 'DELIVERED':
                return classes.chipDelivered;
            case 'REJECTED':
                return classes.chipRejected;
            default:
                return '';
        }
    };

    if (loading && !fetchComplete) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <Alert severity="error" className={classes.error}>Error: {error}</Alert>
            </div>
        );
    }

    return (
        <Container className={classes.container}>
            <Typography variant="h4" gutterBottom>{user?.result?.businessName} Orders</Typography>
            {fetchComplete && orders.length === 0 ? (
                <Box className={classes.noOrdersContainer}>
                    <img src={NoOrdersIcon} alt="No orders" className={classes.noOrdersIcon} />
                    <Typography variant="h6" color="textSecondary">
                        No orders found
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        You have not received any orders yet!
                    </Typography>
                </Box>
            ) : (
                <>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell className={classes.tableHeadCell}>Order ID</TableCell>
                                    <TableCell className={classes.tableHeadCell}>Total Price</TableCell>
                                    <TableCell className={classes.tableHeadCell} align="center">Payment Method</TableCell>
                                    <TableCell className={classes.tableHeadCell}>Delivery Status</TableCell>
                                    <TableCell className={classes.tableHeadCell}>Order Date</TableCell>
                                    <TableCell className={classes.tableHeadCell}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.map((order) => (
                                    <TableRow key={order._id} className={classes.tableRow}>
                                        <TableCell component="th" scope="row">{order._id}</TableCell>
                                        <TableCell>{order.totalPrice.toLocaleString()}</TableCell>
                                        <TableCell align="center">{order.paymentMethod}</TableCell>
                                        <TableCell>
                                            <Chip label={order.delivery_status} className={getChipClass(order.delivery_status)} />
                                        </TableCell>
                                        <TableCell>{new Date(order.createdAt).toLocaleString()}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                style={{ color: '#fff', backgroundColor: '#003547' }}
                                                size="small"
                                                onClick={() => history.push(`/admin/restVenOrderDetails/${order._id}`)}
                                            >
                                                Manage Order
                                                <i style={{ marginLeft: '5px' }} className="fas fa-edit"></i>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={numberOfPages * 10}
                        page={currentPage - 1}
                        onPageChange={(event, newPage) => dispatch(fetchRestaurantVendorOrders(vendorId, newPage + 1))}
                        rowsPerPage={10}
                        rowsPerPageOptions={[10]}
                        className={classes.pagination}
                    />
                </>
            )}
        </Container>
    );
};

export default RestaurantOrderTable;
