import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  cancelDeletionMobileUser: {
    backgroundColor: "#003d66",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#002333",
      color: "#ffffff",
    },
  },

  deleteMobileUserButton: {
    backgroundColor: "#BD2A2E",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#F25764",
      color: "#ffffff",
    },
  },
  searchClient: {
    display: "flex",
    justifyContent: "right",
    alignItems: "right",
    margin: "5px",
    paddingRight: "10px",
  },
  searchClientInput: {
    boxShadow: "0px 0px 5px #ccc",
    marginLeft: "10px",
    width: "200px",
    height: "38px",
    borderTopLeftRadius: "25px",
    borderBottomLeftRadius: "25px",
    border: "1px solid #ccc",
    paddingLeft: "10px",
    fontSize: "14px",
    color: "#333",
    fontFamily: "Roboto, sans-serif",
    fontStyle: "italic",
    outline: "none",
    "&:focus": {
      border: "1px solid #cccc",
    },
  },
});
