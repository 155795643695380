import { toast } from 'react-toastify';
import { decryptData } from './encryption';
import { getTokenFromLocalStorage, isTokenExpired } from './tokenUtils';

export const getUserFromLocalStorage = () => {
    // console.log("Encrypted user:", localStorage.getItem("profile"));
    // console.log("Token:", localStorage.getItem("token"));
    let user = null;
    try {
        const encryptedUser = localStorage.getItem("profile");
        const token = getTokenFromLocalStorage();

        // Check if both user data and token exist
        if (!encryptedUser || !token) {
            // console.log("No user data or token found!");
            return null;
        }

        // Check token expiration
        if (isTokenExpired(token)) {
            throw new Error("Token is expired");
        }


        // Attempt to decrypt user data
        user = decryptData(encryptedUser);

        if (!user) {
            throw new Error("Failed to decrypt user data");
        }

        // user = encryptedUser ? decryptData(encryptedUser) : null;
        // if (!token || isTokenExpired(token)) {
        //     throw new Error("Token is expired or not available");
        // }
    } catch (error) {
        console.error("Failed to decrypt and parse user profile", error.message);

        localStorage.removeItem("profile");
        localStorage.removeItem("token");

        // toast.error("Failed to load user data. Please log in again.");
        // Show appropriate error message
        if (error.message === "Token is expired") {
            toast.warn("Session iis Expired!");
        } else {
            toast.error("Failed to load user data. Please log in again.");
        }

        // Redirect to login page if not already there
        if (window.location.pathname !== "/auth/login-page") {
            window.location.href = "/auth/login-page";
        }

        // console.log(error, "This is the error");
        // if (window.location.pathname !== "/auth/login-page") {
        //     window.location.href = "/auth/login-page";
        // }
        return null;
    }
    return user;
};
