import React, { useEffect, useState } from "react";
import {
  Divider,
  Typography,
  Paper,
  FormControlLabel,
  Switch,
  CircularProgress,
  Chip,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

import moment from "moment";
import {
  Button,
  ButtonToolbar,
  Card,
  Col,
  Container,
  Form,
  Image,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
// import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

import useStyles from "./orderDetailsStyles.js";
import Message from "../../../../views/Components/Message.js";
import DotLoader from "../../../../views/Components/DotLoader";

import { io } from "socket.io-client";

import {
  assignOrderToDeliveryAgent,
  // getOrderDetailsToProcess,
  getVendorOrderDetailsToProcess,
  markOrderAsPaid,
  updateDeliveryStatus,
} from "../../../../actions/webOrderActions.js";
import { useHistory } from "react-router-dom";
import { getListing } from "../../../../actions/listingsActions.js";
import { RECEIPT_URL } from "../../../../api/receiptUrl.js";
import { toast } from "react-toastify";
import { RESET_UPDATE_DELIVERY_STATUS_COMPLETE } from "constants/webOrderConstants.js";
import { getUserFromLocalStorage } from "utilities/user.js";
// import socketUrl from "../../../../constants/socketUrl";

const isDevelopment = process.env.NODE_ENV === "development";

const VendorOrderDetailsCard = ({ match }) => {
  const user = getUserFromLocalStorage();


  const [riders, setRiders] = useState([]);
  const [riderId, setRiderId] = useState("");
  const [receiptLoading, setReceiptLoading] = useState(false);
  const [deliveryStatus, setDeliveryStatus] = useState(null);
  const [loadingRiders, setLoadingRiders] = useState(true);
  const [itemId, setItemId] = useState("");
  const vendorOrderId = match.params.id;
  const history = useHistory();

  const mobileOrderDeliver = useSelector((state) => state.mobileOrderDeliver);
  const { loading: loadingDeliver, success: successDeliver } =
    mobileOrderDeliver;

  const { vendorOrderDetails, loading, isUpdateDeliveryStatusComplete } =
    useSelector((state) => state.orderDetails);
  // console.log(JSON.stringify(vendorOrderDetails, null, 2));

  // const { vendorOrderDetails, order, loading } = orderDetails;

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVendorOrderDetailsToProcess(vendorOrderId, user.result._id)); // fetch order details once initially

    if (isUpdateDeliveryStatusComplete) {
      dispatch(getVendorOrderDetailsToProcess(vendorOrderId, user.result._id));
      dispatch({ type: RESET_UPDATE_DELIVERY_STATUS_COMPLETE });
    }
  }, [isUpdateDeliveryStatusComplete]);

  // const refreshOrderDetails = () => {
  //   dispatch(getVendorOrderDetailsToProcess(vendorOrderId, user.result._id));
  // };

  // GET RIDERS USING SOCKET.IO

  // const socketUrl = "https://9d33-102-222-71-235.ngrok.io";
  const socketUrl = process.env.REACT_APP_SERVER_URL; //Prod & Dev nGROK
  // const socketUrl = "http://localhost:8001"; //Dev

  useEffect(() => {
    const socket = io(
      socketUrl,
      {
        query: {
          user: user?.result?._id,
        },
      },
      {
        transports: ["websocket"],
      }
    );

    socket.on("connect", () => {
      if (isDevelopment) {
        console.log("Webclient Connected");
      }
    });
    socket.on("lusukuRiders", (socketData) => {
      setRiders(socketData);
      setLoadingRiders(false);
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  // Assign order to rider
  const assignOrderToRider = () => {
    const delivery_status = "ASSIGNED";
    if (!itemId) {
      toast.warn("Please select an item.");
    } else {
      dispatch(
        assignOrderToDeliveryAgent(
          vendorOrderDetails._id,
          riderId,
          itemId,
          delivery_status
        )
      );
      setItemId(""); // Reset itemId
    }
  };

  //Dispatch to update order status to DISPATCHED
  const handleDispatchOrder = () => {
    const delivery_status = "DISPATCHED";
    setDeliveryStatus(delivery_status);
    if (!itemId) {
      toast.warn("You have not selected any order item!");
    } else {
      dispatch(
        updateDeliveryStatus(vendorOrderDetails._id, itemId, delivery_status)
      );
      setItemId(""); // Reset itemId
    }
  };

  //Dispatch to update order status to DELIVERED
  const handleDeliverOrder = () => {
    const delivery_status = "DELIVERED";
    setDeliveryStatus(delivery_status);
    if (!itemId) {
      toast.warn("You have not selected any order item!");
    } else {
      dispatch(
        updateDeliveryStatus(vendorOrderDetails._id, itemId, delivery_status)
      );
      setItemId(""); // Reset itemId
    }
  };

  //Dispatch to update order status to REJECTED || Only Lusuku Staff can cancel an order
  const handleCancelOrder = () => {
    if (user?.result?.userType !== "LUSUKU STAFF") {
      toast.warn("You are not authorised to cancel orders!");
      return;
    }
    const delivery_status = "REJECTED";
    setDeliveryStatus(delivery_status);
    if (!itemId) {
      toast.warn("You have not selected any order item!");
    } else {
      dispatch(
        updateDeliveryStatus(vendorOrderDetails._id, itemId, delivery_status)
      );
    }
  };

  // Mark order items as Paid || Only Lusuku Staff can mark cart items as paid
  const handleMarkAsPaid = () => {
    if (user?.result?.userType !== "LUSUKU STAFF") {
      toast.warn("🚫 You are not authorized to Mark order as paid!");
      return;
    } else {
      dispatch(markOrderAsPaid(vendorOrderDetails._id));
    }
  };

  const generateReceiptByAgent = () => {
    setReceiptLoading(true);
    const url = `${RECEIPT_URL}/${user?.result?._id}?customerName=${vendorOrderDetails?.client_id?.name
      }&customerNumber=${vendorOrderDetails?.shippingAddress?.customerNumber
      }&email=${vendorOrderDetails?.client_id?.email}&shippingAddress=${vendorOrderDetails?.shippingAddress?.locationName?.vicinity
      }&shippingCost=${vendorOrderDetails?.shippingCost_id?.cost}
&orderNumber=${vendorOrderDetails?._id}&totalAmount=${vendorOrderDetails?.totalPrice
      }&taxAmount=${vendorOrderDetails?.taxPrice}&itemsInCart=${vendorOrderDetails?.orderItems?.length
      }&orderedItems=${JSON.stringify(
        vendorOrderDetails?.orderItems.map((item) => ({
          ...item,
          name: item.name.replace(/(\r\n|\n|\r)/gm, " "),
        }))
      )}&Qty=${vendorOrderDetails?.orderItems.map(
        (item) => item.qty
      )}&itemPx=${vendorOrderDetails?.orderItems.map(
        (item) => item.price
      )}&cartPx=${vendorOrderDetails.itemsPrice}&updatedAt=${moment(
        vendorOrderDetails?.updatedAt
      )
        .utcOffset("+03:00")
        .format("lll")}&paymentStatus=${vendorOrderDetails?.isPaid ? "Paid" : "Not Paid"
      }&paidDate=${moment(vendorOrderDetails?.paidAt)
        .utcOffset("+03:00")
        .format("lll")}`;

    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        const file = new Blob([arrayBuffer], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = `Lusuku Receipt_${vendorOrderDetails?._id}.pdf`;
        link.click();
        setReceiptLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the PDF:", error);
        setReceiptLoading(false);
      });
  };

  return (
    <>
      <Container fluid>
        <div className="section-image" data-image="../../assets/img/bg5.jpg">
          {loading ? (
            <Spinner
              style={{
                marginTop: "70px",
                top: "50%",
                left: "50%",
                position: "fixed",
              }}
              animation="grow"
              size="lg"
              variant="danger"
            />
          ) : (
            <Paper
              style={{ padding: "20px", borderRadius: "15px" }}
              elevation={6}
            >
              <div className={classes.card}>
                <div className={classes.section}>
                  <Typography variant="h5" elevation={4}>
                    <strong style={{ color: "#195400" }}>
                      {/* {mobileOrder?.clientName}Client Name */}
                      {vendorOrderDetails?.client_id?.name}
                    </strong>
                  </Typography>
                  {/* <Typography gutterBottom variant="h6" color="textSecondary" component="h2">{mobileOrder?.tags.map((tag) => `#${tag} `)}</Typography> */}
                  <Divider style={{ margin: "20px 0" }} />
                  <Typography variant="body2">
                    <strong>Order No.:</strong>{" "}
                    {vendorOrderDetails?.order_number
                      ? vendorOrderDetails?.order_number
                      : vendorOrderDetails?._id}
                  </Typography>

                  <Typography variant="body2">
                    <strong>Cart Total: </strong>

                    {vendorOrderDetails?.itemsPrice
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Items in Cart:</strong>
                    {""} {vendorOrderDetails?.orderItems?.length}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Agent Name:</strong>{" "}
                    {vendorOrderDetails?.agent_id?.name ||
                      vendorOrderDetails?.agent_id}
                  </Typography>

                  <Typography variant="body2">
                    <strong> Customer Number</strong>{" "}
                    {vendorOrderDetails?.shippingAddress?.customerNumber
                      ? vendorOrderDetails?.shippingAddress?.customerNumber
                      : "Not Provided"}
                  </Typography>
                  <Typography variant="body2">
                    <strong> Payment Method:</strong>{" "}
                    {vendorOrderDetails?.paymentMethod}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Total Order Cost:</strong> ~ {""}
                    <span style={{ color: "#D29" }}>
                      {vendorOrderDetails?.totalPrice
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </Typography>
                  <Divider style={{ margin: "20px 0" }} />
                  {vendorOrderDetails?.shippingAddress?.selectedVillage
                    ?.village ? (
                    <Typography variant="body2">
                      <strong>Delivery Zone:</strong>{" "}
                      {
                        vendorOrderDetails?.shippingAddress?.selectedVillage
                          ?.village
                      }
                    </Typography>
                  ) : (
                    <Typography variant="body2">
                      <strong>Delivery Zone:</strong>{" "}
                      {
                        vendorOrderDetails?.shippingAddress?.locationName
                          ?.vicinity
                      }
                      {vendorOrderDetails?.shippingAddress?.description}
                    </Typography>
                  )}

                  {vendorOrderDetails?.shippingAddress?.address ? (
                    <Typography variant="body2">
                      <strong>Address:</strong> <br />
                      {vendorOrderDetails?.shippingAddress?.address}
                      {vendorOrderDetails?.shippingAddress?.address?.address}
                    </Typography>
                  ) : (
                    <Typography variant="body2">
                      <strong>Address:</strong>{" "}
                      {vendorOrderDetails?.shippingAddress?.locationName?.name}
                    </Typography>
                  )}

                  <Typography variant="body2">
                    <strong>Shipping Fees:</strong>{" "}
                    {vendorOrderDetails?.shippingAddress?.selectedVillage
                      ?.shippingCost
                      ? vendorOrderDetails?.shippingAddress?.selectedVillage
                        ?.shippingCost
                      : vendorOrderDetails?.shippingCost_id?.cost
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>

                  <Typography variant="body2">
                    <strong>Delivery Schedule:</strong> ~ {""}
                    <span style={{ color: "#9FC131" }}>
                      {vendorOrderDetails?.deliverySchedule
                        ? vendorOrderDetails?.deliverySchedule
                        : "Call in"}
                    </span>
                  </Typography>
                  <Typography variant="body2">
                    <strong>LatLng:</strong> ~ {""}
                    <span style={{ color: "#004152" }}>
                      <br />
                      {vendorOrderDetails?.shippingAddress?.latLng?.lat} ||{" "}
                      {vendorOrderDetails?.shippingAddress?.latLng?.lng}
                    </span>
                  </Typography>
                  <Divider style={{ margin: "20px 0" }} />
                  <Typography variant="body2">
                    <strong>Time placed</strong>{" "}
                    {moment(vendorOrderDetails?.createdAt).fromNow()}
                  </Typography>
                  {!vendorOrderDetails?.deliveredAt ? (
                    <Typography variant="body2">
                      <strong>Status:</strong>{" "}
                      {vendorOrderDetails?.delivery_status}
                    </Typography>
                  ) : (
                    <Typography variant="body2">
                      <strong>Delivered:</strong>
                      {""} {moment(vendorOrderDetails?.deliveredAt).fromNow()}
                    </Typography>
                  )}
                  {vendorOrderDetails?.riderId && (
                    <Typography variant="body2">
                      <strong>DA:</strong>{" "}
                      {vendorOrderDetails?.riderId?.firstName +
                        " " +
                        vendorOrderDetails?.riderId?.lastName}
                    </Typography>
                  )}

                  <Divider style={{ margin: "20px 0" }} />
                  {loadingDeliver && <DotLoader />}
                  <Divider style={{ margin: "20px 0" }} />

                  {/* Select and Assign Order to a Rider Online || Only when all items have been assigned*/}
                  {!vendorOrderDetails?.delivery_status === "ASSIGNED" ||
                    (vendorOrderDetails?.orderItems?.some(
                      (item) => item.delivery_status === "PROCESSING"
                    ) && (
                        <div>
                          <Typography variant="h6">
                            <strong>Assign Order</strong>
                          </Typography>
                          {/* <Divider style={{ margin: "20px 0" }} /> */}

                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Form.Control
                              as="select"
                              onChange={(e) => {
                                setRiderId(e.target.value);
                              }}
                            >
                              <option value="">Select Rider</option>
                              {loadingRiders ? (
                                <option value="" disabled>
                                  Searching for riders...
                                </option>
                              ) : (
                                riders?.map((rider) => (
                                  <option key={rider._id} value={rider._id}>
                                    {rider.firstName + " " + rider.lastName} ||{" "}
                                    {""}
                                    {rider.onlineStatus}
                                  </option>
                                ))
                              )}
                            </Form.Control>
                            {loadingRiders && (
                              <div
                                style={{
                                  marginLeft: "2px",
                                }}
                              >
                                <CircularProgress size={18} />
                              </div>
                            )}
                          </div>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "5px" }}
                            onClick={assignOrderToRider}
                            className={`${classes.disabledButton} ${classes.notAllowedCursor}`}
                            data-disabled={!riderId || loadingRiders}
                          >
                            Assign Order to Rider
                          </Button>
                        </div>
                      ))}

                  {/*  */}
                  <Divider style={{ margin: "20px 0" }} />

                  {/* DISPATCH ORDER */}
                  {/* Only show the dispatch order button when all or some of the orders items are Assigned. Don't show if all order items are cancelled! */}
                  {vendorOrderDetails?.orderItems?.some(
                    (item) => item.delivery_status === "ASSIGNED"
                  ) &&
                    vendorOrderDetails?.orderItems?.every((item) =>
                      ["ASSIGNED", "CANCELLED"].includes(item.delivery_status)
                    ) && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDispatchOrder}
                      >
                        Dispatch Order
                      </Button>
                    )}

                  {/* DELIVER ORDER */}
                  {/* If there are some items in the ordered items with delivery_status as "DISPATCHED", show the button to mark order item as delivered! */}
                  {vendorOrderDetails?.orderItems?.some(
                    (item) => item.delivery_status === "DISPATCHED"
                  ) && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDeliverOrder}
                      >
                        {" "}
                        Mark Order as Delivered
                      </Button>
                    )}

                  <Divider style={{ margin: "20px 0" }} />

                  {/* CANCEL ORDER */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {/* {vendorOrderDetails?.delivery_status === "PROCESSING" ? ( */}
                    {vendorOrderDetails?.orderItems?.every(
                      (item) => item.delivery_status === "DELIVERED"
                    ) ? null : vendorOrderDetails?.delivery_status ===
                      "PROCESSING" ? (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Button
                          className={classes.buttonCancel}
                          onClick={handleCancelOrder}
                        // disabled={order?.delivery_status === "REJECTED"}
                        >
                          <CancelIcon
                            style={{ marginRight: "5px", fontSize: "16px" }}
                          />
                          Cancel Order
                        </Button>
                      </div>
                    ) : (
                      vendorOrderDetails?.delivery_status === "REJECTED" && (
                        <Typography variant="body2">Order Cancelled</Typography>
                      )
                    )}
                    <FiberManualRecordIcon
                      disabled
                      className={
                        vendorOrderDetails?.delivery_status === "REJECTED"
                          ? classes.red
                          : vendorOrderDetails?.delivery_status === "DISPATCHED"
                            ? classes.dispatchGrey
                            : vendorOrderDetails?.delivery_status === "DELIVERED"
                              ? classes.deliveredColor
                              : classes.processingOrange
                      }
                    />
                  </div>
                  {/* Generate Receipt */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                    }}
                  >
                    {/* Mark as Paid using as switch */}
                    <FormControlLabel
                      control={
                        <Switch
                          checked={vendorOrderDetails?.isPaid || false}
                          onChange={handleMarkAsPaid}
                          color="primary"
                        />
                      }
                      label={
                        !vendorOrderDetails?.isPaid ? "Mark as Paid" : "Paid"
                      }
                      disabled={
                        vendorOrderDetails?.isPaid ||
                        vendorOrderDetails?.delivery_status === "REJECTED"
                      }
                    />
                    {vendorOrderDetails?.isPaid && (
                      <Typography
                        variant="caption"
                        display="block"
                        style={{ color: "green" }}
                      >
                        <strong>Pay date:</strong> {""}{" "}
                        {/* {moment(order?.paidAt).zone("+03:00").format("lll")} */}
                        {moment(vendorOrderDetails?.paidAt)
                          .utcOffset("+03:00")
                          .format("lll")}
                      </Typography>
                    )}
                    <Button
                      variant={receiptLoading ? "warning" : "primary"}
                      size="sm"
                      style={{
                        marginTop: "5px",
                        color: !vendorOrderDetails?.isPaid ? "grey" : "#fff",
                      }}
                      onClick={generateReceiptByAgent}
                      // disabled={!vendorOrderDetails?.isPaid || receiptLoading}
                      disabled
                    >
                      {receiptLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="ml-2">Generating receipt...</span>
                        </>
                      ) : (
                        "Generate Receipt"
                      )}
                    </Button>
                  </div>
                </div>
                <Card className={classes.cardSection} elevation={8}>
                  <Container fluid>
                    <Row>
                      <Col md="12">
                        <Card.Body className="table-full-width">
                          <Table>
                            <thead
                              style={{
                                backgroundColor: "#03A",
                                color: "white",
                              }}
                            >
                              <tr className="info">
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-center"
                                >
                                  <strong>#</strong>
                                </th>
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-left"
                                >
                                  <strong>Item Name</strong>
                                </th>
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-center"
                                >
                                  <strong>Qty</strong>
                                </th>
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-center"
                                >
                                  <strong>Unit Px</strong>
                                </th>
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-center"
                                >
                                  <strong>Image</strong>
                                </th>
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-center"
                                >
                                  <strong>Vendor</strong>
                                </th>
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-center"
                                >
                                  <strong>Selector</strong>
                                </th>
                                {/* Delivery status of individual order Items */}
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-center"
                                >
                                  <strong>Delivery Status</strong>
                                </th>
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-center"
                                >
                                  <strong>Details</strong>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {vendorOrderDetails?.orderItems?.map(
                                (item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td
                                        className="text-left"
                                        style={{
                                          color: "white",
                                          fontSize: "11px",
                                        }}
                                      >
                                        {index + 1}.
                                      </td>
                                      <td
                                        className="text-left"
                                        style={{
                                          color: "#5096d1",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {item.name}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{
                                          color: "#fff",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {item.qty}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ color: "white" }}
                                      >
                                        {item.price}
                                      </td>
                                      <td className="text-center">
                                        <Image
                                          src={item.imageUrl}
                                          resizemode="contain"
                                          style={{
                                            width: 60,
                                            height: 55,
                                            borderRadius: "15%",
                                          }}
                                        />
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{
                                          color: "white",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {item?._id?.creator?.businessName}
                                      </td>
                                      {/* Radio Selection for order Assignment */}
                                      <td className="text-center">
                                        <input
                                          type="radio"
                                          value={item?._id?._id}
                                          name="itemSelection"
                                          onChange={(e) => {
                                            setItemId(e.target.value);
                                          }}
                                          disabled={
                                            item?.delivery_status ===
                                            "DELIVERED"
                                          }
                                          style={{
                                            cursor:
                                              item?.delivery_status ===
                                                "DELIVERED"
                                                ? "not-allowed"
                                                : "pointer",
                                          }}
                                        />
                                      </td>
                                      {/* Delivery status of individual order Items */}
                                      <td className="text-center">
                                        {item.delivery_status ===
                                          "PROCESSING" && (
                                            <Chip
                                              label="Processing"
                                              color="default"
                                            />
                                          )}
                                        {item.delivery_status ===
                                          "ASSIGNED" && (
                                            <Chip
                                              label="Assigned"
                                              style={{
                                                backgroundColor: "#00CCC0",
                                                color: "#455559",
                                              }}
                                            />
                                          )}
                                        {item.delivery_status ===
                                          "DISPATCHED" && (
                                            <Chip
                                              label="Dispatched"
                                              style={{
                                                backgroundColor: "#F26800",
                                                color: "#ffff",
                                              }}
                                            />
                                          )}
                                        {item.delivery_status ===
                                          "DELIVERED" && (
                                            <Chip
                                              label="Delivered"
                                              style={{
                                                backgroundColor: "#84B026",
                                                color: "#ffff",
                                              }}
                                            />
                                          )}
                                        {item.delivery_status ===
                                          "REJECTED" && (
                                            <Chip
                                              label="Rejected"
                                              style={{
                                                backgroundColor: "#BD2A2E",
                                                color: "#ffff",
                                              }}
                                            />
                                          )}
                                      </td>
                                      {/* End Delivery status pill for each item */}
                                      <td className="text-center">
                                        <ButtonToolbar>
                                          {["right"].map((placement) => (
                                            <OverlayTrigger
                                              key={placement}
                                              placement={placement}
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-${placement}`}
                                                >
                                                  More info
                                                </Tooltip>
                                              }
                                            >
                                              <Button
                                                size="lg"
                                                variant="link"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  dispatch(
                                                    getListing(item?._id?._id)
                                                  );
                                                  history.push(
                                                    `/admin/listing-details/${item?._id?._id}`
                                                  );
                                                }}
                                              >
                                                <i className="fa fa-info-circle"></i>
                                              </Button>
                                            </OverlayTrigger>
                                          ))}
                                        </ButtonToolbar>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Container>

                  <Typography
                    className={classes.messageText}
                    variant="body1"
                    component="span"
                    style={{
                      color: "#fff",
                      fontSize: "13px",
                    }}
                  >
                    <strong style={{ color: "#10ABB4" }}>
                      <strong style={{ fontSize: "18px" }}>📩</strong> Delivery
                      Comments:{" "}
                    </strong>
                    {vendorOrderDetails?.message
                      ? vendorOrderDetails?.message
                      : "Non Shared"}
                  </Typography>
                  <Typography
                    // variant="body2"
                    className={classes.messageText}
                    component="div"
                    style={{
                      color: "#10ABB4",
                      fontSize: "13px",
                    }}
                  >
                    <strong>
                      <strong style={{ fontSize: "18px" }}>🚚📦</strong>{" "}
                      Delivery Status:{" "}
                    </strong>
                    {vendorOrderDetails?.delivery_status === "PROCESSING" ? (
                      <Message variant="info">Processing</Message>
                    ) : vendorOrderDetails?.delivery_status === "ASSIGNED" ? (
                      <Message variant="info">Assigned</Message>
                    ) : vendorOrderDetails?.delivery_status === "DISPATCHED" ? (
                      <Message variant="info">Dispatched</Message>
                    ) : vendorOrderDetails?.delivery_status === "DELIVERED" ? (
                      <Message variant="success" color="#010221">
                        Delivered
                      </Message>
                    ) : vendorOrderDetails?.delivery_status === "REJECTED" ? (
                      <Message variant="danger">Rejected</Message>
                    ) : (
                      <Message variant="danger">
                        Error while managing order!{" "}
                      </Message>
                    )}
                  </Typography>
                  <Typography
                    className={classes.messageText}
                    component="div"
                    style={{
                      color: "#10ABB4",
                      fontSize: "13px",
                    }}
                  >
                    <strong>
                      <strong style={{ fontSize: "19px" }}>💰</strong> Payment
                      Status:{" "}
                    </strong>
                    {vendorOrderDetails?.isPaid ? (
                      <Message variant="success" color="#042940">
                        Paid 💰💰🤑
                      </Message>
                    ) : (
                      <Message variant="danger">Not Paid</Message>
                    )}
                  </Typography>
                </Card>
                {/* </div> */}
              </div>
            </Paper>
          )}
        </div>
      </Container>
    </>
  );
};

export default VendorOrderDetailsCard;
