export const CREATE_RIDER = "CREATE_RIDER";
export const UPDATE_RIDER = "UPDATE_RIDER";
export const FETCH_RIDERS = "FETCH_RIDERS";
export const FETCH_RIDER = "FETCH_RIDER";
export const DELETE_RIDER = "DELETE_RIDER";
export const RESET_RIDER = "RESET_RIDER";
export const RIDER_FETCH_FAIL = "RIDER_FETCH_FAIL";
export const START_LOADING_RIDER = "START_LOADING_RIDER";
export const END_LOADING_RIDER = "END_LOADING_RIDER";

export const CREATE_RESTAURANT_RIDER = 'CREATE_RESTAURANT_RIDER';
export const FETCH_RESTAURANT_RIDERS = 'FETCH_RESTAURANT_RIDERS';
export const UPDATE_RESTAURANT_RIDER = 'UPDATE_RESTAURANT_RIDER';
export const DELETE_RESTAURANT_RIDER = 'DELETE_RESTAURANT_RIDER';
export const UPDATE_RESTAURANT_RIDER_STATUS = 'UPDATE_RESTAURANT_RIDER_STATUS';

export const FETCH_RETAILER_RIDERS = 'FETCH_RETAILER_RIDERS';
export const CREATE_RETAILER_RIDER = 'CREATE_RETAILER_RIDER';
export const UPDATE_RETAILER_RIDER = 'UPDATE_RETAILER_RIDER';
export const DELETE_RETAILER_RIDER = 'DELETE_RETAILER_RIDER';
export const UPDATE_RETAILER_RIDER_STATUS = 'UPDATE_RETAILER_RIDER_STATUS';

export const FETCH_ALL_RIDERS = 'FETCH_ALL_RIDERS';
export const UPDATE_RIDER_STATUS = 'UPDATE_RIDER_STATUS';