import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  Form,
  Spinner,
  Alert,
  ProgressBar,
} from "react-bootstrap";
import axios from "axios";
import { fetchAds, createAd, deleteAd } from "../../actions/advertsActions.js";
import { MdAddAPhoto, MdDelete } from "react-icons/md";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

// AdForm component
const AdForm = ({ ad, onSubmit, onHide }) => {
  const [formData, setFormData] = useState(
    ad || {
      title: "",
      description: "",
      imageUrl: "",
      order: 0,
      isActive: true,
    }
  );

  const [imagePreview, setImagePreview] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1, width: 100 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const [isCropping, setIsCropping] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setUploadError("No file was selected.");
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
      setShowCropModal(true);
    };
    reader.onerror = () => {
      setUploadError("Failed to read the image file.");
      setImagePreview(null);
    };
    reader.readAsDataURL(file);
  };

  const handleCropComplete = (crop, percentCrop) => {
    setCompletedCrop(crop);
  };

  const getCroppedImg = useCallback((image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error("Canvas is empty");
            return;
          }
          blob.name = "cropped.jpeg";
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  }, []);

  const uploadImageToCloudinary = async (blob) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("file", blob);
    formData.append("upload_preset", "lusuku-clou");
    formData.append("cloud_name", "MM-iCT");
    formData.append("folder", "Adverts");

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLOUDINARY_URL,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );
      const secureUrl = response.data.secure_url;
      setFormData((prevData) => ({
        ...prevData,
        imageUrl: secureUrl,
      }));
      setUploading(false);
    } catch (error) {
      console.error("Error uploading the image:", error);
      setUploadError(
        `Failed to upload image: ${
          error.response?.data?.message || error.message
        }`
      );
      setUploading(false);
    }
  };

  const handleCropSave = async () => {
    if (imagePreview && completedCrop) {
      setIsCropping(true);
      try {
        const image = document.getElementById("cropImage");
        const croppedImageBlob = await getCroppedImg(image, completedCrop);
        await uploadImageToCloudinary(croppedImageBlob);
        setShowCropModal(false);
      } catch (e) {
        console.error("Error cropping image:", e);
        setUploadError("Failed to crop and upload the image.");
      } finally {
        setIsCropping(false);
      }
    }
  };

  const handleRemoveImage = () => {
    setFormData((prevData) => ({
      ...prevData,
      imageUrl: "",
    }));
    setImagePreview(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Title</Form.Label>
        <Form.Control
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          name="description"
          value={formData.description}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Image</Form.Label>
        <div className="d-flex">
          <Card style={{ width: "18rem" }}>
            <Card.Body
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ minHeight: "200px" }}
            >
              {formData.imageUrl ? (
                <>
                  <img
                    src={formData.imageUrl}
                    alt="Advertisement"
                    style={{ maxHeight: "200px", maxWidth: "100%" }}
                  />
                  <div className="mt-2">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      as="label"
                      className="me-2"
                    >
                      Replace
                      <Form.Control
                        type="file"
                        hidden
                        accept=".jpg, .png, .jpeg"
                        onChange={handleImageChange}
                      />
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={handleRemoveImage}
                    >
                      <MdDelete /> Remove
                    </Button>
                  </div>
                </>
              ) : (
                <Button
                  variant="primary"
                  as="label"
                  className="d-flex align-items-center justify-content-center"
                  style={{ color: "#ffff" }}
                >
                  <MdAddAPhoto size={24} className="me-2" />
                  Add Image
                  <Form.Control
                    type="file"
                    hidden
                    accept=".jpg, .png, .jpeg"
                    onChange={handleImageChange}
                  />
                </Button>
              )}
            </Card.Body>
          </Card>
          <div className="ms-3">
            <Form.Text className="text-muted">
              <p style={{ marginBottom: "0" }}>
                <strong>File requirements: </strong>
              </p>
              <p style={{ fontStyle: "italic", fontSize: "11px" }}>
                JPG or PNG up to 10 MB. Will be cropped to a square.
              </p>
            </Form.Text>
          </div>
        </div>
        {uploadError && (
          <Alert variant="danger" style={{ marginTop: "10px" }}>
            {uploadError}
          </Alert>
        )}
        {uploading && (
          <div style={{ marginTop: "10px" }}>
            <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
          </div>
        )}
      </Form.Group>
      <Form.Group>
        <Form.Label>Order</Form.Label>
        <Form.Control
          type="number"
          name="order"
          value={formData.order}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group>
        <Form.Check
          type="switch"
          id="isActive-switch"
          label="Is Active"
          name="isActive"
          checked={formData.isActive}
          onChange={handleChange}
        />
      </Form.Group>
      <div className="d-flex justify-content-end mt-3">
        <Button variant="secondary" onClick={onHide} className="me-2">
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={uploading}>
          {ad ? "Update" : "Create"}
        </Button>
      </div>

      <Modal
        show={showCropModal}
        onHide={() => setShowCropModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Crop Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactCrop
            src={imagePreview}
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            onComplete={handleCropComplete}
            aspect={1}
          >
            <img src={imagePreview} id="cropImage" alt="Crop preview" />
          </ReactCrop>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCropModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleCropSave}
            disabled={isCropping}
          >
            {isCropping ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                  variant="dark"
                />
              </>
            ) : (
              "Crop and Save"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

// Main AdvertManager component
const AdvertManager = () => {
  const dispatch = useDispatch();
  const ads = useSelector((state) => state.adverts.ads);
  const loading = useSelector((state) => state.loading);
  const [showModal, setShowModal] = useState(false);
  const [currentAd, setCurrentAd] = useState(null);

  useEffect(() => {
    dispatch(fetchAds());
  }, [dispatch]);

  const handleCreateAd = (adData) => {
    dispatch(createAd(adData));
    setShowModal(false);
  };

  const handleUpdateAd = (adData) => {
    dispatch(updateAd(currentAd.id, adData));
    setShowModal(false);
    setCurrentAd(null);
  };

  const handleDeleteAd = (id) => {
    if (window.confirm("Are you sure you want to delete this advertisement?")) {
      dispatch(deleteAd(id));
    }
  };

  const openModal = (ad = null) => {
    setCurrentAd(ad);
    setShowModal(true);
  };

  return (
    <Container>
      <Row className="my-4">
        <Col>
          <Card>
            <Card.Header as="h5">
              Ad Center ||
              <small className="text-muted"> Manage Adverts</small>
            </Card.Header>
            <Card.Body>
              <div className="mb-3">
                <Button variant="primary" onClick={() => openModal()}>
                  Add New Advertisement <i className="fas fa-plus-circle"></i>
                </Button>
              </div>
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Image</th>
                      <th>Ordering</th>
                      <th>Link</th>
                      <th>Active</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ads?.map((ad, index) => (
                      <tr key={ad._id}>
                        <td>{index + 1}.</td>
                        <td>{ad.title}</td>
                        <td>
                          <img
                            src={ad.imageUrl}
                            alt={ad.title}
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                            }}
                          />
                        </td>
                        <td>{ad.order}</td>
                        <td>{ad.imageUrl}</td>
                        {/* A boolean switch for active */}
                        <td>{ad.isActive ? "Yes" : "No"}</td>
                        <td>
                          <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() => openModal(ad)}
                            className="me-2"
                          >
                            Edit
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDeleteAd(ad.id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {currentAd ? "Edit Advertisement" : "Create Advertisement"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AdForm
            ad={currentAd}
            onSubmit={currentAd ? handleUpdateAd : handleCreateAd}
            onHide={() => setShowModal(false)}
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default AdvertManager;
