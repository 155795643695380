
import React, { useState } from 'react';
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { getUserFromLocalStorage } from 'utilities/user';

const processRefund = () => {
  const user = getUserFromLocalStorage();
  const [isLoading, setIsLoading] = useState(false);
  const [refundData, setRefundData] = useState({
    transactionId: "",
    amount: "",
    // comment: ""
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // const response = await axios.post(`https://f21e-102-216-146-221.ngrok-free.app/fw/refund/${user?.result?._id}`, refundData);
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/fw/refund/${user?.result?._id}`, refundData);
      // console.log(response.data.data.data, "response>>>")
      if (response.data.data.status === "success") {
        toast.success(response.data.message);
        setRefundData({
          transactionId: "",
          amount: "",
          //   comment: ""
        });
      } else {
        toast.error(response.data.data.data);
      }
    } catch (err) {
      const errorMessage = err.response ? err.response.data.message : "An error occurred while processing refund!";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };


  const buttonStyles = {
    cursor: isLoading ? 'not-allowed' : 'pointer',
    color: isLoading ? 'gray' : 'white',
    backgroundColor: isLoading ? '#e0e0e0' : '#007BFF',
  };

  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <Card className="mt-4">
            <Card.Header>Process Refund</Card.Header>
            <Card.Body>
              {/* <Form onSubmit={handleSubmit}> */}
              <Form onSubmit={isLoading ? (e) => e.preventDefault() : handleSubmit}>
                <Form.Group controlId="transactionId">
                  <Form.Label>Transaction Ref</Form.Label>
                  <Form.Control
                    type="text"
                    value={refundData.transactionId}
                    onChange={(e) =>
                      setRefundData({ ...refundData, transactionId: e.target.value })
                    }
                    required
                    placeholder="Enter Transaction ID"
                  />
                </Form.Group>
                <Form.Group controlId="amount">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="text"
                    value={refundData.amount}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        setRefundData({ ...refundData, amount: e.target.value })
                      }
                    }
                    }
                    required
                    placeholder="1000.00"
                  />
                </Form.Group>
                {/* <Form.Group controlId="comment">
                    <Form.Label>Comment</Form.Label>
                    <Form.Control
                      type="text"
                      value={refundData.comment}
                      onChange={(e) =>
                        setRefundData({ ...refundData, comment: e.target.value })
                      }
                    />
                  </Form.Group> */}
                <Button variant="primary" type="submit" className="mt-2" style={buttonStyles}>
                  {isLoading ? "Processing..." : "Process Refund"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};


//make this component available to the app
export default processRefund;
