import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import Message from "../../views/Components/Message";
import { addToCart, removeFromCart } from "../../actions/cartActions";
import { getUserFromLocalStorage } from "utilities/user";

const CartScreen = ({ match, location, history }) => {
  // const productId = match.params.id; //Re-Access  the relevance of productId Variable

  // const [user] = useState(JSON.parse(localStorage.getItem("profile")));
  const user = getUserFromLocalStorage();

  const qty = location.search ? Number(location.search.split("=")[1]) : 1;
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { cartItems, isLoading } = cart;

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    history.push("/admin/shipping");
  };
  const addMoreProducts = (e) => {
    e.preventDefault(), history.push("/admin/shopCards");
  };

  return (
    <Row>
      <Col md={8}>
        <h1>Shopping Cart</h1>
        {isLoading ? (
          <Spinner
            animation="grow"
            size="lg"
            variant="primary"
            style={{
              marginTop: "70px",
              top: "50%",
              left: "50%",
              position: "fixed",
            }}
          />
        ) : cartItems.length === 0 ? (
          <Message variant="danger">
            Your cart is empty
            <Link
              to="/admin/shopCards"
              style={{ color: "#0CF732", textDecoration: "none" }}
            >
              {" "}
              , Start Shopping
            </Link>
          </Message>
        ) : (
          <ListGroup variant="flush">
            {cartItems.map((item) => (
              <ListGroup.Item key={item._id}>
                <Row>
                  <Col md={2}>
                    <Image src={item.imageUrl} alt={item.name} fluid rounded />
                  </Col>
                  <Col md={3}>
                    <Link
                      to={`/admin/listing-details/${item._id}`}
                      style={{ color: "#103778", textDecoration: "none" }}
                    >
                      {item.name}
                    </Link>
                  </Col>
                  <Col md={2}>
                    Shs{" "}
                    {item.price
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Col>
                  <Col md={2}>
                    <Form.Control
                      as="select"
                      value={item.qty}
                      onChange={(e) => {
                        dispatch(
                          addToCart(
                            item._id,
                            Number(e.target.value),
                            item.vendorId, //VendorId
                            item.unit
                          )
                        );
                      }}
                    >
                      {[...Array(item.countInStock).keys()].map((x) => (
                        <option key={x + 1} value={x + 1}>
                          {x + 1}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col md={2}>
                    <OverlayTrigger
                      delay={{ hide: 45, show: 30 }}
                      overlay={(props) => <Tooltip {...props}>Delete</Tooltip>}
                      placement="top"
                    >
                      <Button
                        type="button"
                        variant="light"
                        onClick={() => removeFromCartHandler(item._id)}
                      >
                        <i className="fas fa-trash"></i>
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
            <Button
              type="button"
              className="btn-block"
              onClick={addMoreProducts}
            >
              Add More Items to Cart
            </Button>
          </ListGroup>
        )}
      </Col>
      <Col md={4}>
        <Card>
          <ListGroup variant="flush">
            {!qty ? (
              <ListGroup.Item>
                <h2>Subtotal ({cartItems.reduce(() => Number(1))}) items</h2>
                Ushs {""}
                {cartItems.reduce((item) => Number(item.price))}
              </ListGroup.Item>
            ) : (
              <ListGroup.Item>
                <h2>
                  Subtotal (
                  {cartItems.reduce(
                    (acc, item) => acc + Number(item.qty),
                    Number(0)
                  )}
                  ) items
                </h2>
                <strong>Total</strong>:{""} Shs {""}
                {cartItems
                  .reduce((acc, item) => acc + Number(item.qty) * item.price, 0)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </ListGroup.Item>
            )}
            <ListGroup.Item>
              <Button
                type="button"
                className="bg-danger"
                disabled={cartItems.length === 0}
                onClick={checkoutHandler}
              >
                Proceed To Checkout
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </Col>
    </Row>
  );
};

export default CartScreen;
