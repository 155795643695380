import axios from "axios";
import {
  CREATE_SHIPPING_COST,
  DELETE_SHIPPING_COST,
  FETCH_SHIPPING_COST,
  FETCH_SHIPPING_COSTS,
  UPDATE_SHIPPING_COST,
} from "../constants/shippingCostsConstants";
import { toast } from "react-toastify";
import { START_LOADING, END_LOADING } from "../constants/actionTypes";

// Create Shipping Cost
export const createShippingCost = (postData) => async (dispatch) => {
  const { userId, userRole, cost, shippingCostDescription } = postData;
  // console.log(userRole, "userRole....");
  // console.log(postData, "type of Cost....");

  try {
    // userId/:role/createShippingCost
    const { data } = await axios.post(
      `/shippingCost/${userId}/${userRole}/createShippingCost`,
      {
        cost,
        shippingCostDescription,
      }
    );
    // console.log(data, "shippingCost");

    dispatch({ type: CREATE_SHIPPING_COST, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.response.data.message);
    toast.error(error.response.data.message);
  }
};

// Get Shipping Costs Plural
export const getShippingCosts = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await axios.get("/shippingCost");

    dispatch({
      type: FETCH_SHIPPING_COSTS,
      payload: data,
    });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

// Get Shipping Cost Singular
export const getShippingCost = (id) => async (dispatch) => {
  console.log(id, "Whichc IDDDDD");
  try {
    dispatch({ type: START_LOADING });
    const { data } = await axios.get(`/shippingCost/${id}`);

    dispatch({
      type: FETCH_SHIPPING_COST,
      payload: { shippingCost: data },
    });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

// Update Shipping Cost
export const updateShippingCost =
  (id, updatedShippingCost) => async (dispatch) => {
    // console.log(updatedShippingCost, "updatedShippingCost....");
    const { cost, shippingCostDescription } = updatedShippingCost;
    try {
      dispatch({ type: START_LOADING });
      const { data } = await axios.patch(`/shippingCost/update/${id}`, {
        cost,
        shippingCostDescription,
      });

      dispatch({ type: UPDATE_SHIPPING_COST, payload: data });

      dispatch({ type: END_LOADING });
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

// Delete Shipping Cost
export const deleteShippingCost = (postData) => async (dispatch) => {
  const { _id, userRole, userId } = postData;
  // console.log(id, "userRole....");
  // console.log(userId, "userId....");
  // console.log(postData, "postData....");
  // console.log(shippingCostId, "shippingCostId....");
  try {
    const { data } = await axios.delete(
      // `/posts/deleteShippingCost/${_id}/${userRole}`,
      `/shippingCost/deleteShippingCost/${userId}/${userRole}`,
      {
        data: { _id },
      }
    );

    dispatch({ type: DELETE_SHIPPING_COST, payload: _id, data });
    toast.success("Shipping Cost Deleted Successfully!");
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};
