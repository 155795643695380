// reducers/optionReducer.js

import {
    START_LOADING,
    END_LOADING,
    CREATE_OPTION,
    GET_OPTIONS,
    UPDATE_OPTION,
    DELETE_OPTION,
    CREATE_OPTION_GROUP,
    GET_OPTION_GROUPS,
    UPDATE_OPTION_GROUP,
    DELETE_OPTION_GROUP
} from "../constants/actionTypes.js";

const initialState = {
    isLoading: false,
    optionGroups: [],
    options: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, isLoading: true };
        case END_LOADING:
            return { ...state, isLoading: false };
        case CREATE_OPTION_GROUP:
            return {
                ...state,
                optionGroups: [...state.optionGroups, action.payload],
                isLoading: false,
            };
        case GET_OPTION_GROUPS:
            return { ...state, optionGroups: action.payload, isLoading: false };
        case UPDATE_OPTION_GROUP:
            return {
                ...state,
                optionGroups: state.optionGroups.map(optionGroup =>
                    optionGroup._id === action.payload._id ? action.payload : optionGroup
                ),
                isLoading: false,
            };
        case DELETE_OPTION_GROUP:
            return {
                ...state,
                optionGroups: state.optionGroups.filter(group => group._id !== action.payload),
                isLoading: false,
            };
        case CREATE_OPTION:
            return {
                ...state,
                options: [...state.options, action.payload],
                isLoading: false,
            };
        case GET_OPTIONS:
            return { ...state, options: action.payload, isLoading: false };
        case UPDATE_OPTION:
            return {
                ...state,
                options: state.options.map(option =>
                    option._id === action.payload._id ? action.payload : option
                ),
                isLoading: false,
            };
        case DELETE_OPTION:
            return {
                ...state,
                options: state.options.filter(option => option._id !== action.payload),
                isLoading: false,
            };
        default:
            return state;
    }
};
