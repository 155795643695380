import { START_LOADING, END_LOADING } from "constants/actionTypes";
import {
  ADD_TO_CART,
  CART_CLEAR_PAYMENT_METHOD,
  CART_CLEAR_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
  CLEAR_CART,
  REMOVE_FROM_CART,
} from "../constants/cartConstants";
import * as api from "../api/index.js";

export const addToCart =
  (listingId, qty, vendorId, unit) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.apifetchListing(listingId);

      dispatch({
        type: ADD_TO_CART,
        payload: {
          // listingId: data._id,
          _id: data?._id,
          name: data?.title,
          imageUrl: data?.product_image,
          price: data?.price,
          countInStock: data?.countInStock,
          qty,
          vendorId,
          unit,
        },
      });

      dispatch({ type: END_LOADING });

      localStorage.setItem(
        "cartItems",
        JSON.stringify(getState().cart.cartItems)
      );
    } catch (error) {
      console.log(error);
    }
  };

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_FROM_CART,
    payload: id,
  });
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const clearCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CLEAR_CART,

    payload: id, //id Is not needed!
  });
  localStorage.removeItem(
    "cartItems",
    JSON.stringify(getState().cart.cartItems)
  );
  dispatch({ type: CART_CLEAR_SHIPPING_ADDRESS, payload: id });
  localStorage.removeItem(
    "shippingAddress",
    JSON.stringify(getState().cart.shippingAddress)
  );
};

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });
  localStorage.setItem("shippingAddress", JSON.stringify(data));
};

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  });
  localStorage.setItem("paymentMethod", JSON.stringify(data));
};

export const clearPaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_CLEAR_PAYMENT_METHOD,
    payload: data,
  });
  localStorage.removeItem("paymentMethod", JSON.stringify(data));
};

// export const getShippingCost = (id) => async (dispatch) => {
//   console.log(id, "which one is this ID?");
//   try {
//     dispatch({ type: START_LOADING });
//     const { data } = await axios.get(`/posts/shippingCost/${id}`);
//     // console.log(data, "shippingCost");
//     dispatch({
//       // type: SHIPPING_COST_SUCCESS,

//       // type: FETCH_SHIPPING_COST,
//       // type: SHIPPING_COST_SUCCESS,
//       // payload: { shippingCost: data },
//       payload: data,
//     });
//     dispatch({ type: END_LOADING });
//   } catch (error) {
//     console.log(error);
//   }
// };
