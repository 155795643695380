// retailerWholesalerVendorOrdersReducer.js
import {
    RETAILER_WHOLESALER_VENDOR_ORDERS_REQUEST,
    RETAILER_WHOLESALER_VENDOR_ORDERS_SUCCESS,
    RETAILER_WHOLESALER_VENDOR_ORDERS_FAIL,
    RETAILER_WHOLESALER_ORDER_DETAILS_REQUEST,
    RETAILER_WHOLESALER_ORDER_DETAILS_SUCCESS,
    RETAILER_WHOLESALER_ORDER_DETAILS_FAIL,
    UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_REQUEST,
    UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_SUCCESS,
    UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_FAIL,
} from "../constants/webOrderConstants";

const initialState = {
    orders: [],
    order: {},
    loading: false,
    error: null,
    currentPage: 1,
    numberOfPages: 1,
    totalOrders: 0,
};

export const retailerWholesalerVendorOrdersReducer = (state = initialState, action) => {
    switch (action.type) {
        case RETAILER_WHOLESALER_VENDOR_ORDERS_REQUEST:
        case RETAILER_WHOLESALER_ORDER_DETAILS_REQUEST:
        case UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RETAILER_WHOLESALER_VENDOR_ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: action.payload.data,
                currentPage: action.payload.currentPage,
                numberOfPages: action.payload.numberOfPages,
                totalOrders: action.payload.totalOrders,
            };
        case RETAILER_WHOLESALER_ORDER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                order: action.payload,
            };
        case UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                order: {
                    ...state.order,
                    delivery_status: action.payload.delivery_status,
                },
            };
        case RETAILER_WHOLESALER_VENDOR_ORDERS_FAIL:
        case RETAILER_WHOLESALER_ORDER_DETAILS_FAIL:
        case UPDATE_RETAILER_WHOLESALER_ORDER_STATUS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
