import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Typography, Button, CircularProgress, makeStyles, Tabs, Tab, Box, Card, CardContent, FormControl, InputLabel, Input, Switch, FormControlLabel } from '@material-ui/core';
import { Form } from 'react-bootstrap';
import { fetchRetailerWholesalerProfile, updateRetailerWholesalerProfile } from '../../../../actions/retailerWholesalerProfileActions';
import { getUserFromLocalStorage } from '../../../../utilities/user.js';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(4),
        backgroundColor: '#f5f5f5',
        width: '100%',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(2),
    },
    textField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    disabledField: {
        cursor: 'not-allowed',
    },
    button: {
        marginTop: theme.spacing(2),
        alignSelf: 'flex-end', // Align the button to the right
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    error: {
        color: theme.palette.error.main,
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    },
    tabs: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        marginBottom: theme.spacing(2),
        alignSelf: 'flex-start',
    },
    tabContent: {
        padding: theme.spacing(2),
        height: 'calc(100vh - 400px)', // Adjust based on desired fixed height
        overflowY: 'auto',
    },
    card: {
        maxWidth: '100%',
        margin: 'auto',
        marginTop: theme.spacing(2),
        boxShadow: theme.shadows[5],
        height: 'calc(100vh - 200px)', // Adjust based on desired fixed height
    },
    cardContent: {
        padding: theme.spacing(3),
    },
    switchContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        fontWeight: 'normal',
        fontSize: '12px',
        textTransform: 'capitalize'
    },
    note: {
        color: theme.palette.text.secondary,
        fontSize: '0.875rem',
        marginTop: theme.spacing(1),
    }
}));

const RetailerWholesalerProfile = () => {
    const user = getUserFromLocalStorage();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { profile, loading, error } = useSelector((state) => state.retailerWholesalerProfile);

    const vendorId = user?.result?._id;

    const [form, setForm] = useState({
        name: '',
        address: '',
        phone: '',
        email: '',
        isActive: false,
    });

    const [notifications, setNotifications] = useState({
        sms: false,
        email: false,
        whatsapp: false,
        phoneCall: false,
        dropOff: false,
    });

    const [tabIndex, setTabIndex] = useState(0); // Set first tab as default active tab

    useEffect(() => {
        dispatch(fetchRetailerWholesalerProfile(vendorId));
    }, [dispatch, vendorId]);

    useEffect(() => {
        if (profile) {
            setForm(profile);
        }
    }, [profile]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({ ...prevForm, [name]: value }));
    };

    const handleNotificationChange = (e) => {
        const { name, checked } = e.target;
        setNotifications((prevNotifications) => ({ ...prevNotifications, [name]: checked }));
    };

    const handleSwitchChange = (e) => {
        const { name, checked } = e.target;
        setForm((prevForm) => ({ ...prevForm, [name]: checked }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateRetailerWholesalerProfile(vendorId, form));
    };

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    if (loading) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <Container className={classes.root}>
            {error && (
                <Typography variant="h6" className={classes.error}>
                    Error: {error}
                </Typography>
            )}
            <Card className={classes.card}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    aria-label="profile tabs"
                    className={classes.tabs}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Account" />
                    <Tab label="Advanced" />
                    <Tab label="Notifications" />
                    <Tab label="Deactivate" />
                </Tabs>
                <CardContent className={classes.cardContent}>
                    <TabPanel value={tabIndex} index={0} className={classes.tabContent}>
                        <form className={classes.form}>
                            <Form.Group className="mb-3" style={{ width: '100%' }}>
                                <Form.Control
                                    id="name"
                                    name="name"
                                    type='text'
                                    value={form.name}
                                    onChange={handleChange}
                                    // disabled
                                    readOnly
                                />
                            </Form.Group>
                            <FormControl className={classes.textField} variant="outlined">
                                <InputLabel htmlFor="address">Address</InputLabel>
                                <Input
                                    id="address"
                                    name="address"
                                    value={form.address}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <FormControl className={classes.textField} variant="outlined">
                                <InputLabel htmlFor="phone">Phone</InputLabel>
                                <Input
                                    id="phone"
                                    name="phone"
                                    value={form.phone}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <FormControl className={classes.textField} variant="outlined">
                                <InputLabel htmlFor="email">Email</InputLabel>
                                <Input
                                    id="email"
                                    name="email"
                                    value={form.email}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </form>
                    </TabPanel>
                    {/* Advanced */}
                    <TabPanel value={tabIndex} index={1} className={classes.tabContent}>
                        <Typography variant="h6">Shop Activation</Typography>
                        <FormControlLabel
                            control={<Switch checked={form.isActive} onChange={handleSwitchChange} name="isActive" color="secondary" />}
                            label={form.isActive ? 'Active Shop' : 'Shop Deactivated'}
                            classes={{ label: classes.label }}
                        />
                        <Typography className={classes.note}>
                            Note: When your shop is deactivated, it won't be visible to users and you won't be able to receive orders.
                        </Typography>
                    </TabPanel>
                    {/* Notifications */}
                    <TabPanel value={tabIndex} index={2} className={classes.tabContent}>
                        <Typography variant="h6" gutterBottom>Notification Settings</Typography>
                        <div className={classes.switchContainer}>
                            <FormControlLabel
                                control={<Switch checked={notifications.sms} onChange={handleNotificationChange} name="sms" color="primary" />}
                                label="SMS"
                            />
                            <FormControlLabel
                                control={<Switch checked={notifications.email} onChange={handleNotificationChange} name="email" color="primary" />}
                                label="Email"
                            />
                            <FormControlLabel
                                control={<Switch checked={notifications.whatsapp} onChange={handleNotificationChange} name="whatsapp" color="primary" />}
                                label="WhatsApp"
                            />
                            <FormControlLabel
                                control={<Switch checked={notifications.phoneCall} onChange={handleNotificationChange} name="phoneCall" color="primary" />}
                                label="Phone Call"
                            />
                            <FormControlLabel
                                control={<Switch checked={notifications.dropOff} onChange={handleNotificationChange} name="dropOff" color="primary" />}
                                label="Drop-Off"
                            />
                        </div>
                    </TabPanel>
                    {/* Deactivation */}
                    <TabPanel value={tabIndex} index={3} className={classes.tabContent}>
                        <Typography variant="h6">Deactivate Account</Typography>
                        {/* Add your deactivate account fields here */}
                    </TabPanel>
                </CardContent>
            </Card>
            <Button className={classes.button} variant="contained" color="primary" onClick={handleSubmit}>
                Update Profile
            </Button>
        </Container>
    );
};

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={2}>{children}</Box>}
        </div>
    );
};

export default RetailerWholesalerProfile;
