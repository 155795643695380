import { FETCH_RESTAURANT_PROFILE, UPDATE_RESTAURANT_PROFILE, START_LOADING, END_LOADING, FETCH_RESTAURANT_PROFILE_ERROR } from '../constants/actionTypes';

const initialState = {
    profile: null,
    loading: false,
    error: null,
};

export const restaurantProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, loading: true, error: null };
        case END_LOADING:
            return { ...state, loading: false };
        case FETCH_RESTAURANT_PROFILE:
            return { ...state, profile: action.payload };
        case UPDATE_RESTAURANT_PROFILE:
            return { ...state, profile: action.payload };
        case FETCH_RESTAURANT_PROFILE_ERROR:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};
