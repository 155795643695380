import React from "react";
import { AppBar, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

import lusukuLogo517 from "../../assets/img/lusukuLogo517.png";
// import {  vendorRegistration } from "../../assets/full-screen-image-4.jpg";
import vendorRegistration from "../../assets/img/full-screen-image-4.jpg";
// import vendorShop from "../../assets/img/Vendor-Shop-Banner.png";
import taplin from "../../assets/img/taplin2.png";

const VendorRegistration = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <AppBar position="absolute" color="inherit">
          <Toolbar>
            <Link to="/admin/dashboard">
              <Image
                width="80"
                height="85"
                className="d-inline-block align-top pb-2 mt-2"
                src={lusukuLogo517}
                alt="logo"
              />
            </Link>
          </Toolbar>
        </AppBar>
        {/* Insert vendorShop image right below the AppBar */}
        <div className={classes.taplin}>
          <img
            src={taplin}
            alt="Vendor Shop"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              // paddingTop: "85px",
            }}
          />
        </div>
      </div>
      {/* Background image aligned to the top right corner */}
      <div
        className="container-fluid"
        style={{
          position: "relative",
          zIndex: 1,
          backgroundImage: `url(${vendorRegistration})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
        }}
      >
        <div
          className="container"
          style={{
            maxWidth: "500px",
            padding: "20px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            boxShadow:
              "0px 10px 20px rgba(0, 0, 0, 0.19), 0px 6px 6px rgba(0, 0, 0, 0.23)",
            position: "relative",
            zIndex: 3,
          }}
        >
          <h1 style={{ fontSize: 30, fontWeight: 300, textAlign: "center" }}>
            Vendor Registration
          </h1>
          {/* Divider */}
          <hr className="my-3 mx-auto" style={{ width: "80%" }} />
          {/* Paragraph */}
          <p
            style={{
              textAlign: "left",
              fontSize: 12,
              // fontWeight: 300,
              color: "#00396c",
              lineHeight: 1.7,
              marginBottom: "20px",
              marginTop: "20px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            As a vendor, you can register your business with Lusuku and start
            show casing your products to thousands of customers. Register now
            and get a access to the vendor dashboard where you can manage your
            products and orders instantly & on your own!
          </p>
          {/* Bullets */}
          <ul
            style={{
              textAlign: "left",
              fontSize: 12,
              lineHeight: 1.7,
              marginBottom: "20px",
              marginTop: "20px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <li>Register your business on Lusuku Platform for free. </li>
            <li>
              Start show casing your products to thousands of customers who are
              using our mobile and web app.
            </li>
            <li>
              Get access to the vendor dashboard where you can upload images,
              manage your product listings & pricing on your own!
            </li>
            <li>
              Get notified in realtime when a customer places an order from your
              shop.{" "}
            </li>
            <li>
              Get paid instantly via our secure payment gateway or pick cash
              upon delivery.
            </li>
            <li>
              Get access to our customer support team & call center to
              coordinate deliveries quick.
            </li>
            <li>
              Get access to our marketing team and tools to promote your
              products.
            </li>
            <li>Access analytics to track and improve your sales.</li>
            <li>Access our delivery agents, both riders and cargo vans.</li>
            <li>No commissions are charged!</li>
          </ul>

          {/* Register Button */}
          <div
            className="container"
            style={{
              marginTop: "10px",
            }}
          >
            <div className="row">
              <div className="col-md-6">
                <Link to="/vendor-register">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#E1523D",
                      borderColor: "#E1523D",
                      width: "100%",
                      height: "50px",
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    Register
                  </button>
                </Link>
              </div>
              <div className="col-md-6">
                <Link to="/admin/dashboard">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#397739",
                      borderColor: "#397739",
                      width: "100%",
                      height: "50px",
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    Logon
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  taplin: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    [theme.breakpoints.down(1018)]: {
      paddingTop: "85px",
    },
    [theme.breakpoints.down(704)]: {
      width: "100vw",
      height: "26vh",
    },
  },
}));

export default VendorRegistration;
