import {
    START_LOADING,
    CREATE_PRODUCT_RW,
    GET_PRODUCT_RW,
    UPDATE_PRODUCT_RW,
    DELETE_PRODUCT_RW,
    END_LOADING
} from '../constants/actionTypes.js';

const initialState = {
    products: [],
    product: null,
    isLoading: false,
    error: null,
};

export const retailerWholesalerProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, isLoading: true };
        case CREATE_PRODUCT_RW:
            return { ...state, products: [...state.products, action.payload], isLoading: false };
        case GET_PRODUCT_RW:
            return { ...state, product: action.payload, isLoading: false };
        case UPDATE_PRODUCT_RW:
            return {
                ...state,
                products: state.products.map((product) =>
                    product._id === action.payload._id ? action.payload : product
                ),
                isLoading: false,
            };
        case DELETE_PRODUCT_RW:
            return {
                ...state,
                products: state.products.filter((product) => product._id !== action.payload),
                isLoading: false,
            };
        case END_LOADING:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
