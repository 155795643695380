import React from "react";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { Link } from "react-router-dom";
import useStyles from "./paginationStyles";
import { useSelector } from "react-redux";

const Paginate = ({ page }) => {
  const { numberOfPages } = useSelector((state) => state.mobileOrders);
  const classes = useStyles();

  return (
    <Pagination
      classes={{ ul: classes.ul }}
      count={numberOfPages}
      page={Number(page) || 1}
      variant="text"
      color="secondary"
      renderItem={(item) => (
        <PaginationItem
          {...item}
          component={Link}
          to={`/admin/paginatedOrdersTable/?page=${item.page}`}
        />
      )}
    />
  );
};

export default Paginate;
