import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

import lusukuLogo517 from "../../assets/img/lusukuLogo517.png";

const LusukuLogo = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="inherit">
        <Toolbar>
          <Link to="/">
            <Image
              width="80"
              height="85"
              className="d-inline-block align-top pb-2 mt-2"
              src={lusukuLogo517}
              alt="logo"
            />
          </Link>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));
export default LusukuLogo;
