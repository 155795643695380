// import { logout } from 'actions/auth.js';
import {
  LOGOUT,
  AUTH,
  FETCH_USERS,
  START_LOADING,
  END_LOADING,
  USER_LOGIN_FAIL,
  AGENT_CREATION_FAIL,
  CREATE_USER,
  DELETE_USER,
  UPDATE_USER,
  FETCH_USER_SINGLE,
  USER_CREATED,
  FETCH_CLIENT_BY_SEARCH,
} from '../constants/actionTypes.js';
import { encryptData } from '../utilities/encryption.js';

const initialState = {
  authorizedUsers: [],
  agent: null,
  isLoading: false,
  isAuthenticated: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true, error: null };
    case END_LOADING:
      return { ...state, isLoading: false };
    case USER_LOGIN_FAIL:
    case AGENT_CREATION_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    case CREATE_USER:
      return {
        ...state,
        authorizedUsers: [...state.authorizedUsers, action.payload],
        error: null
      };
    case USER_CREATED:
      return { ...state, userCreated: true, isLoading: false, error: null };
    case DELETE_USER:
      return {
        ...state,
        authorizedUsers: state.authorizedUsers.filter((user) => user._id !== action.payload),
        isLoading: false,
        error: null
      };
    case UPDATE_USER:
      return {
        ...state,
        authorizedUsers: state.authorizedUsers.map((agent) =>
          agent._id === action.payload._id ? action.payload : agent
        ),
        isLoading: false,
        error: null
      };
    case FETCH_USERS:
    case FETCH_CLIENT_BY_SEARCH:
      return {
        ...state,
        authorizedUsers: action.payload.data,
        isLoading: false,
        error: null
      };
    case FETCH_USER_SINGLE:
      return { ...state, agent: action.payload.agent, isLoading: false, error: null };
    case LOGOUT:
      localStorage.removeItem('profile');
      localStorage.removeItem('token'); // Also remove the token
      return { ...initialState };
    case AUTH:
      const encryptedProfile = encryptData({ ...action?.data });
      localStorage.setItem('profile', encryptedProfile);
      localStorage.setItem('token', action?.data?.token); // Store the token separately
      return {
        ...state,
        agent: action?.data,
        isLoading: false,
        isAuthenticated: true,
        error: null
      };
    default:
      return state;
  }
};