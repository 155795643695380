import { Switch } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Image, ListGroup, Row, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getDeactivatedListings,
  reactivateSku,
} from "../../../actions/listingsActions";
import { getUserFromLocalStorage } from "utilities/user";

const DeactivatedListings = () => {
  const user = getUserFromLocalStorage();

  const listings = useSelector((state) => state.listings);
  const { deactivatedListings, isLoading } = listings;
  const [reactivated, setReactivated] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeactivatedListings(user?.result?._id));
  }, [reactivated]);

  // Show no data message if there are no listings
  if (isLoading) {
    return (
      <Spinner
        animation="grow"
        size="lg"
        variant="primary"
        style={{
          marginTop: "70px",
          top: "50%",
          left: "50%",
          position: "fixed",
        }}
      />
    );
  } else if (deactivatedListings.length === 0) {
    return (
      <div className="text-center">
        <h1>No Deactivated Listings</h1>
      </div>
    );
  }

  const handleReactivation = async (listingId, userId) => {
    await dispatch(reactivateSku(listingId, userId));
    setReactivated(!reactivated);
  };

  return (
    <div>
      <h1>Deactivated Listings</h1>
      <Row>
        <Col md={12}>
          <ListGroup>
            <ListGroup.Item>
              <Row>
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Image</th>
                      <th>Listing Price</th>
                      <th>Listing Description</th>
                      <th>Listing Category</th>
                      <th>Count In Stock</th>
                      <th>Activate Listing</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(deactivatedListings) &&
                      deactivatedListings.map((listing) => (
                        <tr key={listing._id}>
                          <td>{listing.title}</td>
                          <td>
                            <Image
                              src={listing.product_image}
                              resizemode="contain"
                              style={{
                                width: 60,
                                height: 55,
                                borderRadius: "15%",
                              }}
                            />
                          </td>
                          <td>{listing.price}</td>
                          <td>{listing.description}</td>
                          <td>{listing.category}</td>
                          <td>{listing.countInStock}</td>
                          <td>
                            <Switch
                              checked={listing.active === true ? true : false}
                              onChange={() =>
                                handleReactivation(
                                  listing._id,
                                  user?.result?._id
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
};

export default DeactivatedListings;
