import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Spinner, Container, Row, Col, Modal } from 'react-bootstrap';
import { getRetailerRiders, deleteRetailerRider } from '../../actions/ridersAction';
import { getUserFromLocalStorage } from 'utilities/user';
import { FaPlus } from 'react-icons/fa';
import RetailerRiderForm from '../../views/Forms/RetailerRiderForm.js';
import { FiberManualRecord as FiberManualRecordIcon, Wifi, WifiOff } from '@material-ui/icons';
import io from 'socket.io-client';

const getStatusColor = (status) => {
    switch (status) {
        case "Online":
            return "green";
        case "Offline":
            return "red";
        case "Busy":
            return "orange";
        case "inTransit":
            return "blue";
        default:
            return "gray";
    }
};

const RetailerRidersTable = () => {
    const dispatch = useDispatch();
    const { riders, loading } = useSelector((state) => state.riders);
    const user = getUserFromLocalStorage();
    const vendorId = user?.result?._id;
    const [showRiderForm, setShowRiderForm] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [riderToDelete, setRiderToDelete] = useState(null);
    const [riderToEdit, setRiderToEdit] = useState(null);
    const [riderName, setRiderName] = useState("");

    useEffect(() => {
        if (vendorId) {
            dispatch(getRetailerRiders(vendorId));
        }

        const socket = io(process.env.REACT_APP_SERVER_URL);

        socket.on('connect', () => {
            console.log('Connected to server');
        });

        socket.on('riderStatusUpdate', (statusUpdate) => {
            dispatch({
                type: 'UPDATE_RETAILER_RIDER_STATUS',
                payload: statusUpdate
            });
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from server');
        });

        return () => {
            socket.disconnect();
        };
    }, [dispatch, vendorId]);

    // useEffect(() => {
    //     // const socket = io('http://localhost:8001'); // ensure this matches your server URL
    //     // console.log(process.env.REACT_APP_SERVER_URL, "process.env.REACT_APP_SERVER_URL>>>")
    //     const socket = io(process.env.REACT_APP_SERVER_URL);

    //     socket.on('connect', () => {
    //         console.log('Connected to server');
    //     });

    //     socket.on('riderStatusUpdate', (statusUpdate) => {
    //         dispatch({
    //             type: 'UPDATE_RETAILER_RIDER_STATUS',
    //             payload: statusUpdate
    //         });
    //     });

    //     socket.on('disconnect', () => {
    //         console.log('Disconnected from server');
    //     });

    //     socket.on('connect_error', (error) => {
    //         console.error('Socket connection error:', error);
    //     });

    //     socket.on('reconnect_error', (error) => {
    //         console.error('Socket reconnection error:', error);
    //     });

    //     return () => {
    //         socket.disconnect();
    //     };
    // }, [dispatch, vendorId]);

    const handleDelete = () => {
        dispatch(deleteRetailerRider(vendorId, riderToDelete));
        setShowConfirmDialog(false);
    };

    const handleShowRiderForm = (rider = null) => {
        setRiderToEdit(rider);
        setShowRiderForm(true);
    };

    const handleCloseRiderForm = () => {
        setRiderToEdit(null);
        setShowRiderForm(false);
    };

    const confirmDelete = (riderId, name) => {
        setRiderToDelete(riderId);
        setRiderName(name);
        setShowConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setShowConfirmDialog(false);
        setRiderToDelete(null);
        setRiderName("");
    };

    const onlineRidersCount = riders.filter(rider => rider.onlineStatus === "Online").length;
    const offlineRidersCount = riders.filter(rider => rider.onlineStatus === "Offline").length;

    return (
        <Container>
            <Row className="mb-3">
                <Col>
                    <h2>{user?.result?.businessTradingName || user?.result.businessName} Riders</h2>
                    <Button variant="primary" onClick={() => handleShowRiderForm()}>
                        <FaPlus /> Add Rider
                    </Button>
                </Col>
            </Row>
            {loading ? (
                <Spinner
                    animation="border"
                    style={{
                        marginTop: "70px",
                        top: "50%",
                        left: "50%",
                        position: "fixed",
                    }}
                />
            ) : riders.length === 0 ? (
                <Row>
                    <Col>
                        <p>No riders to your profile were found. Click the button above to add in-house rider for {user?.result?.businessTradingName || user?.result.businessName}</p>
                    </Col>
                </Row>
            ) : (
                <>
                    <Col className="text-right d-flex justify-content-end align-items-center">
                        <div className="mr-3">
                            <p>
                                <Wifi style={{ color: "green" }} /> {onlineRidersCount} Online
                            </p>
                            <p>
                                <WifiOff style={{ color: "red" }} /> {offlineRidersCount} Offline
                            </p>
                        </div>
                    </Col>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Mobile</th>
                                <th>Email</th>
                                <th>Carrier Type</th>
                                <th className="text-center">Online Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {riders.map((rider, index) => (
                                <tr key={rider._id}>
                                    <td>{index + 1}</td>
                                    <td>{`${rider.firstName} ${rider.lastName}`}</td>
                                    <td>{rider.mobile}</td>
                                    <td>{rider.email}</td>
                                    <td>{rider.carrierType}</td>
                                    <td className="text-center">
                                        {rider.onlineStatus} <FiberManualRecordIcon style={{ color: getStatusColor(rider.onlineStatus) }} />
                                    </td>
                                    <td>
                                        <Button
                                            variant="primary"
                                            onClick={() => handleShowRiderForm(rider)}
                                            style={{ marginRight: '10px' }}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            variant="danger"
                                            onClick={() => confirmDelete(rider._id, `${rider.firstName} ${rider.lastName}`)}
                                        >
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            )}
            <RetailerRiderForm
                show={showRiderForm}
                handleClose={handleCloseRiderForm}
                vendorId={vendorId}
                riderToEdit={riderToEdit}
            />

            <Modal show={showConfirmDialog} onHide={handleCloseConfirmDialog}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete {riderName}?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirmDialog}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default RetailerRidersTable;
