import * as api from '../api';
import {
    FETCH_RESTAURANT_PROFILE,
    UPDATE_RESTAURANT_PROFILE,
    START_LOADING, END_LOADING,
    FETCH_RESTAURANT_PROFILE_ERROR
} from '../constants/actionTypes';

export const fetchRestaurantProfile = (vendorId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });

        const { data } = await api.apiFetchRestaurantProfile(vendorId);

        dispatch({ type: FETCH_RESTAURANT_PROFILE, payload: data });

        dispatch({ type: END_LOADING });
    } catch (error) {
        dispatch({ type: FETCH_RESTAURANT_PROFILE_ERROR, payload: error.message });
        dispatch({ type: END_LOADING });
    }
};

export const updateRestaurantProfile = (vendorId, formData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });

        const { data } = await api.apiUpdateRestaurantProfile(vendorId, formData);

        dispatch({ type: UPDATE_RESTAURANT_PROFILE, payload: data });

        dispatch({ type: END_LOADING });
    } catch (error) {
        dispatch({ type: FETCH_RESTAURANT_PROFILE_ERROR, payload: error.message });
        dispatch({ type: END_LOADING });
    }
};
