import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Card,
  Col,
  Container,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import Wifi from "@material-ui/icons/Wifi";
import WifiOff from "@material-ui/icons/WifiOff";
import { FaPlus } from "react-icons/fa";

import { deleteRider, getAllRiders } from "../../actions/ridersAction";
import { getUserFromLocalStorage } from "utilities/user";
import RiderForm from "views/Forms/RiderForm";

const RidersTable = () => {
  const { riders, loading } = useSelector((state) => state.riders);
  const user = getUserFromLocalStorage();
  const dispatch = useDispatch();

  const [showRiderForm, setShowRiderForm] = useState(false);
  const [riderToEdit, setRiderToEdit] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [riderToDelete, setRiderToDelete] = useState(null);

  const socketUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    dispatch(getAllRiders());
  }, [dispatch]);

  useEffect(() => {
    const socket = io(socketUrl, {
      query: { user: user?.result?._id },
      transports: ["websocket"],
    });

    socket.on("connect", () => {
      console.log("Connected to socket");
    });

    socket.on("riderStatusUpdate", (statusUpdate) => {
      dispatch({
        type: "UPDATE_RIDER_STATUS",
        payload: statusUpdate,
      });
    });

    return () => {
      socket.disconnect();
    };
  }, [dispatch, socketUrl, user?.result?._id]);

  const onlineRiders = riders.filter(
    (rider) => rider.onlineStatus === "Online"
  );
  const offLineRiders = riders.filter(
    (rider) => rider.onlineStatus === "Offline"
  );

  const handleShowRiderForm = (rider = null) => {
    setRiderToEdit(rider);
    setShowRiderForm(true);
  };

  const handleCloseRiderForm = () => {
    setRiderToEdit(null);
    setShowRiderForm(false);
  };

  const handleShowDeleteConfirm = (rider) => {
    setRiderToDelete(rider);
    setShowDeleteConfirm(true);
  };

  const handleCloseDeleteConfirm = () => {
    setRiderToDelete(null);
    setShowDeleteConfirm(false);
  };

  const handleConfirmDelete = () => {
    if (riderToDelete) {
      dispatch(deleteRider(riderToDelete._id, user?.result?._id));
    }
    handleCloseDeleteConfirm();
  };

  return (
    <>
      {loading && (
        <Spinner
          animation="grow"
          variant="danger"
          style={{
            marginTop: "70px",
            top: "50%",
            left: "50%",
            position: "fixed",
          }}
        />
      )}
      <Container fluid>
        <Row className="mb-3">
          <Col>
            <Button variant="primary" onClick={() => handleShowRiderForm()}>
              <FaPlus /> Add Rider
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="regular-table-with-color">
              <Card.Header>
                <Card.Title as="h4">Active Riders</Card.Title>
                <div
                  className="d-flex justify-content-between"
                  style={{
                    position: "relative",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      right: "0",
                      top: "0",
                      display: "flex",
                    }}
                  >
                    <p
                      className="card-category"
                      style={{
                        top: "0",
                        margin: "0",
                        padding: "0.5rem 1rem",
                        backgroundColor: "#177",
                        borderRadius: "0 0 0 0.25rem",
                        color: "#fff",
                        fontWeight: "600",
                        fontSize: "0.875rem",
                        lineHeight: "1.5",
                        textTransform: "capitalize",
                        letterSpacing: "0.04em",
                        border: "1px solid rgba(0,0,0,0.125)",
                        borderTop: "none",
                        borderRight: "none",
                        borderBottom: "none",
                        borderLeft: "1px solid rgba(0,0,0,0.125)",
                      }}
                    >
                      <Wifi style={{ color: "#1F2", fontSize: 16 }} />{" "}
                      {onlineRiders.length} Online
                    </p>
                    <p
                      className="card-category"
                      style={{
                        top: "0",
                        margin: "0",
                        padding: "0.5rem 1rem",
                        backgroundColor: "#FF2976",
                        borderRadius: "0 0.25rem 0.25rem 0",
                        color: "#fff",
                        fontWeight: "600",
                        fontSize: "0.875rem",
                        lineHeight: "1.5",
                        textTransform: "capitalize",
                        letterSpacing: "0.04em",
                        border: "1px solid rgba(0,0,0,0.125)",
                        borderTop: "none",
                        borderRight: "none",
                        borderBottom: "none",
                        borderLeft: "1px solid rgba(0,0,0,0.125)",
                      }}
                    >
                      <WifiOff style={{ color: "#FFEC00", fontSize: 17 }} />
                      {"  "}
                      {offLineRiders.length} Offline
                    </p>
                  </div>
                </div>
                <p className="card-category">Riders ~ All Active Riders.</p>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover">
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>Mobile Number</th>
                      <th>Email</th>
                      <th>Name</th>
                      <th>Carrier Type</th>
                      <th>Status</th>
                      <th>Owning Vendor</th>
                      <th className="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {riders?.map((item, index) => (
                      <tr key={item?._id}>
                        <td className="text-center">{index + 1}</td>
                        <td>{item?.mobile}</td>
                        <td>{item?.email}</td>
                        <td>{`${item?.firstName} ${item?.lastName}`}</td>
                        <td>{item?.carrierType}</td>
                        <td>{item?.onlineStatus}</td>
                        <td>{item?.vendorName}</td>
                        <td className="td-actions text-right">
                          <ButtonToolbar>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="edit-tooltip">Edit Rider</Tooltip>
                              }
                            >
                              <Button
                                variant="info"
                                size="sm"
                                onClick={() => handleShowRiderForm(item)}
                              >
                                <i className="fa fa-edit"></i>
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="delete-tooltip">
                                  Delete Rider
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => handleShowDeleteConfirm(item)}
                              >
                                <i className="fa fa-times"></i>
                              </Button>
                            </OverlayTrigger>
                          </ButtonToolbar>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <RiderForm
        show={showRiderForm}
        handleClose={handleCloseRiderForm}
        riderToEdit={riderToEdit}
      />
      <Modal show={showDeleteConfirm} onHide={handleCloseDeleteConfirm}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this rider?
          {riderToDelete && (
            <p>
              {riderToDelete.firstName} {riderToDelete.lastName} (
              {riderToDelete.email})
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteConfirm}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RidersTable;
