import React, { useEffect, useRef, useState, useMemo } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import {
  superUserRoutes,
  supervisorRoutes,
  authRoute,
  restaurantVendorRoutes,
  retailerWholesalerRoutes,
} from "../routes.js";
import image3 from "assets/img/full-screen-image-3.jpg";
import RoutesContext from "../context/routesContext.js";
import { getUserFromLocalStorage } from "../utilities/user.js";

const Admin = () => {
  const history = useHistory();
  const user = getUserFromLocalStorage();
  const [userRoutes, setUserRoutes] = useState([]);

  const getRoutes = useMemo(() => {
    const flattenRoutes = (routes) => {
      return routes.flatMap((prop) => {
        if (prop.collapse) {
          return flattenRoutes(prop.views);
        }
        if (prop.layout === "/admin") {
          return (
            <Route
              path={prop.layout + prop.path}
              render={(props) => <prop.component {...props} />}
              key={prop.path}
            />
          );
        }
        return null;
      }).filter(Boolean);
    };

    return flattenRoutes;
  }, []);

  useEffect(() => {
    const updateRoutes = (user) => {
      if (user === null) return authRoute;
      if (user?.result?.userType === "LUSUKU STAFF") {
        if (user?.result?.user_role === "SUPERUSER" || user?.result?.user_role === "SUPERVISOR") {
          return superUserRoutes;
        }
        if (user?.result?.user_role === "SUPERVISOR") {
          return supervisorRoutes;
        }
      }
      if (user?.result?.user_role === "SUPERVISOR") {
        if (user?.result?.operationMode === "RESTAURANT") {
          return restaurantVendorRoutes;
        }
        if (["RETAILER", "WHOLESALER"].includes(user?.result?.operationMode)) {
          return retailerWholesalerRoutes;
        }
      }
      return authRoute;
    };

    const newRoutes = updateRoutes(user);

    if (JSON.stringify(newRoutes) !== JSON.stringify(userRoutes)) {
      setUserRoutes(newRoutes);
    }

    const unListen = history.listen(() => {
      const newUser = getUserFromLocalStorage();
      const updatedRoutes = updateRoutes(newUser);

      if (JSON.stringify(updatedRoutes) !== JSON.stringify(userRoutes)) {
        setUserRoutes(updatedRoutes);
      }
    });

    return unListen;
  }, [user, userRoutes, history]);

  const sidebarImageRef = useRef(image3);
  const sidebarBackgroundRef = useRef("black");

  return (
    <RoutesContext.Provider value={userRoutes}>
      <div className="wrapper">
        <Sidebar
          routes={userRoutes}
          image={sidebarImageRef.current}
          background={sidebarBackgroundRef.current}
        />
        <div className="main-panel">
          <AdminNavbar />
          <div className="content">
            <Switch>
              {getRoutes(userRoutes)}
              <Route path="/admin" exact>
                <Redirect to="/admin/dashboard" />
              </Route>
            </Switch>
          </div>
          <AdminFooter />
          <div
            className="close-layer"
            onClick={() => document.documentElement.classList.toggle("nav-open")}
          />
        </div>
      </div>
    </RoutesContext.Provider>
  );
};

export default Admin;