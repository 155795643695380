export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";
export const RESET_CART = "RESET_CART";
export const CART_CLEAR_ITEMS = "CART_CLEAR_ITEMS";
export const CART_RESET = "CART_RESET";
export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";
export const CART_SAVE_SHIPPING_ADDRESS = "CART_SAVE_SHIPPING_ADDRESS";
export const CART_CLEAR_SHIPPING_ADDRESS = "CART_CLEAR_SHIPPING_ADDRESS";
export const CART_SAVE_PAYMENT_METHOD = "CART_SAVE_PAYMENT_METHOD";
export const CART_CLEAR_PAYMENT_METHOD = "CART_CLEAR_PAYMENT_METHOD";
// export const CART_LISTING_DETAILS_REQUEST = 'CART_LISTING_DETAILS_REQUEST'
// export const CART_LISTING_DETAILS_SUCCESS = 'CART_LISTING_DETAILS_SUCCESS'
export const CART_LISTING_DETAILS_FAIL = "CART_LISTING_DETAILS_FAIL";
