import React, { useEffect, useRef, useState } from "react";
import AdminFooter from "components/Footers/AdminFooter";
import AdminNavbar from "components/Navbars/AdminNavbar";
import { Card, Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSellerDetail } from "../../../actions/vendorActions";

const SellerEditForm = ({ match }) => {
  const sellerID = match.params.id;
  const { seller, loading } = useSelector((state) => state.sellers);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSellerDetail(sellerID));
  }, []);

  return (
    <Container fluid>
      <div className="section-image" data-image="../../assets/img/bg5.jpg">
        {loading ? (
          <Spinner
            animation="grow"
            variant="primary"
            style={{ position: "absolute", top: "50%", left: "50%" }}
          />
        ) : (
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Seller Details</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Shop Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="First Name"
                            value={seller?.businessName}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Operation Mode</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Last Name"
                            value={seller?.operationMode}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Email"
                            value={seller?.email}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Phone Number"
                            value={seller?.businessPhone}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Shop Address</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Address"
                            value={seller?.businessAddress}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Shop Address</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Address"
                            value={seller?.businessAddress}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Account Role</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="City"
                            value={seller?.user_role}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Account Type</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="State"
                            value={seller?.userType}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Business TIN</Form.Label>
                          <Form.Control
                            type="text"
                            //   placeholder="TIN"
                            value={seller?.businessTIN}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Verification Status</Form.Label>
                          <Form.Control
                            type="text"
                            value={seller?.sellerVerification}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Activation Status</Form.Label>
                          <Form.Control
                            type="text"
                            //   placeholder="Business Type"
                            value={seller?.isActive}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Business Description</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Business products speciality"
                            value={seller?.typeOfGoods}
                            //   disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row></Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </Container>
  );
};

export default SellerEditForm;
