import axios from "axios";
import { toast } from "react-toastify";

import {
  CREATE_SI_UNIT,
  FETCH_SI_UNITS,
  FETCH_SI_UNIT,
  UPDATE_SI_UNIT,
  DELETE_SI_UNIT,
  // CREATE_SHIPPING_COST,
  // UPDATE_SHIPPING_COST,
  // FETCH_SHIPPING_COSTS,
  // DELETE_SHIPPING_COST,
} from "../constants/siUnitConstants";
import { START_LOADING, END_LOADING } from "../constants/webOrderConstants";

export const createSiUnit = (post) => async (dispatch) => {
  try {
    // dispatch({ type: START_LOADING });
    const { data } = await axios.post("/unit/create", post);

    dispatch({ type: CREATE_SI_UNIT, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};

// // Create Shipping Cost
// export const createShippingCost = (postData) => async (dispatch) => {
//   const { userId, userRole, cost, shippingCostDescription } = postData;
//   // console.log(userRole, "userRole....");
//   // console.log(postData, "type of Cost....");

//   try {
//     // userId/:role/createShippingCost
//     const { data } = await axios.post(
//       `/posts/${userId}/${userRole}/createShippingCost`,
//       {
//         cost,
//         shippingCostDescription,
//       }
//     );
//     // console.log(data, "shippingCost");

//     dispatch({ type: CREATE_SHIPPING_COST, payload: data });
//     dispatch({ type: END_LOADING });
//   } catch (error) {
//     console.log(error.response.data.message);
//     toast.error(error.response.data.message);
//   }
// };

// export const getShippingCosts = () => async (dispatch) => {
//   try {
//     dispatch({ type: START_LOADING });
//     const { data } = await axios.get("/posts/shippingCost");

//     dispatch({
//       type: FETCH_SHIPPING_COSTS,
//       payload: data,
//     });

//     dispatch({ type: END_LOADING });
//   } catch (error) {
//     console.log(error);
//   }
// };

// // Update Shipping Cost
// export const updateShippingCost =
//   (id, updatedShippingCost) => async (dispatch) => {
//     try {
//       dispatch({ type: START_LOADING });
//       const { data } = await axios.patch(
//         `/posts/updateShippingCost/${id}`,
//         updatedShippingCost
//       );

//       dispatch({ type: UPDATE_SHIPPING_COST, payload: data });

//       dispatch({ type: END_LOADING });
//     } catch (error) {
//       console.log(error);
//       toast.error(error);
//     }
//   };

// // Delete Shipping Cost
// export const deleteShippingCost = (postData) => async (dispatch) => {
//   const { _id, userRole, userId } = postData;
//   // console.log(id, "userRole....");
//   // console.log(userId, "userId....");
//   // console.log(postData, "postData....");
//   // console.log(shippingCostId, "shippingCostId....");
//   try {
//     const { data } = await axios.delete(
//       // `/posts/deleteShippingCost/${_id}/${userRole}`,
//       `/posts/deleteShippingCost/${userId}/${userRole}`,
//       {
//         data: { _id },
//       }
//     );

//     dispatch({ type: DELETE_SHIPPING_COST, payload: _id, data });
//     toast.success("Shipping Cost Deleted Successfully!");
//   } catch (error) {
//     console.log(error);
//     toast.error(error);
//   }
// };

export const getSiUnits = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await axios.get("/unit");

    dispatch({
      type: FETCH_SI_UNITS,
      payload: data,
    });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getSiUnit = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await axios.get(`/unit/${id}`);

    dispatch({
      type: FETCH_SI_UNIT,
      payload: { siUnit: data },
    });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const updateSiUnit = (id, updatedUnit) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await axios.patch(`/unit/update/${id}`, updatedUnit);

    dispatch({ type: UPDATE_SI_UNIT, payload: data });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
    toast.error(error);
  }
};

export const deleteUnit = (id) => async (dispatch) => {
  try {
    const { data } = await axios.delete(`/unit/delete/${id}`);

    dispatch({ type: DELETE_SI_UNIT, payload: id, data });
    toast.success("Unit Deleted Successfully!");
  } catch (error) {
    console.log(error.response);
    toast.error(error.response);
  }
};
