// actions/optionActions.js

import { toast } from 'react-toastify';
import * as api from '../api';
import { START_LOADING, END_LOADING, CREATE_OPTION, GET_OPTIONS, UPDATE_OPTION, DELETE_OPTION, CREATE_OPTION_GROUP, GET_OPTION_GROUPS, UPDATE_OPTION_GROUP, DELETE_OPTION_GROUP } from '../constants/actionTypes';

// Create Restaurant Option
export const createRestaurantOption = (optionData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiCreateRestaurantOption(optionData);
        dispatch({ type: CREATE_OPTION, payload: data.data });
        dispatch({ type: END_LOADING });
        toast.success(data.message);
    } catch (error) {
        console.error(error.response?.data?.message || error.message);
        toast.error(error.response?.data?.message || error.message);
        dispatch({ type: END_LOADING });
    }
};

// Fetch Restaurant Options
export const fetchOptionsRestaurants = (restaurantId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiFetchOptionsRestaurants(restaurantId);
        dispatch({ type: GET_OPTIONS, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.error("Error fetching options:", error);
        toast.error("Failed to fetch options: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Update Option
export const updateOption = (optionId, updatedOptionData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiUpdateOption(optionId, updatedOptionData);
        dispatch({ type: UPDATE_OPTION, payload: data.data });
        dispatch({ type: END_LOADING });
        toast.success("Option updated successfully!");
    } catch (error) {
        console.error("Error updating option:", error);
        toast.error("Failed to update option: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Delete Option
export const deleteOption = (optionId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        await api.apiDeleteOption(optionId);
        dispatch({ type: DELETE_OPTION, payload: optionId });
        dispatch({ type: END_LOADING });
        toast.success("Option deleted successfully!");
    } catch (error) {
        console.error("Error deleting option:", error);
        toast.error("Failed to delete option: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Create Restaurant Option Group
export const createRestaurantOptionGroup = (optionGroupData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiRestaurantCreateOptionGroup(optionGroupData);
        dispatch({ type: CREATE_OPTION_GROUP, payload: data.data });
        dispatch({ type: END_LOADING });
        toast.success("Option group created successfully!");
    } catch (error) {
        console.log(error, "error in createRestaurantOptionGroup")
        console.error(error.response?.data?.message || error.message);
        toast.error(error.response?.data?.message || error.message);
        dispatch({ type: END_LOADING });
    }
};

// Fetch Restaurant Option Groups
export const fetchOptionGroupsRestaurant = (restaurantId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiFetchOptionGroupsRestaurant(restaurantId);
        // console.log(data, "data in fetchOptionGroupsRestaurant??")
        dispatch({ type: GET_OPTION_GROUPS, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log(error, "error in fetchOptionGroupsRestaurant??")
        console.error("Error fetching option groups:", error);
        toast.error("Failed to fetch option groups: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Update Option Group
export const updateOptionGroup = (optionGroupId, updatedOptionGroupData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiUpdateOptionGroup(optionGroupId, updatedOptionGroupData);
        dispatch({ type: UPDATE_OPTION_GROUP, payload: data.data });
        dispatch({ type: END_LOADING });
        toast.success("Option group updated successfully!");
    } catch (error) {
        console.error("Error updating option group:", error);
        toast.error("Failed to update option group: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Delete Option Group
export const deleteOptionGroup = (optionGroupId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        await api.apiDeleteOptionGroup(optionGroupId);
        dispatch({ type: DELETE_OPTION_GROUP, payload: optionGroupId });
        dispatch({ type: END_LOADING });
        toast.success("Option group deleted successfully!");
    } catch (error) {
        console.error("Error deleting option group:", error);
        toast.error("Failed to delete option group: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};


//OPTIONS & OPTIONS GROUPS FOR RETAILERS
// Create Retailer/Wholesaler Option
export const createRetailerWholesalerOption = (optionData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiCreateRetailerWholesalerOption(optionData);
        dispatch({ type: CREATE_OPTION, payload: data.data });
        dispatch({ type: END_LOADING });
        toast.success(data.message);
    } catch (error) {
        console.error(error.response?.data?.message || error.message);
        toast.error(error.response?.data?.message || error.message);
        dispatch({ type: END_LOADING });
    }
};

// Fetch Retailer/Wholesaler Options
export const fetchRetailerWholesalerOptions = (vendorId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiFetchRetailerWholesalerOptions(vendorId);
        dispatch({ type: GET_OPTIONS, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.error("Error fetching options:", error);
        toast.error("Failed to fetch options: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Update Retailer/Wholesaler Option
export const updateRetailerWholesalerOption = (optionId, updatedOptionData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiUpdateRetailerWholesalerOption(optionId, updatedOptionData);
        dispatch({ type: UPDATE_OPTION, payload: data.data });
        dispatch({ type: END_LOADING });
        toast.success("Option updated successfully!");
    } catch (error) {
        console.error("Error updating option:", error);
        toast.error("Failed to update option: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Delete Retailer/Wholesaler Option
export const deleteRetailerWholesalerOption = (optionId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        await api.apiDeleteRetailerWholesalerOption(optionId);
        dispatch({ type: DELETE_OPTION, payload: optionId });
        dispatch({ type: END_LOADING });
        toast.success("Option deleted successfully!");
    } catch (error) {
        console.error("Error deleting option:", error);
        toast.error("Failed to delete option: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Create Retailer/Wholesaler Option Group
export const createRetailerWholesalerOptionGroup = (optionGroupData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiCreateRetailerWholesalerOptionGroup(optionGroupData);
        dispatch({ type: CREATE_OPTION_GROUP, payload: data.data });
        dispatch({ type: END_LOADING });
        toast.success("Option group created successfully!");
    } catch (error) {
        console.error(error.response?.data?.message || error.message);
        toast.error(error.response?.data?.message || error.message);
        dispatch({ type: END_LOADING });
    }
};

// Fetch Retailer/Wholesaler Option Groups
export const fetchRetailerWholesalerOptionGroups = (vendorId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiFetchRetailerWholesalerOptionGroups(vendorId);
        dispatch({ type: GET_OPTION_GROUPS, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.error("Error fetching option groups:", error);
        toast.error("Failed to fetch option groups: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Update Retailer/Wholesaler Option Group
export const updateRetailerWholesalerOptionGroup = (optionGroupId, updatedOptionGroupData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.apiUpdateRetailerWholesalerOptionGroup(optionGroupId, updatedOptionGroupData);
        dispatch({ type: UPDATE_OPTION_GROUP, payload: data.data });
        dispatch({ type: END_LOADING });
        toast.success("Option group updated successfully!");
    } catch (error) {
        console.error("Error updating option group:", error);
        toast.error("Failed to update option group: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};

// Delete Retailer/Wholesaler Option Group
export const deleteRetailerWholesalerOptionGroup = (optionGroupId) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        await api.apiDeleteRetailerWholesalerOptionGroup(optionGroupId);
        dispatch({ type: DELETE_OPTION_GROUP, payload: optionGroupId });
        dispatch({ type: END_LOADING });
        toast.success("Option group deleted successfully!");
    } catch (error) {
        console.error("Error deleting option group:", error);
        toast.error("Failed to delete option group: " + (error.response?.data?.message || error.message));
        dispatch({ type: END_LOADING });
    }
};