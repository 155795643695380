import React, { useEffect, useState } from "react";
import { getUser, getUsers, deleteStaffUser } from "../../actions/auth";
import {
  Button,
  Card,
  Col,
  Container,
  ButtonToolbar,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button as MaterialButton,
} from "@material-ui/core";
import useStyles from "./userStyle.js";
import { getUserFromLocalStorage } from "utilities/user";

const Users = () => {
  // const [user] = useState(JSON.parse(localStorage.getItem("profile")));
  const user = getUserFromLocalStorage();
  const { authorizedUsers, isLoading, userCreated } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [openDeletionDialog, setOpenDeletionDialog] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState(null);

  useEffect(() => {
    dispatch(getUsers(user?.result?._id, user?.result?.user_role));
  }, [userCreated]);

  const handleDeleteUser = (agentId) => {
    dispatch(deleteStaffUser(agentId, user?.result?._id)).then(() => {
      dispatch(getUsers(user?.result?._id, user?.result?.user_role));
    });
  };

  if (
    user?.result?.user_role === "CLIENT" ||
    user?.result?.user_role === "CREATOR"
  ) {
    return (
      <>
        <Container fluid>
          <Row>
            <Col md={6}>
              <Typography elevation={6} variant="h5" color="error">
                <i className="fas fa-users"></i>&nbsp;&nbsp; Restricted View For
                Admins Only!
              </Typography>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  const showDeletionDialog = (agentId) => {
    setSelectedAgentId(agentId);
    setOpenDeletionDialog(true);
  };

  const handleAgentDeletion = () => {
    if (selectedAgentId) {
      handleDeleteUser(selectedAgentId);
    }
    setOpenDeletionDialog(false);
  };

  return (
    <>
      <Container fluid>
        {isLoading ? (
          <Spinner
            animation="grow"
            variant="danger"
            style={{
              marginTop: "70px",
              top: "50%",
              left: "50%",
              position: "fixed",
            }}
          />
        ) : (
          <Row>
            <Col md="11">
              <Card className="regular-table-with-color">
                <Card.Header>
                  <Card.Title as="h4">Lusuku Staff</Card.Title>
                  <p className="card-category">List of active Agents</p>
                </Card.Header>
                <Col md="12">
                  <Card.Body className="table-responsive p-0">
                    <Table className="table-hover table-striped w-full">
                      <thead>
                        <tr>
                          <th>
                            <strong>#</strong>
                          </th>
                          <th>
                            <strong>Name</strong>
                          </th>
                          <th>
                            <strong>Email</strong>
                          </th>
                          <th>
                            <strong>ID</strong>
                          </th>
                          <th>
                            <strong>Role</strong>
                          </th>
                          <th>
                            <strong>Action</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {authorizedUsers
                          ?.filter(
                            (agent) => agent?.user?.userType == "LUSUKU STAFF"
                          )
                          ?.map((agent, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{agent?.user?.name}</td>
                              <td>{agent?.user?.email}</td>
                              <td>{agent?.user?._id}</td>
                              <td>{agent?.user?.user_role}</td>
                              <td>
                                <ButtonToolbar>
                                  {["top"].map((anchor) => (
                                    <OverlayTrigger
                                      key={anchor}
                                      placement={anchor}
                                      overlay={
                                        <Tooltip id={`tooltip-${anchor}`}>
                                          Edit User Info
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        variant="primary"
                                        size="sm"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          dispatch(
                                            getUser(
                                              agent?.user?._id,
                                              user?.result?._id,
                                              user?.result?.user_role
                                            )
                                          );
                                          history.push(
                                            `/admin/user-edit/${agent?.user?._id}`
                                          );
                                        }}
                                      >
                                        <i className="fas fa-edit"></i>
                                      </Button>
                                    </OverlayTrigger>
                                  ))}
                                </ButtonToolbar>

                                {/* Delete User */}
                                <ButtonToolbar>
                                  {["right"].map((deleteAnchor) => (
                                    <OverlayTrigger
                                      key={deleteAnchor}
                                      placement={deleteAnchor}
                                      overlay={
                                        <Tooltip id={`tooltip-${deleteAnchor}`}>
                                          Delete User
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          showDeletionDialog(agent?.user?._id);
                                        }}
                                      >
                                        <i className="fas fa-trash"></i>
                                      </Button>
                                    </OverlayTrigger>
                                  ))}
                                </ButtonToolbar>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>

                    <Col style={{ paddingBottom: "15px" }}>
                      <Card.Body>
                        <Col
                          className="font-icon-list"
                          lg="2"
                          md="3"
                          sm="4"
                          xs="6"
                        >
                          <Button
                            className="btn-outline btn-round btn-wd mr-1"
                            variant="success"
                            onClick={() => history.push("/admin/user-form/")}
                          >
                            {" "}
                            <i
                              className="nc-icon nc-simple-add"
                              style={{ marginRight: "5px" }}
                            >
                              <i className="fas fa-user" />
                            </i>
                            Create Agent
                          </Button>
                        </Col>
                      </Card.Body>
                    </Col>
                  </Card.Body>
                </Col>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
      {/* Deletion Dialog Component */}
      <Dialog
        open={openDeletionDialog}
        onClose={() => setOpenDeletionDialog(false)}
        aria-labelledby="deletion-dialog-title"
        aria-describedby="deletion-dialog-description"
      >
        <DialogTitle id="deletion-dialog-title">{"Delete Agent"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="deletion-dialog-description">
            Are you sure you want to delete this agent?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MaterialButton
            onClick={() => setOpenDeletionDialog(false)}
            color="primary"
            className={classes.cancelDeletionButton}
          >
            Cancel
          </MaterialButton>
          <MaterialButton
            onClick={handleAgentDeletion}
            color="primary"
            autoFocus
            style={{ marginLeft: "8px" }}
            className={classes.deleteAgentButton}
          >
            Delete
          </MaterialButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Users;
