import { toast } from 'react-toastify';
import {
    FETCH_RESTAURANTS_REQUEST,
    FETCH_RESTAURANTS_SUCCESS,
    FETCH_RESTAURANTS_FAILURE,
    UPDATE_RESTAURANT_REQUEST,
    UPDATE_RESTAURANT_SUCCESS,
    UPDATE_RESTAURANT_FAILURE,
    DELETE_RESTAURANT_REQUEST,
    DELETE_RESTAURANT_SUCCESS,
    DELETE_RESTAURANT_FAILURE,
    DELETE_RESTAURANT_PRODUCT_REQUEST,
    DELETE_RESTAURANT_SUCCESS_PRODUCT,
    DELETE_RESTAURANT_PRODUCT_FAILURE,
    END_LOADING
} from '../constants/actionTypes.js';
import * as api from '../api/index.js';

// Thunk to fetch restaurants from the API || Conditional fetching based on vendorId
export const fetchRestaurants = (vendorId, isSuperAdmin = false) => async (dispatch) => {
    dispatch({ type: FETCH_RESTAURANTS_REQUEST });
    try {
        const response = await api.apiFetchRestaurants(vendorId, isSuperAdmin);
        dispatch({ type: FETCH_RESTAURANTS_SUCCESS, payload: response.data.restaurants });
    } catch (error) {
        dispatch({ type: FETCH_RESTAURANTS_FAILURE, payload: error.message });
    }
};

// Thunk to update a restaurant
export const updateRestaurant = (id, updatedData) => async (dispatch) => {
    dispatch({ type: UPDATE_RESTAURANT_REQUEST });
    try {
        const response = await api.apiUpdateRestaurant(id, updatedData);
        dispatch({ type: UPDATE_RESTAURANT_SUCCESS, payload: response.data.data });
    } catch (error) {
        dispatch({ type: UPDATE_RESTAURANT_FAILURE, payload: error.message });
    }
};

// Thunk to delete a restaurant
export const deleteRestaurant = (restaurantId) => async (dispatch) => {
    dispatch({ type: DELETE_RESTAURANT_REQUEST });
    try {
        const response = await api.apiDeleteRestaurant(restaurantId);
        dispatch({ type: DELETE_RESTAURANT_SUCCESS, payload: response.data.restaurants });
        toast.success(response.data.message);
    } catch (error) {
        dispatch({ type: DELETE_RESTAURANT_FAILURE, payload: error.message });
        toast.error(`Failed to delete restaurant: ${error.response?.data?.message || error.message}`);
    }
};

// Thunk to delete a restaurant product
export const deleteRestaurantProduct = (productId) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_RESTAURANT_PRODUCT_REQUEST });
        const response = await api.apiDeleteRestaurantProduct(productId);
        // console.log(response, "res>>>>>")
        dispatch({ type: DELETE_RESTAURANT_SUCCESS_PRODUCT, payload: productId });
        toast.success(response.data.message);
        dispatch({ type: END_LOADING });
    } catch (error) {
        dispatch({ type: DELETE_RESTAURANT_PRODUCT_FAILURE, payload: error.message });
    }
};





// // src/actions/restaurantActions.js
// import { toast } from 'react-toastify';
// import {
//     FETCH_RESTAURANTS_FAILURE,
//     FETCH_RESTAURANTS_REQUEST,
//     FETCH_RESTAURANTS_SUCCESS,
//     UPDATE_RESTAURANT_FAILURE,
//     UPDATE_RESTAURANT_REQUEST,
//     UPDATE_RESTAURANT_SUCCESS,
//     DELETE_RESTAURANT_REQUEST,
//     DELETE_RESTAURANT_SUCCESS,
//     DELETE_RESTAURANT_FAILURE
// } from '../constants/actionTypes.js';
// import * as api from '../api/index.js';

// // Action Creators
// export const fetchRestaurantsRequest = () => ({
//     type: FETCH_RESTAURANTS_REQUEST,
// });

// export const fetchRestaurantsSuccess = (restaurants) => ({
//     type: FETCH_RESTAURANTS_SUCCESS,
//     payload: restaurants,
// });

// export const fetchRestaurantsFailure = (error) => ({
//     type: FETCH_RESTAURANTS_FAILURE,
//     payload: error,
// });

// // Thunk to fetch restaurants from the API || Conditional fetching based on vendorId
// export const fetchRestaurants = (vendorId, isSuperAdmin = false) => async (dispatch) => {
//     dispatch(fetchRestaurantsRequest());
//     try {
//         const response = await api.apiFetchRestaurants(vendorId, isSuperAdmin);
//         dispatch(fetchRestaurantsSuccess(response.data.restaurants));
//     } catch (error) {
//         dispatch(fetchRestaurantsFailure(error.message));
//     }
// };

// // Action creators for updating a restaurant
// export const updateRestaurantRequest = () => ({
//     type: UPDATE_RESTAURANT_REQUEST,
// });

// export const updateRestaurantSuccess = (updatedRestaurant) => ({
//     type: UPDATE_RESTAURANT_SUCCESS,
//     payload: updatedRestaurant,
// });

// export const updateRestaurantFailure = (error) => ({
//     type: UPDATE_RESTAURANT_FAILURE,
//     payload: error,
// });

// // Thunk to update a restaurant
// export const updateRestaurant = (id, updatedData) => async (dispatch) => {
//     dispatch(updateRestaurantRequest());
//     try {
//         const response = await api.apiUpdateRestaurant(id, updatedData);
//         console.log(response.data.data, "res>>>>>")
//         dispatch(updateRestaurantSuccess(response.data.data));
//         // dispatch(fetchRestaurants());  // Re-fetch the list of restaurants to reflect the update
//     } catch (error) {
//         dispatch(updateRestaurantFailure(error.message));
//     }
// };

// // Action creators for deleting a restaurant
// export const deleteRestaurantRequest = () => ({
//     type: DELETE_RESTAURANT_REQUEST,
// });

// export const deleteRestaurantSuccess = (restaurantId) => ({
//     type: DELETE_RESTAURANT_SUCCESS,
//     payload: restaurantId,
// });

// export const deleteRestaurantFailure = (error) => ({
//     type: DELETE_RESTAURANT_FAILURE,
//     payload: error,
// });


// // Thunk to delete a restaurant
// export const deleteRestaurant = (restaurantId) => async (dispatch) => {
//     dispatch(deleteRestaurantRequest());
//     try {
//         const response = await api.apiDeleteRestaurant(restaurantId);
//         dispatch(deleteRestaurantSuccess(response.data.restaurants));
//         toast.success(response.data.message)
//     } catch (error) {
//         dispatch(deleteRestaurantFailure(error.message));
//         toast.error(`Failed to delete restaurant: ${error.response?.data?.message || error.message}`);
//     }
// };

// export const deleteRestaurantProduct = (productId) => async (dispatch) => {
//     dispatch(deleteProductRequest());
//     try {
//         await api.apiDeleteRestaurantProduct(productId);
//         dispatch(deleteProductSuccess(productId));
//     } catch (error) {
//         dispatch(deleteProductFailure(error.message));
//     }
// };
