import React, { useEffect, useRef, useState } from "react";
import {
  Divider,
  Typography,
  Paper,
  FormControlLabel,
  Switch,
  CircularProgress,
  Chip,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

import moment from "moment";
import {
  Button,
  ButtonToolbar,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
// import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

import useStyles from "./orderDetailsStyles.js";
import Message from "../../../../views/Components/Message.js";
import DotLoader from "views/Components/DotLoader";

import { io } from "socket.io-client";

import {
  assignOrderToDeliveryAgent,
  getOrderDetailsToProcess,
  markOrderAsPaid,
  updateDeliveryStatus,
  updateOverallOrderDeliveryStatus,
} from "../../../../actions/webOrderActions";
import { useHistory } from "react-router-dom";
import { getListing } from "../../../../actions/listingsActions.js";
import { RECEIPT_URL } from "../../../../api/receiptUrl.js";
import { toast } from "react-toastify";
import { RESET_UPDATE_DELIVERY_STATUS_COMPLETE } from "constants/webOrderConstants.js";
import { getUserFromLocalStorage } from "../../../../utilities/user.js";
// import socketUrl from "../../../../constants/socketUrl";

const isDevelopment = process.env.NODE_ENV === "development";

const OrderDetailsCard = ({ match }) => {
  const user = getUserFromLocalStorage()

  const [riders, setRiders] = useState([]);
  const [riderId, setRiderId] = useState("");
  const [receiptLoading, setReceiptLoading] = useState(false);
  const [loadingRiders, setLoadingRiders] = useState(true);
  const [itemId, setItemId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const paramsOrderId = match.params.id;

  const history = useHistory();

  const mobileOrderDeliver = useSelector((state) => state.mobileOrderDeliver);
  const { loading: loadingDeliver, success: successDeliver } =
    mobileOrderDeliver;

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error, isUpdateDeliveryStatusComplete, paymentResult } =
    orderDetails;

  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderDetailsToProcess(paramsOrderId));

    // To update the card without refreshing
    if (isUpdateDeliveryStatusComplete) {
      dispatch(getOrderDetailsToProcess(paramsOrderId));
      dispatch({ type: RESET_UPDATE_DELIVERY_STATUS_COMPLETE });
    }
  }, [isUpdateDeliveryStatusComplete, paramsOrderId]);

  // Show error from redux incase there are errors esp when marking order as paid!
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  // GET RIDERS USING SOCKET.IO

  const socketUrl = process.env.REACT_APP_SERVER_URL; //Prod & Dev Ngrok
  // const socketUrl = "http://localhost:8001"; //Dev

  useEffect(() => {
    const socket = io(
      socketUrl,
      {
        query: {
          user: user?.result?._id,
        },
      },
      {
        transports: ["websocket"],
      }
    );

    // setLoading(true);
    socket.on("connect", () => {
      if (isDevelopment) {
        console.log("Webclient Connected");
      }
    });
    socket.on("lusukuRiders", (socketData) => {
      setRiders(socketData);
      setLoadingRiders(false);
      // console.log(socketData, "socketData");
    });
    // const socket = io("http://localhost:80");
    // socket.on("lusukuRiders", (data) => {
    //   setRiders(data);
    // });
    // setLoading(false);
    return () => {
      // socket.off("connect");
      // socket.off("disconnect");
      // socket.off("lusukuRiders");
      socket.disconnect();
    };
  }, []);

  // For refreshing the orderDetails after dispatching ~ Logic is different in the vendorOrderDetailsCard
  // const refreshOrderDetails = () => {
  //   dispatch(getOrderDetailsToProcess(order._id));
  // };

  const handleMarkAsPaid = () => {
    if (user?.result?.userType !== "LUSUKU STAFF") {
      toast.warn("🚫 You are not authorized to Mark order as paid!");
      return;
    } else {
      dispatch(markOrderAsPaid(order._id));
    }
  };

  // Assign order to rider
  const assignOrderToRider = () => {
    const delivery_status = "ASSIGNED";
    if (!itemId) {
      toast.warn("Please select an item.");
    } else {
      dispatch(
        assignOrderToDeliveryAgent(order._id, riderId, itemId, delivery_status)
      );
      setItemId("");
    }
  };

  //Dispatch to update order status to DISPATCHED
  const handleDispatchOrder = () => {
    const delivery_status = "DISPATCHED";
    if (!itemId) {
      toast.warn("You have not selected any order item to dispatch!");
    } else {
      dispatch(updateDeliveryStatus(order._id, itemId, delivery_status));
      setItemId("");
    }
  };

  //Dispatch to update order status to DELIVERED
  const handleDeliverOrder = () => {
    const delivery_status = "DELIVERED";
    if (!itemId) {
      toast.warn("You have not selected any order item!");
    } else {
      dispatch(updateDeliveryStatus(order._id, itemId, delivery_status));
      setItemId("");
    }
  };

  //Dispatch to update order status to REJECTED This handler updates the delivery status of all order items to "REJECTED" by iterating over the item IDs and dispatching the updateDeliveryStatus action.
  const handleCancelOrder = () => {
    const delivery_status = "REJECTED";
    const itemUpdate = order.orderItems.map((item) => ({
      itemId: item._id._id,
      delivery_status: delivery_status,
    }));

    dispatch(updateOverallOrderDeliveryStatus(order._id, itemUpdate));
    setShowDeleteModal(false);
  };

  // Generate receipt
  const generateReceiptByAgent = () => {
    setReceiptLoading(true);
    const url = `${RECEIPT_URL}/${user?.result?._id}?customerName=${order?.client_id?.name
      }&customerNumber=${order?.shippingAddress?.customerNumber}&email=${order?.client_id?.email
      }&shippingAddress=${order?.shippingAddress?.locationName?.vicinity
      }&shippingCost=${order?.shippingCost_id?.cost}
&orderNumber=${order?._id}&totalAmount=${order?.totalPrice}&taxAmount=${order?.taxPrice
      }&itemsInCart=${order?.orderItems?.length}&orderedItems=${JSON.stringify(
        order?.orderItems.map((item) => ({
          ...item,
          name: item.name.replace(/(\r\n|\n|\r)/gm, " "),
        }))
      )}&Qty=${order?.orderItems.map(
        (item) => item.qty
      )}&itemPx=${order?.orderItems.map((item) => item.price)}&cartPx=${order.itemsPrice
      }&updatedAt=${moment(order?.updatedAt)
        .utcOffset("+03:00")
        .format("lll")}&paymentStatus=${order?.isPaid ? "Paid" : "Not Paid"
      }&paidDate=${moment(order?.paidAt).utcOffset("+03:00").format("lll")}`;

    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        const file = new Blob([arrayBuffer], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = `Lusuku Receipt_${order?._id}.pdf`;
        link.click();
        setReceiptLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching the PDF:", error);
        setReceiptLoading(false);
      });
  };

  // Checks if all items are delivered or some are rejected.
  const getStatusLabel = (order) => {
    if (order && order.orderItems) {
      const allItemsDelivered = order.orderItems.every(
        (item) => item.delivery_status === "DELIVERED"
      );

      const someItemsRejected = order.orderItems.some(
        (item) => item.delivery_status === "REJECTED"
      );

      if (allItemsDelivered || (someItemsRejected && !allItemsDelivered)) {
        return "Delivered";
      }
    }

    return order?.delivery_status;
  };

  function formatDate(dateString) {
    if (!dateString) return '';  // if dateString is undefined or null, return an empty string
    const dateObj = new Date(dateString);
    // If dateObj is not a valid date, return an empty string or some default text
    if (isNaN(dateObj.getTime())) return 'Invalid Date';

    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(dateObj);
  }


  const paymentDetails = [
    order?.paymentResult?.narration,
    `TransactionId: ${order?.paymentResult?.transactionId}`,
    `Charged Amount: ${order?.paymentResult?.amount}`,
    order?.paymentResult?.flw_ref,
    `App_Fee: ${order?.paymentResult?.app_fee}`,
    order?.paymentResult?.card?.first_6digits && order?.paymentResult?.card?.last_4digits
      ? `${order?.paymentResult?.card?.first_6digits}*******${order?.paymentResult?.card?.last_4digits}`
      : `Payment Method: ${order?.paymentResult?.auth_model}`,
    `Currency: ${order?.paymentResult?.currency}`,
    formatDate(order?.paymentResult?.update_time),

  ];

  // Function to show the tooltip only when vendor details exist
  const handleShowTooltip = (item) => {
    if (item && item._id && (item._id.vendorName || item._id.vendorContactNumber || item._id.vendorAddress)) {
      setShowTooltip(true);
      document.addEventListener('click', handleClickOutsideTooltip, true);
    }
  };

  // Function to hide the tooltip
  const handleHideTooltip = () => {
    setShowTooltip(false);
    document.removeEventListener('click', handleClickOutsideTooltip, true);
  };

  const handleClickOutsideTooltip = (event) => {
    // If the click is outside the tooltip content, hide the tooltip
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      handleHideTooltip();
    }
  };

  return (
    <>
      <Container fluid>
        <div className="section-image" data-image="../../assets/img/bg5.jpg">
          {loading ? (
            <Spinner
              style={{
                marginTop: "70px",
                top: "50%",
                left: "50%",
                position: "fixed",
              }}
              animation="grow"
              size="lg"
              variant="danger"
            />
          ) : (
            <Paper
              style={{ padding: "20px", borderRadius: "15px" }}
              elevation={6}
            >
              <div className={classes.card}>
                <div className={classes.section}>
                  <Typography variant="h5" elevation={4}>
                    <strong style={{ color: "#195400" }}>
                      {/* {mobileOrder?.clientName}Client Name */}
                      {order?.client_id?.name}
                    </strong>
                  </Typography>
                  {/* <Typography gutterBottom variant="h6" color="textSecondary" component="h2">{mobileOrder?.tags.map((tag) => `#${tag} `)}</Typography> */}
                  <Divider style={{ margin: "20px 0" }} />
                  <Typography variant="body2">
                    <strong>Order No.:</strong>{" "}
                    {order?.order_number ? order?.order_number : order?._id}
                  </Typography>
                  {/* <Typography variant="body2">
            <strong> Tax:</strong> {mobileOrder?.taxPrice}
          </Typography> */}
                  <Typography variant="body2">
                    <strong>Cart Total: </strong>
                    {/* {mobileOrder?.itemsPrice
              ? mobileOrder?.itemsPrice
              : mobileOrder?.amount} */}
                    {order?.itemsPrice
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Items in Cart:</strong>
                    {""} {order?.orderItems?.length}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Agent Name:</strong>{" "}
                    {order?.agent_id?.name || order?.agent_id}
                  </Typography>
                  <Typography variant="body2">
                    <strong> Customer Number</strong>{" "}
                    {order?.shippingAddress?.customerNumber
                      ? order?.shippingAddress?.customerNumber
                      : "Not Provided"}
                  </Typography>
                  <Typography variant="body2">
                    <strong> Payment Method:</strong> {order?.paymentMethod}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Total Order Cost:</strong> ~ {""}
                    <span style={{ color: "#D29" }}>
                      {order?.totalPrice
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </Typography>
                  <Divider style={{ margin: "20px 0" }} />
                  {order?.shippingAddress?.selectedVillage?.village ? (
                    <Typography variant="body2">
                      <strong>Delivery Zone:</strong>{" "}
                      {order?.shippingAddress?.selectedVillage?.village}
                    </Typography>
                  ) : (
                    <Typography variant="body2">
                      <strong>Delivery Zone:</strong>{" "}
                      {order?.shippingAddress?.locationName?.vicinity}
                      {order?.shippingAddress?.description}
                    </Typography>
                  )}
                  {order?.shippingAddress?.address ? (
                    <Typography variant="body2">
                      <strong>Address:</strong> <br />
                      {order?.shippingAddress?.address}
                      {order?.shippingAddress?.address?.address}
                    </Typography>
                  ) : (
                    <Typography variant="body2">
                      <strong>Address:</strong>{" "}
                      {order?.shippingAddress?.locationName?.name}
                    </Typography>
                  )}
                  <Typography variant="body2">
                    <strong>Shipping Fees:</strong>{" "}
                    {order?.shippingAddress?.selectedVillage?.shippingCost
                      ? order?.shippingAddress?.selectedVillage?.shippingCost
                      : order?.shippingCost_id?.cost
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Delivery Schedule:</strong> ~ {""}
                    <span style={{ color: "#9FC131" }}>
                      {order?.deliverySchedule
                        ? order?.deliverySchedule
                        : "Call in"}
                    </span>
                  </Typography>
                  <Typography variant="body2">
                    <strong>LatLng:</strong> ~ {""}
                    <span style={{ color: "#004152" }}>
                      <br />
                      {order?.shippingAddress?.latLng?.lat} ||{" "}
                      {order?.shippingAddress?.latLng?.lng}
                    </span>
                  </Typography>
                  {/* <Typography variant="body2" style={{ textTransform: "capitalize" }}>
            <strong>MSIDN:</strong>{" "}
            {mobileOrder?.payer?.partyId || mobileOrder?.contactMobile}
          </Typography> */}
                  <Divider style={{ margin: "20px 0" }} />
                  <Typography variant="body2">
                    <strong>Time placed</strong>{" "}
                    {moment(order?.createdAt).fromNow()}
                  </Typography>
                  {!order?.deliveredAt ? (
                    <Typography variant="body2">
                      <strong>Status:</strong> {getStatusLabel(order)}
                    </Typography>
                  ) : (
                    <Typography variant="body2">
                      <strong>Delivered:</strong>
                      {""} {moment(order?.deliveredAt).fromNow()}
                    </Typography>
                  )}
                  {order?.riderId && (
                    <Typography variant="body2">
                      <strong>DA:</strong>{" "}
                      {order?.riderId?.firstName +
                        " " +
                        order?.riderId?.lastName}
                    </Typography>
                  )}
                  <Divider style={{ margin: "20px 0" }} />
                  {loadingDeliver && <DotLoader />}
                  <Divider style={{ margin: "20px 0" }} />
                  {/* Select and Assign Order to a Rider Online */}
                  {/* The 'Assign Order' button is rendered under the following conditions: */}
                  {/* 1. The `delivery_status` of the order is not "REJECTED". */}
                  {/* 2. The `delivery_status` of the order is "ASSIGNED". */}
                  {/* 3. At least one item within the `orderItems` array has a `delivery_status` of "PROCESSING". */}
                  {/* If any of these conditions are not met, the 'Assign Order' button will not be rendered. */}
                  {(order?.delivery_status !== "REJECTED" &&
                    order?.delivery_status === "ASSIGNED") ||
                    (order?.delivery_status === "PROCESSING" &&
                      order?.orderItems?.some(
                        (item) => item.delivery_status === "PROCESSING"
                      ) && (
                        <div>
                          <Typography variant="h6">
                            <strong>Assign Order</strong>
                          </Typography>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Form.Control
                              as="select"
                              onChange={(e) => {
                                setRiderId(e.target.value);
                              }}
                            >
                              <option value="">Select Rider</option>
                              {loadingRiders ? (
                                <option value="" disabled>
                                  Searching for riders...
                                </option>
                              ) : (
                                riders?.map((rider) => (
                                  <option key={rider._id} value={rider._id}>
                                    {rider.firstName + " " + rider.lastName} ||{" "}
                                    {""}
                                    {rider.onlineStatus}
                                  </option>
                                ))
                              )}
                            </Form.Control>
                            {loadingRiders && (
                              <div
                                style={{
                                  marginLeft: "2px",
                                }}
                              >
                                <CircularProgress size={18} />
                              </div>
                            )}
                          </div>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "5px" }}
                            onClick={assignOrderToRider}
                            className={`${classes.disabledButton} ${classes.notAllowedCursor}`}
                            data-disabled={!riderId || loadingRiders}
                          >
                            Assign Order to Rider
                          </Button>
                        </div>
                      ))}

                  {/*  */}
                  <Divider style={{ margin: "20px 0" }} />
                  {/* DISPATCH ORDER */}
                  {/* The button will be displayed when there is at least one item with a delivery_status of "DISPATCHED" and there is at least one item with a status other than "CANCELLED" or "DISPATCHED". It will not be displayed when all items have a delivery_status of "DISPATCHED" or all items have a status of either "REJECTED" or "DISPATCHED". */}
                  {order?.orderItems &&
                    // Check if there are order items.
                    !order.orderItems.every(
                      (item) => item.delivery_status === "DISPATCHED"
                    ) &&
                    // Check if all items have a delivery_status of "ASSIGNED", "DISPATCHED", or "REJECTED".
                    order.orderItems.every(
                      (item) =>
                        item.delivery_status === "ASSIGNED" ||
                        item.delivery_status === "DISPATCHED" ||
                        item.delivery_status === "REJECTED"
                    ) &&
                    // Ensure overall delivery_status is not "REJECTED".
                    order?.delivery_status !== "REJECTED" && (
                      // Render the button if all conditions are met.
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDispatchOrder}
                      >
                        Dispatch Order
                      </Button>
                    )}

                  {/* DELIVER ORDER */}
                  {order?.orderItems?.some(
                    (item) => item.delivery_status === "DISPATCHED"
                  ) && (
                      <Button
                        variant="contained"
                        color="primary"
                        // onClick={deliverMobileOrderHandler}
                        onClick={handleDeliverOrder}
                      >
                        {" "}
                        Mark Order as Delivered
                      </Button>
                    )}
                  <Divider style={{ margin: "20px 0" }} />
                  {/* CANCEL ORDER ~ Cancellation button shouldn't show if order isPaid */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {order?.delivery_status === "PROCESSING" &&
                      !order?.isPaid ? (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {order?.orderItems.every(
                          (item) =>
                            item.delivery_status === "DELIVERED" ||
                            item.delivery_status === "REJECTED"
                        ) ? (
                          <Typography variant="body2">
                            Order Cancelled
                          </Typography>
                        ) : (
                          <Button
                            className={classes.buttonCancel}
                            onClick={() => {
                              setShowDeleteModal(true);
                            }}
                          >
                            <CancelIcon
                              style={{ marginRight: "5px", fontSize: "16px" }}
                            />
                            Cancel Order
                          </Button>
                        )}
                        <Modal
                          show={showDeleteModal}
                          onHide={() => setShowDeleteModal(false)}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>
                              Confirm Order Cancellation!
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            Are you sure you want to Cancel this Order?
                          </Modal.Body>
                          <Modal.Footer
                            style={{
                              backgroundColor: "lightblue",
                              color: "#FA083B",
                              padding: "10px",
                              fontSize: "12px",
                              fontStyle: "italic",
                              borderRadius: "10px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            This will cancel all individual order items in the
                            customer's cart.
                          </Modal.Footer>

                          <Modal.Footer>
                            <Button
                              onClick={() => setShowDeleteModal(false)}
                              style={{
                                width: "150px",
                                color: "#ffff",
                                backgroundColor: "#182625",
                              }}
                            >
                              No
                            </Button>
                            <Button
                              variant="danger"
                              style={{
                                width: "150px",
                                color: "#ffff",
                                backgroundColor: "#BD2A2E",
                              }}
                              onClick={handleCancelOrder}
                            >
                              Reject Order
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    ) : order?.delivery_status === "REJECTED" ? (
                      <Typography variant="body2">Order Cancelled</Typography>
                    ) : null}
                    <FiberManualRecordIcon
                      disabled
                      className={
                        order?.isPaid
                          ? classes.paid
                          : order?.delivery_status === "REJECTED"
                            ? classes.red
                            : order?.delivery_status === "DISPATCHED"
                              ? classes.dispatchGrey
                              : order?.delivery_status === "DELIVERED"
                                ? classes.deliveredColor
                                : classes.processingOrange
                      }
                    />
                  </div>

                  {/* Generate Receipt */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                    }}
                  >
                    {/* Mark as Paid using as switch ~ Disabled until there are some order items with delivery_status as "DELIVERED"*/}
                    <FormControlLabel
                      control={
                        <Switch
                          checked={order?.isPaid || false}
                          onChange={handleMarkAsPaid}
                          color="primary"
                        />
                      }
                      label={!order?.isPaid ? "Mark as Paid" : "Paid"}
                      disabled={
                        order?.isPaid ||
                        order?.delivery_status === "REJECTED" ||
                        !order?.orderItems?.some(
                          (item) => item.delivery_status === "DELIVERED"
                        )
                      }
                    />
                    {order?.isPaid && (
                      <Typography
                        variant="caption"
                        display="block"
                        style={{ color: "green" }}
                      >
                        <strong>Pay date:</strong> {""}{" "}
                        {/* {moment(order?.paidAt).zone("+03:00").format("lll")} */}
                        {moment(order?.paidAt)
                          .utcOffset("+03:00")
                          .format("lll")}
                      </Typography>
                    )}
                    <Button
                      variant={receiptLoading ? "warning" : "primary"}
                      size="sm"
                      style={{
                        marginTop: "5px",
                        color: !order?.isPaid ? "grey" : "#fff",
                      }}
                      onClick={generateReceiptByAgent}
                      // disabled={!order?.isPaid || receiptLoading}
                      disabled
                    >
                      {receiptLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="ml-2">Generating receipt...</span>
                        </>
                      ) : (
                        "Generate Receipt"
                      )}
                    </Button>
                  </div>
                </div>
                <Card className={classes.cardSection} elevation={8}>
                  <Container fluid>
                    <Row>
                      <Col md="12">
                        <Card.Body className="table-full-width">
                          <Table>
                            <thead
                              style={{
                                backgroundColor: "blue",
                                color: "white",
                              }}
                            >
                              <tr className="info">
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-center"
                                >
                                  <strong>#</strong>
                                </th>
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-left"
                                >
                                  <strong>Item Name</strong>
                                </th>
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-center"
                                >
                                  <strong>Qty</strong>
                                </th>
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-center"
                                >
                                  <strong>Price</strong>
                                </th>
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-center"
                                >
                                  <strong>Image</strong>
                                </th>
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-center"
                                >
                                  <strong>Vendor</strong>
                                </th>
                                {/* Item selection */}
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-center"
                                >
                                  <strong>Selector</strong>
                                </th>
                                {/* Delivery status of individual order Items */}
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-center"
                                >
                                  <strong>Delivery Status</strong>
                                </th>
                                <th
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "10px",
                                    color: "#ffff",
                                  }}
                                  className="text-center"
                                >
                                  <strong>Details</strong>
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {order?.orderItems?.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    className="text-left"
                                    style={{ color: "white", fontSize: "11px" }}
                                  >
                                    {index + 1}.
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{
                                      color: "#5096d1",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {item.name}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    {item.qty}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    {item.price}
                                  </td>
                                  <td className="text-center">
                                    {item && item._id && (item._id.vendorName || item._id.vendorContactNumber || item._id.vendorAddress) ? (
                                      <OverlayTrigger
                                        key={index}
                                        placement="top"
                                        show={showTooltip}
                                        overlay={
                                          <Tooltip id={`tooltip-${index}`} style={{ pointerEvents: 'auto' }}>
                                            <div ref={tooltipRef} style={{ pointerEvents: 'auto' }}>
                                              {item._id.vendorName && <><strong>Vendor Name:</strong> {item._id.vendorName}<br /></>}
                                              {item._id.vendorContactNumber && <><strong>Contact:</strong> {item._id.vendorContactNumber}<br /></>}
                                              {item._id.vendorAddress && <><strong>Address:</strong> {item._id.vendorAddress}</>}
                                            </div>
                                          </Tooltip>
                                        }
                                      >
                                        <Image
                                          src={item.imageUrl}
                                          onMouseEnter={() => handleShowTooltip(item)}
                                          resizemode="contain"
                                          style={{
                                            width: 60,
                                            height: 55,
                                            borderRadius: "15%",
                                            cursor: 'pointer'
                                          }}
                                        />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image
                                        src={item.imageUrl}
                                        style={{
                                          width: 60,
                                          height: 55,
                                          borderRadius: "15%",
                                        }}
                                      />
                                    )}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ color: "white", fontSize: "12px" }}
                                  >
                                    {item?._id?.creator?.businessName
                                      ? item._id.creator.businessName
                                      : "In-house"}
                                  </td>

                                  {/* Radio Selection for order Assignment */}
                                  <td className="text-center">
                                    <input
                                      type="radio"
                                      value={item?._id?._id}
                                      name="itemSelection"
                                      onChange={(e) => {
                                        setItemId(e.target.value);
                                      }}
                                      disabled={
                                        item?.delivery_status === "DELIVERED"
                                      }
                                      style={{
                                        cursor:
                                          item?.delivery_status === "DELIVERED"
                                            ? "not-allowed"
                                            : "pointer",
                                      }}
                                    />
                                  </td>
                                  {/* Delivery status of individual order Items */}
                                  <td className="text-center">
                                    {item.delivery_status === "PROCESSING" && (
                                      <Chip
                                        label="Processing"
                                        color="default"
                                      />
                                    )}
                                    {item.delivery_status === "ASSIGNED" && (
                                      <Chip
                                        label="Assigned"
                                        // color="primary"
                                        style={{
                                          backgroundColor: "#00CCC0",
                                          color: "#455559",
                                        }}
                                      />
                                    )}
                                    {item.delivery_status === "DISPATCHED" && (
                                      <Chip
                                        label="Dispatched"
                                        style={{
                                          backgroundColor: "#F26800",
                                          color: "#ffff",
                                        }}
                                      />
                                    )}
                                    {item.delivery_status === "DELIVERED" && (
                                      <Chip
                                        label="Delivered"
                                        style={{
                                          backgroundColor: "#84B026",
                                          color: "#ffff",
                                        }}
                                      />
                                    )}
                                    {item.delivery_status === "REJECTED" && (
                                      <Chip
                                        label="Rejected"
                                        style={{
                                          backgroundColor: "#BD2A2E",
                                          color: "#ffff",
                                        }}
                                      />
                                    )}
                                  </td>
                                  {/* End Delivery status pill for each item */}
                                  <td className="text-center">
                                    <ButtonToolbar>
                                      {["right"].map((placement) => (
                                        <OverlayTrigger
                                          key={placement}
                                          placement={placement}
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-${placement}`}
                                            >
                                              More info
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            size="lg"
                                            variant="link"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              dispatch(
                                                getListing(item?._id?._id)
                                              );
                                              history.push(
                                                // `/shop/${item?._id}`
                                                `/admin/listing-details/${item?._id?._id}`
                                              );
                                            }}
                                          >
                                            <i
                                              className="fa fa-info-circle"
                                            // style={{ color: "#5096d1" }}
                                            // aria-hidden="true"
                                            ></i>
                                          </Button>
                                        </OverlayTrigger>
                                      ))}
                                    </ButtonToolbar>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Container>

                  <Typography
                    className={classes.messageText}
                    variant="body1"
                    component="span"
                    style={{
                      color: "#fff",
                      fontSize: "13px",
                      // fontStyle: "italic",
                    }}
                  >
                    <strong style={{ color: "#10ABB4" }}>
                      <strong style={{ fontSize: "18px" }}>📩</strong> Delivery
                      Comments:{" "}
                    </strong>
                    {order?.message ? order?.message : "Non Shared"}
                  </Typography>
                  <Typography
                    className={classes.messageText}
                    // variant="body1"
                    component="div"
                    style={{
                      color: "#10ABB4",
                      fontSize: "13px",
                    }}
                  >
                    <strong>
                      <strong style={{ fontSize: "18px" }}>🚚📦</strong>{" "}
                      Delivery Status:{" "}
                    </strong>
                    {order?.delivery_status === "PROCESSING" ? (
                      <Message variant="info">Processing</Message>
                    ) : order?.delivery_status === "ASSIGNED" ? (
                      <Message variant="info">Assigned</Message>
                    ) : order?.delivery_status === "DISPATCHED" ? (
                      <Message variant="info">Dispatched</Message>
                    ) : order?.delivery_status === "DELIVERED" ? (
                      <Message variant="success" color="#010221">
                        Delivered
                      </Message>
                    ) : order?.delivery_status === "REJECTED" ? (
                      <Message variant="danger">Rejected</Message>
                    ) : (
                      <Message variant="danger">
                        Error while managing order!{" "}
                      </Message>
                    )}
                  </Typography>
                  <Typography
                    className={classes.messageText}
                    component="div"
                    style={{
                      color: "#10ABB4",
                      fontSize: "13px",
                    }}
                  >
                    <strong>
                      <strong style={{ fontSize: "19px" }}>💰</strong> Payment
                      Status:{" "}
                    </strong>
                    {order?.isPaid ? (
                      <Message variant="success" color="#042940">
                        Paid 💰💰🤑
                      </Message>
                    ) : (
                      <Message variant="danger">Not Paid</Message>
                    )}
                  </Typography>
                  {order?.paymentResult &&
                    <ul className={classes.list}>
                      {paymentDetails.map((detail, index) => (
                        <li key={index} className={classes.listItem}>
                          <Typography
                            className={classes.messageText}
                            component="div"
                            style={{ color: "#10ABB4", fontSize: "13px" }}>
                            {detail}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  }
                </Card>
                {/* </div> */}
              </div>
            </Paper>
          )}
        </div>
      </Container>
    </>
  );
};

export default OrderDetailsCard;
