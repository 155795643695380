import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar } from "@material-ui/core";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";

// import rabbit from "../../assets/img/bannyAuth.png";
import lusukuLogo517 from "../../assets/img/lusukuLogo517.png";
import { Image } from "react-bootstrap";
import { Alert } from "@material-ui/lab";
import { Link } from "react-router-dom";

const AgentPasswordResetRequestScreen = () => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [serverResponse, setServerResponse] = useState("");

  const AgentPasswordResetRequestSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await fetch("/user/post-reset-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      const data = await response.json();
      console.log(data, "data");
      setServerResponse(data);
      setSubmitting(false);
      setSuccess(true);
    } catch (error) {
      console.log(error, "error");
      setSubmitting(false);
      setSuccess(false);
    }
  };

  if (success) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="card">
              <div className="card-body">
                <h4 className="text-center">Password Reset Request Status</h4>
                <hr />
                {/* <p className="text-center">{serverResponse.message}</p> */}
                {serverResponse.message ===
                "An email has been sent with instructions to reset your password." ? (
                  <Alert severity="info">{serverResponse.message}</Alert>
                ) : (
                  <div className="text-center">
                    <Alert severity="error">{serverResponse.message}</Alert>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <AppBar position="static" color="inherit">
          <Toolbar>
            <Link to="/">
              <Image
                width="80"
                height="85"
                className="d-inline-block align-top pb-2 mt-2"
                src={lusukuLogo517}
                alt="logo"
              />
            </Link>
          </Toolbar>
        </AppBar>
      </div>
      <div
        className="container"
        style={{
          marginTop: "100px",
          width: "500px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <h1 style={{ fontSize: 30, fontWeight: 300 }}>
          Lusuku Password Reset Request!
        </h1>
        <Formik
          initialValues={{
            email: "",
            // url: "http://localhost:3000/agent-reset-password",
            // url: "https://lusuku.shop/agent-reset-password",
            url: `${process.env.REACT_APP_DASHBOARD_PROD_URL}/agent-reset-password`,
          }}
          validationSchema={AgentPasswordResetRequestSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            // handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field
                  type="email"
                  name="email"
                  id="email"
                  autoCapitalize="none"
                  autoCorrect="off"
                  placeholder="Enter your email address..."
                  className={
                    "form-control" +
                    (errors.email && touched.email ? " is-invalid" : "")
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  values={values.email}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default AgentPasswordResetRequestScreen;
