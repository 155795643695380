import React, { useEffect, useState } from "react";
import { Divider, Grid, Switch, Typography } from "@material-ui/core";
import moment from "moment";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Chip } from "@material-ui/core";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import TimelapseIcon from "@material-ui/icons/Timelapse";

import useStyles from "./listingDetailsStyles.js";
import {
  addToCart,
  // getListingCart,
  removeFromCart,
} from "../../../actions/cartActions.js";
import {
  deactivateSku,
  getListing,
  reactivateSku,
  verifySellerListing,
  unverifySellerListing,
} from "../../../actions/listingsActions.js";
import DOMPurify from "dompurify";
import { getUserFromLocalStorage } from "utilities/user.js";

const ListingDetails = ({ history, match }) => {
  // const [user] = useState(JSON.parse(localStorage.getItem("profile")));
  const user = getUserFromLocalStorage();

  const listingID = match?.params?.id;

  const [qty, setQty] = useState(1);
  const dispatch = useDispatch();

  const listings = useSelector((state) => state.listings);
  const { isLoading: isLoadingListing, listing } = listings;

  // const vendorName = listing?.creator?.businessName || "In-house";
  const vendorName = listing?.creator?.businessName || listing?.vendorName;
  const vendorContact =
    listing?.creator?.businessPhone?.toString() || listing?.vendorContactNumber;
  const operationMode = listing?.creator?.operationMode || "Lusuku Listing";
  // console.log(listing?.active, "Categories for this  Listing");

  const { cartItems } = useSelector((state) => state.cart);

  const classes = useStyles();

  useEffect(() => {
    dispatch(getListing(listingID));
  }, [listingID, listing?.verifyListing]);

  // const { id } = useParams()

  const addToCartHandler = () => {
    dispatch(
      addToCart(
        listing._id,
        qty,
        // user?.result?._id,
        listing?.creator?._id, // Vendor who owns/uploaded the product
        listing?.unit_id?._id // Unit of the product
      )
    );
    history.push(`/admin/cart-page/${listing._id}?qty=${qty}`);
    // dispatch(addToCart(product._id, qty))
    // history.push(`/admin/cart-page`)
  };

  const isInCart = (prod) => {
    if (cartItems?.filter((item) => item?._id === prod._id).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  // Deactivate SKU
  const handleSkuDeactivation = () => {
    dispatch(deactivateSku(listing._id, user?.result?._id));
  };

  // Reactivate SKU
  const handleSkuReactivation = () => {
    dispatch(reactivateSku(listing._id, user?.result?._id));
  };

  const handleSellerListingVerification = () => {
    if (listing?.verifyListing === "VERIFIED") {
      dispatch(
        unverifySellerListing(listing._id, user?.result?._id, {
          verifyListing: "PENDING",
        })
      );
    } else {
      dispatch(
        verifySellerListing(listing._id, user?.result?._id, {
          verifyListing: "VERIFIED",
        })
      );
    }
  };

  const safeDescription = DOMPurify.sanitize(listing?.description || '');


  return (
    <>
      {listing?.length === 0 || listing === null ? (
        <table>
          <tbody>
            <tr>
              <td
                colSpan={12}
                style={{
                  textAlign: "left",
                  color: "#9008",
                  fontStyle: "italic",
                  fontSize: "1rem",
                }}
              >
                Product not available..
              </td>
            </tr>
          </tbody>
        </table>
      ) : listing?.countInStock < 0 ? (
        <div
          style={{
            marginTop: "70px",
            top: "50%",
            left: "50%",
            position: "fixed",
          }}
        >
          <Typography
            variant="h5"
            style={{
              color: "red",
              fontWeight: "bold",
            }}
          >
            Out of Stock
          </Typography>
        </div>
      ) : (
        <>
          <Paper
            style={{ padding: "20px", borderRadius: "15px" }}
            elevation={6}
          >
            {isLoadingListing ? (
              <div
                style={{
                  marginTop: "70px",
                  top: "50%",
                  left: "50%",
                  position: "fixed",
                }}
              >
                <Spinner animation="grow" size="lg" variant="primary" />
              </div>
            ) : (
              <div className={classes.card}>
                <div className={classes.section}>
                  <Typography variant="h5" elevation={4}>
                    <strong style={{ color: "#195400" }}>
                      {listing?.title}
                    </strong>
                  </Typography>
                  {/* <Typography gutterBottom variant="h6" color="textSecondary" component="h2">{listing?.tags.map((tag) => `#${tag} `)}</Typography> */}
                  <Divider style={{ margin: "20px 0" }} />
                  <Typography variant="body2">
                    <strong>Product SKU:</strong> {listing?._id}
                  </Typography>
                  <Typography variant="body2">
                    <strong>In Stock: </strong>
                    {listing?.countInStock} Units
                  </Typography>
                  {/* <Typography variant="body2">
                    <strong> Description:</strong>{" "}
                    {listing?.description
                      ? listing?.description
                      : "Non Provided"}
                  </Typography> */}
                  <Typography variant="body2">
                    <strong>Description:</strong>{" "}
                    {/* {listing?.description ? (
                        <span dangerouslySetInnerHTML={{ __html: listing.description }} />
                      ) : (
                        "Non Provided"
                      )} */}

                    {safeDescription ? (
                      <span dangerouslySetInnerHTML={{ __html: safeDescription }} />
                    ) : (
                      "Non Provided"
                    )}
                  </Typography>
                  <Typography variant="body2">
                    <strong> Measure:</strong>{" "}
                    {listing?.unit_id?.unit
                      ? listing?.unit_id?.unit
                      : "Non Provided"}
                  </Typography>{" "}
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} lg={4} md={6} xl={6}>
                      <Paper className={classes.categoryChipStyle}>
                        <Typography
                          component={"span"}
                          variant={"body2"}
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          <strong>Associated Categories:</strong>{" "}
                          {listing?.category?.map((data) => {
                            return (
                              <li key={data._id}>
                                <Chip
                                  icon={
                                    data.active_status === "Active" ? (
                                      <RadioButtonCheckedIcon
                                        style={{
                                          color: "green",
                                        }}
                                      />
                                    ) : (
                                      <RemoveCircleIcon />
                                    )
                                  }
                                  label={data.category}
                                  className={classes.chip}
                                />
                              </li>
                            );
                          })}
                          {/* </span> */}
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Divider style={{ margin: "20px 0" }} />
                  <Grid item xs={6} sm={6} lg={4} md={6} xl={6}>
                    {/* <Paper className={classes.categoryChipStyle}> */}
                    <Typography
                      component={"span"}
                      variant={"body2"}
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      <strong>Listing Status:</strong>{" "}
                      {listing?.active ? "Sku Live" : "Deactivated"}
                      {listing?.active ? (
                        <span>
                          <Switch
                            // checked={true}
                            checked={listing?.active ? true : false}
                            onChange={handleSkuDeactivation}
                          />
                        </span>
                      ) : (
                        <span>
                          <Switch
                            checked={false}
                            onChange={handleSkuReactivation}
                          />
                        </span>
                      )}
                    </Typography>
                    {/* </Paper> */}
                    <Divider style={{ margin: "10px 0" }} />
                  </Grid>
                  {/* Switch for activating a listing */}
                  <Typography variant="body2">
                    {listing?.verifyListing === "VERIFIED" ? (
                      <strong>
                        <i>
                          <CheckCircleIcon
                            style={{
                              color: "#419E0B",
                              fontSize: 19,
                            }}
                          />
                          Verified:
                        </i>
                      </strong>
                    ) : (
                      <strong>Verify Listing:</strong>
                    )}

                    <Switch
                      checked={listing?.verifyListing === "VERIFIED"}
                      onChange={handleSellerListingVerification}
                    // disabled={listing?.verifyListing === "PENDING"}
                    />
                  </Typography>
                  <Divider style={{ margin: "20px 0" }} />
                  <Typography variant="body2">
                    <strong>Uploaded:</strong> {moment(listing?.createdAt).fromNow()}
                    <br />
                    <strong>Vendor:</strong> {vendorName}
                    <br />
                    <strong>Contact:</strong> {vendorContact}
                    <br />
                    <strong>Mode:</strong> {operationMode}
                    <br />
                    {listing?.vendorAddress && (
                      <>
                        <strong>Vendor Address:</strong> {listing?.vendorAddress}
                        <br />
                      </>
                    )}
                  </Typography>

                  <Divider style={{ margin: "20px 0" }} />
                  <div>
                    <Typography>
                      Price: Shs {""}
                      <span>
                        {listing?.price
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </span>
                    </Typography>

                    <Typography>
                      Availability:{" "}
                      <span style={{ color: "#1D438A" }}>
                        {listing?.countInStock > 0
                          ? "In Stock"
                          : "Out of Stock"}
                      </span>
                    </Typography>
                    {isInCart(listing) ? (
                      <Chip
                        label="In Cart"
                        // color="secondary"
                        className={classes.inCartChip}
                        size="small"
                        style={{ marginRight: "10px" }}
                        onClick={() => removeFromCartHandler(listing?._id)}
                      />
                    ) : (
                      <Col md={4}>
                        <Grid item xs={6}>
                          <Row>
                            <Form.Group>
                              <label>Qty</label>
                              <Form.Select
                                // aria-label="Default select example"
                                value={qty}
                                onChange={(e) => setQty(e.target.value)}
                              >
                                {[...Array(listing?.countInStock).keys()].map(
                                  (x) => (
                                    <option key={x + 1} value={x + 1}>
                                      {x + 1}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                              <Button
                                variant="danger"
                                type="button"
                                onClick={addToCartHandler}
                              >
                                {""}Add To Cart
                              </Button>
                            </Form.Group>
                          </Row>
                        </Grid>
                      </Col>
                    )}
                  </div>
                </div>

                <Grid item xs={8} sm={8} lg={4} md={8} xl={8}>
                  <div className={classes.imageSection}>
                    <img
                      className={classes.media}
                      src={
                        listing?.product_image ||
                        "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
                      }
                      alt={listing?.title}
                      width="100%"
                      height="100%"
                    />
                  </div>
                </Grid>
                {listing?.verifyListing === "VERIFIED" ? (
                  <VerifiedUserIcon
                    style={{ color: "#1DA1F2", fontSize: 28 }}
                  />
                ) : (
                  <TimelapseIcon style={{ color: "#FF2976", fontSize: 27 }} />
                )}
              </div>
            )}
          </Paper>
        </>
      )}
    </>
  );
};

export default ListingDetails;
