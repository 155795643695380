import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
// import { createSiUnit, updateSiUnit } from "../../actions/siUnitActions";
import { RESET_SHIPPING_COST } from "../../constants/shippingCostsConstants";
import {
  createShippingCost,
  updateShippingCost,
} from "../../actions/shippingCostActions";
import { getUserFromLocalStorage } from "utilities/user";

toast.configure();

const ShippingCostForm = () => {
  const user = getUserFromLocalStorage();
  const { shippingCost, loading } = useSelector((state) => state.shippingCosts);

  // console.log(loading, "loading........");
  const [postData, setPostData] = useState({
    cost: "",
    shippingCostDescription: "",
  });

  const userId = user?.result?._id;
  const userRole = user?.result?.user_role;

  const clear = () => {
    setPostData({ cost: "", shippingCostDescription: "" });
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!shippingCost?._id) {
      dispatch(
        createShippingCost(
          { userId, userRole, ...postData },
          history.push("/shippingCost"),
          toast.success("Shipping Cost Added!"),
          clear()
        )
      );
      // clear();
    } else {
      dispatch(
        updateShippingCost(
          shippingCost?._id,
          { ...postData },
          history.push("/shippingCost"),
          toast.success("Shipping Cost Updated!")
          // clear()
        )
      );
      dispatch({ type: RESET_SHIPPING_COST });
    }
  };

  useEffect(() => {
    if (shippingCost?._id) {
      setPostData(shippingCost);
    } else {
      clear();
    }
  }, [shippingCost]);

  return (
    <>
      {loading ? (
        <Spinner
          animation="grow"
          variant="danger"
          style={{
            marginTop: "70px",
            top: "50%",
            left: "50%",
            position: "fixed",
          }}
        />
      ) : (
        <Col>
          <Form id="deliveryZone" autoComplete="off" onSubmit={handleSubmit}>
            <Card>
              <Card.Title className="pt-2 p-2" as="h4">
                Add || Edit Shipping Cost
              </Card.Title>
              <Card.Body className="pt-0">
                <Col md={6}>
                  <Form.Group>
                    <Card.Body>
                      <label>Cost</label>
                      <Form.Control
                        name="unit"
                        type="text"
                        placeholder="Add Unit..."
                        value={postData.cost}
                        onChange={(e) =>
                          setPostData({ ...postData, cost: e.target.value })
                        }
                      ></Form.Control>
                      <label>Shipping Cost Description</label>
                      <Form.Control
                        name="shippingCostDescription"
                        type="text"
                        placeholder="Add Shipping Cost Description..."
                        value={postData.shippingCostDescription}
                        onChange={(e) =>
                          setPostData({
                            ...postData,
                            shippingCostDescription: e.target.value,
                          })
                        }
                      ></Form.Control>
                    </Card.Body>
                  </Form.Group>
                </Col>
              </Card.Body>

              <Card.Body>
                <Card.Footer className="text-right">
                  <Button
                    className="btn-fill pull-right"
                    variant="danger"
                    onClick={clear}
                    style={{ marginRight: "5px" }}
                  >
                    Clear
                  </Button>
                  <Button
                    className="btn-fill pull-right"
                    variant="info"
                    type="submit"
                    disabled={postData.cost === ""}
                  >
                    Submit
                  </Button>
                  <div className="clearfix"></div>
                </Card.Footer>
              </Card.Body>
            </Card>
          </Form>
        </Col>
      )}
    </>
  );
};

export default ShippingCostForm;
