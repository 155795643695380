import React from "react";
import { TextField, Grid, InputAdornment, IconButton } from "@material-ui/core";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const InputPasswordToggle = ({
  name,
  handleChange,
  label,
  half,
  autoFocus,
  type,
  handleShowPassword,
}) => (
  <Grid item xs={12} sm={half ? 6 : 12}>
    <TextField
      name={name}
      onChange={handleChange}
      variant="filled"
      required
      fullWidth
      label={label}
      autoFocus={autoFocus}
      InputLabelProps={{ style: { color: "#f48" } }} // font color of input label
      inputProps={{ style: { color: "#ffff" } }}
      type={type}
      InputProps={
        name === "password"
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={{ color: "#D3D3D3" }}
                    onClick={handleShowPassword}
                  >
                    {type === "password" ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }
          : null
      }
    />
  </Grid>
);

export default InputPasswordToggle;
