import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Spinner, Container, Row, Col, Modal, Card } from 'react-bootstrap';
import { getRestaurantRiders, deleteRestaurantRider, updateRiderStatus } from '../../actions/ridersAction';
import { getUserFromLocalStorage } from 'utilities/user';
import { FaPlus } from 'react-icons/fa';
import RestaurantRiderForm from '../../views/Forms/RestaurantRiderForm.js';
import { FiberManualRecord as FiberManualRecordIcon, Wifi, WifiOff } from '@material-ui/icons';
import io from 'socket.io-client';

const getStatusColor = (status) => {
    switch (status) {
        case "Online":
            return "green";
        case "Offline":
            return "red";
        case "Busy":
            return "orange";
        case "inTransit":
            return "blue";
        default:
            return "gray";
    }
};

const RestaurantRidersTable = () => {
    const dispatch = useDispatch();
    const { riders, loading } = useSelector((state) => state.riders);
    const user = getUserFromLocalStorage();
    const vendorId = user?.result?._id;
    const [showRiderForm, setShowRiderForm] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [riderToDelete, setRiderToDelete] = useState(null);
    const [riderToEdit, setRiderToEdit] = useState(null);
    const [riderName, setRiderName] = useState("");
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        if (vendorId) {
            dispatch(getRestaurantRiders(vendorId));
        }
    }, [dispatch, vendorId]);

    useEffect(() => {
        const socket = io(process.env.REACT_APP_SERVER_URL);
        socket.on('connect', () => {
            console.log('Connected to server');
        });

        socket.on('riderStatusUpdate', (statusUpdate) => {
            dispatch(updateRiderStatus(statusUpdate));
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from server');
        });

        socket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });

        socket.on('reconnect_error', (error) => {
            console.error('Socket reconnection error:', error);
        });

        return () => {
            socket.disconnect();
        };
    }, [dispatch]);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call once to set initial state

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDelete = () => {
        dispatch(deleteRestaurantRider(vendorId, riderToDelete));
        setShowConfirmDialog(false);
    };

    const handleShowRiderForm = (rider = null) => {
        setRiderToEdit(rider);
        setShowRiderForm(true);
    };

    const handleCloseRiderForm = () => {
        setRiderToEdit(null);
        setShowRiderForm(false);
    };

    const confirmDelete = (riderId, name) => {
        setRiderToDelete(riderId);
        setRiderName(name);
        setShowConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setShowConfirmDialog(false);
        setRiderToDelete(null);
        setRiderName("");
    };

    const onlineRidersCount = riders.filter(rider => rider.onlineStatus === "Online").length;
    const offlineRidersCount = riders.filter(rider => rider.onlineStatus === "Offline").length;

    return (
        <Container>
            <Row className="mb-3">
                <Col>
                    <h2>{user?.result?.businessTradingName || user?.result.businessName} Riders</h2>
                    <Button variant="primary" onClick={() => handleShowRiderForm()}>
                        <FaPlus /> Add Rider
                    </Button>
                </Col>
            </Row>
            {loading ? (
                <Spinner
                    animation="border"
                    style={{
                        marginTop: "70px",
                        top: "50%",
                        left: "50%",
                        position: "fixed",
                    }}
                />
            ) : riders.length === 0 ? (
                <Row>
                    <Col>
                        <p>No riders found. Click the button above to add in-house rider for {user?.result?.businessTradingName || user?.result.businessName}</p>
                    </Col>
                </Row>
            ) : (
                <>
                    <Row className="d-flex justify-content-between align-items-center mb-3">
                        <Col xs={6}>
                            <p>
                                <Wifi style={{ color: "green" }} /> {onlineRidersCount} Online
                            </p>
                            <p>
                                <WifiOff style={{ color: "red" }} /> {offlineRidersCount} Offline
                            </p>
                        </Col>
                    </Row>
                    {isSmallScreen ? (
                        <Row>
                            {riders.map((rider, index) => (
                                <Col xs={12} key={rider._id} className="mb-4">
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>{`${index + 1}. ${rider.firstName} ${rider.lastName}`}</Card.Title>
                                            <Card.Text>
                                                <strong>Mobile:</strong> {rider.mobile}
                                            </Card.Text>
                                            <Card.Text>
                                                <strong>Email:</strong> {rider.email}
                                            </Card.Text>
                                            <Card.Text>
                                                <strong>Carrier Type:</strong> {rider.carrierType}
                                            </Card.Text>
                                            <Card.Text className="text-center">
                                                {rider.onlineStatus} <FiberManualRecordIcon style={{ color: getStatusColor(rider.onlineStatus) }} />
                                            </Card.Text>
                                            <Button
                                                variant="primary"
                                                onClick={() => handleShowRiderForm(rider)}
                                                className="mr-2"
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                variant="danger"
                                                onClick={() => confirmDelete(rider._id, `${rider.firstName} ${rider.lastName}`)}
                                            >
                                                Delete
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Carrier Type</th>
                                    <th className="text-center">Online Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {riders.map((rider, index) => (
                                    <tr key={rider._id}>
                                        <td>{index + 1}</td>
                                        <td>{`${rider.firstName} ${rider.lastName}`}</td>
                                        <td>{rider.mobile}</td>
                                        <td>{rider.email}</td>
                                        <td>{rider.carrierType}</td>
                                        <td className="text-center">
                                            {rider.onlineStatus} <FiberManualRecordIcon style={{ color: getStatusColor(rider.onlineStatus) }} />
                                        </td>
                                        <td>
                                            <Button
                                                variant="primary"
                                                onClick={() => handleShowRiderForm(rider)}
                                                className="mr-2"
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                variant="danger"
                                                onClick={() => confirmDelete(rider._id, `${rider.firstName} ${rider.lastName}`)}
                                            >
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </>
            )}
            <RestaurantRiderForm
                show={showRiderForm}
                handleClose={handleCloseRiderForm}
                vendorId={vendorId}
                riderToEdit={riderToEdit}
            />

            <Modal show={showConfirmDialog} onHide={handleCloseConfirmDialog}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete {riderName}?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirmDialog}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default RestaurantRidersTable;